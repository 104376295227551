<!-- Main -->
<section class="main-session">
    <img src="assets/img/banner-images/Procure.webp" loading="lazy" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="heading">
                    <h1>
                        Truventor <br> Materials <span class="star">*</span>
                        <!-- <span class="span-with-size">(Currently Available In India Only)</span>   -->
                        <!-- ment of <br> Raw Materials -->
                    </h1>
                    <p class="mt-4">
                        Save up to 7% on raw material purchases with Truventor money</p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="https://truventor.typeform.com/to/WXmMncZt" target="_blank" rel="noopener" class="btn btn-primary w-100">
                    Check Rates </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <p class="ref">*Currently Available In India Only</p>
            <div class="border-line">
            </div>
        </div>

    </div>
</section>



<!-- Save On Every Raw Material Purchase -->
<section class="save-on-every-raw-material-purchase global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-5">
                    Save on every <br> raw material <br> purchase
                </h1>

            </div>
            <div class="col col-12 col-md-5">

                <!-- One -->
                <div class="row">
                    <div class="col col-0 col-md-2 d-none d-md-block p-0">
                        <div class="d-flex justify-content-end">
                            <h2> 1. </h2>
                        </div>
                    </div>
                    <div class="col col-12 col-md-10">
                        <h2 class="font-weight-bold">
                            <span class="d-md-none">1.</span> Get volume discounts
                        </h2>
                        <p class="mt-3 mb-5">
                            We aggregate the raw material requirements from several job shops and fulfil them through our network of 100+ traders / distributors across India, identifying best market rate, logistics cost and turnaround time. We do not charge anything in between and
                            pass on the benefit transparently to you.
                        </p>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col col-0 col-md-2 d-none d-md-block p-0">
                        <div class="d-flex justify-content-end">
                            <h2> 2. </h2>
                        </div>
                    </div>
                    <div class="col col-12 col-md-10">
                        <h2 class="font-weight-bold">
                            <span class="d-md-none">2.</span> Affordable raw material finance
                        </h2>
                        <p class="mt-3 mb-5">
                            Truventor.Money provides access to capital to buy raw material at the best rate. We finance your raw material to bring cost savings upto 4%.
                        </p>
                        <div class="row justify-content-center justify-content-md-start">
                            <div class="col-10 col-md-8 col-lg-6">
                                <a class="btn w-100" [routerLink]="['/finance']"> Check Rates
                                </a>

                                <!-- <a [routerLink]="['/finance']" class="btn btn-primary w-100"> Apply Now </a> -->
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Quality Plus Delivery Assured  -->
<section class="quality-plus-delivery-assured global-space-between">
    <div class="container">

        <!-- One -->
        <div class="row justify-content-end align-items-center py-5">
            <div class="col-md-6 pr-md-5">
                <h2 class="font-weight-bold">
                    Quality plus delivery <br> assured
                </h2>
                <!-- Mobile Image -->
                <div class="d-block d-md-none">
                    <img src="assets/img/procure/Quality Plus Delivery Assured.jpg" loading="lazy" class="img-fluid w-100" alt="Truventor">
                </div>
                <p class="mt-4" style="margin-bottom: 60px;">
                    We ensure that the material is delivered to you at your doorstep. In case of any quality issues, we take the material back, No questions asked.
                </p>
                <div class="row justify-content-center justify-content-md-start m-0">
                    <div class="col-10 col-md-6 p-0">
                        <a href="https://truventor.typeform.com/to/WXmMncZt" target="_blank" rel="noopener" class="btn btn-primary w-100"> Apply Now </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6 pr-0 d-none d-md-block">
                <img src="assets/img/procure/Quality Plus Delivery Assured.jpg" loading="lazy" class="img-fluid w-100" alt="Truventor">
            </div>
        </div>

    </div>
</section>





<!-- What We Supply?  -->
<section class="cnc-capabilities global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <!-- Title -->
                    <div class="row align-items-start">
                        <div class="col-12 col-md-4">
                            <h1 class="py-3"> What we supply? </h1>
                        </div>
                        <div class="col-12 col-md-8">
                            <hr class="mt-0">
                            <p>
                                Flat and long form of steel, SS, aluminium, copper and other engineering grade materials
                            </p>
                        </div>
                    </div>

                    <div class="row row-cols-2 row-cols-md-3">

                        <!-- Coils -->
                        <div class="col p-3">
                            <div class="card h-100 ">
                                <img src="./assets/img/procure/Coils@2x.jpg" loading="lazy" class="w-100 p-3 bg-secondary card-img-top" alt="Truventor">
                                <div class="card-body bg-dark p-3 w-100">
                                    <h3 class="text-white">
                                        Coils
                                    </h3>
                                </div>
                            </div>

                        </div>

                        <!-- Sheets / Plates -->
                        <div class="col p-3">
                            <div class="card h-100 ">
                                <img src="./assets/img/procure/SheetsPlates@2x.jpg" loading="lazy" class="w-100 p-3 bg-secondary card-img-top" alt="Truventor">
                                <div class="card-body bg-dark p-3 w-100">
                                    <h3 class="text-white">
                                        Sheets / Plates
                                    </h3>
                                </div>
                            </div>

                        </div>

                        <!-- Flats -->
                        <div class="col p-3">
                            <div class="card h-100 ">
                                <img src="./assets/img/procure/Flats@2x.jpg" loading="lazy" class="w-100 p-3 bg-secondary card-img-top" alt="Truventor">
                                <div class="card-body bg-dark p-3 w-100">
                                    <h3 class="text-white">
                                        Flats
                                    </h3>
                                </div>
                            </div>

                        </div>

                        <!-- Angles & Channels -->
                        <div class="col p-3">
                            <div class="card h-100 ">
                                <img src="./assets/img/procure/Angles & Channels@2x.jpg" loading="lazy" class="w-100 p-3 bg-secondary card-img-top" alt="Truventor">
                                <div class="card-body bg-dark p-3 w-100">
                                    <h3 class="text-white">
                                        Angles and channels
                                    </h3>
                                </div>
                            </div>

                        </div>

                        <!-- Pipes Round / Square -->
                        <div class="col p-3">
                            <div class="card h-100 ">
                                <img src="./assets/img/procure/Pipes@2x.jpg" loading="lazy" class="w-100 p-3 bg-secondary card-img-top" alt="Truventor">
                                <div class="card-body bg-dark p-3 w-100">
                                    <h3 class="text-white">
                                        Pipes round / Square
                                    </h3>
                                </div>
                            </div>

                        </div>

                        <!-- Bars Square / Round / Hex -->
                        <div class="col p-3">
                            <div class="card h-100 ">
                                <img src="./assets/img/procure/Bars@2x.jpg" loading="lazy" class="w-100 p-3 bg-secondary card-img-top" alt="Truventor">
                                <div class="card-body bg-dark p-3 w-100">
                                    <h3 class="text-white">
                                        Bars square / Round / Hex
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- How it Works? -->
<section class="how-it-works global-space-between">
    <div class="container">
        <div class="row d-flex justify-content-between">
            <div class="col-12 col-md-5">
                <div class="content ">
                    <h1>How it works?</h1>
                    <p>
                        Simple and easy four steps to get started to work with truventor team.
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row row-cols-2 row-cols-md-2">

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <h5>
                            Submit RFQ with company’s basic profile
                        </h5>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <h5>
                            Receive quotation within 24 working hours
                        </h5>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <h5>
                            Approval and payment
                        </h5>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <h5>
                            Delivery within 2-3 working days
                        </h5>
                    </div>

                </div>

                <!-- Button -->
                <div class="row justify-content-center justify-content-md-start">
                    <div class="col-10 col-md-6">
                        <a  [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100"> Get In Touch </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Frequently Asked Questions -->
<section class="procurement-faq global-space-between bottom-end-padding">
    <div class="container">

        <div class="row">
            <div class="col">

                <h1 class="text-left pb-5">
                    Frequently asked questions
                </h1>

                <!-- one -->
                <div class="card">
                    <div class="card-head" id="heading-one">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-one" aria-expanded="true" aria-controls="faq-one">
                            Who will deliver the raw material?
                        </h2>
                    </div>
                    <div id="faq-one" class="collapse show" aria-labelledby="heading-one">
                        <div class="card-body">
                            Truventor delivers the material at your doorstep. We take complete responsibility of end-to-end supply chain to ensure quality and on-time delivery.
                        </div>
                    </div>
                </div>

                <!-- two -->
                <div class="card">
                    <div class="card-head" id="heading-two">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-two" aria-expanded="true" aria-controls="faq-two">
                            What are the payment terms of raw materials?
                        </h2>
                    </div>
                    <div id="faq-two" class="collapse show" aria-labelledby="heading-two">
                        <div class="card-body">
                            <p>
                                We charge 100% advance for all raw materials we supply. However, if you would like to access credit from us, you may apply for line of credit on our platform. <a [routerLink]="['/procurement']">Click here</a> to know more.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- three -->
                <div class="card">
                    <div class="card-head" id="heading-three">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-three" aria-expanded="true" aria-controls="faq-three">
                            How raw material from Truventor is cheaper than the market rate?
                        </h2>
                    </div>
                    <div id="faq-three" class="collapse show" aria-labelledby="heading-three">
                        <div class="card-body">
                            <p>
                                We aggregate requirements from several job-shops on daily basis and place the bulk orders to our partners. This way we are able to get better rates and we pass the benefit to you. We do not keep any margin for ourselves. This way you can save upto 3%
                                annually.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- four -->
                <div class="card">
                    <div class="card-head" id="heading-four">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-four" aria-expanded="true" aria-controls="faq-four">
                            How to get best rates from Truventor?
                        </h2>
                    </div>
                    <div id="faq-four" class="collapse show" aria-labelledby="heading-four">
                        <div class="card-body">
                            <p>
                                Our business model is to aggregate and supply. To achieve best rates from us, we request you to share your historical buying requirements. We can provide much better rates using the data points you share.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- five -->
                <div class="card">
                    <div class="card-head" id="heading-five">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-five" aria-expanded="true" aria-controls="faq-five">
                            Will I get quality certification for material? What happens in case of quality issues with raw material?
                        </h2>
                    </div>
                    <div id="faq-five" class="collapse show" aria-labelledby="heading-five">
                        <div class="card-body">
                            <p>
                                Yes. We provide TC (Test Certificate) for all the raw materials we supply. In case of any issues with the supply, we take the complete responsibility. We pick up the order from your doorstep and also refund the money within 48 working hours.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- six -->
                <div class="card">
                    <div class="card-head" id="heading-six">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-six" aria-expanded="true" aria-controls="faq-six">
                            Do I need line of credit to buy raw material from Truventor?
                        </h2>
                    </div>
                    <div id="faq-six" class="collapse show" aria-labelledby="heading-six">
                        <div class="card-body">
                            <p>
                                No. Line of credit is an optional service for our customers. However, to ensure best rates we charge 100% advance on all orders. You may apply for the line of credit if you do not have the flexibility to pay 100% advance and can get credit upto 90 days.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- seven -->
                <div class="card">
                    <div class="card-head" id="heading-seven">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-seven" aria-expanded="true" aria-controls="faq-seven">
                            I am located outside India. Can I still order raw material on the platform?
                        </h2>
                    </div>
                    <div id="faq-seven" class="collapse show" aria-labelledby="heading-seven">
                        <div class="card-body">
                            <p>
                                Yes. You can place an RFQ on our platform even if you are located outside India. However, we will only supply material from traders / distributors located in India.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</section>


<!-- Get Connected With Our Expert Team for More Information -->
<section class="">
    <div class="container">
        <div class="row">
            <!-- Image -->
            <div class="col-md-6 p-4 d-flex justify-content-center justify-content-md-left">
                <img src="./assets/img/supplier/man_on_call_cta@2x.png" loading="lazy" class="img-fluid" alt="contact image">
            </div>
            <!-- Text -->
            <div class="col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                <div class="col-sm-12 col-md-12 pl-md-5">
                    <h1 class="text-center text-md-left">
                        Get connected with our expert team for more information
                    </h1>
                    <div class="action-button mt-4 d-flex justify-content-center justify-content-md-start">
                        <a [routerLink]="['/contact']" class="btn"> Contact Us </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Check out our other offerings -->
<section class="global-space-between bottom-end-padding">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="text-center pb-2 pt-3 pt-md-0 pb-md-5">
                    Check out our other offerings
                </h1>
            </div>
        </div>

        <!-- Truventor Finance -->
        <div class="card border-0">
            <div class="row no-gutters">
                <div class="col-md-5 d-flex justify-content-center">
                    <img src="./assets/img/procure/Truventor Procure@2x.jpg" loading="lazy" class="img-fluid img-border" alt="Truventor">
                </div>
                <div class="col-md-7">
                    <div class="card-body p-0 p-md-5 pt-2">
                        <h5 class="card-title font-weight-bold">Truventor money</h5>
                        <hr class="primary-bg">
                        <div class="row pb-4">
                            <div class="col-12 col-md-6">
                                <p class="card-text">
                                    Flexible collateral free loans for raw material purchases
                                </p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center justify-content-md-start">
                            <button class="btn" [disabled]="true" style="pointer-events: none;"> Coming Soon </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>