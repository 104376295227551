<!-- Main -->
<section class="main-session">
    <img src="assets/img/events/Events_banner image@2x.png" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>
                        Events
                    </h1>
                    <p class="mt-4">
                        Save the day! Here's the list of global events to be held in the coming days.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row mt-5">
            <div class="col-sm-12 col-md-4">
                <a data-toggle="modal" data-target="#subscribeArticle" class="btn btn-primary w-100"> Subscribe </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Events -->
<section class="global-space-between events">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center">
                    Trade show
                </h1>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 mt-4">

            <div class="col mb-4" *ngFor="let data of eventList">
                <a href="{{data.url}}" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="{{data.imgUrl}}" class="card-img-top" alt="Teuventor">
                    <div class="card-body px-3 pt-2">
                        <h4 class="card-title">
                            {{data.name}}
                        </h4>
                        <p>
                            {{data.date}} | {{data.location}}
                        </p>
                        <kbd *ngIf="data?.tags"> {{data?.tags}} </kbd>

                    </div>
                </a>
            </div>


            <div class="col mb-4">
                <a href="https://electronica-india.com/en/" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/events/Electonic india.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3 pt-0">
                        <h4 class="card-title">
                            Electronica India
                        </h4>
                        <p>
                            September 21-23, 2022 | India
                        </p>
                        <kbd> 3D PRINTING </kbd>
                        <kbd> SPOTLIGHT </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.imtex.in/" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/events/IMtex.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3 pt-0">
                        <h4 class="card-title">
                            IMTEX 2022
                        </h4>
                        <p>
                            January 20-26, 2022 | India
                        </p>
                        <kbd> 3D PRINTING </kbd>
                        <kbd> SPOTLIGHT </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.acrex.in/" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/events/Acrex.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3 pt-0">
                        <h4 class="card-title">
                            ACREX
                        </h4>
                        <p>
                            February 17-19, 2022 | Online
                        </p>
                        <kbd> 3D PRINTING </kbd>
                        <kbd> SPOTLIGHT </kbd>
                    </div>
                </a>
            </div>



            <div class="col mb-4">
                <a href="https://www.plastindia.org/" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/events/Plastindia.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3 pt-0">
                        <h4 class="card-title">
                            PlastIndia
                        </h4>
                        <p>
                            Feb 17 21, 2021 | Delhi
                        </p>
                        <kbd> 3D PRINTING </kbd>
                        <kbd> SPOTLIGHT </kbd>
                    </div>
                </a>
            </div>


            <div class="col mb-4">
                <a href="https://www.agritechnica.com/en/" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/events/Agri Techica.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3 pt-0">
                        <h4 class="card-title">
                            AGRITECHNICA
                        </h4>
                        <p>
                            FEB 27- March 5 | Germany
                        </p>
                        <kbd> Machinery </kbd>
                        <kbd> TradeShow </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.metav.com/" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/events/Metav.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3 pt-0">
                        <h4 class="card-title">
                            Metav 2022
                        </h4>
                        <p>
                            March 08-11 | Germany
                        </p>
                        <kbd> Materials </kbd>
                        <kbd> Fabrication </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.tube-tradefair.com/" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/events/Tube.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3 pt-0">
                        <h4 class="card-title">
                            Tube 2022
                        </h4>
                        <p>
                            May 09-13, 2022 | Germany
                        </p>
                        <kbd> Fabrication </kbd>
                        <kbd> Machinery </kbd>
                    </div>
                </a>
            </div>

        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-12 col-md-4">
                <div class="btn w-100"> Show More </div>
            </div>
        </div>
    </div>
</section>

<!-- Last Section -->
<section class="global-space-between bottom-end-padding a-trueventor">
    <div class="container-fluid">
        <div class="row">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-4 p-0 d-none d-md-block">
                <img src="./assets/img/customer_landing/Book a Demo.jpg" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-8 pl-md-5 d-flex align-items-center">
                <div class="w-100">
                    <!-- Title -->
                    <h1>
                        Do you want <br> a free quote <br> in 24 hours?
                    </h1>
                    <!-- Paragraph -->
                    <p class="mt-4 mb-0">
                        Sign up for a free trial and find out how  <br> Truventor makes Manufacturing simple.
                    </p>
                    <!-- Action Buttons -->
                    <div class="row">

                        <div class="col-sm-12 col-md-4">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                                Get In Touch
                            </a>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn w-100">
                                Book A Demo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<!-- Modal -->
<div class="modal fade" id="subscribeArticle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Subscribe</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form method="POST" id="zcampaignOptinForm" action="https://maillist-manage.in/weboptin.zc" target="_zcSignup">

                    <div class="input-group mb-3">
                        <input class="form-control" type="text" placeholder="Email" changeitem="SIGNUP_FORM_FIELD" name="CONTACT_EMAIL" id="EMBED_FORM_EMAIL_LABEL">
                        <div class="input-group-append">
                            <button class="btn btn-primary input-group-text" type="submit" name="SIGNUP_SUBMIT_BUTTON" id="zcWebOptin" value="Subscribe">Subscribe</button>
                        </div>
                    </div>
                    <input type="hidden" id="fieldBorder" value="">
                    <input type="hidden" id="submitType" name="submitType" value="optinCustomView">
                    <input type="hidden" id="emailReportId" name="emailReportId" value="">
                    <input type="hidden" id="formType" name="formType" value="QuickForm">
                    <input type="hidden" name="zx" id="cmpZuid" value="1df8b9e1b0">
                    <input type="hidden" name="zcvers" value="2.0">
                    <input type="hidden" name="oldListIds" id="allCheckedListIds" value="">
                    <input type="hidden" id="mode" name="mode" value="OptinCreateView">
                    <input type="hidden" id="zcld" name="zcld" value="1b9f03965b77f7c">
                    <input type="hidden" id="zctd" name="zctd" value="">
                    <input type="hidden" id="document_domain" value="">
                    <input type="hidden" id="zc_Url" value="unfi.maillist-manage.in">
                    <input type="hidden" id="new_optin_response_in" value="0">
                    <input type="hidden" id="duplicate_optin_response_in" value="0">
                    <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW">
                    <input type="hidden" id="zc_formIx" name="zc_formIx" value="3za006630a1be27fae133925247f132ea6bb8404408ae745fa02b9ce8d99fc4796">
                    <input type="hidden" id="viewFrom" value="URL_ACTION">
                    <input type="hidden" id="scriptless" name="scriptless" value="yes">
                </form>
            </div>

        </div>
    </div>
</div>

<!--Zoho Campaigns Web-Optin Form Starts Here-->

<div id="customForm">
    <div class="quick_form_9_css" name="SIGNUP_BODY">
        <div>
            <span id="SIGNUP_HEADING"></span>
            <div style="position:relative;">
                <div id="Zc_SignupSuccess" style="display:none;position:absolute;margin-left:4%;width:90%;background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154);  margin-top: 10px;margin-bottom:10px;word-break:break-all ">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                        <tbody>
                            <tr>
                                <td width="10%">
                                    <img class="successicon" src="https://unfi.maillist-manage.in/images/challangeiconenable.jpg" align="absmiddle">
                                </td>
                                <td>
                                    <span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px;word-break:break-word">&nbsp;&nbsp;Thank
                                        you for Signing Up</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
<div id="zcOptinOverLay" oncontextmenu="return false" style="display:none;text-align: center; background-color: rgb(0, 0, 0); opacity: 0.5; z-index: 100; position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;">
</div>
<div id="zcOptinSuccessPopup" style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position: fixed; left: 26%;background-color: #FFFFFF;border-color: #E6E6E6; border-style: solid; border-width: 1px;  box-shadow: 0 1px 10px #424242;padding: 35px;">
    <span style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;" id="closeSuccess">
        <img src="https://unfi.maillist-manage.in/images/videoclose.png">
    </span>
    <div id="zcOptinSuccessPanel"></div>
</div>

<!--Zoho Campaigns Web-Optin Form Ends Here-->