import { Component, OnInit } from '@angular/core';
import { AdminService } from '../shared/admin.service';

@Component({
  selector: 'app-supplier-page-not-found',
  templateUrl: './supplier-page-not-found.component.html',
  styleUrls: ['./supplier-page-not-found.component.scss']
})
export class SupplierPageNotFoundComponent implements OnInit {

  constructor(
    private adminservice: AdminService
  ) { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.adminservice.check_customer.next('customer')
  }

}
