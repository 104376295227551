import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rubber-vc3300',
  templateUrl: './rubber-vc3300.component.html',
  styleUrls: ['./rubber-vc3300.component.scss']
})
export class RubberVc3300Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })

  }

}
