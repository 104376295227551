<!-- Main -->
<section class="main-session">
    <img src="assets/img/cnc_metal_capabilities/turning/Mask Group 232.jpg" class="img-banner" loading="lazy" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        CNC <br> Turning
                    </h1>
                    <p class="mt-4">
                        We provide CNC Turning services meeting customer’s specifications within the stipulated time.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- CNC Milling -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    CNC turning
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    We offer CNC Turning services across diameter upto 20 cm. It is primarily used to machine bars of material, which are held in a chuck and rotated while a tool is fed to the piece to remove material to create the profile. We also use a CNC lathe to create
                    features like axial/ radial holes, grooves, threads, slots, etc.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div class="mt-5 mt-md-0">
                    <div class="row align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/cnc_machining_service/tool-steel.png" class="img-fluid mb-3" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                Tool steel, stainless steel
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/cnc_machining_service/titanium.png" class="img-fluid mb-3" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                Aluminum, copper, titanium
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Max part size</th>
                            <td>Max dia. 250 mm</td>
                        </tr>
                        <tr>
                            <th>Accuracy </th>
                            <td>1-500 units</td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>7-15 days</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Types of Milling Operations -->
<section class="side-tab-view-toggle global-space-between">
    <div class="container">

        <!-- Desktop View -->
        <div class="d-none d-md-block">

            <div class="row">
                <div class="col">
                    <h1 class="text-center mb-5">
                        Types of turning processes
                    </h1>
                </div>
            </div>

            <div class="row align-items-center mt-5">
                <div class="col-6">
                    <div class="tab-content" id="main_id">
                        <div class="tab-pane show active" id="slider_1" role="tabpanel" aria-labelledby="slider_1-tab">
                            <img src="./assets/img/cnc_metal_capabilities/turning/Group 20150.jpg" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_2" role="tabpanel" aria-labelledby="slider_2-tab">
                            <img src="./assets/img/cnc_metal_capabilities/turning/Group 20151.jpg" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active" id="slider_1-tab" data-toggle="pill" href="#slider_1" role="tab" aria-controls="slider_1" aria-selected="true">
                            <span class="toggle">
                                Vertical turning centers
                            </span>
                            <hr>
                            <p>
                                Vertical turning centers or vertical turning lathes let gravity reinforce the workpiece’s stability. The weight of the workpiece goes straight down into the machine’s foundation to minimize inaccuracies. The vertical turning orientation reduces the clamping
                                force needed for workpiece holding. This also allows for greater workpiece weight capacity.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_2-tab" data-toggle="pill" href="#slider_2" role="tab" aria-controls="slider_2" aria-selected="false">
                            <span class="toggle">
                                Horizontal turning centre
                            </span>
                            <hr>
                            <p>
                                Horizontal turning centers do not have a fixed spindle but use a variety of heads mounted to a horizontal arbor. These machines accommodate a wider variety of shapes and sizes and gravity works to keeping the workpiece clean during machining. Most CNC
                                horizontal centers have the capacity to incorporate automatic bar-stock feeders.
                            </p>
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <!-- Mobile View -->
        <div class="d-block d-md-none">
            <div class="row">
                <div class="col">

                    <!-- Title -->
                    <h2 class="text-left text-md-left">
                        Types of turning processes
                    </h2>

                    <!-- One -->
                    <div>
                        <img src="./assets/img/cnc_metal_capabilities/turning/Group 20150.jpg" class="img-fluid py-4" width="100%" loading="lazy" alt="Truventor">
                        <h5 class="text-left">
                            Vertical Turning Centers
                        </h5>
                        <hr>
                        <p>
                            Vertical turning centers or vertical turning lathes let gravity reinforce the workpiece’s stability. The weight of the workpiece goes straight down into the machine’s foundation to minimize inaccuracies. The vertical turning orientation reduces the clamping
                            force needed for workpiece holding. This also allows for greater workpiece weight capacity.

                        </p>
                    </div>
                    <!-- Two -->
                    <div>
                        <img src="./assets/img/cnc_metal_capabilities/turning/Group 20151.jpg" class="img-fluid py-4" width="100%" loading="lazy" alt="Truventor">
                        <h5 class="text-left">
                            Horizontal turning centre
                        </h5>
                        <hr>
                        <p>
                            Horizontal turning centre do not have a fixed spindle but use a variety of heads mounted to a horizontal arbor. These machines accommodate a wider variety of shapes and sizes and gravity works to keeping the workpiece clean during machining. Most CNC
                            horizontal centres have the capacity to incorporate automatic bar-stock feeders.
                        </p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>

<!-- Attributes -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1 class="mb-5 mb-md-4">
                        Attributes
                    </h1>
                    <p class="mb-5 mb-md-4">
                        Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                    </p>
                    <!-- Desktop Button -->
                    <div class="d-none d-md-block">
                        <a [routerLink]="['/contact']" class="mt-4 btn px-5"> Get In Touch </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/cnc_metal_capabilities/svg/Overview.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Advantages
                        </h3>
                        <p>
                            Programming a CNC to complete a job creates a consistent and accurate cut to ensure product quality.
                        </p>
                        <p>
                            Higher cost effectiveness
                        </p>
                        <p>
                            CNC turning can churn out hundreds of parts while retaining a consistency in the end product.
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/cnc_metal_capabilities/svg/Application.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Characteristics
                        </h3>
                        <p>
                            Employs single-point cutting tools
                        </p>
                        <p>
                            Rotates workpiece
                        </p>
                        <p>
                            Cutting tool fed along the surface of the workpiece
                        </p>
                        <p>
                            Removes material from the workpiece
                        </p>
                        <p>
                            Produces round or cylindrical parts
                        </p>
                    </div>
                </div>
                <hr>

                <!-- Mobile Button -->
                <div class="px-3 d-block d-md-none">
                    <a [routerLink]="['/contact']" class="mt-4 btn btn-block"> Get In Touch </a>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant CNC turning quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <img src="./assets/img/cnc_metal_capabilities/turning/Group 13311.jpg" class="fluid-img w-100" loading="lazy" alt="Truventor">
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>