<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Low Carbon Steel CR 1008@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Low Carbon <br> Steel 1008
                    </h1>
                    <p class="text-primary">
                        Sheet Metal Fabrication
                    </p>
                    <p class="mt-4">
                        Low carbon steel 1008 provides excellent surface finish and press formability.
                    </p>
                </div>
            </div>
        </div>


        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Low Carbon Steel CR 1008.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    A1008 (AISI 1008) cold rolled (CR) steel sheet exhibits improved formability over hot rolled sheet. ASTM A1008 CR is produced by annealing and temper rolling hot rolled steel sheets. This process provides formability, improved surface texture and flatness.
                    The process of cold rolling the sheet creates a smooth surface which is typically oiled to prevent surface rust from forming. Compared to hot rolled steel, A1008 is known for having excellent weldability and formability.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Low Carbon Steel CR 1008
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Low Carbon Steel CR 1008 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Tensile strength
                            </th>
                            <td>
                                340 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Yield strength
                            </th>
                            <td>
                                285MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Elastic modulus
                            </th>
                            <td>
                                190-210 Gpa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Bulk modulus
                            </th>
                            <td>
                                200 GPa
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            High-strength
                        </p>
                        <p class="mb-2">
                            Good formability
                        </p>
                        <p class="mb-2">
                            Durable and versatile material
                        </p>
                        <hr>
                    </div>
                </div>



                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Home Appliances
                        </p>
                        <p class="mb-2">
                            Exterior Automotive Components
                        </p>
                        <p class="mb-2">
                            Furniture Components
                        </p>
                        <p class="mb-2">
                            Machinery framing
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>