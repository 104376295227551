<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Titanium Grade 3@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Titanium <br> Grade 3
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Grade 3 has good impact properties at low temperatures
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Titanium Grade 3.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Commercially pure Titanium Grade 3 offers slightly higher mechanical properties than Grade 2, while displaying similar ductility and formability. Grade 3 is a general-purpose grade of commercially pure titanium that has excellent corrosion resistance
                    in highly oxidizing to mildly reducing environments, including chlorides, and an excellent strength to weight ratio. This material features the highest ASME allowable design stresses of any commercially pure grade of titanium.

                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Titanium Grade 3
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Titanium Grade 2 3@2x.png" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Tensile strength
                            </th>
                            <td>
                                585 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Yield strength
                            </th>
                            <td>
                                345 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Poisson's ratio
                            </th>
                            <td>
                                0.34-0.40
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Elastic modulus
                            </th>
                            <td>
                                105 - 120 GPa
                            </td>
                        </tr>


                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Excellent strength-to-density-ratio
                        </p>
                        <p class="mb-2">
                            Good Corrosion resistance
                        </p>
                        <p class="mb-2">
                            Higher mechanical strength
                        </p>
                        <p class="mb-2">
                            Excellent weldability
                        </p>
                        <hr>
                    </div>
                </div>






                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Aircraft structural
                        </p>
                        <p class="mb-2">
                            Gaskets
                        </p>
                        <p class="mb-2">
                            Architectural panelling
                        </p>
                        <p class="mb-2">
                            Heat exchanger parts
                        </p>
                        <p class="mb-2">
                            Architectural components
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>