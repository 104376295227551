<div class="not-found">
    <div class="container my-5 py-5">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <div class="d-flex justify-content-center justify-content-md-start">
                    <img src="./assets/img/404/404 Icon.svg" class="img-fluid" alt="Truventor">
                </div>
                <h1>Page not found</h1>
                <p>
                    Oops! The Link You Just Clicked On May Be Broken Or The Page Has Been Removed!
                </p>
                <div class="col-sm-12 col-md-3 p-0">
                    <a href="#" class="btn btn-light w-100"> Go Home </a>
                </div>
            </div>
        </div>
    </div>
</div>