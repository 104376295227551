import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../admin.service';
declare var $: any
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  is_customer: boolean = true;

  constructor(
    private router: Router,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.adminService.check_customer.subscribe(
      message => {
        if (message == 'partner') {
          this.is_customer = false;
        }
        else {
          this.is_customer = true;
        }
      }
    )
  }

  ngAfterViewInit() {
    $('li.dropdown .capabilities a').on('click', function (event) {
      $(this).parent().toggleClass('open');
    });

    $('.navbar-nav .list-group-item a , #navbarNav > ul > li > .about-link').on('click', function () {
      $('.navbar-collapse').collapse('hide');
    });

    $('.navbar-nav h5').on('click', function () {
      $('.navbar-collapse').collapse('hide');
    });
  }

  platformPage() {
    this.router.navigate(['/platform']);
  }

  security() {
    this.router.navigate(['/security']);
  }

  sheetMetal() {
    this.router.navigate(['/sheet-metal-fabrication']);
  }

  threedPrinting() {
    this.router.navigate(['/3d-printing']);
  }

  vacuumCasting() {
    this.router.navigate(['/vacuum-casting']);
  }

  injectionMolding() {
    this.router.navigate(['/plastic-injection-moulding']);
  }

  metalCasting() {
    this.router.navigate(['/metal-casting']);
  }

  cncmachining() {
    this.router.navigate(['/cnc-machining']);
  }

  blogs() {
    window.open("https://blogs.truventor.ai/blogs/", '_blank');
  }

  caseStudy() {
    this.router.navigate(['/case-study']);
  }

  articles() {
    this.router.navigate(['/articles']);
  }

  media() {
    this.router.navigate(['/media']);
  }

  events() {
    this.router.navigate(['/events']);
  }

  faqs() {
    this.router.navigate(['/faq']);
  }

  quality() {
    this.router.navigate(['/quality-assurance']);
  }

  buyerProtection() {
    this.router.navigate(['/buyer-protection']);
  }
  truventorOne() {
    this.router.navigate(['/truventorone']);
  }
}
