<!-- Main -->
<section class="main-session">
    <img src="assets/img/banner-images/Product Security@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="heading">
                    <h1>
                        Your Files Are <span>Secure</span> With Us!
                    </h1>
                    <p class="mt-4">
                        Truventor has a secure file handling system in place. Our end-to-end file protection and stringent rules to access data will ensure your CAD files stay confidential.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">Get In Touch</a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- more -->
<section class="global-space-between">
    <div class="container">
        <div class="row align-items-center">
            <div class="col col-12 col-md-6">
                <div class="d-flex justify-content-center align-items-center h-100 pb-5 pb-md-0">
                    <img src="./assets/img/product-security/svg/File Protection.svg" class="img-fluid w-75" alt="how it works">
                </div>
            </div>
            <div class="col col-12 col-md-6">
                <p>
                    At Truventor, we take data security very seriously. Nothing is more important to us than ensuring that your CAD data is safe! We have employed state-of-the art AWS technology to ensure that our file handling system is robust and globally accessible. This
                    system provides multiple layers of security and ultra-strict access rules to your highly valuable and confidential CAD files.
                </p>
                <p>
                    Whenever you upload a CAD file on Truventor’s cloud manufacturing platform, we encrypt your files and store them in very secure AWS servers. These are the same servers that USA’s Pentagon uses to save its “Classified” information. Once stored, the only
                    way to access these files is to generate a special digital signature using a dynamic URL, Thereby making the entire system highly secure and foolproof!
                </p>
            </div>
        </div>
    </div>
</section>

<!-- How it works -->
<section class="how-it-works-product-security global-space-between">
    <div class="container">
        <div class="row">
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 order-11 order-md-1 pl-md-5 d-flex align-items-center">
                <div class="w-100">
                    <!-- Title -->
                    <h1 class="text-left d-none d-md-block">How it works ?</h1>
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="col-sm-12 col-md-10 pl-0">
                            <div class="accordion" id="howToUseOurPlatform">

                                <!-- Level 1 -->
                                <div>
                                    <div id="how-it-works-accordion-one">
                                        <button class="w-100 p-0" type="button" data-toggle="collapse" aria-expanded="true" aria-controls="how-it-works-collapse-one">
                                            <h6 class="text-left" tabindex="0">Layer 1</h6>
                                        </button>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="how-it-works-collapse-one" class="collapse show" aria-labelledby="how-it-works-accordion-one" data-parent="#howToUseOurPlatform">
                                        <p>
                                            When you upload a CAD file data on truventor platform, the system assigns you as the sole owner of the data! This data is then straight away uploaded on our secure AWS servers where the data is stored in an encrypted format.
                                        </p>
                                        <hr class="m-0">
                                    </div>
                                </div>

                                <!-- Level 2 -->
                                <div>
                                    <div id="how-it-works-accordion-two">
                                        <button class="w-100 p-0" type="button" data-toggle="collapse" aria-expanded="true" aria-controls="how-it-works-collapse-two">
                                            <h6 class="text-left" tabindex="1">Layer 2</h6>
                                        </button>
                                    </div>
                                    <hr class="m-0">
                                    <div id="how-it-works-collapse-two" class="collapse show show-mobile" aria-labelledby="how-it-works-accordion-two" data-parent="#howToUseOurPlatform">
                                        <p>
                                            Once stored, only the owner or authorized personnel can access this data with a verified digital signature! Thus, when you, or one of Truventor’s authorized personnel request an access to the file, our system checks the owner’s identity and authorization.
                                            Once validated, our system generates a dynamic URL to access the file. This dynamic URL is a one-time link that expires within 10 seconds from its generation.
                                        </p>
                                        <hr class="m-0">
                                    </div>
                                </div>

                                <!-- Level 3 -->
                                <div>
                                    <div id="how-it-works-accordion-three">
                                        <button class="w-100 p-0" type="button" data-toggle="collapse" aria-expanded="true" aria-controls="how-it-works-collapse-three">
                                            <h6 class="text-left" tabindex="2">Layer 3</h6>
                                        </button>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="how-it-works-collapse-three" class="collapse show show-mobile" aria-labelledby="how-it-works-accordion-three" data-parent="#howToUseOurPlatform">
                                        <p>
                                            To ensure full transparency of your data, we aim to provide you with real-time updates every time your CAD file is accessed. The system will notify the date, time, and area of access.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Side Image -->
            <div class="col-sm-12 col-md-6 order-1 order-md-11 p-0">
                <!-- Mobile Title -->
                <h1 class="d-block d-md-none text-center"> How it Works ? </h1>
                <div class="d-flex justify-content-center align-items-center h-100 pb-5 pb-md-0">
                    <img src="./assets/img/product-security/svg/How it Works.svg" class="img-fluid" style="height: 812px;" alt="how it works">
                </div>
            </div>

        </div>
    </div>
</section>

<!-- Only You Control -->
<section class="global-space-between">
    <div class="container">
        <div class="row align-items-center">
            <div class="col col-12 col-md-6">
                <!-- Mobile Title -->
                <h1 class="d-block d-md-none text-center">
                    Only you control your data
                </h1>
                <div class="d-flex justify-content-center align-items-center py-4 py-md-0">
                    <img src="./assets/img/product-security/svg/Access to global supplier network.svg" class="img-fluid w-75" alt="secure image">
                </div>
            </div>
            <div class="col col-12 col-md-5">
                <!-- Desktop Title -->
                <h1 class="d-none d-md-block">
                    Only you control your data
                </h1>
                <p>
                    We believe that you should be in total control of your data. Thus, to strengthen our commitment, we give you access to DELETE the part file anytime you want. Once the file is deleted by you, it is deleted from our server as well, and so are the links
                    associated with it. This makes it better than any e-mail system out there.
                </p>
                <p>
                    However, this does NOT delete the information associated with your CAD design. This information could be part name, dimensions, attributes, recommendations, quotations, manufacturing log etc.
                </p>
            </div>
        </div>
    </div>
</section>


<!-- Question about security -->
<section class="global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col text-center">
                <h1 style="font-size: 60px;">
                    Question about security ?
                </h1>
                <p class="my-5">
                    Have a question, concern, or comment about COCO's Security?
                </p>
                <a [routerLink]="['/contact']" class="btn btn-primary"> Contact Truventor support </a>
            </div>
        </div>
    </div>
</section>