import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-inconel718',
  templateUrl: './material-inconel718.component.html',
  styleUrls: ['./material-inconel718.component.scss']
})
export class MaterialInconel718Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

}
