<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Stainless Steel -301@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Stainless <br> Steel 301
                    </h1>
                    <p class="text-primary">
                        CNC Machining / Sheet Metal
                    </p>
                    <p class="mt-4">
                        301 steel is mainly used in the cold working state to withstand higher loads
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Stainless Steel 301.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Type 301 is an austenitic stainless steel with a nominal composition of 17 percent chromium and 7 percent nickel, which provides good strength and ductility when cold worked. It also has excellent corrosion resistance properties. 301 is well suited to
                    welding, forming and drawing. This high strength grade of steel is available in several conditions. With a lower level of chromium, Type 301 is more susceptible to corrosion and oxidation.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Stainless Steel 301
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Stainless Steel 301@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Density, g/cm3 (lb/in3):
                            </th>
                            <td>
                                8 (0.289)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Melting point, °C (°F)
                            </th>
                            <td>
                                1400-1420 (2550-2590)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Electrical resistivity, μΩ·m
                            </th>
                            <td>
                                0.72 at 20 °C (68 °F)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Magnetic permeability
                            </th>
                            <td>
                                1.02 (Approximate)
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Good corrosion resistance
                        </p>
                        <p class="mb-2">
                            Provides good strength and ductility when cold worked
                        </p>
                        <p class="mb-2">
                            Tensile Strength
                        </p>
                        <p class="mb-2">
                            Well suited to welding and forming
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <!-- <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Not suited for high stress application
                        </p>
                        <p class="mb-2">
                            Relatively heavy parts than Aluminium thus not suited for application in automotive,
                            aerospace etc.
                        </p>
                        <p class="mb-2">
                            Not suitable for food packaging or medicinal usage or any health related application </p>
                        <hr>
                    </div>
                </div> -->

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Automotive trim
                        </p>
                        <p class="mb-2">
                            kitchen equipment
                        </p>
                        <p class="mb-2">
                            Aircraft Structural Parts
                        </p>
                        <p class="mb-2">
                            Hose clamps
                        </p>

                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>