<!-- Main -->
<section class="main-session">
    <img src="./assets/img/material_template/abs.jpg" class="img-banner d-none d-md-block" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        ABS
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Versatile machining plastic used for making functional and large parts with good mechanical properties
                    </p>
                </div>
            </div>
        </div>
        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/abs.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/ABS.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Acrylonitrile Butadiene Styrene (ABS) is one of the most widely used thermoplastic in the engineering industry. ABS blocks can be easily machined to create components with good mechanical properties and surface finish. The components manufactured via
                    machining are used directly as functional parts, fitment testing and jigs & fixtures.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        ABS
                    </strong>
                </h6>
                <img src="./assets/img/material_template/abs.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                Yellowish White
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-) 150µm (Standard)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                370 x 350 x 200 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships in
                            </th>
                            <td>
                                5-7 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Widely available
                        </p>
                        <p class="mb-2">
                            Cost effective owing to its high machinability and reduced machining time
                        </p>
                        <p class="mb-2">
                            Multiple pieces can be glued together to form large parts
                        </p>
                        <p class="mb-2">
                            Easy material to work with for post-processing
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Not suited for high temperature applications
                        </p>
                        <p class="mb-2">
                            Poor chemical resistance
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Cost-effective functional parts
                        </p>
                        <p class="mb-2">
                            Fitment testing components
                        </p>
                        <p class="mb-2">
                            Jigs & fixtures
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <h1 class="mb-5">
                    Design Guidelines For Acrylic (PMMA)
                </h1>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">

                    <!-- One -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pmma/General Achievable Tolerance@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            General achievable tolerance
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the Standard Tolerance : (+/-) <span class="text-primary">125</span> µm & Achievable: (+/-) <span class="text-primary">100
                                µm</span> , (+/-) <span class="text-primary"> 50 µm </span> For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1.2mm</span>. For decent strength: <span class="text-primary">1.5mm</span>                            & For rigid wall: <span class="text-primary">2mm</span>.
                        </p>
                    </div>

                    <!-- Two -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pmma/Wall Thickness@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1.2mm</span>. For decent strength: <span class="text-primary">2mm</span> & For rigid wall: <span class="text-primary">2.5mm</span>.
                        </p>
                    </div>

                    <!-- Three -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pmma/Pocket Depth@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Pocket Depth
                        </h6>
                        <p class="mt-2">
                            General pocket depth up to 3 times the diameter of the end mill
                        </p>
                    </div>

                    <!-- Four -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pmma/Engraving@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Engraving:
                        </h6>
                        <p class="mt-2">
                            Always prefer engraving over embossing Recommended fonts for a clear, legible text: Arial, Gothic Letter strand width should be at least <span class="text-primary">2 mm</span>
                        </p>
                    </div>

                    <!-- Five -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pmma/Internal Radii@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Internal Radii
                        </h6>
                        <p class="mt-2">
                            Minimum Internal radii achieved is <span class="text-primary">1 mm</span> Depends highly on the depth of pocket being milled
                        </p>
                    </div>

                    <!-- Five -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pmma/Holes@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Holes
                        </h6>
                        <p class="mt-2">
                            Use standard drill bit sizes Minimum : <span class="text-primary">1 mm</span>
                        </p>
                    </div>

                    <!-- Six -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pmma/Threads@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Threads
                        </h6>
                        <p class="mt-2">
                            Use standard metric inserts Smallest threaded insert available: M2
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Looking for a cost estimate? -->
<section class="find-the-cost global-space-between bottom-end-padding">
    <div class="container-fluid">
        <div class="row justify-content-start align-items-center">
            <!-- Image -->
            <div class="col col-12 col-md-4 px-0 ">
                <img src="./assets/img/material_template/Group 19465.jpg" class="img-fluid w-100" alt="Truventor">
            </div>
            <!-- Heading -->
            <div class="col col-12 col-md-4 ml-md-3">
                <h1 class="mt-5">
                    Looking for a cost estimate?
                </h1>

                <p class="my-5">
                    Step Into The Future With Our Highly Flexible, On-Demand Manufacturing Option.
                </p>

                <div class="row">
                    <div class="col-12 col-md-6 mt-3">
                        <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100"> Upload Design </a>
                    </div>
                    <div class="col-12 col-md-6 mt-3">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn w-100"> Book A Demo
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>