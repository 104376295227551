<section class="contact-cover imprint-main-session">
    <!-- <img src="assets/img/contact/terms.png" class="img-banner" alt="Truventor"> -->

    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="content">
                    <h1>Supplier Terms and Conditions</h1>
                    <p>All your questions answered at one place
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="privacy">
    <div class="container">
        <div class="">
            <div class="row">
                <div class="col-md-4 col-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#home" role="tab"
                                aria-controls="home">Overview</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#profile" role="tab"
                                aria-controls="profile">Acceptance of terms and conditions</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#messages" role="tab"
                                aria-controls="messages">Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#settings" role="tab"
                                aria-controls="settings">Supplier standards</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#automeans" role="tab"
                                aria-controls="automeans">Receiving orders</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#intellectual" role="tab"
                                aria-controls="intellectual">Pricing and payment</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#useoftvweb" role="tab"
                                aria-controls="useoftvweb">Manufacturing an order</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#otherweb" role="tab"
                                aria-controls="otherweb">Shipping</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#changeprivacy" role="tab"
                                aria-controls="changeprivacy">Product</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#contactus" role="tab"
                                aria-controls="contactus">Intellectual property in the design and product

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#eleven" role="tab"
                                aria-controls="eleven">Marketplace only
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#twelve" role="tab"
                                aria-controls="twelve">Pricing and payment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#thirteen" role="tab"
                                aria-controls="thirteen">Your obligations
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#fourteen" role="tab"
                                aria-controls="fourteen">Delivery and performance
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#fifteen" role="tab"
                                aria-controls="fifteen">Refunds and returns

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#sixteen" role="tab"
                                aria-controls="sixteen">Intellectual property in the platform
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#seventeen" role="tab"
                                aria-controls="seventeen">Operation of the platform

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#eighteen" role="tab"
                                aria-controls="eighteen">Modifications to the platform
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#nineteen" role="tab"
                                aria-controls="nineteen">Advertising
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#twenty" role="tab"
                                aria-controls="twenty">Warranties
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#twentyone" role="tab"
                                aria-controls="twentyone">Limitation of liability
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#twentytwo" role="tab"
                                aria-controls="twentytwo">Indemnity
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#twentythree" role="tab"
                                aria-controls="twentythree">General
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <div class="verticle-line text-center"></div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="tab-content">
                        <div class="tab-pane active" id="home" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                1 Overview
                                1.1 Truventor AI and Robotics Pty Ltd ACN 622 249 969 (we, us, our, TV) operates an
                                online
                                intelligent cloud manufacturing platform with domain address www.truventor.ai and
                                related
                                applications (Platform) on which clients (Clients) can access a range of manufacturing
                                services
                                from us and our registered suppliers (Supplier, you, your). These terms and conditions
                                (Terms
                                and Conditions) constitute an agreement between us and you for the purpose of your use
                                of
                                the Platform and any services offered by the Platform from time to time. These are the
                                Supplier
                                terms and conditions and apply to Suppliers only.
                                1.2 If you are a Client, please access our Client Terms and Conditions at link.
                                1.3 The key features of our Platform include:
                                (a) Truventor Manufacture (TV Manufacture), an online manufacturing service through
                                which Clients can:
                                (i) Place manufacturing orders;
                                (ii) Upload 3D models and designs relating to parts, assembly requirements, or
                                products (Designs) for their manufacturing projects;
                                (iii) Have their Designs manufactured; and
                                (iv) Through which you can receive and accept manufacturing orders from Clients.
                                (b) Truventor Manage (TV Manage), an online marketplace and software as a service
                                through which Clients can access and manage a private network of their own
                                independent Suppliers.
                                (c) Other Truventor services which may be available through the Platform.
                                (Services)
                                1.4 We reserve the right to update, modify, replace, limit, and expand the Platform and
                                the Services.
                                1.5 You should read these Terms and Conditions carefully prior to using the Platform.
                            </p>
                        </div>
                        <div class="tab-pane" id="profile" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>

                            <p>
                                2 Acceptance of Terms and Conditions
                                2.1 These Terms and Conditions record an agreement between you and us for the purpose of
                                you
                                browsing or using the Platform.
                                2.2 By using the Platform you are accepting these Terms and Conditions and agree to be
                                bound by
                                them.
                                2.3 We reserve the right to make changes to these Terms and Conditions from time to time
                                without
                                notice.
                            </p>
                        </div>
                        <div class="tab-pane" id="messages" role="tabpanel">

                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                3 Use
                                3.1 To use the Platform, you must register for an account in accordance with this
                                clause.
                                2
                                3.2 To register for an account, you must be a manufacturer or supplier that meets our
                                standards and
                                requirements to operate as a Truventor Supplier.
                                3.3 To register an account and / or place an order, you may be required to provide
                                (among other
                                things):
                                (a) Your name;
                                (b) Details of your business, including:
                                (i) Name;
                                (ii) Address; and
                                (iii) Any other required details.
                                (c) Valid contact details including;
                                (i) An email address; and
                                (ii) A telephone number.
                                (d) A name and address for delivery purposes; and
                                (e) Any other information required to complete the registration or ordering process,
                                (Supplier Data).
                                What constitutes Supplier Data may vary from time to time, noting that we may require
                                additional information to that set out above.
                                3.4 Failure to supply correct Supplier Data may result in us being unable to provide the
                                Services,
                                and / or may limit your access to the Platform.
                                3.5 We reserve the right to decline your registration request if you do not comply with
                                our
                                registration requirements as detailed in these Terms and Conditions and on the Platform.
                                3.6 You agree to permit us to use your Supplier Data for the purpose of providing the
                                functionality
                                of the Platform and any related purpose. You acknowledge that in order to do so, we may
                                share
                                the Supplier Data internally, and with other parties, including Clients, financiers,
                                logistic
                                partners and other stakeholders.
                                3.7 You agree to use the Platforms for their intended purpose. You may not use the
                                Platform for
                                any illegal or unauthorised purpose, including the transmission of malware. You must not
                                use
                                the Platform to violate any laws, including but not limited to copyright laws.
                                3.8 You must not use the Platform for any unconscionable behaviour or activity,
                                including but not
                                limited to:
                                (a) Engaging in abusive, derogatory, discriminatory, or vexatious behaviour;
                                3
                                (b) Engaging in any activity that disrupts or corrupts the Platform or the networks that
                                host
                                the Platform; or
                                (c) Attempting to hack, reverse-engineer, disable, circumvent, or otherwise gain access
                                to
                                any security, database, or other secure aspect of the Platform.
                                3.9 You are responsible for maintaining the security of your login and account
                                information
                                (including the password). We are not liable for any loss or damage from your failure to
                                comply
                                with this security obligation.
                                3.10 You are responsible for all activities that occur on your account, and we will not
                                be liable for
                                any loss or damage from activities on your account.
                                3.11 All Supplier Data will be stored and maintained by or on behalf of us in accordance
                                with
                                applicable laws.
                                3.12 We may from time to time email you regarding our Service or third party products or
                                services
                                that may be of interest to you. The emails may contain code that enables our database to
                                track
                                your usage of the emails, including whether the email was opened and / or what links (if
                                any)
                                were clicked. We may use this information to improve the functionality of the Platform
                                and to
                                improve the Service for you.
                                3.13 Our use of any information provided within the Supplier Data will be in accordance
                                with our
                                Privacy Policy available at link.
                            </p>
                        </div>
                        <div class="tab-pane" id="settings" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>

                            <p>4 Supplier Standards
                                4.1 We require that our Suppliers and their manufacturing process meets relevant
                                standards and
                                regulations. We may require that you provide proof of compliance with such relevant
                                standards
                                and code. If we request proof of compliance of such standards and codes, you are
                                required to
                                provide proof within a reasonable time, and in any event not less than within 14 days of
                                request.
                                4.2 If we learn, suspect, or have reason to suspect that you do not meet relevant
                                standards and
                                regulations, we may terminate or suspend your account until such time as we are
                                satisfied that
                                such standards have been met.
                            </p>
                        </div>
                        <div class="tab-pane" id="automeans" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                5 Receiving Orders
                                5.1 To place a manufacturing order (Order) on TV Manufacture to have a product (Product)
                                manufactured, a Client will first upload a request for quotation consisting of a Design,
                                raw
                                material specification, and other details as required (RFQ).
                                5.2 Once a RFQ is uploaded, subject to clause 5.6, the Platform will extract the data
                                from the Design
                                to calculate timelines and pricing of parts to complete the Order. The Design and other
                                information contained in the Order will constitute the Client’s Order specifications
                                (Specifications).
                                5.3 Once an order is accepted and paid for by a Client, we will submit the Order to our
                                Suppliers
                                to manufacture. You will then be invited to prepare a quote to complete the Order. The
                                quote
                                must include, at a minimum:
                                (a) Your fee (Fee) to complete the Order, including a comprehensive breakdown of all
                                charges included in your Fee;
                                4
                                (b) The estimated timeline to complete and deliver the Order including shipping and
                                delivery;
                                (c) Payment details for receipt of payment; and
                                (d) Any other information that may be required from us when we submit the Order and/or
                                RFQ.
                                (Quote)
                                5.4 We retain full discretion as to which Supplier we engage to manufacture an Order. In
                                determining a Supplier to fulfil an Order, we may consider, among other things, the
                                quote
                                provided by the Supplier to fill the Order, the timelines provided by the Supplier, the
                                Supplier’s
                                location, and the suitability of the Supplier for the specific Order.
                                5.5 You may refuse an Order that has been referred to you, or elect not to provide a
                                Quote for an
                                Order. Where you provide a Quote and that Quote is accepted by us, you are required to
                                complete the Order in accordance with the Details of Quote.
                                5.6 Particularly large Orders or Orders with specific manufacturing requirements, may
                                require us
                                to submit the RFQ to our Suppliers to review and provide a Quote. If we refer a RFQ to
                                you
                                for this purpose, we require that you answer the quote within a 48 hours period, subject
                                to
                                weekends and public holidays. RFQ’s may be referred to you only, or to a number of
                                Suppliers.
                                Once the RFQ is answered, we will pass the quoted cost and delivery timelines on to the
                                Client.
                                5.7 Upon a Quote being accepted by us, you will manufacture or have manufactured the
                                Order in
                                accordance with the Specifications and the Quote. You are solely responsible for
                                ensuring that
                                the Product manufactured is functional, complete, and in accordance with the Quote and
                                Specifications. You indemnify us fully against any claims brought against us by a
                                Client,
                                whether for damages or any other remedy, due to a fault, error, or defect caused to the
                                Product
                                by you.
                            </p>
                        </div>
                        <div class="tab-pane" id="intellectual" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>6 Pricing and Payment
                                6.1 When you provide a Quote to complete an Order, the Fee contained in the Quote will
                                be
                                inclusive of all costs and charges associated with your fulfillment of the Order,
                                including any
                                applicable tax. For the avoidance of doubt, we will be under no obligation to pay you
                                any
                                amount in excess of the Fee unless otherwise agreed in writing.
                                6.2 Once an Order is completed and the Product is delivered to the Client, the Client
                                will have the
                                opportunity to conduct an inspection of the Product and accept the Product. Payment of
                                the Fee
                                will not be remitted to you until such time as the Client confirms its acceptance of the
                                Product.
                                6.3 Once the Product is accepted by the Client, we will pay you in accordance with the
                                Quote to
                                your elected bank account. You are fully responsible for ensuring that you have provided
                                us
                                with complete and accurate payment details. We accept no liability for any loss or
                                damage
                                caused by you providing incorrect or incomplete payment details.
                                6.4 If a Product is not accepted by a Client due to a fault or defect caused by you, we
                                reserve the
                                right to withhold payment. If, upon our inspection, we confirm that the fault or defect
                                was
                                caused by you, you will have the right to repair the fault or defect at your own cost.
                                We reserve
                                the right to apply and deduct any damages caused by any defect or fault caused by you
                                against
                                the Fee.
                                6.5 We use a third-party service provider for provision of the payment service and we
                                are not liable
                                for any issues, including charges from your financial institution, which may arise as a
                                result of
                                this service.</p>
                        </div>
                        <div class="tab-pane" id="useoftvweb" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                7 Manufacturing an Order
                                7.1 If any alteration, modification, or change to the Specifications of any Order is
                                required to
                                complete the Order, you must notify us of this requirement as soon as is reasonably
                                practical
                                after becoming aware. You must not depart from the Specifications without our prior
                                approval.
                                You indemnify us fully against any claim brought against us by a Client due a departure
                                by you
                                from the Specifications without our approval.
                                7.2 We do not allow the manufacture of any Order that is illegal or designed to cause
                                harm or injury
                                to any human, or which is designed with the intention of facilitating any illegal
                                activity such as
                                but not limited to the product of illicit drugs, the unlawful destruction of property,
                                or any other
                                criminal activity. If you become aware or reasonably suspect that an Order that you are
                                manufacturing is being manufactured for any illegal or unethical purpose, you must cease
                                manufacturing the Order immediately and raise your concerns with us. We will then
                                conduct
                                our own investigation and advise accordingly.
                                7.3 Orders must be manufactured in accordance with relevant standards and regulations.
                                All our
                                Suppliers are required to be compliant with relevant standards and regulations, which we
                                may
                                inform you of from time to time. You must not depart from these standards and
                                regulations.
                                You fully indemnify us against any claim bought against us by any party, including of a
                                government, pseudo-government, or regulatory body as a result of you departing from
                                applicable manufacturing standards and regulations.
                                7.4 All Orders must be manufactured with care, expertise, and professionalism. As a
                                Truventor
                                Supplier, you are expected to meet certain standards of quality and expertise. If we
                                determine
                                that you are incapable of or for whatever reason failing to meet our standards of
                                quality and
                                expertise, we may cancel your account.
                            </p>
                        </div>
                        <div class="tab-pane" id="otherweb" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                8 Shipping
                                8.1 You must provide an estimated shipping date at the time you submit a Quote. You must
                                make
                                all reasonable endeavours to meet the shipping date provided. You must let us know
                                immediately if you will be unable to meet the shipping date provided so that we may
                                manage
                                the Clients’ expectations.
                                8.2 You must ensure that the completed Product is shipped according to our instructions.
                                All
                                Products shipped must be shipped in a way that is safe and secure, and does not
                                jeapordize the
                                integrity of the completed Product. You must make reasonable endeavours to ensure that
                                Products are protected from physical harm or damage during the shipping process.
                                8.3 We will provide you the delivery address for the completed Product to be shipped
                                too. It is your
                                responsibility to ensure that the Product is shipped to the correct address, and you
                                fully
                                indemnify us against any claims for damages brought against us by a Client due to you
                                shipping
                                a Product to an incorrect address.
                            </p>
                        </div>
                        <div class="tab-pane" id="changeprivacy" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>9 Product
                                9.1 Upon delivery of a Product, the Client will have the opportunity to inspect the
                                Product. If the
                                Client identifies that there is a fault or defect contained in the Product, we will
                                conduct an
                                internal investigation to confirm whether this is the case. If we determine that there
                                is a fault or
                                defect in the Product, and we determine that the fault or defect was caused by you, you
                                agree
                                that you will take any steps reasonably required by us to remedy the fault or defect.
                                This may
                                include but is not limited to:
                                (a) Repairing the fault or defect;
                                (b) Replacing the product;
                                6
                                (c) If the fault or defect relates only to a part or component of the Product, replacing
                                or
                                repairing the part or component; or
                                (d) Any other steps reasonably required by us. </p>
                        </div>
                        <div class="tab-pane" id="contactus" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>

                            <p>
                                10 Intellectual Property in the Design and Product
                                10.1 The Client will upload Specifications, Designs, and other content or data on to the
                                Platform
                                (Client IP). We claim no ownership over the Client IP, and all intellectual property and
                                other
                                proprietary rights in the Client IP remains the sole and exclusive property of the
                                Client. Where
                                the Client IP is shared with you, you agree to only use the Client IP for the purposes
                                of providing
                                a Quote and, if applicable, fulfilling the Order.
                                10.2 You shall treat all Client IP with the utmost confidentiality. If you are required
                                to share the
                                Client IP with any party, including your employees, partners, contractors, suppliers,
                                stakeholders, and affiliates, you must take reasonable steps to ensure that they comply
                                with the
                                requirements of this clause. You must take reasonable steps to ensure the security of
                                any Client
                                IP accessed or held by you, and ensure that the Client IP is protected from theft,
                                hacking, or
                                leaking.
                                10.3 You fully indemnify and hold harmless us against any claims for damages or any
                                other remedy
                                made against us by a Client or any other party due to a breach of the proprietary rights
                                of a
                                Client in the Client IP.
                                10.4 If we become aware, suspect, or have reason to suspect that you are in breach of
                                your
                                confidentiality requirements with respect to any Client IP, your account may be
                                terminated or
                                suspended, and we may take any legal action against you that we see fit to remedy such
                                breach
                            </p>
                        </div>

                        <div class="tab-pane" id="eleven" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>

                            <p>

                                11 Marketplace Only
                                11.1 TV Manage provides a marketplace and software as a service through which Clients
                                can engage
                                with and manage their network of Suppliers, which includes access to our network of
                                Suppliers,
                                and buy products or services from Suppliers(Transaction). Our involvement in the
                                Transaction
                                is limited to that of a facilitator to the Transaction. We are not and do not purport to
                                be a
                                Supplier, manufacturer, guarantor, or otherwise a contractual party to the Transaction.
                                We are
                                in no way responsible for completion of you or the Client’s obligations and by
                                transacting with
                                the Client you assume all legal and transaction risk in dealing with the Client.
                                11.2 Our involvement in the Transaction is limited to the following capacity:
                                (a) Connecting you with Clients via TV Manage;
                                (b) Facilitating the Transaction by allowing the Client and you to engage through TV
                                Manage and facilitate all steps of the Transaction; and
                                (c) Collecting payment from the Client and remitting it to you.
                                11.3 When you engage with a Client through TV Manage, you are entering a legally binding
                                contract
                                with the Client. We are not a party to this contract and are not responsible for the
                                performance
                                of your or the Client’s obligations under the contract.
                                7
                                11.4 You may have your own terms and conditions, standard form contracts, or other
                                agreements
                                that Clients may be required to adhere to in engaging you (Supplier Terms). When this is
                                the
                                case, the Transaction is subject to the Supplier Terms.
                                11.5 Where you do not have any Supplier Terms, the Transaction will be subject to terms
                                and
                                conditions implied at law, and implied by your conduct and exchanges with the Client.
                                Please
                                make yourself aware of such implied conditions before entering the Transaction.
                                11.6 By using TV Manage, you fully release and discharge us from any claims, actions,
                                demands or
                                proceedings which may arise from a Transaction. You agree that we are in no way liable
                                for
                                any obligation arising from a Transaction, including but not limited to:
                                (a) Delivery or performance of a product or service;
                                (b) The quality or standard of a product or service;
                                (c) Reimbursement, compensation, or damages arising from a product or service;
                                (d) Product or service warranties that you may provide; or
                                (e) Product or service warranties and obligations placed on you in relation to a product
                                or
                                service under a relevant law.
                            </p>
                        </div>
                        <div class="tab-pane" id="twelve" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                12 Pricing and Payment
                                12.1 Product prices and delivery charges must include taxes including GST and other
                                taxes where
                                applicable.
                                12.2 Purchases are placed and paid for through the payment processing facility contained
                                on the
                                Platform, using the payment methods designated by the Platform.
                                12.3 When a Client initiates a Transaction on the Platform, payment for the Transaction
                                will be
                                accepted and processed by us or our nominated third-party payment processor, and the
                                Order
                                will be sent to you. You must accept the Order if you have the capacity to provide the
                                relevant
                                service.
                                12.4 You must reject the Order if you are unable to fulfil the order.
                                12.5 You must provide confirmation that you have accepted or rejected the Order within 1
                                business
                                day of the Order being placed. Failure to do so may result in the Transaction being
                                cancelled
                                and the payment being refunded to the Client.
                                12.6 If you cancel an order that has already been processed, or otherwise fail to
                                deliver to the Client
                                the Client’s order whether in full or in part, you agree that any complaint, demand for
                                refund
                                or remedy, legal action, or action otherwise is to be taken against you directly and you
                                fully
                                indemnify us against any such action. To the maximum extent permitted by law, we will
                                not be
                                liable to you for any loss, whether liquidated or otherwise, associated with you
                                entering into a
                                Transaction with a Client.
                                12.7 It is your responsibility to ensure your payment details are valid and up to date.
                                12.8 Subject to this clause, upon completion of a Transaction, you are entitled to
                                receive payment
                                for the Order completed (Supplier Fee). The Supplier Fee will be the amount paid by the
                                Client
                                in a Transaction, less:
                                8
                                (a) Any applicable transaction fee as notified to you from time to time;
                                (b) Any applicable third-party fees as notified to you from time to time; and
                                (c) Any other amount which you owe to us.
                                12.9 The Supplier Fee will be remitted to you upon completion of the Transaction.
                                12.10 Details of the amount, if any, deducted from the Supplier Fee will be provided at
                                the time you
                                are remitted the Supplier Fee.
                                12.11 In the event that we suspect the existence of a fraudulent or fake account:
                                (a) We reserve the right to investigate all accounts where we suspect such activity.
                                (b) We reserve the right to terminate your order following the investigation.
                                12.12 We are in no way liable for payment failure or other transactional risks even in
                                circumstances
                                where you may have already despatched products or provided services. You hold us
                                entirely
                                harmless in relation to any and all obligations in this regard.
                            </p>
                        </div>
                        <div class="tab-pane" id="thriteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                13 Your Obligations
                                13.1 You agree that you will interact with Clients with professionalism, respect and
                                decency.
                                13.2 You agree that you all products or services listed on the Platforms and your
                                accompanying
                                terms of sale will be compliant with all relevant standards, laws and applicable
                                regulations and
                                codes. You agree that all manufacturing services you supply over the Platform are
                                provided to
                                Clients with acceptable care and skill and all applicable standards and obligations
                                under law.
                                13.3 You must:
                                (a) Respond to Clients within 1 business day of receiving a communication from a Client;
                                (b) Ship products within the estimated delivery time provided to a Client in a
                                Transaction;
                                (c) Respond to and resolve customer complaints without unnecessary delay; and
                                (d) Fairly and lawfully comply with your returns policies and procedures set out in your
                                Suppler Terms and comply with these Terms and Conditions in responding to and
                                handling Client complaints.
                                13.4 You bear sole responsibility for maintaining your listings and associated
                                information in
                                accordance with our guidelines which are accessible via the Platforms and may be updated
                                from
                                time to time.
                                13.5 Your profile must be accurate and a true depiction of the products or services
                                offered by you.
                                13.6 You warrant and guarantee that you have full authority, title, and legal right and
                                capacity to
                                offer your manufacturing services.
                                13.7 You agree that will not provide false or misleading information to us or to a
                                Client.
                                9
                                13.8 You will provide Clients with proof of transaction for all orders.
                                13.9 You agree that you will not use the Platforms as a means of lead generation. You
                                agree that you
                                will not contact Clients by reaching out to them outside of the context of the
                                Platforms.
                                13.10 You agree that you will not accept any payment for a product or service from a
                                Client directly,
                                and that all payments must be processed by us or our nominated third party payment
                                processor,
                                and that all Transactions with Clients must take place through the Platform.
                            </p>
                        </div>
                        <div class="tab-pane" id="fourteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                14 Delivery and Performance
                                14.1 You are responsible for the delivery of a product or performance of a service the
                                subject of a
                                Transaction. We are not responsible and accept no responsibility for the delivery of a
                                product
                                or performance of a service listed on the Platforms.
                                14.2 Failure to deliver a product or perform a service listed on the Platforms to a
                                Client may result
                                in your account being terminated or suspended.
                                14.3 You must provide timeframes to the Client setting out the expected time for
                                delivery of a
                                product or performance of a service where possible. Where timeframes are provided, you
                                must
                                endeavour to take required steps to meet the timeframe provided. You agree and
                                acknowledge
                                that a Client may contact you directly to ascertain an estimated timeframe.
                                14.4 You are responsible for delivery to the Client. We are not responsible and accept
                                no liability
                                for delivery or performance of a service you have listed on the Platforms.
                                14.5 You may only execute partial delivery of an order to a Client with the express
                                prior permission
                                of the Client, which must be attained by contacting the Client through the Platform.
                                14.6 Where you have had a dealing with a Client in which the Client has breached these
                                Terms and
                                Conditions or the Suppler Terms you may report this breach to us to investigate. We may
                                at our
                                own discretion suspe
                            </p>
                        </div>
                        <div class="tab-pane" id="fifteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                15 Refunds and returns
                                15.1 Notwithstanding any other provision of these Terms and Conditions, we are in no way
                                required
                                to refund any money spent on a Transaction.
                                15.2 Where a refund is required or requested by a Client, you agree that the Client can
                                contact you
                                directly to arrange or request such a refund.
                                15.3 Clients may also request refunds through our team. In such circumstances, we will
                                review the
                                refund request and forward it to you with any of our own comments.
                                15.4 You must execute refunds promptly and in any case within 2 business days of the
                                request for a
                                refund. If you fail to execute a refund within 2 business days, we may exercise our
                                authority to
                                execute the refund on your behalf, in which event the amount refunded will be a debt
                                payable
                                by you to us upon demand. We may elect to deduct the refund from the Supplier Fee.
                                15.5 Any claims under any consumer law with respect to a product or service are against
                                you only
                                and you agree and fully release, discharge and indemnify us from and against any
                                liability,
                                claim, or action against the supply or delivery of a service or product under any
                                consumer law.
                                10
                                15.6 We may require that you refund the full or partial amount paid by a Client in a
                                Transaction to
                                a Client where:
                                (a) We consider that the Client is entitled to a refund under law;
                                (b) Where a Client is entitled to a refund because you have breached these Terms and
                                Conditions;
                                (c) Where you have delivered faulty goods or an inadequate service; or
                                (d) Any other valid reason.
                                Where we require you to refund an amount to a Client, you must do so within 2 business
                                days
                                of being notified of this requirement. Failure to comply
                            </p>
                        </div>
                        <div class="tab-pane" id="sixteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                16 Intellectual Property in the Platform
                                16.1 Nothing in these Terms and Conditions or otherwise creates an assignment of any
                                rights in the
                                Platform beyond the ability to use it for its intended use.
                                16.2 Nothing in these Terms and Conditions or otherwise creates the right for you to
                                sublicense the
                                Platform.
                                16.3 By accepting these Terms and Conditions you undertake not to reverse engineer,
                                replicate, or
                                otherwise copy the Platform. We reserve all our rights to seek compensation, damages,
                                injunctions, or any other remedy available to us at law if any attempt to do so, whether
                                successful or unsuccessful, is made by you, your business or its members, employees,
                                officers,
                                affiliates, customers, or anyone else.
                                16.4 All intellectual property and ownership rights subsisting in the Platform
                                (including the user
                                interface and the software comprising the Platform), the data derived from use of the
                                Platform,
                                and any other documentation, information or materials that are supplied by the Platform
                                to you,
                                remain our or our third party licensors exclusive property. For the avoidance of doubt,
                                nothing
                                in these Terms and Conditions assigns any ownership rights to you.
                            </p>
                        </div>
                        <div class="tab-pane" id="seventeen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>

                                17 Modifications to the Platform
                                17.1 We reserve the right to modify or discontinue, temporarily or permanently the
                                Platform and its
                                products and services with or without notice.
                                17.2 We accept no liability if any such modification or discontinuance causes any loss
                                or damage to you or causes any disruptions to your use of the Platform.
                            </p>
                        </div>
                        <div class="tab-pane" id="eighteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                18 Modifications to the Platform
                                18.1 We reserve the right to modify or discontinue, temporarily or permanently the
                                Platform and its
                                products and services with or without notice.
                                18.2 We accept no liability if any such modification or discontinu
                            </p>

                        </div>
                        <div class="tab-pane" id="nineteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                19 Advertising
                                19.1 We may display advertisements by third party sponsors on the Platform (Ads). In
                                doing so, we
                                11
                                do not warrant the functionality of the business, product, or service being advertised
                                in the Ad.
                                We do not hold ourselves out as partners, affiliates, guarantors, or has having any
                                other type of
                                legal or professional relationship with the third party sponsors.
                                19.2 Where Ads link to third party websites, we do not warrant the safety,
                                functionality, or suitability
                                of these websites. We are in no way liable for any loss, damage, risk or liability
                                incurred from
                                you visiting any third party websites accessible through Ads.
                                19.3 Advertised third party websites may be subject to their own terms and conditions.
                                We do not
                                warrant the validity or suitability of these third party terms and conditions.
                            </p>

                        </div>
                        <div class="tab-pane" id="twenty" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                20 Warranties
                                20.1 By using the Platform, you represent and warrant to use that we will not be liable
                                for any direct
                                or indirect expense, damage or injury (including indirect loss of revenue, profits,
                                goodwill or
                                business opportunity) howsoever caused, including arising directly or indirectly from or
                                in
                                connection with your use of the Platform.
                                20.2 Subject to any of the rights you may have under consumer protection laws, we
                                exclude to the
                                fullest extent possible all implied terms and warranties, whether statutory or
                                otherwise, relating
                                to the Platform or any other matter under these Terms and Conditions.
                            </p>
                        </div>
                        <div class="tab-pane" id="twentyone" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                21 Limitation of liability
                                21.1 The Platform is made available to you on an “as is” basis. We make no warranties,
                                representations or conditions of any kind, express, statutory or implied as to:
                                (a) The operation and functionality of the Platform;
                                (b) the accuracy, integrity, completeness, quality, legality, usefulness, safety and IP
                                rights
                                of any of the Platform content; and/or
                                (c) the products and services associated with the Platform or its content. We further
                                disclaim all warranties, express, statutory or implied, including, but not limited to,
                                implied warranties of merchantability, merchantable quality, durability, fitness for a
                                particular purpose and/or non-infringement. No advice or information, whether oral or
                                written, that you obtain from us shall create any warranty, representation or condition
                                not expressly stated herein.
                                21.2 We disclaim all liability for any:
                                (a) indirect, special, incidental, punitive, exemplary, reliance or consequential
                                damages;
                                (b) loss of profits;
                                (c) business interruption;
                                (d) network interruptions;
                                (e) loss or damage to reputation of us or any third party; or
                                (f) loss of information or data. We further disclaim all liability for any loss or
                                damage
                                arising out of your use of the Platform.
                                21.3 Your use of the Platform is at your own discretion and risk, and you will be solely
                                responsible
                                for any resulting loss or damage, including but not limited to, any loss of data or
                                damage to
                                your computer(s) or networks from viruses that may be downloaded to your computer in the
                                course of using the Platform. Some jurisdictions do not allow the exclusion of certain
                                warranties, the limitation or exclusion of implied warranties, or limitations on how
                                long an
                                12
                                implied warranty may last, so the above limitations may not apply to you. If you reside
                                in such
                                a jurisdiction, the above limitations shall apply to you to the fullest extent permitted
                                under
                                applicable law.
                            </p>

                        </div>
                        <div class="tab-pane" id="twentytwo" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>22 Indemnity
                                22.1 You agree to defend, indemnify and hold harmless us, our members, managers,
                                subsidiaries,
                                affiliates, any related companies, suppliers, licensors and partners, and the officers,
                                directors,
                                employees, agents and representatives of each of them, including costs, liabilities and
                                legal fees,
                                from any claim or demand made by any third party due to or arising out of:
                                (a) Your access to or use of the Platform;
                                (b) Your violation of these Terms and Conditions;
                                (c) Any infringement by you of any intellectual property or other right of any person or
                                entity. We reserve the right, at your expense, to assume the exclusive defence and
                                control of any matter for which you are required to indemnify us and you agree to
                                cooperate with our defence of these claims. You agree not to settle any matter without
                                our prior written consent. We will use reasonable efforts </p>

                        </div>
                        <div class="tab-pane" id="twentythree" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>23 General
                                23.1 To the extent permitted by law, we reserve the right to create, collect and / or
                                store any metadata
                                resulting from your use of the Platform. To the extent permitted by law, we may use
                                metadata
                                for a variety of functions including (but not limited to) conducting investigations,
                                helping us
                                manage and administer the Platform, for purposes of search engine optimisation and to
                                drive
                                traffic to the Platform. To the extent permitted by law, we reserve the right to share
                                your
                                information (including metadata) with government agencies and authorities to the extent
                                this is
                                required or requested of us.
                                23.2 You understand that we use third party vendors and hosting partners to provide the
                                necessary
                                hardware, software, networking, storage, and related technology required to run the
                                Platform.
                                23.3 You must not modify, adapt or hack the Platform or modify another website or
                                software so as
                                to falsely imply that it is associated with the Platform or us.
                                23.4 You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of
                                the Platform,
                                use of the Platform, or access to the Platform without our prior written express
                                permission. We
                                claim copyright and all other intellectual property rights over the Platform and its
                                other products
                                and services and reserves all of our rights.
                                23.5 Verbal, physical, written or other abuse (including threats of abuse or
                                retribution) of any of our
                                employees, members, representatives, or officers may result in you being banned from the
                                Platform.
                                23.6 We do not warrant that:
                                (a) The Platform will meet your specific requirements;
                                (b) The Platform will be uninterrupted, timely, secure, or error-free;
                                (c) The content that may be obtained from the use of the Platform will be accurate or
                                reliable;
                                (d) Any errors in the Platform will be corrected.
                                13
                                23.7 You expressly understand, agree and warrant that we shall not be liable for any
                                direct, indirect,
                                incidental, special, consequential or exemplary damages, including but not limited to,
                                damages
                                for loss of profits, goodwill, use, data or other intangible losses (even if we have
                                been advised
                                of the possibility of such damages), resulting from:
                                (a) the use or the inability to use the Platform;
                                (b) unauthorised access to or alteration of your transmissions or data;
                                (c) or any other matter relating to the Platform.
                                23.8 Our failure to exercise or enforce any right or provision of these Terms and
                                Conditions shall
                                not constitute a waiver of such right or provision. The Terms and Conditions constitutes
                                the
                                entire agreement between you and us and governs your use of the Platform, superseding
                                any
                                prior agreements between you and us.
                                23.9 Any part of these Terms and Conditions that is invalid, prohibited or not
                                enforceable will not
                                void the balance of the Terms and Conditions. All other parts will be enforced without
                                regard
                                to the invalid provision and continue to be valid in accordance with their terms.
                                23.10 We make no representations or warranties that the content of the Platform complies
                                with the
                                laws (including intellectual property laws) of your jurisdiction.
                                23.11 Any queries about the Terms and Conditions should be send to us by email at
                                [care@truventor.ai].
                                23.12 These Terms and Conditions are governed by the laws of the State of Victori</p>

                        </div>

                    </div>


                </div>
            </div>
        </div>
    </div>
</section>