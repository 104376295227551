<!-- Main -->
<section class="main-session">
    <img src="assets/img/banner-images/3d printing@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1> Online <br> <span>3D</span> Printing </h1>
                    <p class="mt-4">
                        Increase resource efficiency and sustainability with our 3D printing services
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch </a>
            </div>
        </div>

        <!-- Statistics -->
        <div class="row statistics d-none d-md-flex">

            <!-- Material Types -->
            <div class="col">
                <div class="small-info">
                    <h6> 20+ </h6>
                    <hr>
                    <p>Material Types</p>
                </div>
            </div>

            <!-- Materials -->
            <div class="col">
                <div class="small-info">
                    <h6> 10+ </h6>
                    <hr>
                    <p>3D Printing Technologies</p>
                </div>
            </div>

            <!-- Customers -->
            <div class="col">
                <div class="small-info">
                    <h6> 100+ </h6>
                    <hr>
                    <p>Machines</p>
                </div>
            </div>

            <div class="col"></div>

        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Our 3D Printing Services -->
<section class="our-3d-printing-service global-space-between">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 order-12 order-md-1 col-md-5">
                <h1 class="text-left">
                    Our 3D printing services
                </h1>
                <p>
                    Considered to be the ‘future of manufacturing’, 3D Printing has revolutionised the way we produce parts. At Truventor, we work in all kinds of 3D printing processes such as FFF/Desktop FDM, SLS, Stereolithography, Micro-SLA and Polyjet.
                    <br><br> From conceptualisation to reality, our team will help you get your desired parts with required accuracy and finish.
                </p>
            </div>
            <div class="col-12 order-1 order-md-12 col-md-6 d-flex justify-content-center">
                <img src="assets/img/online_3d_printing/svg/3D Printing.svg" class="img-fluid machining-img" alt="3D printing">
            </div>
        </div>
    </div>
</section>

<!-- Advantages of 3D Printing  -->
<section class="advantages-of-3d-printing global-space-between">
    <div class="container">
        <div class="row">
            <!-- Side Image - Desktop -->
            <div class="col-sm-12 col-md-6 d-none d-md-block">
                <img src="./assets/img/online_3d_printing/Advantages of 3D Printing.jpg" class="img-fluid" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5 d-flex align-items-center">
                <div class="col-sm-12 col-md-12 p-0">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 col-md-12">
                            <h1 class="text-left"> Advantages of 3D printing </h1>
                        </div>
                    </div>
                    <!-- Side Image - Mobile -->
                    <div class="col-sm-12 col-md-12 p-0 d-block d-md-none">
                        <img src="./assets/img/online_3d_printing/Advantages of 3D Printing.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <p class="my-4">
                        3D printing overcomes the limitations of traditional manufacturing methods. It enables reduction of lead times and lowers costs significantly while ensuring added functionalities, improved performance and reduced overall weight.
                    </p>
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="w-100">
                            <div class="accordion" id="advantages-of-3d-printing">

                                <!-- Flexible design -->
                                <div>
                                    <div id="advantages-of-3d-printing-accordion-one">
                                        <h5 class="text-left"> Flexible design </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                </div>

                                <!-- Faster design to production -->
                                <div>
                                    <div id="advantages-of-3d-printing-accordion-two">
                                        <h5 class="text-left">
                                            Faster design to production
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                </div>

                                <!-- Save weight with complex part designs -->
                                <div>
                                    <div id="advantages-of-3d-printing-accordion-three">
                                        <h5 class="text-left">
                                            Save weight with complex part designs
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                </div>

                                <!-- Save on tooling costs with on-demand 3D printing -->
                                <div>
                                    <div id="advantages-of-3d-printing-accordion-three">
                                        <h5 class="text-left">
                                            Save on tooling costs with on-demand 3D printing
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                </div>

                                <!-- Minimise waste -->
                                <div>
                                    <div id="advantages-of-3d-printing-accordion-three">
                                        <h5 class="text-left">
                                            Minimise waste
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- some cards -->
<section class="some-cards global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>Capabilities
                </h1>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="content">
                    <div class="row row-cols-1 row-cols-md-3">

                        <!-- 3D Printing Process -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0 h-100">
                                <div class="card-body d-flex align-items-end" style="background-color: rgba(16, 24, 32, 1);">
                                    <div class="text-holder d-flex justify-content-between flex-column" style="height: 400px;background-color: rgba(16, 24, 32, 1);">
                                        <div>
                                            <h4 class="card-title">
                                                3D printing process
                                            </h4>
                                            <p class="card-text">
                                                With a large number of manufacturing partners from the 3D printing domain located in different geographies and equipped with diverse capabilities, we can produce complex-low-volume 3D printed parts.
                                            </p>
                                        </div>

                                        <div class="w-100 d-flex align-items-end">
                                            <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light w-100">Book A Demo</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- FFF -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0">
                                <div class="square">
                                    <img src="/assets/img/online_3d_printing/pj/FFF_2@2x.png" class="card-img" alt="Laser Cutting">
                                </div>

                                <div class="card-body d-flex align-items-end">
                                    <div class="text-holder hover-holder">
                                        <h5 class="card-title">FFF</h5>
                                        <p class="card-text">
                                            Fused filament fabrication
                                        </p>
                                    </div>
                                    <div class="w-100 d-none">
                                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light w-100">Book A Demo</a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Micro-SLA -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0">
                                <div class="square">
                                    <img src="/assets/img/online_3d_printing/pj/Micro-SLA@2x.jpg" class="card-img" alt="Laser Cutting">
                                </div>
                                <div class="card-body d-flex align-items-end flex-wrap">
                                    <div>
                                        <h5 class="card-title">
                                            Micro-SLA
                                        </h5>
                                        <p class="card-text">
                                            Micro stereolithography
                                        </p>
                                    </div>
                                    <div class="w-100 d-none">
                                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light w-100">Book A Demo</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- SLA -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0">
                                <div class="square">

                                    <img src="/assets/img/online_3d_printing/pj/SLA@2x.jpg" class="card-img" alt="Laser Cutting">
                                </div>
                                <div class="card-body d-flex align-items-end flex-wrap">
                                    <div>
                                        <h5 class="card-title">
                                            SLA
                                        </h5>
                                        <p class="card-text">
                                            Stereolithography
                                        </p>
                                    </div>
                                    <div class="w-100 d-none">
                                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light w-100">Book A Demo</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- PJ -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0">
                                <div class="square">

                                    <img src="/assets/img/online_3d_printing/pj/PJ@2x.jpg" class="card-img" alt="Laser Cutting">
                                </div>
                                <div class="card-body d-flex align-items-end flex-wrap">
                                    <div>
                                        <h5 class="card-title">
                                            PJ
                                        </h5>
                                        <p class="card-text">
                                            Polyjet
                                        </p>
                                    </div>
                                    <div class="w-100 d-none">
                                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light w-100">Book A Demo</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- SLS -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0">
                                <div class="square">

                                    <img src="/assets/img/online_3d_printing/pj/SLS@2x.jpg" class="card-img" alt="Laser Cutting">
                                </div>
                                <div class="card-body d-flex align-items-end flex-wrap">
                                    <div>
                                        <h5 class="card-title">
                                            SLS
                                        </h5>
                                        <p class="card-text">
                                            Selective laser sintering
                                        </p>
                                    </div>
                                    <div class="w-100 d-none">
                                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light w-100">Book A Demo</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Industrial-grade 3D printing materials -->
<section class="industrial-grade-3d-printing-materials top-materials global-space-between" id="3dprinting-material">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h1 class="py-3"> Industrial-grade 3D printing materials </h1>
                        </div>
                        <div class="col-12 col-md-6 d-flex align-items-center">
                            With new materials introduced for 3D Printing applications every day, it can be tricky to select the right material for your application.
                        </div>
                    </div>
                    <!-- Table -->
                    <div class="row my-4">
                        <div class="col">

                            <!-- Collapse One -->

                            <div class="card border-0 mt-2" id="industrial-grade-3d-printing-materials">

                                <div class="card-head">
                                    <h2 class="mb-0 px-0 card-header border-dark bg-transparent" data-toggle="collapse" data-target="#industrial-grade-3d-printing-collapse" aria-expanded="false" aria-controls="industrial-grade-3d-printing-collapse">
                                        Top plastics
                                    </h2>
                                </div>


                                <div id="industrial-grade-3d-printing-collapse" class="collapse show" aria-labelledby="industrial-grade-3d-printing-accordion-two" data-parent="#industrial-grade-3d-printing-materials">
                                    <div class="card-body">

                                        <div *ngFor="let plastic of threedPrintingMaterial">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-md-2 p-md-0">
                                                    <img src="{{plastic?.imgUrl}}" class="img-fluid list-image" alt="Truventor">
                                                </div>
                                                <div class="col-12 col-md-6 pl-3 pl-md-0">
                                                    <div class="row align-items-center">
                                                        <div class="col-12 col-md-6">
                                                            <b> {{plastic?.name}} </b>
                                                            <p class="m-0"> {{plastic?.label}} </p>
                                                        </div>
                                                        <div class="col-12 col-md-6">
                                                            <p class="m-0"> {{plastic?.description}} </p>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-4">
                                                    <div class="text-right mt-2 mt-md-0">
                                                        <a href="{{plastic?.url}}" class="btn btn-primary py-2 px-4 mt-md-0 mr-2">
                                                            Know More
                                                        </a>
                                                        <a [routerLink]="['/contact']"  rel="noopener" class="btn float-left float-md-right py-2 px-4 mt-md-0">
                                                            Get In Touch
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col p-0">
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Surface Finishing 3D Printing  -->
<section class="surface-finishing-3d-printing exapandable-accordion global-space-between">
    <div class="container">
        <!-- Title -->
        <div class="row">
            <div class="col p-md-0">
                <h1 class="py-4 text-left">
                    Post processing options

                </h1>
            </div>
        </div>
        <!-- accordion -->
        <div class="row align-items-center">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-6 p-3 p-md-0 d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start">
                <img src="./assets/img/online_3d_printing/Surface Finishing 3D Printing@2x.png" class="img-fluid w-100" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5">
                <!-- collapse -->
                <div class="w-100">
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="col-sm-12 col-md-12 pl-0">


                            <div id="surface-finishing-cnc-machine" class="myaccordion">
                                <div class="card">
                                    <div class="card-header" id="growheadingOne">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100" data-toggle="collapse" data-target="#growcollapseOne" aria-expanded="true" aria-controls="growcollapseOne">
                                                SANDING
                                                <i class="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseOne" class="collapse show" aria-labelledby="growheadingOne" data-parent="#surface-finishing-cnc-machine">
                                        <div class="card-body">
                                            <p>Sanding is a popular and easiest finishing technique in 3D printing. This method involves using a type of rough material like sandpaper to smooth out the surface.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingTwo">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseTwo" aria-expanded="false" aria-controls="growcollapseTwo">
                                                BEAD BLASTING
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseTwo" class="collapse" aria-labelledby="growheadingTwo" data-parent="#surface-finishing-cnc-machine">
                                        <div class="card-body">
                                            <p>One of the most used finishing process for 3D printing is bead blasting. In this method, little beads are blasted against a 3D part to smooth out its appearance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingThree">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseThree" aria-expanded="false" aria-controls="growcollapseThree">
                                                TUMBLING
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseThree" class="collapse" aria-labelledby="growheadingThree" data-parent="#surface-finishing-cnc-machine">
                                        <div class="card-body">
                                            <p>This method is mostly used to polish metals. It is also used to soften materials such as ceramic, plastic or synthetic.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingFour">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseFour" aria-expanded="false" aria-controls="growcollapseFour">
                                                VAPOUR SMOOTHING
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseFour" class="collapse" aria-labelledby="growheadingFour" data-parent="#surface-finishing-cnc-machine">
                                        <div class="card-body">
                                            <p>Vapour smoothing works by melting the surface of the 3D part using a solvent. This process involves the exposing of the 3D part to a vapour chamber for seconds resulting in liquefied plastic.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingFive">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseFive" aria-expanded="false" aria-controls="growcollapseFive">
                                                PLATING
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseFive" class="collapse" aria-labelledby="growheadingFive" data-parent="#surface-finishing-cnc-machine">
                                        <div class="card-body">
                                            <p>Plating is a process of covering a part with metal to give the parts additional functionalities and benefits.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingSix">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseSix" aria-expanded="false" aria-controls="growcollapseSix">
                                                COATING AND PAINTING
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseSix" class="collapse" aria-labelledby="growheadingSix" data-parent="#surface-finishing-cnc-machine">
                                        <div class="card-body">
                                            <p>Parts that need colouring would ideally be printed using white material. Before the model is painted a layer of primer is usually applied. Painting can be done manually using a brush or spray.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Application Spotlight -->
<section class="application-spotlight sheet-fab-applications global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <!-- Title -->
                <h1 class="text-center pb-5"> Application spotlight </h1>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3">

            <!-- Inito using 3D Printing for manufacturing their Parts. -->
            <div class="col mb-4">
                <div class="card border-0">
                    <img src="/assets/img/online_3d_printing/Inito using 3D Printing for manufacturing their Parts.@2x.jpg" class="card-img-top" alt="consumer goods">
                    <div class="card-body px-0 px-md-3">
                        <h4 class="card-title py-1"> Inito uses 3D printing to manufacture parts.</h4>
                        <div class="py-1 d-flex justify-content-start align-items-cener flex-wrap">
                            <kbd>3D PRINTING</kbd>
                            <kbd>Selective Laser Sintering</kbd>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 3D printed Jewellry wax part for investment casting  -->
            <div class="col mb-4">
                <div class="card border-0">
                    <img src="/assets/img/online_3d_printing/3D-printed jewellery wax part for investment casting@2x.png" class="card-img-top" alt="consumer goods">
                    <div class="card-body px-0 px-md-3">
                        <h4 class="card-title py-1"> 3D-printed jewellery wax part for investment casting </h4>
                        <div class="py-1 d-flex justify-content-start align-items-cener flex-wrap">
                            <kbd>3D PRINTING</kbd>
                            <kbd>Micro Stereolithography</kbd>
                        </div>
                    </div>
                </div>
            </div>

            <!-- High Temperature withstanding enclosures -->
            <div class="col mb-4">
                <div class="card border-0">
                    <img src="/assets/img/online_3d_printing/High Temperature withstanding enclosures@2x.jpg" class="card-img-top" alt="consumer goods">
                    <div class="card-body px-0 px-md-3">
                        <h4 class="card-title py-1"> High temperature withstanding enclosures </h4>
                        <div class="py-1 d-flex justify-content-start align-items-cener flex-wrap">
                            <kbd>3D PRINTING</kbd>
                            <kbd>Micro SLA</kbd>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<app-quality-assurance></app-quality-assurance>


<!-- Schedule a meeting or book a demo -->
<section class="amazing-quote schedule-a-meeting-3d-printing global-space-between">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div>
                    <!-- Quote -->
                    <h1 class="text-center text-white">
                        Schedule a <br> meeting or <br> book a demo
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center mt-4">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light px-md-5">
                            Request a call back </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- 3D Printing Pricing -->
<section class="printing-pricing global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1>3D printing pricing</h1>
                    <p>
                        Manufacturing costs depends <br> directly on several factors <br> including:
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row row-cols-2 row-cols-md-2 right">
                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <h5> Type of material chosen </h5>
                        <p> Different procurement rates are associated with different materials. </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <h5> Part volume </h5>
                        <p> Amount of material being consumed by your part geometry. </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <h5> Amount of support structures required </h5>
                        <p> Support structures are generated on the basis of part geometry </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <h5> Manufacturing time </h5>
                        <p> Depends upon the material, process, part size, volume and orientation </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 5 </div>
                        <hr>
                        <h5> Bounding box dimensions </h5>
                        <p> Plays a major role in cost because of the space part occupies in the build. </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 6 </div>
                        <hr>
                        <h5> Quantity </h5>
                        <p> The size of the order impacts the pricing </p>
                    </div>

                    <div class="col p-3 " data-aos="fade-up">
                        <div class="circle"> 7 </div>
                        <hr>
                        <h5> Post processing </h5>
                        <p> Depends on the efforts needed in polishing, painting and cleaning the support structures
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 8 </div>
                        <hr>
                        <h5> MOQ </h5>
                        <p>MOQ may be applied depending on the product and quantity. </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Check out our other offerings -->
<section class="check-out-other-offer sheet-fab-other-offering check-out-our-other-offerings global-space-between bottom-end-padding">
    <div class="container">
        <div class="row pb-5">
            <div class="col">
                <h1 class="text-center pb-5"> Check out our other offerings </h1>
            </div>
        </div>
        <div class="row row-cols-2 row-cols-md-5 justify-content-center">

            <!-- Vaccum Casting -->
            <div class="col p-4 py-md-0 pointer" (click)="vacuumCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/online_3d_printing/svg/Vacuum Casting.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Vacuum<br>Casting</p>
                    </div>
                </div>
            </div>

            <!-- Injection Molding -->
            <div class="col p-4 py-md-0 pointer" (click)="injectionMolding()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/online_3d_printing/svg/Injection Moulding.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Injection<br>Molding</p>
                    </div>
                </div>
            </div>

            <!-- Metal Casting -->
            <div class="col p-4 py-md-0 pointer " (click)="metalCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/online_3d_printing/svg/Metal Casting.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Metal<br>Casting</p>
                    </div>
                </div>
            </div>

            <!-- Sheet Metal -->
            <div class="col p-4 py-md-0 pointer" (click)="sheetMetal()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/online_3d_printing/svg/Sheet Metal.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Sheet<br>Metal</p>
                    </div>
                </div>
            </div>

            <!-- CNC Machining -->
            <div class="col p-4 py-md-0 pointer" (click)="cncmachining()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/online_3d_printing/svg/CNC Machining.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">CNC<br>Machining</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>