import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../shared/admin.service';
import { reloadTime, VACUUM_CASTING_METAL } from '../../shared/data/index'
@Component({
  selector: 'app-vaccum-casting',
  templateUrl: './vaccum-casting.component.html',
  styleUrls: ['./vaccum-casting.component.scss']
})
export class VaccumCastingComponent implements OnInit {

  private fragment: string;
  public vacuumData = VACUUM_CASTING_METAL;
  constructor(
    private adminservice: AdminService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      if (this.fragment != 'vacuumcasting-material') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        setTimeout(() => {
          document.getElementById('vacuumcasting-material').scrollIntoView();          
        }, reloadTime);
      }
    }); 

    this.adminservice.check_customer.next('customer')
  }


  // ngAfterViewInit(): void {
  //   try {
  //     document.querySelector('#' + this.fragment).scrollIntoView(
  //       {
  //         behavior: 'smooth'
  //       }
  //     );
  //   } catch (e) { }
  // }


  threedPrinting() {
    this.router.navigate(['/3d-printing']);
  }

  injectionMolding() {
    this.router.navigate(['/plastic-injection-moulding']);
  }

  metalCasting() {
    this.router.navigate(['/metal-casting']);
  }

  cncmachining() {
    this.router.navigate(['/cnc-machining']);
  }

  sheetMetal() {
    this.router.navigate(['/sheet-metal-fabrication']);
  }

}
