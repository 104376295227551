import { Component, OnInit } from '@angular/core';
import { AdminService } from '../shared/admin.service';

@Component({
  selector: 'app-cncmachine-subcapabilities',
  templateUrl: './cncmachine-subcapabilities.component.html',
  styleUrls: ['./cncmachine-subcapabilities.component.scss']
})
export class CncmachineSubcapabilitiesComponent implements OnInit {


  constructor(
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.adminService.check_customer.next('customer')
  }

}
