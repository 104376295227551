<!-- Main -->
<section class="main-session">
    <img src="assets/img/case-studies/Case study banner image.jpg" class="img-banner" loading="lazy" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>
                        Case Studies
                    </h1>
                    <p class="mt-4">
                        Real-life examples and success stories of how our brand helps customers reach their goals.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row mt-5">
            <div class="col-sm-12 col-md-4">
                <a href="#" class="btn btn-primary w-100"> Subscribe </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Applications -->
<section class="application global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <!-- Title -->
                <h1 class="text-center pb-5"> Case studies </h1>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3">

            <!-- Automotive -->
            <div class="col mb-4">
                <a href="/assets/pdf/CaseStudy1.pdf" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/case-studies/Truventor Cloud Manufacturing Platform helps Divide By Zero simplify the part procurement@2x.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <h4 class="card-title py-2">
                            Truventor Cloud Manufacturing Platform helps Divide By Zero simplify the part procurement
                        </h4>
                        <p>
                            Truventor's cloud manufacturing platform helped DBZ with accountability in supply chain.
                        </p>
                        <kbd> CNC Machining </kbd>
                        <kbd> Milling </kbd>
                    </div>
                </a>
            </div>

            <!--  -->
            <div class="col mb-4">
                <a href="/assets/pdf/CaseStudy3.pdf" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/case-studies/Truventor and Objectify partnership, six years and going strong@2x.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <h4 class="card-title py-2">
                            Truventor and Objectify partnership, six years and going strong
                        </h4>
                        <p>
                            Truventor shared the first enquiry with Objectify in May 2015, and the response from the young team was quick and reasonable
                        </p>
                        <kbd> Manufacturing </kbd>
                        <kbd> IOT </kbd>
                    </div>
                </a>
            </div>

            <!-- Heavy Equipment -->
            <div class="col mb-4">
                <a href="/assets/pdf/CaseStudy2.pdf" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/case-studies/Mahindra Design Studio achieves higher productivity and faster delivery with Truventor@2x.jpg" class="card-img-top" alt="consumer goods">
                    <div class="card-body px-3">
                        <h4 class="card-title py-2">
                            Mahindra Design Studio achieves higher productivity and faster delivery with Truventor
                        </h4>
                        <p>
                            With the help of Truventor’s AI-backed platform, Mahindra could reach out to 20+ suppliers at one go cutting down on the time.
                        </p>
                        <kbd> 3D PRINTING </kbd>
                        <kbd> CNC </kbd>
                    </div>
                </a>
            </div>

        </div>
    </div>
</section>

<!-- Last Section -->
<section class="global-space-between bottom-end-padding a-trueventor">
    <div class="container-fluid">
        <div class="row">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-4 p-0 d-none d-md-block">
                <img src="./assets/img/customer_landing/Book a Demo.jpg" width="100%" loading="lazy" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-8 pl-md-5 d-flex align-items-center">
                <div class="w-100">
                    <!-- Title -->
                    <h1>
                        Do you want <br> a free quote <br> in 24 hours?
                    </h1>
                    <!-- Paragraph -->
                    <p class="mt-4 mb-0">
                        Sign up for a free trial and find out how <br> Truventor makes Manufacturing simple.
                    </p>
                    <!-- Action Buttons -->
                    <div class="row">

                        <div class="col-sm-12 col-md-4">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                                Get In Touch
                            </a>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn w-100">
                                Book A Demo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>