<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Brass C360@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Brass <br> C360
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Excellent high speed machining operations and superior thread rolling and knurling characteristics. </p>
                </div>
            </div>
        </div>



        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Brass C360.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Brass 360 —also referred to as free-machining Brass—is a copper-zinc alloy known for having the highest machinability among all of the copper alloys and exceptional strength and corrosion resistance. Brass alloys can be easily shaped and are available
                    in different colors. Although ductile in its softened state, 360 Brass is a strong material to work with and maintains its strength even under some of the most demanding conditions.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Brass C360
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Brass C360 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Tensile Strength (PSI)
                            </th>
                            <td>
                                58,000
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Yield Strength (PSI)
                            </th>
                            <td>
                                45,000
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Elongation
                            </th>
                            <td>
                                in 2″: 25
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Rockwell Hardness
                            </th>
                            <td>
                                B78
                            </td>
                        </tr>


                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            High strength
                        </p>
                        <p class="mb-2">
                            Good corrosion resistance
                        </p>
                        <p class="mb-2">
                            Good for precision machining
                        </p>
                        <p class="mb-2">
                            Ideal for heavy industrial part
                        </p>

                        <hr>
                    </div>
                </div>

                <!-- Two -->


                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Industrial Hardware
                        </p>
                        <p class="mb-2">
                            Plumbing
                        </p>
                        <p class="mb-2">
                            Electrical Components
                        </p>
                        <p class="mb-2">
                            Fittings
                        </p>
                        <p class="mb-2">
                            Shafts
                        </p>


                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<app-cost-estimate></app-cost-estimate>