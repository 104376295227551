<div class="row row-cols-3 row-cols-sm-3 row-cols-md-5 px-md-5" data-aos="fade-up">
    <div class="col">
        <img src="./assets/img/customer_landing/logo/Volvo.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
    <div class="col">
        <img src="./assets/img/customer_landing/logo/Eaton.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
    <div class="col">
        <img src="./assets/img/customer_landing/logo/Mahindra.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
    <div class="col">
        <img src="./assets/img/customer_landing/logo/Johnson Controls.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
    <div class="col">
        <img src="./assets/img/customer_landing/logo/LAM Research.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
    <div class="col">
        <img src="./assets/img/customer_landing/logo/Wabco.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
    <div class="col">
        <img src="./assets/img/customer_landing/logo/Sedemac.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
    <div class="col">
        <img src="./assets/img/customer_landing/logo/Tata Elxsi.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
    <div class="col">
        <img src="./assets/img/customer_landing/logo/Inito.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
    <div class="col d-none d-md-block">
        <img src="./assets/img/customer_landing/logo/Funskool.jpg" width="100%" loading="lazy" alt="Truventor">
    </div>
</div>