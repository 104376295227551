<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Stainless Steel 316@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Stainless <br> Steel 316
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Stainless Steel 316 gives excellent toughness, even at cryogenic temperatures. </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Stainless Steel 316.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Type 316 stainless steel is austenitic steel that differs from grade 304 through the addition of molybdenum, which increases its resistance to chemical corrosion and marine environments. These materials excel in harsher atmospheres, where pitting and
                    crevice corrosion can occur through chloride exposure or solvents used in processing.




                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Stainless Steel 316
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Stainless Steel 316 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Melting Range
                            </th>
                            <td>
                                2540-2630°F (1390-1440°C)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Density
                            </th>
                            <td>
                                0.29 lb/in³ (8.027 g/cm³)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Modulus of Elasticity
                            </th>
                            <td>
                                29 x 106 psi (200 GPa)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Modulus of Shear
                            </th>
                            <td>
                                11.9 x 106 psi (82 Gpa)
                            </td>
                        </tr>


                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Ideal for welding applications
                        </p>
                        <p class="mb-2">
                            High strength
                        </p>
                        <p class="mb-2">
                            Good Machinability
                        </p>
                        <p class="mb-2">
                            Excellent toughness
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            High cost
                        </p>
                        <p class="mb-2">
                            Corrosion in Marine Environments
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Oil & petroleum refining equipment
                        </p>
                        <p class="mb-2">
                            Aerospace structures
                        </p>
                        <p class="mb-2">
                            Automotive parts
                        </p>
                        <p class="mb-2">
                            Pharmaceutical processing equipment
                        </p>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>