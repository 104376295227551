<!-- Main -->
<section class="main-session">
    <img src="./assets/img/material_template/Group 19438.jpg" class="img-banner d-none d-md-block" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        ABS (Basic)
                    </h1>
                    <p class="text-primary">
                        3D Printing | FFF
                    </p>
                    <p class="mt-4">
                        It is well suited for cost-effective prototypes and models requiring form and visual validation.
                    </p>
                </div>
            </div>
        </div>
        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19438@2x.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>
        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/ABS (Basic).pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    ABS (Basic) is a widely used thermoplastic. When combined with Fused Filament Fabrication (FFF) systems, the material is ideal for rapid prototyping. ABS (Basic) comes in the form of a filament and is well suited for cost-effective prototypes and models
                    requiring form and visual validation. ABS (Basic) filament is white in colour, and the parts have a rough surface finish.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Acrylonitrile Butadiene Styrene (ABS)
                    </strong>
                </h6>
                <img src="./assets/img/material_template/Group 19452.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                White
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-)500µm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                550 x 550 x 550 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships in
                            </th>
                            <td>
                                1-2 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Honeycomb structure to make the models hollow
                        </p>
                        <p class="mb-2">
                            Easy material to work with for post-processing

                        </p>
                        <p class="mb-2">
                            Best ABS Material properties
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Rough surface finish
                        </p>
                        <p class="mb-2">
                            Support structure generation
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Prototypes for form testing
                        </p>
                        <p class="mb-2">
                            Concept models
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <h1 class="mb-5">
                    3D Printing Design Guidelines For ABS
                </h1>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">

                    <!-- One -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/abs-basic/Wall Thickness@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1.2mm</span>. For decent strength: <span class="text-primary">1.5mm</span> & For rigid wall: <span class="text-primary">2mm</span>.
                        </p>
                    </div>

                    <!-- Two -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/abs-basic/Minimum Detail Size@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Minimum detail size
                        </h6>
                        <p class="mt-2">
                            The minimum supported feature thickness: At least <span class="text-primary">0.8 mm</span> .
                        </p>
                    </div>

                    <!-- Three -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/abs-basic/Embossing And Engraving@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Embossing and Engraving:
                        </h6>
                        <p class="mt-2">
                            Letter strand width: at least <span class="text-primary">0.8mm</span> Embossing height: at least <span class="text-primary">0.7mm</span> Engraving (cavity) depth: at least <span class="text-primary">0.7mm</span>
                        </p>
                    </div>

                    <!-- Four -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/abs-basic/Overhangs@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Overhangs
                        </h6>
                        <p class="mt-2">
                            description <span class="text-primary"> colorful </span> If the length of the wall is greater than 50mm (5 cm), then a minimum wall thickness of
                            <span class="text-primary">2.5mm</span> should be kept to impart decent strength.
                        </p>
                    </div>

                    <!-- Five -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/abs-basic/Overhangs@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Support Structures
                        </h6>
                        <p class="mt-2">
                            Since ABS parts are fabricated in Fused Filament Fabrication (FFF) technology, support structures are generated on part geometries with overhangs and unsupported features.
                        </p>
                    </div>

                </div>


            </div>
        </div>
    </div>
</section>

<!-- post_processing -->
<section class="post_processing global-space-between">
    <div class="container-fluid">
        <div class="row justify-content-end">
            <!-- Heading -->
            <div class="col col-12 col-md-5 pr-md-5">
                <h1>
                    POST- <br> PROCESSING
                </h1>
                <p>
                    Some of the post-processing options available for ABS (Basic) parts are as follows:
                </p>
                <hr>
                <h6>
                    Sand Polishing
                </h6>
                <hr>
                <h6>
                    Painting (options)
                    <!-- Color boxes -->
                    <div class="color-options">
                        <div class="option red"></div>
                        <div class="option cyan"></div>
                        <div class="option yellow"></div>
                        <div class="option black"></div>
                    </div>
                </h6>
                <hr>
                <a [routerLink]="['/contact']" rel="noopener" class="btn my-4">
                    Get In Touch
                </a>
            </div>
            <!-- Image -->
            <div class="col col-12 col-md-6 px-0">
                <img src="./assets/img/material_template/Group 19464.jpg" class="img-fluid w-100" alt="Truventor">
            </div>
        </div>
    </div>
</section>

<app-cost-estimate></app-cost-estimate>