<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/ABS 9012 2@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        ABS 9012
                    </h1>
                    <p class="text-primary">
                        3D Printing / Vacuum Casting
                    </p>
                    <p class="mt-4">
                        It is well suited for cost-effective prototypes and models requiring form and visual validation.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/ABS 9012.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    ABS is an amorphous plastic created by combining acrylonitrile, butadiene, and styrene polymers. ABS 9012 is a food-grade plastic safe for use in food processing tools like food processors and refrigerator linings. Excellent resistance to strain and abrasion,
                    maintained impact resistance at low temperatures, and insulating properties make ABS 9012 great for food transportation applications as well.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5">
                    Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        ABS 9012
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/ABS 9012 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Carbon
                            </th>
                            <td>
                                C 0.14 - 0.20 %
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Iron
                            </th>
                            <td>
                                Fe 98.81 - 99.26 % (as remainder)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Manganese
                            </th>
                            <td>
                                Mn 0.60 - 0.90 %
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Phosphorous <br> Sulfur
                            </th>
                            <td>
                                P ≤ 0.040 % <br> S ≤ 0.050 %
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">
                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            High electrical insulating properties
                        </p>
                        <p class="mb-2">
                            Abrasion and stain resistance
                        </p>
                        <p class="mb-2">
                            Structural/Dimensional stability
                        </p>
                        <p class="mb-2">
                            Strong & durable
                        </p>
                        <p class="mb-2">
                            Chemically resistant resin
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Prone to stress cracking
                        </p>
                        <p class="mb-2">
                            Poor weathering and solvent resistance
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Food processing components
                        </p>
                        <p class="mb-2">
                            Dry and wet food container usage
                        </p>
                        <p class="mb-2">
                            Food Packaging
                        </p>
                        <p class="mb-2">
                            Fridge components
                        </p>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>