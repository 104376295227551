<!-- Main -->
<section class="main-session">
    <img src="assets/img/sheet_metal_capabilities/welding/Group 20167.jpg" class="img-banner d-none d-md-block" alt="Truventor">
    <img src="assets/img/sheet_metal_capabilities/welding/Group 20169.jpg" class="img-banner d-block d-md-none" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        Welding
                    </h1>
                    <p class="mt-4">
                        Explore our welding operations with a wide variety of materials
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Description -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    Welding
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    Welding is the process of joining or fusing materials together with the application of heat. There are 3 types of welding techniques that can be used on sheet metal, namely, gas welding, MIG (metal inert gas) welding and TIG (tungsten inert gas) welding.
                    While MIG welding has the advantage of elasticity and can be used for both very thick and thin metals, TIG is preferred where a high-precision level is required.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div>
                    <div class="row align-items-center my-3">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/Stainless Steel, Carbon Steel.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-8">
                            <h5>
                                Steel, stainless steel
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row align-items-center my-3">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/Aluminum-Copper.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-8">
                            <h5>
                                Aluminum, copper, nickel
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Laser power</th>
                            <td>
                                1-3 KW
                            </td>
                        </tr>
                        <tr>
                            <th>Maximum laser energy</th>
                            <td>
                                25 J
                            </td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>
                                2 - 3 business days
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Most Common Type -->
<section class="side-tab-view-toggle global-space-between">
    <div class="container">

        <div class="row">
            <div class="col">
                <h1 class="text-center mb-5">
                    Types of welding processes
                </h1>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-12 col-md-6">
                <div class="tab-content" id="main_id">
                    <div class="tab-pane show active" id="slider_1" role="tabpanel" aria-labelledby="slider_1-tab">
                        <img src="./assets/img/sheet_metal_capabilities/welding/Group 20173@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_2" role="tabpanel" aria-labelledby="slider_2-tab">
                        <img src="./assets/img/sheet_metal_capabilities/welding/Group 20173@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_3" role="tabpanel" aria-labelledby="slider_3-tab">
                        <img src="./assets/img/sheet_metal_capabilities/welding/Group 20173@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="slider_1-tab" data-toggle="pill" href="#slider_1" role="tab" aria-controls="slider_1" aria-selected="true">
                        <span class="toggle">
                            MIG Welding
                        </span>
                        <hr>
                        <p>
                            Metal inert gas (MIG) welding are gas metal arc welding processes that use heat created from a DC electric arc between a consumable metal electrode and a workpiece which melt together to create a weld pool that fuses to form a join. MIG or MAG welding
                            are used where high productivity and high flexibility of use are required.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_2-tab" data-toggle="pill" href="#slider_2" role="tab" aria-controls="slider_2" aria-selected="false">
                        <span class="toggle">
                            TIG welding
                        </span>
                        <hr>
                        <p>
                            Tungsten inert gas (TIG) welding is one of the most popular welding methods since it provides high quality components. Especially indicated to weld thin parts, it can be used for both continuous and spot welding.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_3-tab" data-toggle="pill" href="#slider_3" role="tab" aria-controls="slider_3" aria-selected="false">
                        <span class="toggle">
                            Arc welding
                        </span>
                        <hr>
                        <p>
                            Arc welding uses an electric arc to melt materials before joining them together. To melt the metals at the point of contact, a power supply is used to create an electric arc between a consumable or non-consumable electrode and the base metal.
                        </p>
                    </a>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- Application Of Metal Bending -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1>
                        Advantages of welding process
                    </h1>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row row-cols-2 row-cols-md-2">
                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <p>
                            Welding creates powerful joints which will not accidentally loosen or disassemble
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <p>
                            Welded joints offer structural efficiency and integrity against leaks
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <p>
                            The process can be used for a wide variety of materials
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <p>
                            Cost-effective
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant welding quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <img src="assets/img/sheet_metal_capabilities/welding/Group 13311.jpg" class="fluid-img w-100" alt="video">
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>