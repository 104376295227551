<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Tool Steel W Grade@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Tool Steel W <br> Grade
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        This grade of tool steel provides maximum toughness and adequate wear-resistance
                    </p>
                </div>
            </div>
        </div>



        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Tool Steel W Grade.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Known as Water Hardening tool steel, this group contains low-cost high carbon steels with high hardness. The price factor makes it the most widely used amongst tool steels. However, fragility is a side-effect of the W-grade’s hardness. Also, they are
                    not suitable for working at elevated temperatures. This is basically a high carbon steel. While it generally has a lower cost it cannot be used where high temperatures are involved.

                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Tool Steel W Grade
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Tool Steel W Grade 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Tensile Strength
                            </th>
                            <td>
                                1680 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Yield strength
                            </th>
                            <td>
                                3.50%
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Elastic modulus
                            </th>
                            <td>
                                190-210 GPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Bulk modulus
                            </th>
                            <td>
                                80.0 GPa
                            </td>
                        </tr>


                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Cost effective
                        </p>
                        <p class="mb-2">
                            High carbon content
                        </p>
                        <p class="mb-2">
                            Maximum toughness
                        </p>
                        <p class="mb-2">
                            Adequate wear-resistance
                        </p>

                        <hr>
                    </div>
                </div>


                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Blanking and Cartridge Dies
                        </p>
                        <p class="mb-2">
                            Forming Dies and gauges
                        </p>
                        <p class="mb-2">
                            Cutters and knifes
                        </p>
                        <p class="mb-2">
                            Lathe tools
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>