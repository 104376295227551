<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/ABC Black(PX-223HT)@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        ABCBlack <br> (PX-223HT)
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Low cost steel with moderate strength, good case hardening properties, formability and weldability. </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/ABS_Black PX223HT.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6"> 
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Mild Steel is basically iron containing a small percentage of carbon, and is strong and tough but not readily tempered. It also known as plain-carbon steel and low-carbon steel and is now the most common form of steel because its price is relatively low
                    while it provides material properties that are acceptable for many applications. Mild steel contains approximately 0.05–0.25% carbon.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Acrylonitrile Butadiene Styrene (ABS)
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/ABC Black(PX-223HT) small@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                Milky-White
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-)150µm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                650 x 600 x 400 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships in
                            </th>
                            <td>
                                3-4 business days
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Good hardness and efficient malleability
                        </p>
                        <p class="mb-2">
                            Good formability and weldability
                        </p>
                        <p class="mb-2">
                            Affordable and easily available in different shapes and sizes
                        </p>
                        <p class="mb-2">
                            Good choice for general purpose metal parts
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Not suited for high stress application
                        </p>
                        <p class="mb-2">
                            Relatively heavy parts than Aluminium thus not suited for application in automotive, aerospace etc.
                        </p>
                        <p class="mb-2">
                            Not suitable for food packaging or medicinal usage or any health related application </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Rivets and parts requiring strong welds
                        </p>
                        <p class="mb-2">
                            General purpose fabrication parts
                        </p>
                        <p class="mb-2">
                            Machinery parts
                        </p>
                        <p class="mb-2">
                            Jigs and fixtures
                        </p>
                        <p class="mb-2">
                            Axles, shafts, bolts, and pins
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>