<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/nylonvc-3365/Nylon-VC 3365@2x.png" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Nylon-VC <br> 3365
                    </h1>
                    <p class="text-primary">
                        Vacuum Casting
                    </p>
                    <p class="mt-4">
                        Specifically designed for use in gravity vacuum casting machines.
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Nylon-VC 3365.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    AISI 1018 mild/low carbon steel has excellent weldability and produces a uniform and harder case and it is considered as the best steel for carburized parts. AISI 1018 mild/low carbon steel offers a good balance of toughness, strength and ductility. There
                    are many different categories of steel, and carbon steels are one such category that contains 0.12 to 2% carbon in them. The steel gains hardness and strength with heat treatment when the carbon percentage content increases; however
                    its ductility is reduced. SAE 1018 carbon steel is a free machining grade that is the most commonly available grade around the world.


                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Nylon-VC 3365
                    </strong>
                </h6>
                <img src="./assets/img/material_list/nylonvc-3365/Nylon-VC -1@2x.png" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Carbon
                            </th>
                            <td>
                                C 0.14 - 0.20 %
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Iron
                            </th>
                            <td>
                                Fe 98.81 - 99.26 % (as remainder)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Manganese
                            </th>
                            <td>
                                Mn 0.60 - 0.90 %
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Phosphorous <br> Sulfur,
                            </th>
                            <td>
                                P ≤ 0.040 % <br> S ≤ 0.050 %
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Good Strength And Ductility
                        </p>
                        <p class="mb-2">
                            Good Machining Characteristics
                        </p>
                        <p class="mb-2">
                            Suitable For Welding, Forging, Machining, And Heat Treating
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Poor Corrosion Resistance
                        </p>

                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Fixtures And Mounting Plates
                        </p>
                        <p class="mb-2">
                            Shafts Spindle
                        </p>
                        <p class="mb-2">
                            Pump Shaft
                        </p>
                        <p class="mb-2">
                            Hydraulic Shaft
                        </p>

                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>