import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-starupprogram',
  templateUrl: './starupprogram.component.html',
  styleUrls: ['./starupprogram.component.scss']
})
export class StarupprogramComponent implements OnInit {

  constructor() { }

  
  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

}
