<!-- Main -->
<section class="main-session">
    <img src="assets/img/3d_printing_capabilities/fff/Group20083@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        Fused Filament Fabrication (FFF)
                    </h1>
                    <p class="mt-4">
                        We provide a range of material options for FFF operations
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!--  -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    Fused filament fabrication (FFF)
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    Fused filament fabrication (FFF) is an extrusion based 3D printing technology used to manufacture cost-effective prototypes. It finds application in parts requiring form and visual validation. It’s a desktop version of the industrial-grade fused deposition
                    modeling (FDM) systems.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div class="mt-5 mt-md-0">
                    <div class="row my-3 align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/3d_printing_capabilities/fff/Group 19359.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                ABS
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row my-3 align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/3d_printing_capabilities/fff/Group 19360.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                PLA (basic)
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Max part size</th>
                            <td>
                                550 x 550 x 550 mm
                            </td>
                        </tr>
                        <tr>
                            <th>Accuracy </th>
                            <td>
                                (+/-) 500µm
                            </td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>
                                2 - 3 business days
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!--  -->
<section class="side-tab-view-toggle global-space-between">
    <div class="container">

        <!-- Desktop View -->
        <div class="d-none d-md-block">

            <div class="row">
                <div class="col">
                    <h1 class="text-center mb-5">
                        How does FFF work?
                    </h1>
                </div>
            </div>

            <div class="row align-items-center mt-5">
                <div class="col-6">
                    <div class="tab-content" id="main_id">
                        <div class="tab-pane show active" id="slider_1" role="tabpanel" aria-labelledby="slider_1-tab">
                            <img src="./assets/img/3d_printing_capabilities/fff/Group 20084.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_2" role="tabpanel" aria-labelledby="slider_2-tab">
                            <img src="./assets/img/3d_printing_capabilities/fff/Group 20085.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_3" role="tabpanel" aria-labelledby="slider_3-tab">
                            <img src="./assets/img/3d_printing_capabilities/fff/Group 20086.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active" id="slider_1-tab" data-toggle="pill" href="#slider_1" role="tab" aria-controls="slider_1" aria-selected="true">
                            <span class="toggle">
                                Generating The 3D Model
                            </span>
                            <hr>
                            <p>
                                The first stage consists of generating the 3D model with design software. Thereafter, the 3D model needs to be exported in STL format.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_2-tab" data-toggle="pill" href="#slider_2" role="tab" aria-controls="slider_2" aria-selected="false">
                            <span class="toggle">
                                File preparation
                            </span>
                            <hr>
                            <p>
                                To configure the model to be printed, it is important to use a slicing software and include all printing requirements such as material selection and the nozzle size of the printer. The software also separates the model into layers, and the printing quality,
                                and movement commands can be configured.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_3-tab" data-toggle="pill" href="#slider_3" role="tab" aria-controls="slider_3" aria-selected="false">
                            <span class="toggle">
                                The printing phase
                            </span>
                            <hr>
                            <p>
                                The printing phase is the deposition of the melted plastic.
                            </p>
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <!-- Mobile View -->
        <div class="d-block d-md-none">
            <div class="row">
                <div class="col">

                    <!-- Title -->
                    <h2 class="text-left text-md-left">
                        How does FFF work?
                    </h2>

                    <!-- One -->
                    <div>
                        <img src="./assets/img/3d_printing_capabilities/fff/Group 20084.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                        <h5 class="text-left">
                            Generating The 3D Model
                        </h5>
                        <hr>
                        <p>
                            The first stage consists of generating the 3D model with design software. Thereafter, the 3D model needs to be exported in STL format.
                        </p>
                    </div>
                    <!-- Two -->
                    <div>
                        <img src="./assets/img/3d_printing_capabilities/fff/Group 20085.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                        <h5 class="text-left">
                            File preparation
                        </h5>
                        <hr>
                        <p>
                            To configure the model to be printed, it is important to use a slicing software and include all printing requirements such as material selection and the nozzle size of the printer. The software also separates the model into layers, and the printing quality,
                            and movement commands can be configured.
                        </p>
                    </div>
                    <!-- Three -->
                    <div>
                        <img src="./assets/img/3d_printing_capabilities/fff/Group 20086.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                        <h5 class="text-left">
                            The printing phase
                        </h5>
                        <hr>
                        <p>
                            The printing phase is the deposition of the melted plastic.
                        </p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>

<!-- Attributes -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1 class="mb-5 mb-md-4">
                        Attributes
                    </h1>
                    <p class="mb-5 mb-md-4">
                        Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                    </p>
                    <!-- Desktop Button -->
                    <div class="d-none d-md-block">
                        <a [routerLink]="['/contact']" class="mt-4 btn px-5"> Get In Touch </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Overview.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Highlights
                        </h3>
                        <p>
                            Large selection of materials
                        </p>
                        <p>
                            Cost-effective printer technology
                        </p>
                        <p>
                            Possible to print using multiple materials
                        </p>
                        <p>
                            Printers and materials offered by many manufacturers
                        </p>
                        <p>
                            High speed and efficiency
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Limitation.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Limitations
                        </h3>
                        <p>
                            The detail of finished prints is limited by the size of the nozzle
                        </p>
                        <p>
                            The strength of finished parts is limited
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Application.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Applications
                        </h3>
                        <p>
                            Automotive and aerospace parts
                        </p>
                        <p>
                            Prototyping projects
                        </p>
                        <p>
                            Medical applications
                        </p>
                    </div>
                </div>
                <hr>

                <!-- Mobile Button -->
                <div class="px-3 d-block d-md-none">
                    <a [routerLink]="['/contact']" class="mt-4 btn btn-block"> Get In Touch </a>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant 3D printing quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <div class="video-wrapper">
                            <img src="./assets/img/3d_printing_capabilities/fff/Screen Shot.webp" class="fluid-img w-100" alt="video">
                            <img src="./assets/img/3d_printing_capabilities/svg/PlayButton.svg" class="play-button" alt="Truventor">
                        </div>

                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>