<!-- Main -->
<section class="main-session">
    <img src="assets/img/discover/Discover Banner iMage.jpg" class="img-banner" alt="Truventor">

    <div class="container">

        <div class="row align-items-center">
            <div class="col-md-12">
                <!-- Heading -->
                <div class="row">
                    <div class="col-sm-12 col-md-7">
                        <div class="heading">
                            <h1>
                                Truventor Discover
                            </h1>
                            <p class="mt-4">
                                The No. 1 all-in-one supplier discovery platform for all your start-to-end manufacturing needs.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- Action Buttons -->
                <div class="row">
                    <div class="col-sm-12 col-md-4">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-primary w-100">
                            Book A Demo </a>
                    </div>
                </div>
            </div>
        </div>


        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Discover Find New Manufacturing -->
<section class="discover-find-new-manufacturing global-space-between">
    <div class="container">

        <!-- accordion -->
        <div class="row align-items-center">
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 order-12 order-md-1 pl-0">
                <!-- collapse -->
                <div class="w-100">
                    <!-- Accordion -->
                    <div class="mt-0">
                        <div class="col-sm-12 col-md-10 pl-3 pl-md-0">
                            <h1 class="pb-4">
                                Find new manufacturing partners
                            </h1>
                            <!-- Accordion -->
                            <div class="accordion" id="discover-find-new-manufacturing">

                                <!-- Discover verified manufacturing partners across the globe for your parts -->
                                <div>
                                    <div class="row">
                                        <div class="col">
                                            <hr class="m-0 mb-2">
                                            <h6 class="text-left font-weight-bold">
                                                Discover verified manufacturing partners across the globe for your parts
                                            </h6>
                                            <p>
                                                A unified platform to access manufacturing partners from multiple geographies at single place on cloud.
                                            </p>
                                            <hr class="m-0">
                                        </div>
                                    </div>
                                </div>

                                <!-- Global network of suppliers -->
                                <div>
                                    <div id="discover-find-new-manufacturing-accordion-two">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#discover-find-new-manufacturing-collapse-two" aria-expanded="true" aria-controls="discover-find-new-manufacturing-collapse-two">
                                                    <h5 class="text-left">
                                                         Global network of suppliers
                                                    </h5>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0">

                                </div>

                                <!-- Free RFQs -->
                                <div>
                                    <div id="discover-find-new-manufacturing-accordion-three">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#discover-find-new-manufacturing-collapse-three" aria-expanded="true" aria-controls="discover-find-new-manufacturing-collapse-three">
                                                    <h5 class="text-left">
                                                         Free RFQs
                                                    </h5>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0">

                                </div>

                                <!-- Realtime and updated Supplier info -->
                                <div>
                                    <div id="discover-find-new-manufacturing-accordion-four">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#discover-find-new-manufacturing-collapse-four" aria-expanded="true" aria-controls="discover-find-new-manufacturing-collapse-four">
                                                    <h5 class="text-left">
                                                         Realtime and updated Supplier info
                                                    </h5>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0">

                                </div>

                                <!-- Supplier Relationship Score -->
                                <div>
                                    <div id="discover-find-new-manufacturing-accordion-four">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#discover-find-new-manufacturing-collapse-four" aria-expanded="true" aria-controls="discover-find-new-manufacturing-collapse-four">
                                                    <h5 class="text-left">
                                                         Supplier Relationship Score
                                                    </h5>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0">

                                </div>


                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Side Image -->
            <div class="col-sm-12 col-md-6 order-1 order-md-12 p-3 p-md-0 d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start">
                <img src="./assets/img/discover/Group 18669.jpg" class="img-fluid w-100" alt="Truventor">
            </div>
        </div>
    </div>
</section>

<!-- Advantages -->
<section class="discover-advantages global-space-between">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="my-5 text-center font-weight-bold">
                    Advantages
                </h1>
                <p class="text-center">
                    <b>
                        The quickest and most accurate way to conduct bids & place orders
                    </b>
                </p>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-1 row-cols-md-4 justify-content-center pt-4 px-1 px-md-0">

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2 ">
                    <img src="./assets/img/discover/svg/Find The Lowest Bidder.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Find The Lowest Bidder
                        </p>
                        <hr>
                        <p class="text-center">
                            A single and secure platform to send suppliers RFQs, render bids, get the best costing, and award business.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2 ">
                    <img src="./assets/img/discover/svg/Reduce Risk Upfront.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Reduce Risk Upfront
                        </p>
                        <hr>
                        <p class="text-center">
                            View Qualified Suppliers, Check Their Rating And Past Performances.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2 ">
                    <img src="./assets/img/discover/svg/Increase Transparency.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Increase Transparency
                        </p>
                        <hr>
                        <p class="text-center">
                            Track your order in real-time during each manufacturing phase.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2 ">
                    <img src="./assets/img/discover/svg/Data driven decision making.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Data driven decision making
                        </p>
                        <hr>
                        <p class="text-center">
                            Track, monitor and compile qualitative and quantitative assessments for fact-based performance & reviews
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- Works in 3 simple steps -->
<section class="works-in-3-simple-steps global-space-between">
    <div class="container-fluid">

        <!-- 1, 2, 3 -->
        <div class="row">
            <div class="col">

                <!-- Heading -->
                <div class="row pb-5">
                    <div class="col-md-12 text-center pr-5">
                        <h1 class="font-weight-bold">
                            <!-- Desktop -->
                            <div class="d-block d-md-none">
                                Find new manufacturing partners
                            </div>
                            <!-- Mobile -->
                            <div class="d-none d-md-block">
                                Works in 3 simple steps
                            </div>
                        </h1>
                        <b>
                            Empowering Buyers to source Faster and Smarter
                        </b>
                    </div>
                </div>

                <!-- One -->
                <div class="row align-items-center justify-content-end py-5">
                    <div class="col-md-5 pr-5">
                        <h1 class="font-weight-bold">
                            Discover Your <br> Supplier
                        </h1>
                        <hr>
                        <h5 class="mt-3">
                            Search from the list of Truventor-verifed network
                        </h5>
                        <p class="mt-4">
                            Before sending out your inquiry, explore our detailed list of Truventor-verified suppliers. Get access to their information on cost, rating, and geography, among other parameters. Check their detailed profiles before floating an enquiry.
                        </p>
                    </div>
                    <div class="col-md-6 pr-0">
                        <div class="tab-content">
                            <img src="assets/img/discover/Discover-Your-Supplier.png" class="img-fluid" alt="Truventor">
                        </div>
                    </div>
                </div>

                <!-- Two -->
                <div class="row align-items-center justify-content-end py-5">
                    <div class="col-md-5 pr-5">
                        <h1 class="font-weight-bold">

                            Conduct Bids - <br> Get Best Costing
                        </h1>
                        <hr>
                        <h5 class="mt-3">
                            The Quickest And Most Accurate Way To Level Your Bids.
                        </h5>
                        <p class="mt-4">
                            - Send RFQs, get and compare quotes from different suppliers. <br> - Network more efficiently and effectively.<br> - Upload your drawings / BOM.<br> - Set your bid conditions.<br> - Get the best cost within a stipulated timeline
                            without the hassle of negotiations.<br> - Award business.<br> - Keep a track of your business allocations.<br>
                        </p>
                    </div>
                    <div class="col-md-6 pr-0">
                        <div class="tab-content">
                            <img src="assets/img/discover/Conduct-Bids.png" class="img-fluid" alt="Truventor">
                        </div>
                    </div>
                </div>

                <!-- Three -->
                <div class="row align-items-center justify-content-end py-5">
                    <div class="col-md-5 pr-5">
                        <h1 class="font-weight-bold">
                            Order Tracking <br> & Delivery
                        </h1>
                        <hr>
                        <h5 class="mt-3">
                            Single dashboard for all your orders.
                        </h5>
                        <p class="mt-4">
                            - Track in real-time the status and quality updates of the complete part manufacturing lifecycle. <br> - Get complete visibility of the shop floor.
                        </p>
                    </div>
                    <div class="col-md-6 pr-0">
                        <div class="tab-content">
                            <img src="assets/img/discover/Order-Tracking.png" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- Button -->
        <div class="row justify-content-center">
            <div class="col-3 mt-5 d-none d-md-block">
                <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-primary w-100"> Book A
                    Demo </a>
            </div>
        </div>

    </div>
</section>

<!-- Advantages for suppliers -->
<section class="works-in-3-simple-steps global-space-between bottom-end-padding">
    <div class="container">

        <div class="row align-items-center">
            <div class="col">

                <!-- One -->
                <div class="row justify-content-end align-items-center py-5">
                    <!-- Accordion -->
                    <div class="col-md-6 pr-5">
                        <!-- Title -->
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <h1 class="text-left">
                                    Advantages for suppliers
                                </h1>
                            </div>
                        </div>
                        <!-- Side Image - Mobile -->
                        <div class="col-sm-12 col-md-6 p-0 d-block d-md-none">
                            <img src="assets/img/discover/Advantages.jpg" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Accordion -->
                        <div class="mt-4 mr-0 mr-md-5">
                            <div class="w-100">
                                <div class="accordion" id="advantages-for-suppliers">

                                    <!-- + Mobile app based workflow -->
                                    <div>
                                        <div id="advantages-for-suppliers-accordion-one">
                                            <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#advantages-for-suppliers-one" aria-expanded="true" aria-controls="advantages-for-suppliers-one">
                                                <h5 class="text-left">
                                                    Mobile app based workflow
                                                </h5>
                                            </button>
                                        </div>
                                        <hr class="m-0">

                                    </div>

                                    <!-- + Enquiry management system for their shop floor -->
                                    <div>
                                        <div id="advantages-for-suppliers-accordion-two">
                                            <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#advantages-for-suppliers-two" aria-expanded="true" aria-controls="advantages-for-suppliers-two">
                                                <h5 class="text-left">
                                                    Enquiry management system for their shop floor
                                                </h5>
                                            </button>
                                        </div>
                                        <hr class="m-0">

                                    </div>

                                    <!-- + Cash-flow & Payment management -->
                                    <div>
                                        <div id="advantages-for-suppliers-accordion-three">
                                            <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#advantages-for-suppliers-three" aria-expanded="true" aria-controls="advantages-for-suppliers-three">
                                                <h5 class="text-left">
                                                    Cash-flow & Payment management
                                                </h5>
                                            </button>
                                        </div>
                                        <hr class="m-0">

                                    </div>

                                    <!-- + Access to early payments -->
                                    <div>
                                        <div id="advantages-for-suppliers-accordion-four">
                                            <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#advantages-for-suppliers-four" aria-expanded="true" aria-controls="advantages-for-suppliers-four">
                                                <h5 class="text-left">
                                                    Access to early payments
                                                </h5>
                                            </button>
                                        </div>
                                        <hr class="m-0">

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Side Image - Desktop -->
                    <div class="col-md-6 pr-0 d-none d-md-block">
                        <div class="tab-content">
                            <img src="assets/img/discover/Advantages.jpg" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>