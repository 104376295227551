<!-- Main -->
<section class="main-session">
    <img src="assets/img/finance/Flexible Collateral Free Loans Banner Image.jpg" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="heading">
                    <h1>
                        Flexible Collateral Free Loans
                    </h1>
                    <p class="mt-4">
                        Get credit for up to 90 days with Truventor.Money
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="https://truventor.typeform.com/to/Is0sUjwx" target="_blank" rel="noopener" class="btn btn-primary w-100">
                    Apply Now </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Save Upto 4% -->
<section class="save-upto-four-percent global-space-between">
    <div class="container">
        <div class="row align-items-center">
            <!-- Title Mobile -->
            <h1 class="text-left d-block d-md-none pl-4">
                Save Upto 4% On Raw Material Purchase With Our Line Of Credit
            </h1>
            <!-- Image -->
            <div class="col-sm-12 col-md-6">
                <img src="assets/img/finance/save_upto.jpg" style="height: 575px;" class="img-fluid w-100" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5 d-flex align-items-center">
                <div class="col-sm-12 col-md-12 p-2">
                    <!-- Title Desktop -->
                    <h1 class="text-left d-none d-md-block">
                        Save upto 4% on raw material with our line of credit
                    </h1>
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="w-100">
                            <div class="accordion" id="save-four-percent-accordion">

                                <!-- 1. Get Started -->
                                <div>
                                    <div id="save-four-percent-accordion-one">
                                        <h2 class="mb-0">
                                            <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#save-four-percent-collapse-one" aria-expanded="true" aria-controls="save-four-percent-collapse-one">
                                                <h5 class="text-left font-weight-bold">
                                                    1. Get started
                                                </h5>
                                            </button>
                                        </h2>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="save-four-percent-collapse-one" class="collapse show" aria-labelledby="save-four-percent-accordion-one" data-parent="#save-four-percent-accordion">
                                        <p>
                                            Our manufacturing partners network is closed ecosystem of qualified and skilled manufacturers. To be the part of network and qualify to to receive the order, we follow globally recognized Quality Vetting and Audit process.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <!-- 2. Quality Vetting & Audit -->
                                <div>
                                    <div id="save-four-percent-accordion-two">
                                        <h2 class="mb-0">
                                            <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#save-four-percent-collapse-two" aria-expanded="true" aria-controls="save-four-percent-collapse-two">
                                                <h5 class="text-left font-weight-bold">2. Quality vetting and audit</h5>
                                            </button>
                                        </h2>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="save-four-percent-collapse-two" class="collapse show show-mobile" aria-labelledby="save-four-percent-accordion-two" data-parent="#save-four-percent-accordion">
                                        <p>
                                            Get upto INR 1 Cr of credit line. Pay for what you use. No Prepayment charges
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <!-- 3. Get on Work -->
                                <div>
                                    <div id="save-four-percent-accordion-three">
                                        <h2 class="mb-0">
                                            <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#save-four-percent-collapse-three" aria-expanded="true" aria-controls="save-four-percent-collapse-three">
                                                <h5 class="text-left font-weight-bold">
                                                    3. Get on work
                                                </h5>
                                            </button>
                                        </h2>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="save-four-percent-collapse-three" class="collapse show show-mobile" aria-labelledby="save-four-percent-accordion-three" data-parent="#save-four-percent-accordion">
                                        <p>
                                            Free your existing working capital blocked in raw material.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Eligibility Criteria -->
<section class="eligibility-criteria global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1>Eligibility <br> Criteria?</h1>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row row-cols-1 row-cols-md-2">

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <h5>
                            Average Monthly Turnover: INR 1Cr
                        </h5>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <h5>
                            Registered on or before 2015
                        </h5>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <h5>
                            Minimum <br> investment in <br> machinery INR 10Cr
                        </h5>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <h5>
                            Registered as Pvt ltd /LLP
                        </h5>
                    </div>

                </div>

                <!-- Button -->
                <div class="row justify-content-center justify-content-md-start mt-4">
                    <div class="col-10 col-md-6">
                        <a href="https://truventor.typeform.com/to/Is0sUjwx" target="_blank" rel="noopener" class="btn btn-primary w-100"> Submit Requirement </a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Required Documents -->
<section class="required-documents global-space-between">
    <div class="container">
        <div class="row">
            <!-- Side Image - Desktop -->
            <div class="col-sm-12 col-md-6 d-none d-md-block">
                <img src="./assets/img/finance/Required Documents.jpg" loading="lazy" class="img-fluid" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5 d-flex align-items-center">
                <div class="col-sm-12 col-md-12 p-2">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 col-md-12">
                            <h1 class="text-left"> Required Documents </h1>
                        </div>
                    </div>
                    <!-- Side Image - Mobile -->
                    <div class="col-sm-12 col-md-6 p-0 d-block d-md-none">
                        <img src="./assets/img/finance/Required Documents.jpg" loading="lazy" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <p class="my-4">
                        Want to enhance your infrastructure or need capital to carry out certain projects? We have a range of financing options available with an easy documentation process.
                    </p>
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="w-100">
                            <div class="accordion" id="required-document">

                                <hr class="m-0 mb-2">

                                <!-- KYC - Director / Propreitors -->
                                <div>
                                    <div id="required-document-accordion-one">
                                        <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#required-document-collapse-one" aria-expanded="true" aria-controls="required-document-collapse-one">
                                            <h5 class="text-left">
                                                KYC -
                                                Director /
                                                Propreitors
                                            </h5>
                                        </button>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="required-document-collapse-one" class="collapse show" aria-labelledby="required-document-accordion-one" data-parent="#required-document">
                                        <!-- <hr class="m-0 mb-2"> -->
                                    </div>
                                </div>

                                <!-- Bank Account Statement - Last 12 months -->
                                <div>
                                    <div id="required-document-accordion-two">
                                        <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#required-document-collapse-two" aria-expanded="true" aria-controls="required-document-collapse-two">
                                            <h5 class="text-left">
                                                Bank
                                                Account Statement - Last 12 months
                                            </h5>
                                        </button>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="required-document-collapse-two" class="collapse show-mobile" aria-labelledby="required-document-accordion-two" data-parent="#required-document">
                                        <!-- <hr class="m-0 mb-2"> -->
                                    </div>
                                </div>

                                <!-- Residence / Place of business proof -->
                                <div>
                                    <div id="required-document-accordion-three">
                                        <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#required-document-collapse-three" aria-expanded="true" aria-controls="required-document-collapse-three">
                                            <h5 class="text-left">
                                                Residence / Place of business proof
                                            </h5>
                                        </button>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="required-document-collapse-three" class="collapse show-mobile" aria-labelledby="required-document-accordion-three" data-parent="#required-document">
                                        <!-- <hr class="m-0 mb-2"> -->
                                    </div>
                                </div>

                                <!-- Balance Sheet & P&L - Last 3 years -->
                                <div>
                                    <div id="required-document-accordion-three">
                                        <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#required-document-collapse-three" aria-expanded="true" aria-controls="required-document-collapse-three">
                                            <h5 class="text-left">

                                                Balance Sheet & P&L - Last 3 years
                                            </h5>
                                        </button>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="required-document-collapse-three" class="collapse show-mobile" aria-labelledby="required-document-accordion-three" data-parent="#required-document">
                                        <!-- <hr class="m-0 mb-2"> -->
                                    </div>
                                </div>

                                <!-- Last 2 years of loan statement -->
                                <div>
                                    <div id="required-document-accordion-four">
                                        <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#required-document-collapse-four" aria-expanded="true" aria-controls="required-document-collapse-four">
                                            <h5 class="text-left">
                                                Last 2
                                                years of loan statement
                                            </h5>
                                        </button>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="required-document-collapse-four" class="collapse show-mobile" aria-labelledby="required-document-accordion-four" data-parent="#required-document">
                                        <!-- <hr class="m-0 mb-2"> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Frequently Asked Questions -->
<section class="finance-faq global-space-between bottom-end-padding">
    <div class="container">

        <div class="row">
            <div class="col">
                <h1 class="text-left pb-5">
                    Frequently Asked Questions
                </h1>
                <!-- one -->
                <div class="card">
                    <div class="card-head" id="heading-one">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-one" aria-expanded="true" aria-controls="faq-one">
                            Who Will Deliver The Raw Material?
                        </h2>
                    </div>
                    <div id="faq-one" class="collapse show" aria-labelledby="heading-one">
                        <div class="card-body">
                            Truventor delivers the material at your doorstep. We take complete responsibility of end-to-end supply chain to ensure quality and on-time delivery.
                        </div>
                    </div>
                </div>

                <!-- two -->
                <div class="card">
                    <div class="card-head" id="heading-two">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-two" aria-expanded="true" aria-controls="faq-two">
                            What Are The Payment Terms Of Raw Materials?
                        </h2>
                    </div>
                    <div id="faq-two" class="collapse show" aria-labelledby="heading-two">
                        <div class="card-body">
                            <p>
                                We charge 100% advance for all raw materials we supply. However, if you would like to access credit from us, you may apply for line of credit on our platform. <a href="https://form.typeform.com/to/Is0sUjwx" target="_blank"
                                    rel="noopener">Click here</a> to know more.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- three -->
                <div class="card">
                    <div class="card-head" id="heading-three">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-three" aria-expanded="true" aria-controls="faq-three">
                            How raw material from Truventor is cheaper than the market rate?
                        </h2>
                    </div>
                    <div id="faq-three" class="collapse show" aria-labelledby="heading-three">
                        <div class="card-body">
                            <p>
                                We aggregate requirements from several job-shops on daily basis and place the bulk orders to our partners. This way we are able to get better rates and we pass the benefit to you. We do not keep any margin for ourselves. This way you can save upto 3%
                                annually.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- four -->
                <div class="card">
                    <div class="card-head" id="heading-four">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-four" aria-expanded="true" aria-controls="faq-four">
                            How to get best rates from Truventor?
                        </h2>
                    </div>
                    <div id="faq-four" class="collapse show" aria-labelledby="heading-four">
                        <div class="card-body">
                            <p>
                                Our business model is to aggregate and supply. To achieve best rates from us, we request you to share your historical buying requirements. We can provide much better rates using the data points you share.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- five -->
                <div class="card">
                    <div class="card-head" id="heading-five">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-five" aria-expanded="true" aria-controls="faq-five">
                            Will I get quality certification for material? What happens in case of quality issues with raw material?
                        </h2>
                    </div>
                    <div id="faq-five" class="collapse show" aria-labelledby="heading-five">
                        <div class="card-body">
                            <p>
                                Yes. We provide TC (Test Certificate) for all the raw materials we supply. In case of any issues with the supply, we take the complete responsibility. We pick up the order from your doorstep and also refund the money within 48 working hours.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- six -->
                <div class="card">
                    <div class="card-head" id="heading-six">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-six" aria-expanded="true" aria-controls="faq-six">
                            Do I need line of credit to buy raw material from Truventor?
                        </h2>
                    </div>
                    <div id="faq-six" class="collapse show" aria-labelledby="heading-six">
                        <div class="card-body">
                            <p>
                                No. Line of credit is an optional service for our customers. However, to ensure best rates we charge 100% advance on all orders. You may apply for the line of credit if you do not have the flexibility to pay 100% advance and can get credit upto 90 days.
                            </p>
                        </div>
                    </div>
                </div>

                <!-- seven -->
                <div class="card">
                    <div class="card-head" id="heading-seven">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-seven" aria-expanded="true" aria-controls="faq-seven">
                            I am located outside India. Can I still order raw material on the platform?
                        </h2>
                    </div>
                    <div id="faq-seven" class="collapse show" aria-labelledby="heading-seven">
                        <div class="card-body">
                            <p>
                                Yes. You can place an RFQ on our platform even if you are located outside India. However, we will only supply material from traders / distributors located in India.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</section>

<!-- Get Connected With Our  -->
<section class="finance-quote global-space-between bottom-end-padding amazing-quote">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-12">
                <div class="">
                    <!-- Quote -->
                    <h1 class="text-center text-white">
                        Get Connected With Our Expert Team For More Information
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center">
                        <a [routerLink]="['/contact']"  rel="noopener" class="btn btn-light px-5">
                            Get In Touch </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Check Out Our Other Offerings -->
<section class="global-space-between bottom-end-padding">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="mb-5 text-center">
                    Check out our other offerings
                </h1>
            </div>
        </div>

        <!-- Truventor Procure -->
        <div class="card border-0">
            <div class="row no-gutters">
                <div class="col-md-5 d-flex justify-content-center">
                    <img src="./assets/img/finance/Truventor Procure@2x.jpg" loading="lazy" width="508px" height="250px" class="img-fluid img-border" alt="Truventor">
                </div>
                <div class="col-md-7">
                    <div class="card-body p-0 p-md-5 pt-2">
                        <h5 class="card-title font-weight-bold">Truventor Procure</h5>
                        <hr class="primary-bg">
                        <div class="row pb-4">
                            <div class="col-12 col-md-6">
                                <p class="card-text">
                                    Find Or Sell Your New And Used Machines For Free.
                                </p>
                            </div>
                        </div>
                        <div class="d-flex justify-content-center justify-content-md-start">
                            <a [routerLink]="['/procurement']" class="btn">Check Rates</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>