import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-toolsteels7',
  templateUrl: './material-toolsteels7.component.html',
  styleUrls: ['./material-toolsteels7.component.scss']
})
export class MaterialToolsteels7Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }


}
