<!-- Main -->
<section class="main-session">
    <img src="assets/img/banner-images/Vacuum Casting@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="heading">
                    <h1>
                        Vacuum Casting <br> Services
                    </h1>
                    <p class="mt-4">
                        Urethane Casting / Silicon Moulding <br> For cost-efficient, quick and production-level quality parts
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Our Vacuum Casting Services -->
<section class="our-vacuum-casting-services global-space-between">
    <div class="container">
        <!-- <div class="row py-5">
            <div class="col col-12 col-md-6">
                
            </div>
        </div> -->
        <div class="row justify-content-between align-items-center">
            <div class="col-12 order-12 order-md-1 col-md-5">
                <h1 class="text-left">
                    Our vacuum casting services
                </h1>
                <p>
                    We offer a complete turnkey solution for creating master patterns and cast copies based on your CAD designs. We not only make high-quality moulds, but also offer a full line of finishing services including painting, sanding, pad printing and more. We
                    will help you create parts with high accuracy and faster turnaround.
                </p>
            </div>
            <div class="col-12 order-1 order-md-12 col-md-6 text-center">
                <img src="assets/img/vacuum_casting/svg/Vacuum Casting.svg" class="img-fluid mx-auto machining-img" alt="vacuum-casting">
            </div>
        </div>
    </div>
</section>

<!-- Why Choose Vacuum Casting-->
<section class="why-choose-vacuum-casting global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <h1>Why go for it?</h1>
                    <p class="pt-4">
                        Vacuum casting is a manufacturing technology that uses a vacuum to draw liquid casting material into a mould. It differs significantly from injection moulding, which pushes liquid material into a mould using a screw. It is used for producing small numbers
                        of accurate polyurethane prototype parts with characteristics and quality comparable to an injection moulded product. Vacuum casting makes it possible to produce flexible or rigid parts, of different hardness, transparent or of
                        different colours, with different textures in small series.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Technical Specifications -->
<section class="technical-specifications global-space-between">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-md-5">
                <div class="content">
                    <h1>Technical specifications</h1>
                    <p>
                        Manufacturing costs depends directly on several factors
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row row-cols-2 row-cols-md-2">
                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <h5>
                            Lead time
                        </h5>
                        <p>
                            Up to <span>10-12</span> copies within 15 working days. Includes time from making master to final parts.
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <h5>
                            Standard accuracy
                        </h5>
                        <p>
                            <span>±0.3%</span> (with a lower limit On ± 0.3 mm on dimensions smaller than 100 mm)
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <h5>
                            Feature definition
                        </h5>
                        <p>
                            Since the mold is made of urethane or silicone, features lower than <span>0.5mm</span> are difficult to capture and may require additional method /work.
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <h5>
                            Surface finish
                        </h5>
                        <p>
                            Vaccum casting gives injection molding-like surface finish. The option available are <span>
                                gloss, matte, polished </span> or custom finishes.

                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 5 </div>
                        <hr>
                        <h5>
                            Quantities
                        </h5>
                        <p>
                            We can get upto <span>25 parts per</span> mold depending upon the part geometry and material being cast
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 6 </div>
                        <hr>
                        <h5>
                            Dimensions
                        </h5>
                        <p>
                            We can manufacture parts from <span>10- 2000mm</span> using this process.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Vacuum Casting Process -->
<section class="vacuum-casting-process side-tab-view global-space-between">
    <div class="container">

        <div class="row">
            <div class="col">
            </div>
        </div>

        <div class="">

            <div class="d-block d-md-none">
                <h1>
                    Vacuum casting process
                </h1>
                <p>
                    Vacuum casting process flow:
                </p>
            </div>

            <div class="row">
                <div class="col-12 col-md-6 order-12 order-md-1 pr-md-5">

                    <div class="d-none d-md-block">
                        <h1>
                            Vacuum casting process
                        </h1>
                        <p>
                            Vacuum casting process flow:
                        </p>
                    </div>
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active" id="slider_1-tab" data-toggle="pill" href="#slider_1" role="tab" aria-controls="slider_1" aria-selected="true">
                            <span class="plus">
                                Preparation of master pattern
                            </span>
                            <p>
                                The first step in metalcasting is to create an object with a similar shape of the desired product, called a pattern. The pattern is a tool used to produce the mold, and must be slightly larger than the final product in order to account for shrinkage during
                                solidification.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_2-tab" data-toggle="pill" href="#slider_2" role="tab" aria-controls="slider_2" aria-selected="false">
                            <span class="plus">
                                Making of mould
                            </span>
                            <p>
                                A silicone mould, fabricated from this master pattern, is cut into half. The master pattern is removed to pave the way for the casting medium to be filled in the cavity.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_3-tab" data-toggle="pill" href="#slider_3" role="tab" aria-controls="slider_3" aria-selected="false">
                            <span class="plus">
                                Pouring
                            </span>
                            <p>
                                The casting medium (molten plastic or rubber) is then poured in the silicon mould under vacuum and the mould is allowed to cool.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_4-tab" data-toggle="pill" href="#slider_4" role="tab" aria-controls="slider_4" aria-selected="false">
                            <span class="plus">
                                Extracting and finishing
                            </span>
                            <p>
                                The individual components are then taken out from the mould and are post-processed and cleaned to give them the desired surface finish and quality.
                            </p>
                        </a>

                    </div>


                </div>
                <div class="col-12 col-md-6 order-1 order-md-12 pl-0">
                    <div class="tab-content" id="main_id">
                        <div class="tab-pane show active" id="slider_1" role="tabpanel" aria-labelledby="slider_1-tab">
                            <img src="./assets/img/vacuum_casting/svg/Preparation Of Master Pattern.svg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_2" role="tabpanel" aria-labelledby="slider_2-tab">
                            <img src="./assets/img/vacuum_casting/svg/Making Of Mould.svg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_3" role="tabpanel" aria-labelledby="slider_3-tab">
                            <img src="./assets/img/vacuum_casting/svg/Pouring.svg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="slider_4" role="tabpanel" aria-labelledby="slider_4-tab">
                            <img src="./assets/img/vacuum_casting/svg/Extracting & Finishing.svg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                    </div>
                </div>
            </div>

        </div>


    </div>
</section>












<!-- Vacuum Casting Materials -->
<section class="global-space-between vacuum-casting-materials main-faq" id="vacuumcasting-material">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 col-md-7">
                            <h1 class="py-3">Industrial-grade vacuum casting materials </h1>
                        </div>
                        <div class="col-12 col-md-5 d-flex align-items-center">
                            <p class="d-none d-md-block">
                                For projects using vacuum casting or vacuum duplication, there are multiple transparent materials with different properties.
                            </p>
                            <p class="d-block d-md-none">
                                In engineering, the right materials matter. We can make sure your final product looks and performs exactly as intended.

                            </p>
                        </div>
                    </div>
                    <!-- Table -->
                    <div class="row my-4">
                        <div class="col">

                            <!-- Collapse One -->
                            <div class="card border-0 mt-2" id="vacuum-casting-materials">
                                <div class="card-header px-3 border-dark bg-transparent" id="vacuum-casting-accordion-two">
                                    <h3 class="m-0" data-toggle="collapse" data-target="#vacuum-casting-collapse" aria-expanded="false" aria-controls="vacuum-casting-collapse">
                                        Top plastics
                                    </h3>

                                </div>
                                <div id="vacuum-casting-collapse" class="collapse show" aria-labelledby="vacuum-casting-accordion-two" data-parent="#vacuum-casting-materials">
                                    <div class="card-body">

                                        <div *ngFor="let item of vacuumData">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-md-2 p-0">
                                                    <img src="{{item?.imgUrl}}" class="img-fluid" alt="Trueventor">
                                                </div>
                                                <div class="col-12 col-md-10 pl-3 pl-md-0">
                                                    <div class="row align-items-center">
                                                        <div class="col-12 col-md-4">
                                                            <b class="d-block mt-2 mt-md-0"> {{item?.name}} </b>
                                                        </div>
                                                        <div class="col-12 col-md-3">
                                                            <p class="m-0">
                                                                {{item?.description}}
                                                            </p>
                                                        </div>
                                                        <div class="col-12 col-md-5 text-md-right">
                                                            <a href="{{item?.url}}" class="btn btn-primary py-2 px-4 mt-2 mt-md-0 mr-md-3 mr-0">
                                                                Know More
                                                            </a>
                                                            <a [routerLink]="['/contact']"  rel="noopener" class="btn float-left float-md-right py-2 px-4 mt-2 mt-md-0 mr-3 mr-md-0">
                                                                Get In Touch
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col p-0">
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Advantages of Vacuum Casting  -->
<section class="advantages-of-vacuum-casting">
    <div class="container">
        <div class="row">
            <!-- Side Image - Desktop -->
            <div class="col-sm-12 col-md-6 p-0 d-none d-md-block">
                <img src="./assets/img/vacuum_casting/Advantages of Vacuum Casting@2x (2).jpg" class="img-fluid" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5 d-flex align-items-center">
                <div class="col-sm-12 col-md-12 p-2">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 col-md-12">
                            <h1 class="text-left"> Advantages of vacuum casting </h1>
                        </div>
                    </div>
                    <!-- Side Image - Mobile -->
                    <div class="col-sm-12 col-md-6 p-0 d-block d-md-none">
                        <img src="./assets/img/vacuum_casting/Advantages of Vacuum Casting@2x (2).jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <p class="my-4">
                        Silicone moulding has an upper handing in producing high-quality parts when compared to injection moulding. This actually ensures that vacuum casting is perfect for function testing as well as marketing purposes for the production of limited quantities.
                    </p>
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="w-100">


                            <div class="accordion" id="advantages-of-vacuum-casting">

                                <hr class="m-0">

                                <!-- Large range of finish options -->
                                <div>
                                    <div id="advantages-of-vacuum-casting-accordion-one">

                                        <h5 class="text-left">
                                            Large range of finish options
                                        </h5>
                                    </div>
                                    <hr class="m-0">

                                </div>

                                <!-- No need for any sand reconditioning or sand restoration -->
                                <div>
                                    <div id="advantages-of-vacuum-casting-accordion-two">

                                        <h5 class="text-left">
                                            No need for any sand reconditioning or sand restoration
                                        </h5>
                                    </div>
                                    <hr class="m-0">

                                </div>

                                <!-- Reduction in lead time -->
                                <div>
                                    <div id="advantages-of-vacuum-casting-accordion-three">

                                        <h5 class="text-left">

                                            Reduction in lead time
                                        </h5>
                                    </div>
                                    <hr class="m-0">

                                </div>
                                <!-- High accuracy  -->
                                <div>
                                    <div id="advantages-of-vacuum-casting-accordion-three">

                                        <h5 class="text-left">
                                            High accuracy
                                        </h5>
                                    </div>
                                    <hr class="m-0">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Quality Assurance -->
<section class="quality-assurance-vacuum-casting">
    <div class="container mt-2 py-2 mt-md-5 py-md-5">
        <div class="row">
            <div class="col">
                <div class="content">
                    <h1 class="text-center pt-5" data-aos="fade-up"> Quality assurance </h1>
                    <!-- Sentences -->
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-6">
                            <p class="text-left text-md-center pt-2" data-aos="fade-up">
                                Truventor strives to achieve best-in-class quality through a systematic approach at every stage of part manufacturing.
                            </p>
                        </div>
                    </div>

                    <!-- Quality Icons -->
                    <div class="row row-cols-1 row-cols-md-3 py-5">
                        <!-- ISO -->
                        <div class="col mt-4">
                            <div data-aos="fade-up">
                                <div class="d-flex justify-content-center justify-content-md-start">
                                    <img class="img-fluid" src="./assets/img/vacuum_casting/svg/ISO 90012015 certification.svg" alt="quality_assurance_image">
                                </div>
                                <hr class="my-3">
                                <p class="text-center text-md-left">
                                    ISO 9001:2015 certification
                                </p>
                            </div>
                        </div>
                        <!-- Quality inspection report (On request) -->
                        <div class="col mt-4">
                            <div data-aos="fade-up">
                                <div class="d-flex justify-content-center justify-content-md-start">
                                    <img class="img-fluid" src="./assets/img/vacuum_casting/svg/Quality inspection report (On request).svg" alt="quality_assurance_image">
                                </div>
                                <hr class="my-3">
                                <p class="text-center text-md-left">
                                    Quality inspection report (On request)
                                </p>
                            </div>
                        </div>
                        <!-- Truventor certified quality & manufacturing benchmarks -->
                        <div class="col mt-4">
                            <div data-aos="fade-up">
                                <div class="d-flex justify-content-center justify-content-md-start">
                                    <img class="img-fluid" src="./assets/img/vacuum_casting/svg/Truventor certified quality & manufacturing benchmarks.svg" alt="quality_assurance_image">
                                </div>
                                <hr class="my-3">
                                <p class="text-center text-md-left">
                                    Truventor-certified quality and manufacturing benchmarks
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Ideal Applications -->
<section class="ideal-application">
    <div class="container mb-5 py-5">
        <div class="row">
            <div class="col">
                <!-- Title -->
                <h1 class="text-center pb-5"> Ideal applications </h1>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3">

            <!-- Flexible Medical parts -->
            <div class="col mb-4">
                <div class="card border-0 ">
                    <img src="/assets/img/vacuum_casting/Flexible Medical Parts.jpg" class="card-img-top" alt="consumer goods">
                    <div class="card-body px-3">
                        <h4 class="card-title pt-3">
                            Flexible medical parts
                        </h4>
                        <p>
                            Prosthetic and medical devices
                        </p>
                    </div>
                </div>
            </div>

            <!-- IOT Products -->
            <div class="col mb-4">
                <div class="card border-0 ">
                    <img src="/assets/img/vacuum_casting/IOT Products.jpg" class="card-img-top" alt="consumer goods">
                    <div class="card-body pt-3">
                        <h4 class="card-title pt-3">
                            IOT products
                        </h4>
                        <p>
                            Custom enclosures and packaging inserts
                        </p>
                    </div>
                </div>
            </div>

            <!-- Enclosures -->
            <div class="col mb-4">
                <div class="card border-0 ">
                    <img src="/assets/img/vacuum_casting/Enclosures.jpg" class="card-img-top" alt="consumer goods">
                    <div class="card-body px-3">
                        <h4 class="card-title pt-3">
                            Enclosures
                        </h4>
                        <p>
                            Production of plastic-based parts and prototypes
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- Schedule a meeting or book a demo -->
<section class="amazing-quote">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="">
                    <!-- Quote -->
                    <h1 class="text-center text-white">
                        Schedule a <br> meeting or <br> book a demo
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center mt-4">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light px-md-5">
                            Request a call back </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Check out our other offerings -->
<section class="check-out-other-offer">
    <div class="container my-5 py-5">
        <div class="row">
            <div class="col">
                <h1 class="text-center pb-5" data-aos="fade-up"> Check out our other offerings </h1>
            </div>
        </div>
        <div class="row row-cols-2 row-cols-md-5 justify-content-center">

            <!-- Injection Molding -->
            <div class="col p-4 py-md-0 pointer" (click)="injectionMolding()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/vacuum_casting/svg/Injection Moulding.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Injection<br>Molding</p>
                    </div>
                </div>
            </div>

            <!-- Metal Casting -->
            <div class="col p-4 py-md-0 pointer" (click)="metalCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/vacuum_casting/svg/Metal Casting.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Metal<br>Casting</p>
                    </div>
                </div>
            </div>

            <!-- Sheet Metal -->
            <div class="col p-4 py-md-0 pointer" (click)="sheetMetal()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/vacuum_casting/svg/Sheet Metal.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Sheet<br>Metal</p>
                    </div>
                </div>
            </div>

            <!-- CNC Machining -->
            <div class="col p-4 py-md-0 pointer" (click)="cncmachining()">
                <div class="card border-0 " data-aos="fade-up">
                    <img src="./assets/img/vacuum_casting/svg/CNC Machining.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">CNC<br>Machining</p>
                    </div>
                </div>
            </div>

            <!-- 3D Printing -->
            <div class="col p-4 py-md-0 pointer" (click)="threedPrinting()">
                <div class="card border-0 " data-aos="fade-up">
                    <img src="./assets/img/vacuum_casting/svg/3DPrinting.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">3D<br>Printing</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>