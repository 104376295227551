<!-- Main -->
<section class="main-session">
    <img src="assets/img/articles/article-banner.jpg" class="img-banner" alt="Truventor">

    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>
                        Articles
                    </h1>
                    <p class="mt-4">
                        Forget scouting through different websites. We present you with the most insightful technical articles hand-picked from the global B2B media houses.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row mt-5">
            <div class="col-sm-12 col-md-4">
                <a data-toggle="modal" data-target="#subscribeArticle" class="btn btn-primary w-100"> Subscribe </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Events -->
<section class="global-space-between articles">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center">
                    Articles
                </h1>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 mt-4">
            <div class="col mb-4">
                <a href="https://www.moldmakingtechnology.com/articles/the-benefits-of-alloying-tool-steel" target="blank" class="card border-0 h-100">
                    <img src="/assets/img/articles/The Benefits of Alloying Tool Steel@2x.jpg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            October 11, 2021
                        </p>
                        <h4 class="card-title">
                            The Benefits of Alloying Tool Steel
                        </h4>
                        <p>
                            Improve tooling efficiencies, extend mold life and reduce overall tooling costs with a focus on tool steel properties that solve wear, flaking and cracking issues.
                        </p>
                        <kbd> CNC Machining </kbd>
                        <kbd> Milling </kbd>
                    </div>
                </a>
            </div>
            <div class="col mb-4">
                <a href="https://www.additivemanufacturing.media/articles/what-are-the-unexplored-frontiers-of-am-lattice-fabrication" target="blank" class="card border-0 h-100">
                    <img src="/assets/img/articles/What Are the Unexplored Frontiers of AM Lattice Fabrication-1@2x.jpg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            October 11, 2021
                        </p>
                        <h4 class="card-title">
                            What Are the Unexplored Frontiers of AM Lattice Fabrication?
                        </h4>
                        <p>
                            A recent literature review discovered that most research into AM lattices is limited to PBF in metal and extrusion in polymer — and that experiments could be underestimating in-service lattice mechanical behavior.
                        </p>
                        <kbd> CNC Machining </kbd>
                        <kbd> Milling </kbd>
                    </div>
                </a>
            </div>
            <div class="col mb-4">
                <a href="https://www.mmsonline.com/articles/how-to-manage-cutting-tool-inventory-in-a-small-job-shop-the-one-person-shop-4" target="blank" class="card border-0 h-100">
                    <img src="/assets/img/articles/What Are the Unexplored Frontiers of AM Lattice Fabrication@2x.jpg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            October 11, 2021
                        </p>
                        <h4 class="card-title">
                            How to Manage Cutting Tool Inventory in a Small Job Shop
                        </h4>
                        <p>
                            Working in short lead times means maintaining a large range of tools to be ready. What is the right way to stock and organize this investment?
                        </p>
                        <kbd> CNC Machining </kbd>
                        <kbd> Milling </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://mfgtechupdate.com/2019/11/chatter-in-milling/" target="blank" class="card border-0 h-100">
                    <img src="/assets/img/articles/chatter.jpg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            June 22, 2021
                        </p>
                        <h4 class="card-title">
                            Chatter in Milling
                        </h4>
                        <p>
                            You are sitting in an auditorium listening to a beautiful song from a renowned singer. You are totally immersed in the song and suddenly, the song is shattered by a loud screeching noise from the PA system....
                        </p>
                        <kbd> CNC Machining </kbd>
                        <kbd> Milling </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.industr.com/en/india-iot-in-manufacturing-market-to-grow-at-a-cagr-of-until-fy-2616456" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/articles/india-iot.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            Jul 13, 2021
                        </p>
                        <h4 class="card-title">
                            India IOT in Manufacturing Market To Grow At A Cagr Of 13.81% Until Fy2027
                        </h4>
                        <p>
                            Greetings from Team Chizel. If you are reading this article, I am assuming you already know about us, and you are well aware of Chizel’s primary business of Manufacturing-as-a-Service….
                        </p>
                        <kbd> Manufacturing </kbd>
                        <kbd> IOT </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.mmsonline.com/articles/five-3d-printing-trends-mirrored-in-machining" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/articles/five-3d.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            29 March 2021
                        </p>
                        <h4 class="card-title">
                            Five 3D Printing Trends Mirrored in Machining
                        </h4>
                        <p>
                            Although HP’s Digital Manufacturing Trends Report is all about 3D printing, there is good reason why the document is not called the “Additive Manufacturing Trends Report.”
                        </p>
                        <kbd> 3D PRINTING </kbd>
                        <kbd> CNC </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://mfgtechupdate.com/2018/04/top-10-tips-create-premium-grinding-conditions-workshop/" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/articles/ttop-10.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            16 April, 2018
                        </p>
                        <h4 class="card-title">
                            Top 10 Tips to Create Premium Grinding Conditions for Your Workshop
                        </h4>
                        <p>
                            Simon Richardson, ANCA Product Manager has been working with Tool Grinders for many years and has had the privilege of visiting several tooling workshops around the world.
                        </p>
                        <kbd> Grinding </kbd>
                        <kbd> Finishing </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.americanmachinist.com/machining-cutting/article/21164711/costsaving-tips-for-crosshole-deburring-shop-operations" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/articles/quick-tips.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            19 MAY, 2021
                        </p>
                        <h4 class="card-title">
                            Quick Tips for Saving Money on Cross-Hole Deburring
                        </h4>
                        <p>
                            Thanks to technological advancements, automated machining has become remarkably reliable and effective.
                        </p>
                        <kbd> Deburring </kbd>
                        <kbd> Finishing </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.additivemanufacturing.media/articles/why-self-supervised-deep-learning-may-be-additive-manufacturings-ai-solution" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/articles/why-selfstart.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            8 November 2021
                        </p>
                        <h4 class="card-title">
                            Why Self-Supervised Deep Learning May Be Additive Manufacturing's AI Solution
                        </h4>
                        <p>
                            Artificial intelligence (AI) has undeniable usefulness for the advance of additive manufacturing (AM). The variables affecting a laser powder bed fusion build — laser power, hatch distance, gas flow and more
                        </p>
                        <kbd> 3D PRINTING </kbd>
                        <kbd> AI </kbd>
                    </div>
                </a>
            </div>



            <div class="col mb-4">
                <a href="https://www.industr.com/en/india-s-opportunity-to-become-a-global-manufacturing-hub-2619237" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/articles/india-opurtunity.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            10 AUG, 2021
                        </p>
                        <h4 class="card-title">
                            India’s opportunity to become a global manufacturing hub
                        </h4>
                        <p>
                            A new study by the World Economic Forum presents five ways India can realise its manufacturing potential and build a thriving manufacturing sector.
                        </p>
                        <kbd> Manufacturing </kbd>
                        <kbd> India </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.moldmakingtechnology.com/articles/exploring-am-powders-for-tooling" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/articles/exploring.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            19 MAY, 2021
                        </p>
                        <h4 class="card-title">
                            Exploring AM Powders for Tooling
                        </h4>
                        <p>
                            Additive manufacturing (AM) has been making its way into more tooling applications and the importance of conformal cooling in molds and dies has moved from the novelty stage to accepted practice.
                        </p>
                        <kbd> 3D PRINTING </kbd>
                        <kbd> SPOTLIGHT </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.industr.com/en/disruptive-technologies-for-disruptive-manufacturing-2617458" target="_blank" rel="noopener" class="card border-0 h-100">
                    <img src="/assets/img/articles/disruptive.jpg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body px-3">
                        <p>
                            27 Jul, 2021
                        </p>
                        <h4 class="card-title">
                            Disruptive Technologies For Disruptive Manufacturing
                        </h4>
                        <p>
                            The year 2020 had plenty of lessons taught to industries, and the year 2021 has been about implementing these lessons.
                        </p>
                        <kbd> Digital </kbd>
                        <kbd> AI </kbd>
                    </div>
                </a>
            </div>


        </div>
        <!-- <div class="row justify-content-center mt-4">
            <div class="col">
                <div aria-label="Page navigation">
                    <ul class="pagination pagination-sm justify-content-center">
                      <li class="page-item"><a class="page-link" href="">Previous</a></li>
                      <li class="page-item"><a class="page-link" href="">1</a></li>
                      <li class="page-item"><a class="page-link" href="">2</a></li>
                      <li class="page-item"><a class="page-link" href="">3</a></li>
                      <li class="page-item"><a class="page-link" href="">4</a></li>
                      <li class="page-item"><a class="page-link" href="">5</a></li>
                      <li class="page-item"><a class="page-link" href="">Next</a></li>
                    </ul>
                </div>
                <p class="text-center">
                    Showing orders 1-5 of 10 total Articles
                </p>
            </div>
        </div>
         -->
    </div>
</section>

<!-- Last Section -->
<section class="global-space-between bottom-end-padding a-trueventor">
    <div class="container-fluid">
        <div class="row">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-4 col-lg-4 p-0 d-none d-md-block">
                <img src="./assets/img/customer_landing/Book a Demo.jpg" width="100%" loading="lazy" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-8 col-lg-8 pl-md-5 d-flex align-items-center">
                <div class="w-100">
                    <!-- Title -->
                    <h1>
                        Do you want <br> a free quote <br> in 24 hours?
                    </h1>
                    <!-- Paragraph -->
                    <p class="mt-4 mb-0">
                        Sign up for a free trial and find out how <br> Truventor makes Manufacturing simple.
                    </p>
                    <!-- Action Buttons -->
                    <div class="row">

                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <a  [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                                Get In Touch
                            </a>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn w-100">
                                Book A Demo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<!-- Modal -->
<div class="modal fade" id="subscribeArticle" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Subscribe</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form method="POST" id="zcampaignOptinForm" action="https://maillist-manage.in/weboptin.zc" target="_zcSignup">

                    <div class="input-group mb-3">
                        <input class="form-control" type="text" placeholder="Email" changeitem="SIGNUP_FORM_FIELD" name="CONTACT_EMAIL" id="EMBED_FORM_EMAIL_LABEL">
                        <div class="input-group-append">
                            <button class="btn btn-primary input-group-text" type="submit" name="SIGNUP_SUBMIT_BUTTON" id="zcWebOptin" value="Subscribe">Subscribe</button>
                        </div>
                    </div>
                    <input type="hidden" id="fieldBorder" value="">
                    <input type="hidden" id="submitType" name="submitType" value="optinCustomView">
                    <input type="hidden" id="emailReportId" name="emailReportId" value="">
                    <input type="hidden" id="formType" name="formType" value="QuickForm">
                    <input type="hidden" name="zx" id="cmpZuid" value="1df8b9e1b0">
                    <input type="hidden" name="zcvers" value="2.0">
                    <input type="hidden" name="oldListIds" id="allCheckedListIds" value="">
                    <input type="hidden" id="mode" name="mode" value="OptinCreateView">
                    <input type="hidden" id="zcld" name="zcld" value="1b9f03965b77f7c">
                    <input type="hidden" id="zctd" name="zctd" value="">
                    <input type="hidden" id="document_domain" value="">
                    <input type="hidden" id="zc_Url" value="unfi.maillist-manage.in">
                    <input type="hidden" id="new_optin_response_in" value="0">
                    <input type="hidden" id="duplicate_optin_response_in" value="0">
                    <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW">
                    <input type="hidden" id="zc_formIx" name="zc_formIx" value="3za006630a1be27fae133925247f132ea6bb8404408ae745fa02b9ce8d99fc4796">
                    <input type="hidden" id="viewFrom" value="URL_ACTION">
                    <input type="hidden" id="scriptless" name="scriptless" value="yes">
                </form>
            </div>

        </div>
    </div>
</div>

<!--Zoho Campaigns Web-Optin Form Starts Here-->

<div id="customForm">
    <div class="quick_form_9_css" name="SIGNUP_BODY">
        <div>
            <span id="SIGNUP_HEADING"></span>
            <div style="position:relative;">
                <div id="Zc_SignupSuccess" style="display:none;position:absolute;margin-left:4%;width:90%;background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154);  margin-top: 10px;margin-bottom:10px;word-break:break-all ">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                        <tbody>
                            <tr>
                                <td width="10%">
                                    <img class="successicon" src="https://unfi.maillist-manage.in/images/challangeiconenable.jpg" align="absmiddle">
                                </td>
                                <td>
                                    <span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px;word-break:break-word">&nbsp;&nbsp;Thank
                                        you for Signing Up</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
</div>
<div id="zcOptinOverLay" oncontextmenu="return false" style="display:none;text-align: center; background-color: rgb(0, 0, 0); opacity: 0.5; z-index: 100; position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;">
</div>
<div id="zcOptinSuccessPopup" style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position: fixed; left: 26%;background-color: #FFFFFF;border-color: #E6E6E6; border-style: solid; border-width: 1px;  box-shadow: 0 1px 10px #424242;padding: 35px;">
    <span style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;" id="closeSuccess">
        <img src="https://unfi.maillist-manage.in/images/videoclose.png">
    </span>
    <div id="zcOptinSuccessPanel"></div>
</div>

<!--Zoho Campaigns Web-Optin Form Ends Here-->