<!-- Main -->
<section class="main-session">
    <img src="assets/img/cnc_metal_capabilities/milling/Mask Group 231.jpg" class="img-banner" loading="lazy" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        CNC <br> Milling
                    </h1>
                    <p class="mt-4">
                        Cost-effective CNC milling solution with <br> accuracy and precision.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- CNC Milling -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    CNC Milling
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    We offer CNC milling with 3-axis, and 4- axis machines for plastics and metal. With standard raw material block readily available with us, our average turnaround time is less than 4 days. Millled parts may have visible tool marks that are later post processed
                    depending on required tolerance and finishes.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div>
                    <div class="row">
                        <!-- Image -->
                        <div class="col-5">
                            <img src="assets/img/placeholder/demmy_2.PNG" class="img-fluid" loading="lazy" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-7">
                            <h5>
                                3- Axis
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row">
                        <!-- Image -->
                        <div class="col-5">
                            <img src="assets/img/placeholder/demmy_2.PNG" class="img-fluid" loading="lazy" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-7">
                            <h5>
                                4 Axis
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row">
                        <!-- Image -->
                        <div class="col-5">
                            <img src="assets/img/placeholder/demmy_2.PNG" class="img-fluid" loading="lazy" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-7">
                            <h5>
                                Conventional Milling
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content">
                    <div class="row py-2">
                        <div class="col"> <b>Max part size</b> </div>
                        <div class="col">
                            <p>800mm x 400mm x 400mm</p>
                        </div>
                    </div>
                    <hr class="p-0 m-0">

                    <div class="row py-2">
                        <div class="col"> <b>Accuracy</b> </div>
                        <div class="col">
                            <p>1-500 Nos</p>
                        </div>
                    </div>
                    <hr class="p-0 m-0">

                    <div class="row py-2">
                        <div class="col"> <b>Lead Time</b> </div>
                        <div class="col">
                            <p>7-15 Days</p>
                        </div>
                    </div>
                    <hr class="p-0 m-0">
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Attributes  -->
<section class="attributes-cnc-machining-sub-capababilites global-space-between">
    <div class="container">
        <!-- Title -->
        <div class="row mb-5">
            <div class="col col-12 p-md-0">
                <h1 class="py-4 text-center">
                    Types of Milling Operations
                </h1>
            </div>
        </div>
        <!-- accordion -->
        <div class="row">

            <!-- Side Image -->
            <div class="col-sm-12 col-md-5 p-3 d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start">
                <img src="./assets/img/cnc_metal_capabilities/milling/Group 20137.jpg" class="img-fluid w-100" loading="lazy" alt="Truventor">
            </div>

            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-7">
                <!-- collapse -->
                <div class="w-100">
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="col-sm-12 col-md-10 pl-3 pl-md-0">
                            <!-- Accordion -->
                            <div class="accordion" id="type-of-milling-operations">

                                <!-- Preparation of master pattern -->
                                <div>
                                    <div id="type-of-milling-operations-accordion-one">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#type-of-milling-operations-collapse-one" aria-expanded="true" aria-controls="type-of-milling-operations-collapse-one">
                                                    <h6 class=" text-left">
                                                        Plain milling
                                                    </h6>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="type-of-milling-operations-collapse-one" class="collapse show" aria-labelledby="type-of-milling-operations-accordion-one" data-parent="#type-of-milling-operations">
                                        <p>
                                            Also known as surface milling, this milling process uses a cutting tool to remove material along the surface of the workpiece. In plain milling, the rotation axis is parallel to the workpiece.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <!-- Making of Mould -->
                                <div>
                                    <div id="type-of-milling-operations-accordion-two">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#type-of-milling-operations-collapse-two" aria-expanded="true" aria-controls="type-of-milling-operations-collapse-two">
                                                    <h6 class="text-left">
                                                        Face milling
                                                    </h6>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="type-of-milling-operations-collapse-two" class="collapse" aria-labelledby="type-of-milling-operations-accordion-two" data-parent="#type-of-milling-operations">
                                        <p>
                                            Face milling uses a rotational axis perpendicular to the material’s surface. The cutting or grinding tool faces down against the workpiece surface to remove material.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <!-- Pouring -->
                                <div>
                                    <div id="type-of-milling-operations-accordion-three">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#type-of-milling-operations-collapse-three" aria-expanded="true" aria-controls="type-of-milling-operations-collapse-three">
                                                    <h6 class="text-left">
                                                        Angular milling
                                                    </h6>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="type-of-milling-operations-collapse-three" class="collapse" aria-labelledby="type-of-milling-operations-accordion-three" data-parent="#type-of-milling-operations">
                                        <p>
                                            This milling method positions the cutting tool’s rotary axis at an angle to the workpiece surface to produce angular cuts as specified by the design, such as grooves or dovetails.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <!-- Extracting & Finishing -->
                                <div>
                                    <div id="type-of-milling-operations-accordion-four">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#type-of-milling-operations-collapse-four" aria-expanded="true" aria-controls="type-of-milling-operations-collapse-four">
                                                    <h6 class="text-left">
                                                        Form milling
                                                    </h6>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="type-of-milling-operations-collapse-four" class="collapse" aria-labelledby="type-of-milling-operations-accordion-four" data-parent="#type-of-milling-operations">
                                        <p>
                                            Form milling makes non-flat cuts, such as contours, curves, and radii. Each type of curve will require a specific cutting tool to create an accurate form cut.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Application Of Metal Bending -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1 class="mb-5 mb-md-4">
                        Attributes
                    </h1>
                    <p class="mb-5 mb-md-4">
                        Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                    </p>
                    <!-- Desktop Button -->
                    <div class="d-none d-md-block">
                        <a [routerLink]="['/contact']" class="mt-4 btn px-5"> Get In Touch </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/cnc_metal_capabilities/svg/Overview.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Overview
                        </h3>
                        <p>
                            CAD model design: Creating a 2D or 3D design
                        </p>
                        <p>
                            Conversion to a CNC program: Convert the CAD file to CNC programming using CAM software.
                        </p>
                        <p>
                            Machine setup: Operator prepares the machine and workpiece
                        </p>
                        <p>
                            Operation execution: Initiative the machining program.
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/cnc_metal_capabilities/svg/Application.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Applications
                        </h3>
                        <p>
                            Aerospace and aircraft
                        </p>
                        <p>
                            Automotive
                        </p>
                        <p>
                            Electronics & electrical
                        </p>
                        <p>
                            Industrial and OEM
                        </p>
                        <p>
                            Medical
                        </p>
                        <p>
                            Technology and security
                        </p>
                    </div>
                </div>
                <hr>

                <!-- Mobile Button -->
                <div class="px-3 d-block d-md-none">
                    <a [routerLink]="['/contact']" class="mt-4 btn btn-block"> Get In Touch </a>
                </div>

            </div>
        </div>
    </div>
</section>


<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant 3D printing quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <img src="./assets/img/cnc_metal_capabilities/milling/Group 13311.jpg" class="fluid-img w-100" loading="lazy" alt="Truventor">
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>