<section class="contact-cover imprint-main-session">
    <!-- <img src="assets/img/privacy/banner.jpg" class="img-banner" alt="Truventor"> -->

    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="content">
                    <h1>Privacy Policy</h1>
                    <p>
                        All your questions answered at one place
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="privacy">
    <div class="container">
        <div class="">
            <div class="row">
                <div class="col-md-4 col-12">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#intro" role="tab"
                                aria-controls="intro">Introduction</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#home" role="tab"
                                aria-controls="home">Information we collect</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#profile" role="tab" aria-controls="profile">How
                                personal information is collected and held by us</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#messages" role="tab"
                                aria-controls="messages">Data protection rights</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#settings" role="tab"
                                aria-controls="settings">Collection of personal information through website activity</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#automeans" role="tab"
                                aria-controls="automeans">Analytics and anonymisation</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#intellectual" role="tab"
                                aria-controls="intellectual">The purposes for which we collect, hold, use and disclose
                                personal information</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#useoftvweb" role="tab"
                                aria-controls="useoftvweb">Publicly available data</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#otherweb" role="tab"
                                aria-controls="otherweb">Marketing</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#changeprivacy" role="tab"
                                aria-controls="changeprivacy">Third party service providers</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#contactus" role="tab"
                                aria-controls="contactus">Intellectual property
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#one" role="tab" aria-controls="one">How we
                                handle requests to access your personal information
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#two" role="tab" aria-controls="two">How we
                                handle requests to correct your personal information
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#three" role="tab" aria-controls="three">Contact
                                us
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <div class="verticle-line text-center"></div>
                </div>
                <div class="col-md-6 col-12">
                    <div class="tab-content">
                        <div class="tab-pane active" id="intro" role="tabpanel">
                            This Privacy Policy of Truventor AI and Robotics Pty Ltd ACN 622 249 969 (we, us, our) sets
                            out how
                            we treat the information, including personal information, that we collect, use and disclose
                            and our
                            procedures regarding the handling of information (information), including the collection,
                            use,
                            disclosure and storage of information, as well as the right of individuals to access and
                            correct that
                            information. This Privacy Policy applies to the use of our website with domain name
                            www.truventor.ai,
                            and ancillary platforms included TV Manage, TV Manufacture, and the mobile application
                            Truventor.Calculate (Platforms), and the services provided therein (Services).
                            From time to time, we may revise or update this Privacy Policy or our information handling
                            practices.
                            If we do so, the revised Privacy Policy will be published on our Platform.
                            We may collect information in order to conduct our business, to provide and market our
                            Services and
                            to meet our legal obligations. By using our Platforms or our Services, or by providing any
                            personal
                            information to us, you consent to the processing, collection, use and disclosure of your
                            personal
                            information as set out in this Privacy Policy.
                        </div>
                        <div class="tab-pane" id="home" role="tabpanel">
                            The types of information we may collect and hold includes (but is not limited to)
                            information about:
                            • clients, business associates and potential clients and their employees;
                            • suppliers and their employees; and
                            • prospective employees and contractors.
                            Personal information that we may collect and hold include:
                            • your name, address, contact telephone number and other contact details such as your email
                            address;
                            • payment information (such as credit card or bank details);
                            • other personal information required to provide our services in specific cases; and
                            • activity data and details of your use of our products or services.
                            You are not obliged to provide personal information to us. However, in many cases, if you do
                            not
                            provide your personal information to us, we may not be able to supply the relevant product
                            or service
                            that you have requested from us.
                            If it is necessary to provide specific services to you, we may collect sensitive information
                            about you.
                            However, we will only collect sensitive information from you if you agree to provide it to
                            us, you
                            authorise us to obtain it from a third party or where the collection of the information is
                            required or
                            authorised by or under law, or a Court/Tribunal order or otherwise where the collection is
                            not prohibited
                            under relevant laws.
                        </div>
                        <div class="tab-pane" id="profile" role="tabpanel">
                            We collect personal information in the following ways:
                            • when you fill in and return to us a client form;
                            • when you submit personal information through our website or software (such as when you
                            send us a message or fill out an online form);
                            • in person, for example, when you engage with our employees, agents, or customer service
                            representatives; and
                            • in the course of providing services to you.
                            We store personal information in computer storage facilities and paper-based files. We take
                            steps to
                            protect your personal information against loss, unauthorised access, use modification or
                            disclosure.
                            Some examples of the steps we take to protect your personal information include:
                            • ensuring there are suitable password protection measures and access privileges in place to
                            monitor and control access to our IT systems;
                            • imposing restrictions on physical access to paper files;
                            • requiring any third parties engaged by us to provide appropriate assurances to handle your
                            personal information in a manner consistent with relevant laws; and
                            • taking reasonable steps to destroy or de-identify personal information after we no longer
                            need
                            it for our business or to comply with the law.
                        </div>
                        <div class="tab-pane" id="messages" role="tabpanel">
                            Every user of the Platform has the following rights:
                            • the right to request us for copies of your personal data.
                            • the right to request that we rectify any information you believe is inaccurate.
                            • the right to request that we erase your personal, identifying data, in certain
                            circumstances.
                            • the right to request that we restrict the processing of your personal data, in certain
                            circumstances.
                            • the right to object to our processing of your personal data, in certain circumstances.
                            • the right to request that transfer your personal data to another organisation, in certain
                            circumstances.
                            We will handle any requests made in accordance with this Privacy Policy and the requirements
                            of
                            applicable laws.
                        </div>
                        <div class="tab-pane" id="settings" role="tabpanel">
                            Information that may identify you as a user may be gathered during your access to the
                            Platform through
                            cookies and other means.
                            A cookie is a piece of data that allows the server to identify and interact more effectively
                            with your
                            computer. The cookie assists us in identifying what our users find interesting on the
                            Platform.
                            Cookies will be allocated the first time you use the Platform and may be updated upon
                            subsequent uses.
                            The cookies do not identify you as an individual in our data collection process, however
                            they do identify
                            your internet service provider.
                            You can configure your web browser to refuse cookies. If you do so, you may not be able to
                            use all or
                            part of the Platform.
                        </div>
                        <div class="tab-pane" id="automeans" role="tabpanel">
                            We use Google Analytics, a web analytics service provided by Google, Inc. (Google). Google
                            Analytics
                            uses cookies (see above), to help us analyse how users use our platform and our Services.
                            Although
                            Google Analytics records data such as your geographical location, device, internet browser
                            and
                            operating system, none of this information personally identifies you with us.
                            The information generated by your use of the Platform and Services will be transmitted to
                            and stored
                            by Google on servers in your country of residence. Google will use the information on behalf
                            of us for
                            the purpose of evaluating your use of the Platform and Services, compiling reports on
                            activity for us
                            and providing us with other services relating to activity and internet usage. You may refuse
                            the use of
                            these cookies via the settings in your browser as explained above. You can also opt out of
                            being tracked
                            by Google Analytics in the future by downloading and installing Google Analytics Opt-out
                            Browser
                            Add-on for your current web browser: http://tools.google.com/dlpage/gaoptout?hl+en
                        </div>
                        <div class="tab-pane" id="intellectual" role="tabpanel">
                            We collect, hold, use and disclose personal information for a variety of business purposes
                            including:
                            • to provide the products or services you have requested from us;
                            • to process payments;
                            • to improve our business, products and the Services, or the business, products and Services
                            of
                            our clients;
                            • to promote our business or our client’s business to you;
                            • to market other Truventor services or products to you;
                            • to handle and respond to your enquiries, complaints or concerns;
                            • to generate statistical data generated out of your and others use of the Platform; and
                            • to provide personal information to third parties as set out in this Privacy Policy.
                        </div>
                        <div class="tab-pane" id="useoftvweb" role="tabpanel">
                            We may also process and make use of personal data that is publicly available and relevant to
                            our
                            Services.
                            When we collect publicly available information we may not have a way to ensure that personal
                            data in
                            such materials has been processed in accordance with applicable laws. However, if you notify
                            us that
                            your personal data contained in such materials is inaccurate or has been processed in
                            violation of
                            applicable data protection laws, we may delete it upon verification of your identity.
                        </div>
                        <div class="tab-pane" id="otherweb" role="tabpanel">
                            We also collect, hold, use and disclose your personal information to:
                            • notify you about the details of new services and products offered by us;
                            • send you our newsletters and other marketing publications;
                            • administer our databases for client service, marketing and financial accounting purposes;
                            and
                            • to comply with our legal requirements regarding the collection and retention of
                            information
                            concerning the products and services that we provide.
                            If you do not wish to disclose your personal information for the purpose of direct marketing
                            or you
                            would like to opt-out of receiving direct marketing communications, you can do so by
                            contacting the
                            us using the contact details set out below, or by following the instructions to unsubscribe
                            which are
                            contained in a communication that you receive from us.
                            We gather data and work with third parties to show you personalised ads on behalf of
                            advertisers. This
                            data comes from cookies, the personal information you provide, your use of the Platform, and
                            anything
                            inferred from this data. We only share this information in a way that does not reveal your
                            personal
                            identity, to show you ads that may be of particular interest to you. We may also use such
                            data on an
                            anonymised aggregate basis to measure the use behaviour and demographics of users based.
                            This
                            information can be employed to group users based on their common attributes for use in
                            targeted ad
                            campaigns on the Platform.
                        </div>
                        <div class="tab-pane" id="changeprivacy" role="tabpanel">
                            We may disclose your personal information to third parties who work with us in our business
                            to
                            promote, market or improve the services that we provide, including:
                            • providers of customer relations management database services and marketing database
                            services;
                            • marketing consultants, promotion companies and website hosts; and
                            • consultants and professional advisers.
                            We may also combine your information with information available from other sources,
                            including the
                            entities mentioned above, to help us provide better services to you.
                            Where we do share information with third parties, we require that there are contracts in
                            place that only
                            allow use and disclosure of personal information to provide the service and that protect
                            your personal
                            information. Otherwise, we will disclose personal information to others if you've given us
                            permission,
                            or if the disclosure relates to the main purpose for which we collected the information and
                            you would
                            reasonably expect us to do so.
                        </div>
                        <div class="tab-pane" id="contactus" role="tabpanel">
                            The Services include the function for certain users to upload 2D and 3D designs for their
                            manufacturing
                            projects (Designs), and other content to the Platforms (Client IP). All Client IP is treated
                            with the
                            utmost confidentiality, and we ensure that there are security measures in place to protect
                            the
                            confidentiality in all Client IP.
                            Client IP may be shared with third parties from time to time. This may include our
                            employees, affiliates,
                            suppliers, contractors, other users of the Platforms, or other third parties. When Client IP
                            is shared, we
                            ensure that the recipient is aware of our privacy obligations, and take reasonable measures
                            to ensure
                            that your Client IP is protected.
                            If you believe that your intellectual property rights have been infringed by us or by any
                            third party in
                            relation with the Platforms or the Services, please notify us at the contact details below,
                            and we will
                            conduct an internal investigation.
                        </div>

                        <div class="tab-pane" id="one" role="tabpanel">
                            You have a right to request access to your personal information which we hold about you and
                            to request
                            its correction. You can make such a request by contacting us using the contact details set
                            out in this
                            policy.
                            We will respond to any such request for access as soon as reasonably practicable. Where
                            access is to be
                            given, we will provide you with a copy or details of your personal information in the manner
                            requested
                            by you where it is reasonable and practicable to do so.
                            We will not charge you a fee for making a request to access your personal information.
                            However, we
                            may charge you a reasonable fee for giving you access to your personal information.
                            In some cases, we may refuse to give you access to the information you have requested or
                            only give
                            you access to certain information. If we do this, we will provide you with a written
                            statement setting
                            out our reasons for refusal, except where it would be unreasonable to do so.
                        </div>
                        <div class="tab-pane" id="two" role="tabpanel">
                            We will take such steps (if any) as are reasonable in the circumstances to make sure that
                            the personal
                            information we collect, use or disclose is accurate, complete, up to date and relevant.
                            If you believe the personal information we hold about you is inaccurate, irrelevant, out of
                            date or
                            incomplete, you can ask us to update or correct it. To do so, please contact us using the
                            contact details
                        </div>
                        <div class="tab-pane" id="three" role="tabpanel">
                            If you have any questions about this Privacy Policy, if you wish to correct or update
                            information we
                            hold about you or if you wish to request access or correction of your personal information
                            or make a
                            complaint about a breach by us of our privacy obligations (including the way we have
                            collected,
                            disclosed or used your personal information), please contact us at:
                            <br>
                            <div>
                                <b>
                                    Amit Joshi/ Compliance Manager <br>
                                    Level 19, 180 Lonsdale Street, Mitcham VIC 3132<br>
                                    amit.joshi@truventor.ai<br>
                                    +61 411 627 339
                                </b>
                            </div>

                            We will acknowledge and investigate any complaint about the way we manage personal
                            information as
                            soon as practicable. We will take reasonable steps to remedy any failure to comply with our
                            privacy
                            obligations.
                        </div>

                    </div>


                </div>
            </div>
        </div>

    </div>
</section>