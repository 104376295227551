<!-- Main -->
<section class="imprint-main-session">
  <div class="container">

    <!-- Heading -->
    <div class="row">
      <div class="col-sm-12 col-md-12">
        <div class="heading">
          <h1> FAQs </h1>
          <p class="mt-4">
            All your questions
            answered at one place
          </p>
        </div>
      </div>
    </div>

  </div>
</section>

<!-- FAQs -->
<section class="main-faq">
  <div class="container py-5">

    <!-- For Customers -->
    <div class="row">
      <div class="col">

        <h1 class="text-left">For customers</h1>

        <!-- one -->
        <div class="card">
          <div class="card-head" id="heading-one">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-one" aria-expanded="true"
              aria-controls="faq-one">
              How do I register through the platform?
            </h2>
          </div>
          <div id="faq-one" class="collapse show" aria-labelledby="heading-one">
            <div class="card-body">
              The website has the login option on the top right corner of the ‘Home' page. If you are looking to procure
              a part, select the ‘Register Here’ option, fill in the details and submit them to create your account.
            </div>
          </div>
        </div>

        <!-- two -->
        <div class="card">
          <div class="card-head" id="heading-two">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-two" aria-expanded="true"
              aria-controls="faq-two">
              What manufacturing services does Truventor provide?
            </h2>
          </div>
          <div id="faq-two" class="collapse show" aria-labelledby="heading-two">
            <div class="card-body">
              Truventor currently caters to six technologies, namely, Sheet Metal Fabrication, CNC Machining, 3D
              Printing, Vacuum Casting, Injection Moulding, and Metal Casting. For more information, visit the website’s
              ‘Capabilities’ section.
            </div>
          </div>
        </div>

        <!-- three -->
        <div class="card">
          <div class="card-head" id="heading-three">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-three" aria-expanded="true"
              aria-controls="faq-three">
              How does Truventor select the right supplier for a specific requirement?
            </h2>
          </div>
          <div id="faq-three" class="collapse show" aria-labelledby="heading-three">
            <div class="card-body">
              Based on the requirement, our website algorithm selects the top three suppliers for a specific project.
              The selection of any supplier depends on the project, supplier’s capabilities, supplier’s infrastructure,
              supplier’s rating, location, and timeline.
            </div>
          </div>
        </div>

        <!-- four -->
        <div class="card">
          <div class="card-head" id="heading-four">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-four" aria-expanded="true"
              aria-controls="faq-four">
              How does Truventor ensure confidentiality of data?
            </h2>
          </div>
          <div id="faq-four" class="collapse show" aria-labelledby="heading-four">
            <div class="card-body">
              All our Manufacturing Partners sign an agreement along with an NDA for an order. Truventor’s CAD viewer
              app, ‘Truventor Calculate’, will allow for viewing of the CAD file but not permit downloading until the
              order is awarded to a specific supplier. For more details, read: <a
                [routerLink]="['/security']">https://www.truventor.ai/security</a>
            </div>
          </div>
        </div>

        <!-- five -->
        <div class="card">
          <div class="card-head" id="heading-five">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-five" aria-expanded="true"
              aria-controls="faq-five">
              Can I sign an NDA with Truventor.ai? </h2>
          </div>
          <div id="faq-five" class="collapse show" aria-labelledby="heading-five">
            <div class="card-body">
              Protecting the Data and IP of our customers is our topmost priority. We sign an NDA with our customers, as
              well as our manufacturing partners.
            </div>
          </div>
        </div>

        <!-- six -->
        <div class="card">
          <div class="card-head" id="heading-six">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-six" aria-expanded="true"
              aria-controls="faq-six">
              Who qualifies to be Truventor.ai’s manufacturing partners?
            </h2>
          </div>
          <div id="faq-six" class="collapse show" aria-labelledby="heading-six">
            <div class="card-body">
              Our manufacturing partners specialize in different operations and possess manufacturing capacities ranging
              from two machines to 50+ machines.
            </div>
          </div>
        </div>

        <!-- seven -->
        <div class="card">
          <div class="card-head" id="heading-seven">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-seven" aria-expanded="true"
              aria-controls="faq-seven">
              How does Truventor ensure quality and timely delivery of the part? </h2>
          </div>
          <div id="faq-seven" class="collapse show" aria-labelledby="heading-seven">
            <div class="card-body">
              Every project is assigned to a ‘Project Manager’, who will be the contact point between suppliers and
              customers. The ‘Project Manager’ will ensure that the timeline is met and will arrange for a third party
              to inspect the quality of the manufactured part.
            </div>
          </div>
        </div>

        <!-- eight -->
        <div class="card">
          <div class="card-head" id="heading-eight">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-eight" aria-expanded="true"
              aria-controls="faq-eight">
              What types of files does Truventor accept?
            </h2>
          </div>
          <div id="faq-eight" class="collapse show" aria-labelledby="heading-eight">
            <div class="card-body">
              Truventor accepts various CAD files, such as AutoCAD, SolidWorks, Creo, and NX, among others, in .dwg,
              AutoCAD, IGES, STL, Parasolid, etc., file formats. We also work on 2D engineering drawings.
            </div>
          </div>
        </div>

        <!-- nine -->
        <div class="card">
          <div class="card-head" id="heading-nine">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-nine" aria-expanded="true"
              aria-controls="faq-nine">
              Does Truventor provide design services?

            </h2>
          </div>
          <div id="faq-nine" class="collapse show" aria-labelledby="heading-nine">
            <div class="card-body">
              Currently, we do not provide any design services. However, if you need help related to creating a CAD
              drawing, you can email us at: <a href="mailto:care@truventor.ai">care@truventor.ai</a>.
            </div>
          </div>
        </div>

        <!-- ten -->
        <div class="card">
          <div class="card-head" id="heading-ten">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-ten" aria-expanded="true"
              aria-controls="faq-ten">
              Will I know who the part manufacturer is?
            </h2>
          </div>
          <div id="faq-ten" class="collapse show" aria-labelledby="heading-ten">
            <div class="card-body">
              No, we will be the point of contact for you and will supply you the part as per your desired quality and
              quantity. </div>
          </div>

          <!-- eleven -->
          <div class="card">
            <div class="card-head" id="heading-eleven">
              <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-eleven" aria-expanded="true"
                aria-controls="faq-eleven">
                Does Truventor have any restrictions in terms of geography?
              </h2>
            </div>
            <div id="faq-eleven" class="collapse show" aria-labelledby="heading-eleven">
              <div class="card-body">
                Truventor currently delivers all over the world. We have already served customers from 16 countries.
              </div>
            </div>
          </div>

          <!-- twelve -->
          <div class="card">
            <div class="card-head" id="heading-twelve">
              <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-twelve" aria-expanded="true"
                aria-controls="faq-twelve">
                Will I be able to know who the part manufacturer is?
              </h2>
            </div>
            <div id="faq-twelve" class="collapse show" aria-labelledby="heading-twelve">
              <div class="card-body">
                No, we will be the point of contact for you and will supply you the part as per your desired quality and
                quantity.
              </div>
            </div>
          </div>

          <!-- thirteen -->
          <div class="card">
            <div class="card-head" id="heading-thirteen">
              <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-thirteen" aria-expanded="true"
                aria-controls="faq-thirteen">
                Do you have any restrictions in terms of geography?
              </h2>
            </div>
            <div id="faq-thirteen" class="collapse show" aria-labelledby="heading-thirteen">
              <div class="card-body">
                We are currently delivering all over the world. We have already served customers from 16 countries.
              </div>
            </div>
          </div>

          <!-- fourteen -->
          <div class="card">
            <div class="card-head" id="heading-fourteen">
              <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-fourteen" aria-expanded="true"
                aria-controls="faq-fourteen">
                What is your minimum order quantity?
              </h2>
            </div>
            <div id="faq-fourteen" class="collapse show" aria-labelledby="heading-fourteen">
              <div class="card-body">
                There is no minimum order quantity. We can make arrangements for 1 to 10,000 parts.
              </div>
            </div>
          </div>


        </div>
      </div>


    </div>

    <!-- For Customers -->
    <div class="row">
      <div class="col">

        <h1 class="text-left">For suppliers</h1>

        <!-- one -->
        <div class="card">
          <div class="card-head" id="heading-one">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-one" aria-expanded="true"
              aria-controls="faq-supplier-one">
              How do I register through the platform?
            </h2>
          </div>
          <div id="faq-supplier-one" class="collapse show" aria-labelledby="heading-supplier-one">
            <div class="card-body">
              Log in by clicking on the ‘Become a Partner’ option at the top right corner of the ‘Home’ page. Then,
              click on the ‘Truventor Partner’ option, fill in the details, and submit the application. A Truventor
              executive will then get in touch with you to complete the subsequent procedures.
            </div>
          </div>
        </div>

        <!-- two -->
        <div class="card">
          <div class="card-head" id="heading-supplier-two">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-two" aria-expanded="true"
              aria-controls="faq-supplier-two">
              How can a company become a manufacturing partner?
            </h2>
          </div>
          <div id="faq-supplier-two" class="collapse show" aria-labelledby="heading-supplier-two">
            <div class="card-body">
              The company seeking to become a manufacturing partner will need to fill in an application comprising the
              company’s manufacturing, infrastructure, and financial details. Once Truventor receives the application,
              our executive will visit the factory for the final verification and audit. As soon as Truventor is
              apprised of the required information, we sign the ‘Manufacturing Partner Agreement’ with the supplier
              and provide them the dashboard for order management.
            </div>
          </div>
        </div>

        <!-- three -->
        <div class="card">
          <div class="card-head" id="heading-supplier-three">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-three" aria-expanded="true"
              aria-controls="faq-supplier-three">
              What are the benefits of becoming a manufacturing partner? </h2>
          </div>
          <div id="faq-supplier-three" class="collapse show" aria-labelledby="heading-supplier-three">
            <div class="card-body">
              The benefits include: Increase in machine utilization, more business, access to a global customer
              network, and working capital support, among others. For more details, visit <a
                [routerLink]="['/manufacturing-partners']">https://www.truventor.ai/manufacturing-partners</a>
            </div>
          </div>
        </div>

        <!-- four -->
        <div class="card">
          <div class="card-head" id="heading-supplier-four">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-four" aria-expanded="true"
              aria-controls="faq-supplier-four">
              Are there any hidden charges for becoming a manufacturing partner?
            </h2>
          </div>
          <div id="faq-supplier-four" class="collapse show" aria-labelledby="heading-supplier-four">
            <div class="card-body">
              No, the only cost you might have to incur is to produce sample parts at the time of onboarding. However,
              if we find the quality, timeliness, and rating suitable, we will refund the cost of the sample parts.
            </div>
          </div>
        </div>

        <!-- five -->
        <div class="card">
          <div class="card-head" id="heading-supplier-five">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-five" aria-expanded="true"
              aria-controls="faq-supplier-five">
              What kinds of enquiries will I receive?

            </h2>
          </div>
          <div id="faq-supplier-five" class="collapse show" aria-labelledby="heading-supplier-five">
            <div class="card-body">
              This will depend on your infrastructure, capabilities, and capacity. Rest assured, if you are into CNC
              machining, we will not share a sheet metal fabrication enquiry with you.
            </div>
          </div>
        </div>

        <!-- six -->
        <div class="card">
          <div class="card-head" id="heading-supplier-six">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-six" aria-expanded="true"
              aria-controls="faq-supplier-six">
              Which industry verticals do you mostly serve?
            </h2>
          </div>
          <div id="faq-supplier-six" class="collapse show" aria-labelledby="heading-supplier-six">
            <div class="card-body">
              With our on-demand manufacturing model, we can serve any industry that requires custom parts. For
              instance, we serve industries such as agriculture machinery, construction industry, oil & gas, consumer
              durables, white goods, racks & panels, electrical & electronics, automotive, and railways, to name a
              few.
            </div>
          </div>
        </div>

        <!-- seven -->
        <div class="card">
          <div class="card-head" id="heading-supplier-seven">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-seven" aria-expanded="true"
              aria-controls="faq-supplier-seven">
              Do you undertake export orders?
            </h2>
          </div>
          <div id="faq-supplier-seven" class="collapse show" aria-labelledby="heading-supplier-seven">
            <div class="card-body">
              Yes, we have already supplied parts to companies in more than 10 countries.
            </div>
          </div>
        </div>

        <!-- eight -->
        <div class="card">
          <div class="card-head" id="heading-supplier-eight">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-eight" aria-expanded="true"
              aria-controls="faq-supplier-eight">
              What are the payment terms?
            </h2>
          </div>
          <div id="faq-supplier-eight" class="collapse show" aria-labelledby="heading-supplier-eight">
            <div class="card-body">
              We have transparent, structured, and no-questions-asked payment terms of NET30 days.
            </div>
          </div>
        </div>

        <!-- nine -->
        <div class="card">
          <div class="card-head" id="heading-supplier-nine">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-nine" aria-expanded="true"
              aria-controls="faq-supplier-nine">
              Will manufacturing partners bill the customer directly or through Truventor.ai? </h2>
          </div>
          <div id="faq-supplier-nine" class="collapse show" aria-labelledby="heading-supplier-nine">
            <div class="card-body">
              For a manufacturing partner, Truventor is the customer. So, the bill will be made in the name of
              Truventor.
            </div>
          </div>
        </div>

        <!-- ten -->
        <div class="card">
          <div class="card-head" id="heading-supplier-ten">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-ten" aria-expanded="true"
              aria-controls="faq-supplier-ten">
              Will you connect us with the customer/end-user?
            </h2>
          </div>
          <div id="faq-supplier-ten" class="collapse show" aria-labelledby="heading-supplier-ten">
            <div class="card-body">
              We usually do not connect the supplier and customer directly. However, if there is a requirement, it
              could be done. </div>
          </div>
        </div>

        <!-- eleven -->
        <div class="card">
          <div class="card-head" id="heading-supplier-eleven">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-eleven" aria-expanded="true"
              aria-controls="faq-supplier-eleven">
              Do you support manufacturing partners with working capital and raw material supplies? </h2>
          </div>
          <div id="faq-supplier-eleven" class="collapse show" aria-labelledby="heading-supplier-eleven">
            <div class="card-body">
              For more details, please write to us at <a
                href="mailto:supplier.associate@truventor.ai">supplier.associate@truventor.ai</a>
            </div>
          </div>
        </div>

        <!-- twelve -->
        <div class="card">
          <div class="card-head" id="heading-supplier-twelve">
            <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#faq-supplier-twelve" aria-expanded="true"
              aria-controls="faq-supplier-twelve">
              Who is responsible in case of rejections?
            </h2>
          </div>
          <div id="faq-supplier-twelve" class="collapse show" aria-labelledby="heading-supplier-twelve">
            <div class="card-body">
              A detailed analysis will be done to understand the cause of rejection. The party at fault will be
              responsible for the damage.
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>