import { Component, OnInit } from '@angular/core';
import { AdminService } from '../shared/admin.service';

@Component({
  selector: 'app-cnc-machining-milling',
  templateUrl: './cnc-machining-milling.component.html',
  styleUrls: ['./cnc-machining-milling.component.scss']
})
export class CncMachiningMillingComponent implements OnInit {

  constructor(
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.adminService.check_customer.next('customer')
  }

}
