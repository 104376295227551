<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Galvanized steel@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Galvanized <br> steel
                    </h1>
                    <p class="text-primary">
                        CNC Machining / Sheet Metal
                    </p>
                    <p class="mt-4">
                        Galvanized steel procides extended durability and formability of steel and corrosion protection of zinc. </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Galvanized steel.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Galvanized steel is produced by coating the steel in zinc. This coating protects the steel from particle corrosion and general surface scratches and abrasions. The properties of galvanized steel are a unique combination that make it ideal for use in interior
                    and exterior applications such as car bodies, appliances, nuts and bolts, roofs, and rebar.


                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Galvanized steel
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Galvanized steel 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Lead Time
                            </th>
                            <td>
                                Less than 10 days
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Wall Thickness
                            </th>
                            <td>
                                0.75 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Tolerances
                            </th>
                            <td>
                                ±0.125mm (±0.005″)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Max part size
                            </th>
                            <td>
                                200 x 80 x 100 cm
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Excellent durability
                        </p>
                        <p class="mb-2">
                            Good strength and formability
                        </p>
                        <p class="mb-2">
                            Corrosion protection of the zinc-iron coating
                        </p>
                        <p class="mb-2">
                            Excellent mechanical properties
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Subject to chipping, cracking, and similar damage
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Car and bicycle bodies
                        </p>
                        <p class="mb-2">
                            Heavy duty shafts
                        </p>
                        <p class="mb-2">
                            Heavy industry component
                        </p>
                        <p class="mb-2">
                            Agriculture industry
                        </p>

                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>