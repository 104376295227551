import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ToastrModule } from 'ngx-toastr';
import { AbcblackPx223htComponent } from './abcblack-px223ht/abcblack-px223ht.component';
import { Abs9012Component } from './abs9012/abs9012.component';
import { AlumniumA5052Component } from './alumnium-a5052/alumnium-a5052.component';
import { AlumniumA7075Component } from './alumnium-a7075/alumnium-a7075.component';
import { ArticlesComponent } from './articles/articles.component';
import { BlogComponent } from './blog/blog.component';
import { BuyerProtectionComponent } from './buyer-protection/buyer-protection.component';
import { CalculateComponent } from './calculate/calculate.component';
import { CaseStudiesComponent } from './case-studies/case-studies.component';
import { ClientTermsComponent } from './client-terms/client-terms.component';
import { CncMachiningMillingComponent } from './cnc-machining-milling/cnc-machining-milling.component';
import { CncMachiningTurningComponent } from './cnc-machining-turning/cnc-machining-turning.component';
import { CncmachineSubcapabilitiesComponent } from './cncmachine-subcapabilities/cncmachine-subcapabilities.component';
import { CncmachiningComponent } from './cncmachining/cncmachining.component';
// import { ContactComponent } from './contact/contact.component';
import { CustomerLandingComponent } from './customer-landing/customer-landing.component';
import { DiscoverComponent } from './discover/discover.component';
import { EnterprisePageComponent } from './enterprise-page/enterprise-page.component';
import { EventsComponent } from './events/events.component';
import { FaqComponent } from './faq/faq.component';
import { FinanceComponent } from './finance/finance.component';
import { ImprintComponent } from './imprint/imprint.component';
import { InjectionmoduleComponent } from './injectionmodule/injectionmodule.component';
import { ManageComponent } from './manage/manage.component';
import { ManufacturingComponent } from './manufacturing/manufacturing.component';
import { MarketingPortalRoutingModule } from './marketing-portal-routing.module';
import { MaterialAbsBasicComponent } from './material-abs-basic/material-abs-basic.component';
import { MaterialAbsLikeComponent } from './material-abs-like/material-abs-like.component';
import { MaterialAbsComponent } from './material-abs/material-abs.component';
import { MaterialAcrylicPmmaComponent } from './material-acrylic-pmma/material-acrylic-pmma.component';
import { MaterialAluminium3003Component } from './material-aluminium3003/material-aluminium3003.component';
import { MaterialAluminium6061T6Component } from './material-aluminium6061-t6/material-aluminium6061-t6.component';
import { MaterialAluminium6082He30Component } from './material-aluminium6082-he30/material-aluminium6082-he30.component';
import { MaterialBrassc360Component } from './material-brassc360/material-brassc360.component';
import { MaterialCopperc101Component } from './material-copperc101/material-copperc101.component';
import { MaterialCopperc106Component } from './material-copperc106/material-copperc106.component';
import { MaterialCopperc110Component } from './material-copperc110/material-copperc110.component';
import { MaterialCopperc260Component } from './material-copperc260/material-copperc260.component';
import { MaterialGalvanizedSteelComponent } from './material-galvanized-steel/material-galvanized-steel.component';
import { MaterialGfNylon12Component } from './material-gf-nylon12/material-gf-nylon12.component';
import { MaterialHighTemperatureComponent } from './material-high-temperature/material-high-temperature.component';
import { MaterialInconel625Component } from './material-inconel625/material-inconel625.component';
import { MaterialInconel718Component } from './material-inconel718/material-inconel718.component';
import { MaterialLowcarbonSteel1008Component } from './material-lowcarbon-steel1008/material-lowcarbon-steel1008.component';
import { MaterialMildsteelMsComponent } from './material-mildsteel-ms/material-mildsteel-ms.component';
import { MaterialNylon12Pa2200Component } from './material-nylon12-pa2200/material-nylon12-pa2200.component';
import { MaterialNylon6CastComponent } from './material-nylon6-cast/material-nylon6-cast.component';
import { MaterialPcLikeClearComponent } from './material-pc-like-clear/material-pc-like-clear.component';
import { MaterialPlaBasicComponent } from './material-pla-basic/material-pla-basic.component';
import { MaterialPolycarbonattePcComponent } from './material-polycarbonatte-pc/material-polycarbonatte-pc.component';
import { MaterialPomDelrinComponent } from './material-pom-delrin/material-pom-delrin.component';
import { MaterialPpLikeComponent } from './material-pp-like/material-pp-like.component';
import { MaterialRubberLikeTangoComponent } from './material-rubber-like-tango/material-rubber-like-tango.component';
import { MaterialStainlessSteelSs316Component } from './material-stainless-steel-ss316/material-stainless-steel-ss316.component';
import { MaterialStainlessSteel301Component } from './material-stainless-steel301/material-stainless-steel301.component';
import { MaterialStainlessSteel304Component } from './material-stainless-steel304/material-stainless-steel304.component';
import { MaterialStainlessSteel316Component } from './material-stainless-steel316/material-stainless-steel316.component';
import { MaterialTeflonPtfeComponent } from './material-teflon-ptfe/material-teflon-ptfe.component';
import { MaterialTemplateComponent } from './material-template/material-template.component';
import { MaterialTitaniumG1Component } from './material-titanium-g1/material-titanium-g1.component';
import { MaterialTitaniumG2Component } from './material-titanium-g2/material-titanium-g2.component';
import { MaterialTitaniumG3Component } from './material-titanium-g3/material-titanium-g3.component';
import { MaterialTitaniumG4Component } from './material-titanium-g4/material-titanium-g4.component';
import { MaterialTitaniumG5Component } from './material-titanium-g5/material-titanium-g5.component';
import { MaterialToolsteeld2Component } from './material-toolsteeld2/material-toolsteeld2.component';
import { MaterialToolsteels7Component } from './material-toolsteels7/material-toolsteels7.component';
import { MaterialToolsteelwgradeComponent } from './material-toolsteelwgrade/material-toolsteelwgrade.component';
import { MaterialToosteelAgradeComponent } from './material-toosteel-agrade/material-toosteel-agrade.component';
import { MaterialToughResinComponent } from './material-tough-resin/material-tough-resin.component';
import { MaterialUltraDetailVeroComponent } from './material-ultra-detail-vero/material-ultra-detail-vero.component';
import { MediaComponent } from './media/media.component';
import { MetalcastingComponent } from './metalcasting/metalcasting.component';
import { MildSteel1018Component } from './mild-steel1018/mild-steel1018.component';
import { NylonVc3365Component } from './nylon-vc3365/nylon-vc3365.component';
import { Online3dprintingComponent } from './online3dprinting/online3dprinting.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PpMachiningComponent } from './pp-machining/pp-machining.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProcurementComponent } from './procurement/procurement.component';
import { ProductComponent } from './product/product.component';
import { ProductsecurityComponent } from './productsecurity/productsecurity.component';
import { QualityassuranceComponent } from './qualityassurance/qualityassurance.component';
import { RubberVc3300Component } from './rubber-vc3300/rubber-vc3300.component';
import { ClientsListComponent } from './shared/components/clients-list/clients-list.component';
import { CostEstimateComponent } from './shared/components/cost-estimate/cost-estimate.component';
import { QualityAssuranceComponent } from './shared/components/quality-assurance/quality-assurance.component';
import { SheetmetalBendingComponent } from './sheetmetal-bending/sheetmetal-bending.component';
import { SheetmetalLaserComponent } from './sheetmetal-laser/sheetmetal-laser.component';
import { SheetmetalPunchingComponent } from './sheetmetal-punching/sheetmetal-punching.component';
import { SheetmetalSubcapabilitiesComponent } from './sheetmetal-subcapabilities/sheetmetal-subcapabilities.component';
import { SheetmetalWeldingComponent } from './sheetmetal-welding/sheetmetal-welding.component';
import { SheetmetalfabricationComponent } from './sheetmetalfabrication/sheetmetalfabrication.component';
import { StarupprogramComponent } from './starupprogram/starupprogram.component';
import { SupplierLandingComponent } from './supplier-landing/supplier-landing.component';
import { SupplierPageNotFoundComponent } from './supplier-page-not-found/supplier-page-not-found.component';
import { SupplierTermsComponent } from './supplier-terms/supplier-terms.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ThreeDPrintingFffComponent } from './three-d-printing-fff/three-d-printing-fff.component';
import { ThreeDPrintingMicroSlaComponent } from './three-d-printing-micro-sla/three-d-printing-micro-sla.component';
import { ThreeDPrintingPolyjetComponent } from './three-d-printing-polyjet/three-d-printing-polyjet.component';
import { ThreeDPrintingSlaComponent } from './three-d-printing-sla/three-d-printing-sla.component';
import { ThreeDPrintingSlsComponent } from './three-d-printing-sls/three-d-printing-sls.component';
import { TruventorDesignComponent } from './truventor-design/truventor-design.component';
import { VaccumCastingComponent } from './vaccum-casting/vaccum-casting.component';
// import { AdowrdThreeDPrintingServiceComponent } from './adowrd-three-d-printing-service/adowrd-three-d-printing-service.component';
// import { AdwordFdm3dprinitingBangloreComponent } from './adword-fdm3dpriniting-banglore/adword-fdm3dpriniting-banglore.component';
// import { AdwordFdm3dprintingDelhiComponent } from './adword-fdm3dprinting-delhi/adword-fdm3dprinting-delhi.component';
// import { AdwordFdm3dprintingHyderabadComponent } from './adword-fdm3dprinting-hyderabad/adword-fdm3dprinting-hyderabad.component';
// import { AdwordFdm3dprintingIndiaComponent } from './adword-fdm3dprinting-india/adword-fdm3dprinting-india.component';
// import { AdwordFdm3dprintingMumbaiComponent } from './adword-fdm3dprinting-mumbai/adword-fdm3dprinting-mumbai.component';
// import { AdwordFdm3dprintingComponent } from './adword-fdm3dprinting/adword-fdm3dprinting.component';
// import { AdwordOnline3dprintingPuneComponent } from './adword-online3dprinting-pune/adword-online3dprinting-pune.component';
// import { AdwordThreeDPrintingCompanyInIndiaComponent } from './adword-three-d-printing-company-in-india/adword-three-d-printing-company-in-india.component';

@NgModule({
  declarations: [
    // AboutComponent,
    PageNotFoundComponent,
    SupplierPageNotFoundComponent,
    // ContactComponent,
    CustomerLandingComponent,
    TermsConditionsComponent,
    ProductComponent,
    SupplierLandingComponent,
    CncmachiningComponent,
    Online3dprintingComponent,
    SheetmetalfabricationComponent,
    VaccumCastingComponent,
    InjectionmoduleComponent,
    MetalcastingComponent,
    FaqComponent,
    ImprintComponent,
    ProcurementComponent,
    ManageComponent,
    DiscoverComponent,
    FinanceComponent,
    BlogComponent,
    MaterialTemplateComponent,
    // MaterialListComponent,
    ProductsecurityComponent,
    QualityassuranceComponent,
    CncmachineSubcapabilitiesComponent,
    SheetmetalSubcapabilitiesComponent,
    PrivacyPolicyComponent,
    MaterialAbsBasicComponent,
    MaterialAbsComponent,
    MaterialGfNylon12Component,
    MaterialHighTemperatureComponent,
    MaterialPcLikeClearComponent,
    MaterialUltraDetailVeroComponent,
    MaterialRubberLikeTangoComponent,
    MaterialPlaBasicComponent,
    MaterialPpLikeComponent,
    MaterialAluminium6082He30Component,
    MaterialAluminium6061T6Component,
    MaterialMildsteelMsComponent,
    MaterialStainlessSteelSs316Component,
    MaterialNylon12Pa2200Component,
    MaterialAcrylicPmmaComponent,
    MaterialAbsLikeComponent,
    MaterialNylon6CastComponent,
    MaterialPomDelrinComponent,
    MaterialTeflonPtfeComponent,
    MaterialToughResinComponent,
    CncMachiningMillingComponent,
    CncMachiningTurningComponent,
    ThreeDPrintingFffComponent,
    ThreeDPrintingSlsComponent,
    ThreeDPrintingSlaComponent,
    ThreeDPrintingMicroSlaComponent,
    ThreeDPrintingPolyjetComponent,
    SheetmetalBendingComponent,
    SheetmetalPunchingComponent,
    SheetmetalWeldingComponent,
    SheetmetalLaserComponent,
    ManufacturingComponent,
    ClientTermsComponent,
    SupplierTermsComponent,
    CaseStudiesComponent,
    MediaComponent,
    EventsComponent,
    ArticlesComponent,
    CalculateComponent,
    StarupprogramComponent,
    AlumniumA5052Component,
    MildSteel1018Component,
    NylonVc3365Component,
    AlumniumA7075Component,
    RubberVc3300Component,
    PpMachiningComponent,
    Abs9012Component,
    AbcblackPx223htComponent,
    MaterialStainlessSteel301Component,
    MaterialCopperc110Component,
    MaterialGalvanizedSteelComponent,
    MaterialCopperc106Component,
    MaterialCopperc101Component,
    MaterialStainlessSteel316Component,
    MaterialStainlessSteel304Component,
    MaterialCopperc260Component,
    MaterialBrassc360Component,
    MaterialAluminium3003Component,
    BuyerProtectionComponent,
    MaterialTitaniumG1Component,
    MaterialTitaniumG2Component,
    MaterialTitaniumG3Component,
    MaterialTitaniumG4Component,
    MaterialTitaniumG5Component,
    MaterialLowcarbonSteel1008Component,
    EnterprisePageComponent,
    MaterialToolsteeld2Component,
    MaterialToolsteels7Component,
    MaterialToolsteelwgradeComponent,
    MaterialInconel625Component,
    MaterialInconel718Component,
    MaterialPolycarbonattePcComponent,
    MaterialToosteelAgradeComponent,
    CostEstimateComponent,
    ClientsListComponent,
    QualityAssuranceComponent,
    TruventorDesignComponent,
    // AdwordFdm3dprintingComponent,
    // AdwordFdm3dprinitingBangloreComponent,
    // AdwordFdm3dprintingMumbaiComponent,
    // AdwordFdm3dprintingDelhiComponent,
    // AdwordFdm3dprintingHyderabadComponent,
    // AdwordThreeDPrintingCompanyInIndiaComponent,
    // AdwordFdm3dprintingIndiaComponent,
    // AdowrdThreeDPrintingServiceComponent,
    // AdwordOnline3dprintingPuneComponent,
    
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule, 
    ToastrModule.forRoot(),
    MarketingPortalRoutingModule,
    CarouselModule,

  ]
})
export class MarketingPortalModule { }
