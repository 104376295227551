<!-- Main -->
<section class="main-session">
    <img src="assets/img/3d_printing_capabilities/polyjet/Group 20126.jpg" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        PolyJet (PJ)
                    </h1>
                    <p class="mt-4">
                        Dental applications or orthodontics products, explore our Polyjet offerings.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!--  -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    PolyJet
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    PolyJet (PJ) is a 3D printing technology that makes use of a liquid photopolymer resin, which cures (hardens) itself when exposed to UV light, to manufacture aesthetically pleasing full colour, multi-material prototypes.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div class="mt-5 mt-md-0">
                    <div class="row my-3 align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/3d_printing_capabilities/polyjet/Group 20131@2x.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                Ultra detail (vero)
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row my-3 align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/3d_printing_capabilities/polyjet/Group 20132@2x.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                Rubber-like (tango)
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Max part size</th>
                            <td>
                                490 x 390 x 200 mm
                            </td>
                        </tr>
                        <tr>
                            <th>Accuracy </th>
                            <td>
                                (+/-) 100µm
                            </td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>
                                5-7 business days
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1 class="mb-5 mb-md-4">
                        Attributes
                    </h1>
                    <p class="mb-5 mb-md-4">
                        Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                    </p>
                    <!-- Desktop Button -->
                    <div class="d-none d-md-block">
                        <a [routerLink]="['/contact']" class="mt-4 btn px-5"> Get In Touch </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Overview.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Highlights
                        </h3>
                        <p>
                            Complex geometries with intricate details
                        </p>
                        <p>
                            Smooth & detailed prototypes
                        </p>
                        <p>
                            Faster build times
                        </p>
                        <p>
                            Wide variety of material & colour options
                        </p>
                        <p>
                            Good tensile strength
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Limitation.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Limitations
                        </h3>
                        <p>
                            Additional step to remove support material
                        </p>
                        <p>
                            Materials used are sensitive to UV light, not recommended for outdoor use
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Application.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Applications
                        </h3>
                        <p>
                            Organ replicas, prosthetic limbs & joint replacements
                        </p>
                        <p>
                            Dental applications & other orthodontics
                        </p>
                        <p>
                            Rapid prototyping, injection modeling & functional prototyping
                        </p>
                        <p>
                            Flexible parts that simulate traditional elastomers
                        </p>
                    </div>
                </div>
                <hr>

                <!-- Mobile Button -->
                <div class="px-3 d-block d-md-none">
                    <a [routerLink]="['/contact']" class="mt-4 btn btn-block"> Get In Touch </a>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant 3D printing quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <div class="video-wrapper">
                            <img src="./assets/img/3d_printing_capabilities/polyjet/Screen Shot.webp" class="fluid-img w-100" alt="video">
                            <img src="./assets/img/3d_printing_capabilities/svg/PlayButton.svg" class="play-button" alt="Truventor">

                        </div>

                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>