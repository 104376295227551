<!-- Nav -->
<nav class="navbar navbar-expand-lg px-sm-4 navbar-dark fixed-top">
    <a class="navbar-brand" href="#"><img src="./assets/img/header-img/Logotype-Inverted.svg" alt="Truventor"
            class="logo-img"></a>

    <div class="d-flex align-items-center">
        <div class="login d-block d-md-none">
            <a [routerLink]="['/contact']" rel="noopener">LOGIN</a>
        </div>
        <!-- Toggle Button -->
        <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
    </div>

    <!-- Nav bar and Login -->


    <div class="collapse navbar-collapse justify-content-around" id="navbarNav">
        <!-- Nav bar -->
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link about-link" [routerLink]="['/about']">
                    About Us
                    <span class="sr-only">(current)</span>
                </a>
            </li>

            <!-- Add this One Before Doing Any changes *ngIf="is_customer" -->
            <ng-container>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">The Platform</a>
                    <div class="dropdown-menu capabilities" aria-labelledby="navbarDropdown">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="border-right-one">
                                        <span class="text-capitalize text-white">Cloud Manufacturing Platform</span>
                                        <p>Our Cloud Software For Design And Sourcing Teams Will Simplify The
                                            Manufacturing Workflow From RFQs To Delivery. The Predictability And
                                            Transparency Offered Will Ensure You Stay In Control.</p>
                                        <img src="./assets/img/header-img/HeaderPlatform1.svg" alt="Truventor"
                                            class="img-fluid">
                                    </div>
                                </div>

                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-6 pointer" (click)="platformPage()">
                                            <h5>Product</h5>
                                            <p>Auto File Repair, 3D Viewer, Process Recommendation Tool Part Analysis,
                                                and Instant Quotations.
                                            </p>
                                        </div>
                                        <div class="col-md-6 pointer" (click)="security()">
                                            <h5>Security</h5>
                                            <p>Platform Security and NDA, IP Protection
                                            </p>
                                        </div>

                                        <div class="col-md-6 pointer" (click)="quality()">
                                            <h5>Quality assurance</h5>
                                            <p>ISO Certification, Inspection Report, NDA, Certified Benchmarks, DFM
                                                Analysis
                                            </p>
                                        </div>

                                        <!-- <div class="col-md-6 pointer" (click)="truventorOne()">
                                            <h5>Truventor One</h5>
                                            <p>A comprehensive software suite for Buyer-Seller integration
                                            </p>
                                        </div> -->
                                        <div class="col-md-6 pointer" (click)="buyerProtection()">
                                            <h5>Buyer Protection</h5>
                                            <p>Quality Assurance, Delivery Promise, IP Protection and Payment Protection
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </li>


                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Our Capabilities</a>
                    <div class="dropdown-menu capabilities" aria-labelledby="navbarDropdown">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="border-right-one">
                                        <span class="text-capitalize text-white">Capabilities</span>
                                        <p style="margin-top: 20px;">We evaluate and deliver the best manufacturing
                                            solutions for your products.</p>
                                        <img src="./assets/img/header-img/CapbilitiesIcon.svg" alt="Truventor"
                                            class="img-fluid mt-3 mt-md-5">
                                    </div>
                                </div>

                                <div class="col-md-9">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="sheetMetal()">Sheet Metal </h5>
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/sheet-metal/bending']">Bending Services</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/sheet-metal/laser-cutting']">Laser
                                                        Processing</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/sheet-metal/welding']">Welding</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/sheet-metal/punching']">Punching </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="threedPrinting()">3D Printing </h5>
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/3d-printing/processes/fff']">FFF/Desktop FDM</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/3d-printing/processes/sls']">SLS</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a
                                                        [routerLink]="['/3d-printing/processes/sla']">Stereolithography</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/3d-printing/processes/micro-sla']">Micro-SLA
                                                    </a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/3d-printing/processes/polyjet']">Polyjet </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="cncmachining()">CNC Machining</h5>
                                            <ul class="list-group list-group-flush">
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/cnc-machining/milling']">CNC Milling</a>
                                                </li>
                                                <li class="list-group-item">
                                                    <a [routerLink]="['/cnc-machining/turning']">CNC Turning</a>
                                                </li>
                                                <!-- <li class="list-group-item">
                                                <a [routerLink]="['/cnc-machining/drilling']">CNC Drilling</a>
                                            </li> -->
                                            </ul>
                                        </div>


                                    </div>

                                    <div class="row">

                                        <div class="col-md-4">
                                            <h5 class="mt-3 mt-md-5 pointer" (click)="metalCasting()">Metal Casting
                                            </h5>
                                            <!-- <ul class="list-group list-group-flush">
                                            <li class="list-group-item">
                                                <a [routerLink]="['/']">Applications</a>
                                            </li>
                                            <li class="list-group-item">
                                                <a href="#">Why Truventor</a>
                                            </li>
                                        </ul> -->
                                        </div>


                                        <div class="col-md-4">
                                            <h5 class="mt-3 mt-md-5 pointer" (click)="injectionMolding()">Injection
                                                Molding
                                            </h5>
                                            <!-- <ul class="list-group list-group-flush">
                                            <li class="list-group-item">
                                                <a [routerLink]="['/']">Plastic</a>
                                            </li>
                                            <li class="list-group-item">
                                                <a href="#"> Rubber</a>
                                            </li>
                                            <li class="list-group-item">
                                                <a href="#"> Overmould</a>
                                            </li>

                                        </ul> -->
                                        </div>
                                        <div class="col-md-4">
                                            <h5 class="mt-3 mt-md-5 pointer" (click)="vacuumCasting()">Vacuum Casting
                                            </h5>
                                            <!-- <ul class="list-group list-group-flush">
                                            <li class="list-group-item">
                                                <a [routerLink]="['/']">Process</a>
                                            </li>
                                            <li class="list-group-item">
                                                <a href="#">Technical Specifications</a>
                                            </li>
                                        </ul> -->
                                        </div>

                                        <div class="col-md-4">
                                            <h5 class="mt-3 mt-md-5 pointer">
                                                <a [routerLink]="['/materials']">Materials</a>
                                            </h5>
                                            <!-- <ul class="list-group list-group-flush">
                                            <li class="list-group-item">
                                                <a [routerLink]="['/']">Applications</a>
                                            </li>
                                            <li class="list-group-item">
                                                <a href="#">Why Truventor</a>
                                            </li>
                                        </ul> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>


                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">Resources</a>
                    <div class="dropdown-menu capabilities" aria-labelledby="navbarDropdown">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="border-right-one">
                                        <span class="text-capitalize text-white">Resources</span>
                                        <p>Learn | Tips | Tutorials</p>
                                        <img src="./assets/img/header-img/Resources–1.svg" alt="Truventor"
                                            class="img-fluid">
                                    </div>

                                </div>

                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="blogs()">Knowledge Cockpit</h5>
                                            <p>Stay updated with the latest manufacturing trends and happenings in the
                                                industry
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="caseStudy()">Case Studies</h5>
                                            <p>Real-life examples and success stories of how our brand helps customers
                                                reach their goals.</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="articles()">Articles</h5>
                                            <p>Forget scouting through different websites. We present you with the most
                                                insightful technical articles hand-picked from the global B2B media
                                                houses.
                                            </p>
                                        </div>
                                        <!-- <div class="col-md-4">
                                            <h5 class="pointer" (click)="media()">Media</h5>
                                            <p>The world took notice! Read on to know what the media has to say about
                                                us.</p>
                                        </div> -->
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="events()">Events</h5>
                                            <p>Save the day! Here's the list of global events to be held in the coming
                                                days.
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="faqs()">FAQs</h5>
                                            <p>All Your Questions Answered At One Place</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </li>
                <li class="nav-item">
                    <a class="nav-link about-link" [routerLink]="['/manufacturing-partners']">For Partners</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link about-link st-link" [routerLink]="['/startup-program']">For Startups
                        <div class="pulse"></div>
                        <div class="marker"></div>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link about-link st-link" [routerLink]="['/truventorone']">For Enterprises
                        <div class="pulse"></div>
                        <div class="marker"></div>
                    </a>
                </li>

            </ng-container>
            <!-- <ng-container *ngIf="!is_customer">
                <li class="nav-item pointer">
                    <a class="nav-link" [routerLink]="['/calculate']">Truventor.Calculate</a>
                </li>
                <li class="nav-item pointer">
                    <a class="nav-link" [routerLink]="['/procurement']">Truventor.Materials</a>
                </li>

                <li class="nav-item pointer">
                    <a class="nav-link" [routerLink]="['/truventorone']">Truventor.Control</a>
                </li>
                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Resources</a>
                    <div class="dropdown-menu capabilities" aria-labelledby="navbarDropdown">
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="border-right-one">
                                        <span class="text-capitalize text-white">Resources</span>
                                        <p>Learn | Tips | Tutorials</p>
                                        <img src="./assets/img/header-img/Resources–1.svg" alt="Truventor" class="img-fluid">
                                    </div>

                                </div>

                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="blogs()">Knowledge Cockpit</h5>
                                            <p>Stay updated with the latest manufacturing trends and happenings in the industry
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="caseStudy()">Case Studies</h5>
                                            <p>Real-life examples and success stories of how our brand helps customers reach their goals.</p>
                                        </div>
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="articles()">Articles</h5>
                                            <p>Forget scouting through different websites. We present you with the most insightful technical articles hand-picked from the global B2B media houses.
                                            </p>
                                        </div>

                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="events()">Events</h5>
                                            <p>Save the day! Here's the list of global events to be held in the coming days.
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <h5 class="pointer" (click)="faqs()">FAQs</h5>
                                            <p>All Your Questions Answered At One Place</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                </li>
                
            </ng-container> -->

            <!-- <li class="nav-item pointer">
                    <a class="nav-link" [routerLink]="['/finance']">Truventor Finance</a>
                </li> -->
            <!-- <div class="col-md-4">
                    <h5 class="pointer" (click)="media()">Media</h5>
                    <p>The world took notice! Read on to know what the media has to say about
                        us.</p>
                </div> -->

            <!-- <li class="nav-item">
                <a class="nav-link about-link st-link" [routerLink]="['/truventorone']">Truventor.One
                    <div class="pulse"></div>
                    <div class="marker"></div>
                </a>
            </li> -->
        </ul>
    </div>

    <!-- Login -->
    <!-- <div class="login text-right d-none d-md-block" *ngIf="is_customer">
        Customer: <a [routerLink]="['/contact']" rel="noopener">LOGIN</a>
    </div>
    <div class="login text-right d-none d-md-block" *ngIf="!is_customer">
        Partner: <a [routerLink]="['/contact']" rel="noopener">LOGIN</a>
    </div> -->

</nav>