import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AdminService } from '../shared/admin.service';

@Component({
  selector: 'app-finance',
  templateUrl: './finance.component.html',
  styleUrls: ['./finance.component.scss'],
  changeDetection : ChangeDetectionStrategy.OnPush
})
export class FinanceComponent implements OnInit {

  constructor(
    private adminservice: AdminService
  ) { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.adminservice.check_customer.next('customer')
  }

}
