import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AdminService } from '../shared/admin.service';

@Component({
  selector: 'app-manufacturing',
  templateUrl: './manufacturing.component.html',
  styleUrls: ['./manufacturing.component.scss']
})
export class ManufacturingComponent implements OnInit {

  constructor(private router: Router,
    private adminservice: AdminService
  ) { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.adminservice.check_customer.next('customer')
  }

  sheetMetal() {
    this.router.navigate(['/sheet-metal-fabrication']);
  }

  threedPrinting() {
    this.router.navigate(['/3d-printing']);
  }

  vacuumCasting() {
    this.router.navigate(['/vacuum-casting']);
  }

  injectionMolding() {
    this.router.navigate(['/plastic-injection-moulding']);
  }

  metalCasting() {
    this.router.navigate(['/metal-casting']);
  }

  cncmachining() {
    this.router.navigate(['/cnc-machining']);
  }


  clientsOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["<img src='assets/img/about/icon/arrow-left.png' alt='Truventor.ai'>", "<img src='assets/img/about/icon/arrow-right.png' alt='Truventor.ai'>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }
}
