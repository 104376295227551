<section class="main-session">
    <img src="assets/img/supplier/supplier-banner.webp" class="img-banner" alt="banner">

    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <div class="heading">
                    <h1>
                        Manufacturing <span>OS</span> <br> At Your Fingertips

                    </h1>
                    <p>
                        For an easy, efficient and profitable growth of your business
                    </p>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener"
                    class="btn btn-primary w-100"> Join Our
                    Partner Network </a>
            </div>
        </div>

        <div class="row statistics d-none d-md-flex">
            <div class="col">
                <div class="small-info ">
                    <h6> 20+ </h6>
                    <hr>
                    <p>Countries Served</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info ">
                    <h6> 250+ </h6>
                    <hr>
                    <p>Customer</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info ">
                    <h6> 700+ </h6>
                    <hr>
                    <p>Partners</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info ">
                    <h6> 300k+ </h6>
                    <hr>
                    <p>Parts</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info ">
                    <h6> 10k+ </h6>
                    <hr>
                    <p>Order Fulfilled</p>
                </div>
            </div>

        </div>

        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Why become partner -->
<section class="why-become-partner global-space-between">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="mb-5 text-center font-weight-bold">Why partner with Truventor? </h1>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-2 row-cols-md-5">

            <div class="col">
                <div class="card border-0 " data-aos="fade-up">
                    <img src="./assets/img/supplier/Obtain new.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Procure new<br>orders</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 " data-aos="fade-up">
                    <img src="./assets/img/supplier/Access to global supplier network.svg" class="card-img-top"
                        alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Access to global<br>customer base</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 " data-aos="fade-up">
                    <img src="./assets/img/supplier/Obtain financial support.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Receive financial
                            <br>support
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 " data-aos="fade-up">
                    <img src="./assets/img/supplier/Achieve Cost Savings.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Save on
                            <br>costs
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 " data-aos="fade-up">
                    <img src="./assets/img/supplier/Build diverse capabilities.svg" class="card-img-top"
                        alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Build diverse<br>capabilities</p>
                    </div>
                </div>
            </div>


        </div>

        <!-- Instant Quote Button -->
        <div class="d-none d-md-block">
            <div class="d-flex my-5 justify-content-center">
                <a [routerLink]="['/contact']" rel="noopener" class="btn"> Join Our
                    Partner Network </a>
            </div>
        </div>

    </div>
</section>

<!-- Grow your business -->
<section class="grow-your-business global-space-between">
    <div class="container">

        <!-- Desktop Slider -->
        <div class="d-none d-md-block">

            <div class="row align-items-center">
                <div class="col-6 pl-0">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                            aria-labelledby="v-pills-home-tab">
                            <img src="./assets/img/supplier/Group 16934.jpg" class="img-fluid" width="100%"
                                alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                            aria-labelledby="v-pills-profile-tab">
                            <img src="./assets/img/supplier/Group 16935.jpg" class="img-fluid" width="100%"
                                alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel"
                            aria-labelledby="v-pills-messages-tab">
                            <img src="./assets/img/supplier/GetOnWork.png" class="img-fluid" width="100%"
                                alt="Truventor">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <h1 class="text-center text-md-left pl-5">
                        Grow your business in 3 simple steps.
                    </h1>

                    <div class="content">
                        <div class="nav flex-column nav-pills pl-5" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home"
                                role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <span class="plus">
                                    Get started
                                </span>
                                <p>
                                    Complete your shop profile, list your machines, and note your job preferences. Our
                                    partner team will then review this information and determine if your capabilities
                                    are a match for our customer network.
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile"
                                role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                <span class="plus">
                                    Quality vetting and audit
                                </span>
                                <p>
                                    All our partners are required to complete a paid test part before being granted
                                    access to the job board. This is your opportunity to show us your process and
                                    capabilities.
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages"
                                role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Get on work
                                </span>
                                <p>
                                    Based on the rating given by our internal team, you will be approved and granted
                                    access to our live job board, where you can start taking orders. Our platform will
                                    only show you those orders, which are based on your ratings and capabilities.
                                </p>
                            </a>
                        </div>
                    </div>

                    <div class="d-none d-md-block pl-5">
                        <div class="pt-5">
                            <a [routerLink]="['/contact']" rel="noopener"
                                class="btn">
                                Become A Supplier
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Mobile Slider -->
        <div class="row d-block d-md-none">
            <div class="col">

                <!-- Title -->
                <h1 class="text-center text-md-left">
                    Grow your Business in 3 simple steps.
                </h1>

                <!-- One -->
                <div>
                    <img src="./assets/img/supplier/Group 16934-mobile.jpg" class="img-fluid py-4" width="100%"
                        alt="Truventor">
                    <h5 class="text-left">
                        - Get Started
                    </h5>
                    <hr>
                    <p>
                        Complete your Shop Profile, list your machines, and note your job preferences. Our Partner Team
                        will review this information to determine if your capabilities are a match for our customer
                        network.
                    </p>
                </div>
                <!-- Two -->
                <div>
                    <img src="./assets/img/supplier/Group 16935-mobile.jpg" class="img-fluid py-4" width="100%"
                        alt="Truventor">
                    <h5 class="text-left">
                        - Quality Vetting & Audit
                    </h5>
                    <hr>
                    <p>
                        All partners are required to complete a paid test part before being granted access to the job
                        board. This is your opportunity to shows us your process and capabilities
                    </p>
                </div>
                <!-- Three -->
                <div>
                    <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%"
                        alt="Truventor">
                    <h5 class="text-left">
                        - Get On Work
                    </h5>
                    <hr>
                    <p>
                        Based on rating given by our internal team, you will be approved and granted access to our live
                        job board where you can start taking orders. Our platform will show only orders, based on your
                        capabilities and ratings.
                    </p>
                </div>

            </div>
        </div>

    </div>
</section>

<!-- Seeking For Manufacturing Capabilities -->
<section class="capabilities-inner global-space-between">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="mb-5 text-center font-weight-bold">Looking for these manufacturing capabilities! </h1>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-2 row-cols-md-6 p-4 p-md-0">

            <div class="col pointer" (click)="sheetMetal()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Sheet Metal.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Sheet<br>Metal</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="threedPrinting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/3D Printing.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">3D<br>Printing</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="metalCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Metal Casting.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Metal<br>Casting</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="cncmachining()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/CNC Machining.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">CNC<br>Machining</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="injectionMolding()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Injection Molding.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Injection<br>Molding</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="vacuumCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Vacuum Casting.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Vacuum<br>Casting</p>
                    </div>
                </div>
            </div>


        </div>

    </div>
</section>


<section class="container global-space-between">
    <div class="row">
        <div class="col-md-12">
            <h1 class="text-center mb-5">It starts here!</h1>
        </div>
    </div>


    <div class="row know-more-card">
        <div class="col-md-4">
            <!-- Discover -->
            <div class="card border-0 h-100">
                <img src="./assets/img/supplier/Calculate@2x.png" class="img-fluid img-border mx-auto" loading="lazy"
                    alt="Truventor">
                <div class="card-body pt-2">
                    <h5 class="card-title font-weight-bold">Calculate</h5>
                    <hr class="primary-bg">
                    <p class="card-text"> 3D CAD viewer platform that enables a collaborative work environment and
                        provides transparency

                    </p>
                </div>
                <div class="card-footer">
                    <a [routerLink]="['/calculate']" class="btn">Know more</a>
                </div>
            </div>
        </div>
        <div class="col-md-4">

            <!-- Manufacture -->
            <div class="card border-0 h-100">
                <img src="./assets/img/supplier/Procure@2x.png" class="img-fluid img-border w-100" loading="lazy"
                    alt="Truventor">
                <div class="card-body pt-2">
                    <h5 class="card-title font-weight-bold">Procure</h5>
                    <hr class="primary-bg">
                    <p class="card-text">Major saving on raw materail purchases through Procure</p>
                </div>
                <div class="card-footer">
                    <a [routerLink]="['/procurement']" class="btn">Know more</a>
                </div>
            </div>

        </div>


        <div class="col-md-4">
            <!-- Manage -->
            <div class="card border-0 h-100">
                <img src="./assets/img/supplier/Control@2x.png" class="img-fluid img-border w-100" loading="lazy"
                    alt="Truventor">
                <div class="card-body pt-2">
                    <h5 class="card-title font-weight-bold">Control</h5>
                    <hr class="primary-bg">
                    <p class="card-text">Full control on asset utilisation, energy saving, machine / manpower
                        utilisation and other shop floor activities</p>
                </div>
                <div class="card-footer">
                    <a class="btn">Coming soon</a>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- How it works -->
<section class="how-it-works global-space-between">
    <div class="container-fluid">
        <!-- how it works desktop -->
        <div class="d-none d-sm-none d-md-block">
            <div class="row justify-content-start">
                <div class="col-md-6 pl-0">
                    <div class="tab-content text-right">
                        <div class="tab-pane active" id="home" role="tabpanel">
                            <img src="assets/img/supplier/Expert.png" class="img-fluid" alt="upload image">
                        </div>
                        <div class="tab-pane" id="messages" role="tabpanel">
                            <img src="assets/img/supplier/Quote.png" class="img-fluid" alt="process selection image">
                        </div>
                        <div class="tab-pane" id="profile" role="tabpanel">
                            <img src="assets/img/supplier/Track.png" class="img-fluid" alt="instant quate image">
                        </div>
                        <div class="tab-pane" id="settings" role="tabpanel">
                            <img src="assets/img/supplier/Process.png" class="img-fluid" alt="live tracking image">
                        </div>
                    </div>
                </div>


                <div class="col-md-5">
                    <div class="wrapper">
                        <h1 class="font-weight-bold">Features</h1>
                        <p> Highly-collaborative manufacturing that is easier, faster, cheaper and reliable. </p>
                        <ul class="nav nav-tabs row" id="myTab" role="tablist">
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link active" data-toggle="tab" href="#home" role="tab"
                                    aria-controls="home">
                                    <hr>
                                    <h6 class="font-weight-bold">Expert feedback</h6>
                                    <p> Get real-time support from our team of engineering experts.</p>
                                </a>
                            </li>
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#messages" role="tab"
                                    aria-controls="messages">
                                    <hr>
                                    <h6 class="font-weight-bold">RFQ Management</h6>
                                    <p>You will spend less time answering RFQs and chasing customers for payments.</p>
                                </a>
                            </li>
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#profile" role="tab"
                                    aria-controls="profile">
                                    <hr>
                                    <h6 class="font-weight-bold">View and track</h6>
                                    <p>Helps you track all your order updates, making it a hassle-free manufacturing
                                        process.
                                    </p>
                                </a>
                            </li>
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#settings" role="tab"
                                    aria-controls="settings">
                                    <hr>
                                    <h6 class="font-weight-bold">Process efficiency</h6>
                                    <p>We assists partners with well defined and efficient processes for them to focus
                                        on the core business.</p>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

        </div>

        <!-- done how it works desktop -->
        <!-- start how it works mobile -->

        <div class="d-block d-sm-block d-md-none">
            <div class="row">

                <!-- Text -->
                <div class="col col-sm-12 col-md-6 d-flex align-items-center flex-row-reverse">

                    <div class="col-sm-12 col-md-8">
                        <h3 class="font-weight-bold">Features</h3>
                        <p> Highly-collaborative manufacturing that is easier, faster, cheaper and reliable. </p>
                        <!-- 2x2 -->
                        <div class="text-2x2">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 hover-on">
                                    <hr>
                                    <h6 class="font-weight-bold">Expert Feedback</h6>
                                    <p>Get real-time support from our team of engineering experts.</p>
                                    <!-- Mobile Only Image -->
                                    <div class="col col-sm-12 col-md-6 d-block d-md-none"> <img
                                            src="./assets/img/supplier/Expert.png" class="img-fluid" width="100%"
                                            alt="Truventor">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 hover-on">
                                    <hr>
                                    <h6 class="font-weight-bold">RFQ Management</h6>
                                    <p>Through our platform, you will receive only curated orders. This means you will
                                        spend less time answering RFQs and chasing customers for payments.</p>
                                    <!-- Mobile Only Image -->
                                    <div class="col col-sm-12 col-md-6 d-block d-md-none"> <img
                                            src="./assets/img/supplier/Quote.png" class="img-fluid" width="100%"
                                            alt="Truventor">
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-6 hover-on">
                                    <hr>
                                    <h6 class="font-weight-bold">View And Track</h6>
                                    <p>Helps you track all your order updates, making it a hassle-free manufacturing
                                        process.
                                    </p>
                                    <!-- Mobile Only Image -->
                                    <div class="col col-sm-12 col-md-6 d-block d-md-none"> <img
                                            src="./assets/img/supplier/Track.png" class="img-fluid" width="100%"
                                            alt="Truventor">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 hover-on">
                                    <hr>
                                    <h6 class="font-weight-bold">Process effeciency </h6>
                                    <p>We assists partners with well defined and efficient processes for them to focus
                                        on the core business.</p>
                                </div>
                                <!-- Mobile Only Image -->
                                <div class="col col-sm-12 col-md-6 d-block d-md-none"> <img
                                        src="./assets/img/supplier/Process.png" class="img-fluid" width="100%"
                                        alt="Truventor">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>



<section class="global-space-between bottom-end-padding">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="content">
                    <h1>Join our partner <br> network, today! </h1>
                    <p>Become our manufacturing partner and start using Truventor as your business operting system to
                        fuel your growth</p>
                    <div class="d-flex align-items-center">
                        <a [routerLink]="['/contact']" rel="noopener"
                            class="btn px-5">
                            Become A Supplier
                        </a>
                        <p class="m-0 ml-3" style="color: #000;
                        font-size: 0.95rem;
                        font-weight: 500;">Watch a quick video <i class="fa fa-play-circle"></i> </p>
                    </div>

                </div>
            </div>
            <div class="col-md-6">
                <button type="button" class="video-wrapper video-btn" data-toggle="modal"
                    data-src="https://www.youtube.com/embed/Fwyu9UVNlkk" data-target="#myModal">
                    <img src="./assets/img/supplier/Suppler  video asset.png" class="img-fluid" alt="Truventor">
                </button>
            </div>
        </div>
    </div>
</section>




<!-- Schedule a meeting -->
<section class="amazing-quote global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="quote-background-image">
                    <h1 class="text-center">
                        Schedule <br> a meeting <br> or book a demo
                    </h1>
                    <div class="action-button d-flex justify-content-center mt-5">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener"
                            class="btn btn-light"> Book A Demo </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- FAQs -->
<section class="faqs global-space-between bottom-end-padding">
    <div class="container">
        <div class="row mb-5">
            <div class="col">
                <h1 class="text-center">FAQs</h1>
            </div>
        </div>
        <!-- Collapse -->
        <div class="container">
            <div class="accordion" id="accordionExample">
                <div class="card">
                    <div class="card-head" id="headingOne">
                        <h2 class="mb-0 card-header" data-toggle="collapse" data-target="#collapseOne"
                            aria-expanded="true" aria-controls="collapseOne">
                            How much business can one generate through Truventor's cloud manufacturing network?
                            <!-- How much business can I generate through the Truventor’s cloud<br>manufacturing network? -->
                        </h2>
                    </div>

                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            It depends on factors including the manufacturing process, machine, idle capacity, costing,
                            location and the overall rating that we maintain. </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-head" id="headingTwo">
                        <h2 class="mb-0 card-header collapsed" data-toggle="collapse" data-target="#collapseTwo"
                            aria-expanded="false" aria-controls="collapseTwo">
                            Are there any costs I may incur? </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div class="card-body">
                            Yes, the only cost you may have to incur is for the production of sample parts at the time
                            of onboarding. If we find the quality, timelines and ratings favourable, we refund the cost
                            of the samples.
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-head" id="headingThree">
                        <h2 class="mb-0 card-header  collapsed" data-toggle="collapse" data-target="#collapseThree"
                            aria-expanded="false" aria-controls="collapseThree">
                            Can you explain the payment terms?
                        </h2>
                    </div>
                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            We have a very transparent, structured and 'no-questions-asked' payment terms of NET30 days.
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- Hightlight Contect Us -->
<section class="">
    <div class="container">
        <div class="row">
            <!-- Image -->
            <div class="col-md-6 p-4 d-flex justify-content-center justify-content-md-left">
                <img src="./assets/img/supplier/man_on_call_cta@2x.png" class="img-fluid" alt="contact image">
            </div>
            <!-- Text -->
            <div class="col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                <div class="col-sm-12 col-md-12 pl-0 pl-md-5">
                    <h1 class="text-center text-md-left talk-to">
                        Talk to our team of manufacturing experts to get started.
                    </h1>
                    <div class="action-button mt-4 d-flex justify-content-center justify-content-md-start">
                        <a [routerLink]="['/contact']" class="btn mb-5 mb-md-0"> Contact Us </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<!-- But wait there’s more -->
<section class="global-space-between bottom-end-padding">
    <div class="container">
        <!-- Title -->
        <div class="row">
            <div class="col">
                <h1 class="text-center pb-5">Wait! There is more!</h1>
            </div>
        </div>

        <!-- Row -->
        <div class="row">
            <div class="col">

                <!-- Truventor Procure -->
                <div class="card border-0">
                    <div class="row no-gutters mb-5">
                        <div class="col-md-4 d-flex justify-content-center">
                            <img src="./assets/img/supplier/Group 16947.png" width="100%" class="img-fluid bg-warning"
                                alt="Truventor">
                        </div>
                        <div class="col-md-8 pl-sm-0 pl-md-3">
                            <div class="card-body action-button">
                                <h5 class="card-title font-weight-bold">Truventor materials</h5>
                                <hr class="primary-bg w-75 ml-0 mr-auto">
                                <p class="card-text">From Nylon to Titanium, raw material purchase made simple and fast.
                                </p>
                                <div class="col-sm-12 col-md-3 p-0">
                                    <a [routerLink]="['/procurement']" class="btn btn-disabled"> Coming Soon </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Truventor Finance -->
                <div class="card border-0 mt-5">
                    <div class="row no-gutters mb-5">
                        <div class="col-md-4 d-flex justify-content-center">
                            <img src="./assets/img/supplier/Group 16948.png" width="100%" class="bg-warning"
                                alt="Truventor">
                        </div>
                        <div class="col-md-8 pl-sm-0 pl-md-3">
                            <div class="card-body action-button">
                                <h5 class="card-title font-weight-bold">Truventor money</h5>
                                <hr class="primary-bg w-75 ml-0 mr-auto">
                                <p class="card-text">Avail of flexible collateral-free loans for purchasing raw
                                    materials.
                                </p>
                                <div class="col-sm-12 col-md-3 p-0">
                                    <a [routerLink]="['/finance']" class="btn btn-disabled"> Coming Soon
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">


            <div class="modal-body p-0">


                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always"
                        allow="autoplay"></iframe>
                </div>


            </div>

        </div>
    </div>
</div>