<!-- Main -->
<section class="main-session">
    <img src="assets/img/cnc_metal_capabilities/milling/Mask Group 231.jpg" class="img-banner" loading="lazy" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        CNC <br> Milling
                    </h1>
                    <p class="mt-4">
                        Cost-effective CNC milling solution with <br> accuracy and precision.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- CNC Milling -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    CNC milling
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    We offer CNC milling with 3-axis, and 4-axis machines for plastics and metal. With the standard raw material block readily available with us, our average turnaround time is less than 4 days. Millled parts may have visible tool marks that are later post
                    processed depending on the required tolerance and finishes.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div>
                    <div class="row align-items-center">
                        <!-- Image -->
                        <div class="col-4">
                            <img src="assets/img/cnc_machining_service/tool-steel.png" class="img-fluid" loading="lazy" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-7">
                            <h5>
                                Tool Steel, stainless steel
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row mt-3 align-items-center">
                        <!-- Image -->
                        <div class="col-4">
                            <img src="assets/img/cnc_machining_service/titanium.png" class="img-fluid" loading="lazy" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-7">
                            <h5>
                                Aluminum, copper, titanium
                            </h5>
                        </div>
                    </div>

                </div>

                <!-- Details -->
                <div class="content mt-3">
                    <table class="table">
                        <tr>
                            <th>Max part size</th>
                            <td>800mm x 400mm x 400mm</td>
                        </tr>
                        <tr>
                            <th>Accuracy </th>
                            <td>1-500 units</td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>7-15 days</td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Types of Milling Operations -->
<section class="side-tab-view-toggle global-space-between">
    <div class="container">

        <!-- Desktop View -->
        <div class="d-none d-md-block">

            <div class="row">
                <div class="col">
                    <h1 class="text-center mb-5">
                        Types of milling operations
                    </h1>
                </div>
            </div>

            <div class="row align-items-center mt-5">
                <div class="col-6">
                    <div class="tab-content" id="main_id">
                        <div class="tab-pane show active" id="slider_1" role="tabpanel" aria-labelledby="slider_1-tab">
                            <img src="./assets/img/cnc_metal_capabilities/milling/Group 20137.jpg" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_2" role="tabpanel" aria-labelledby="slider_2-tab">
                            <img src="./assets/img/cnc_metal_capabilities/milling/Group 20142.jpg" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_3" role="tabpanel" aria-labelledby="slider_3-tab">
                            <img src="./assets/img/cnc_metal_capabilities/milling/Group 20139.jpg" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_4" role="tabpanel" aria-labelledby="slider_4-tab">
                            <img src="./assets/img/cnc_metal_capabilities/milling/Group 20144.jpg" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active" id="slider_1-tab" data-toggle="pill" href="#slider_1" role="tab" aria-controls="slider_1" aria-selected="true">
                            <span class="toggle">
                                Plain milling
                            </span>
                            <hr>
                            <p>
                                Also known as surface milling, this milling process uses a cutting tool to remove material along the surface of the workpiece. In plain milling, the rotation axis is parallel to the workpiece.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_2-tab" data-toggle="pill" href="#slider_2" role="tab" aria-controls="slider_2" aria-selected="false">
                            <span class="toggle">
                                Face milling
                            </span>
                            <hr>
                            <p>
                                Face milling uses a rotational axis perpendicular to the material’s surface. The cutting or grinding tool faces down against the workpiece surface to remove material.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_3-tab" data-toggle="pill" href="#slider_3" role="tab" aria-controls="slider_3" aria-selected="false">
                            <span class="toggle">
                                Angular milling
                            </span>
                            <hr>
                            <p>
                                This milling method positions the cutting tool’s rotary axis at an angle along the workpiece surface to produce angular cuts as specified by the design, such as grooves or dovetails.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_4-tab" data-toggle="pill" href="#slider_4" role="tab" aria-controls="slider_4" aria-selected="false">
                            <span class="toggle">
                                Form milling
                            </span>
                            <hr>
                            <p>
                                Form milling makes non-flat cuts, such as contours, curves, and radii. Each type of curve will require a specific cutting tool to create an accurate form cut.
                            </p>
                        </a>

                    </div>

                </div>
            </div>

        </div>

        <!-- Mobile View -->
        <div class="d-block d-md-none">
            <div class="row">
                <div class="col">

                    <!-- Title -->
                    <h1 class="text-center text-md-left">
                        Types of milling operations
                    </h1>

                    <!-- One -->
                    <div>
                        <img src="./assets/img/cnc_metal_capabilities/milling/Group 20137.jpg" class="img-fluid py-4" width="100%" loading="lazy" alt="Truventor">
                        <h5 class="text-left">
                            Plain milling
                        </h5>
                        <hr>
                        <p>
                            Also known as surface milling, this milling process uses a cutting tool to remove material along the surface of the workpiece. In plain milling, the rotation axis is parallel to the workpiece.
                        </p>
                    </div>
                    <!-- Two -->
                    <div>
                        <img src="./assets/img/cnc_metal_capabilities/milling/Group 20142.jpg" class="img-fluid py-4" width="100%" loading="lazy" alt="Truventor">
                        <h5 class="text-left">
                            Face milling
                        </h5>
                        <hr>
                        <p>
                            Face milling uses a rotational axis perpendicular to the material’s surface. The cutting or grinding tool faces down against the workpiece surface to remove material.
                        </p>
                    </div>
                    <!-- Three -->
                    <div>
                        <img src="./assets/img/cnc_metal_capabilities/milling/Group 20139.jpg" class="img-fluid py-4" width="100%" loading="lazy" alt="Truventor">
                        <h5 class="text-left">
                            Angular milling
                        </h5>
                        <hr>
                        <p>
                            This milling method positions the cutting tool’s rotary axis at an angle along the workpiece surface to produce angular cuts as specified by the design, such as grooves or dovetails.
                        </p>
                    </div>
                    <!-- Four -->
                    <div>
                        <img src="./assets/img/cnc_metal_capabilities/milling/Group 20144.jpg" class="img-fluid py-4" width="100%" loading="lazy" alt="Truventor">
                        <h5 class="text-left">
                            Form milling
                        </h5>
                        <hr>
                        <p>
                            Form milling makes non-flat cuts, such as contours, curves, and radii. Each type of curve will require a specific cutting tool to create an accurate form cut.
                        </p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>

<!-- Attributes -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1 class="mb-5 mb-md-4">
                        Attributes
                    </h1>
                    <p class="mb-5 mb-md-4">
                        Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                    </p>
                    <!-- Desktop Button -->
                    <div class="d-none d-md-block">
                        <a [routerLink]="['/contact']" class="mt-4 btn px-5"> Get In Touch </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/cnc_metal_capabilities/svg/Overview.svg" class="img-fluid w-100 mt-2" loading="lazy" alt="Truventor">
                    </div>
                    <div class="col-11">
                        <h3>
                            Overview
                        </h3>
                        <p>
                            CAD model design: Creating a 2D or 3D design
                        </p>
                        <p>
                            Conversion to a CNC program: Convert the CAD file to CNC programming using CAM software.
                        </p>
                        <p>
                            Machine setup: Operator prepares the machine and workpiece
                        </p>
                        <p>
                            Operation execution: Initiative the machining operation.
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/cnc_metal_capabilities/svg/Application.svg" class="img-fluid w-100 mt-2" loading="lazy" alt="Truventor">
                    </div>
                    <div class="col-11">
                        <h3>
                            Applications
                        </h3>
                        <p>
                            Aerospace and aircraft
                        </p>
                        <p>
                            Automotive
                        </p>
                        <p>
                            Electronics and electrical
                        </p>
                        <p>
                            Industrial and OEM
                        </p>
                        <p>
                            Medical
                        </p>
                        <p>
                            Technology and security
                        </p>
                    </div>
                </div>
                <hr>

                <!-- Mobile Button -->
                <div class="px-3 d-block d-md-none">
                    <a [routerLink]="['/contact']" class="mt-4 btn btn-block"> Get In Touch </a>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant CNC milling quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <img src="./assets/img/cnc_metal_capabilities/milling/Group 13311.jpg" class="fluid-img w-100" loading="lazy" alt="Truventor">
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>