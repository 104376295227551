import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../shared/admin.service';
declare var $: any;
@Component({
  selector: 'app-supplier-landing',
  templateUrl: './supplier-landing.component.html',
  styleUrls: ['./supplier-landing.component.scss']
})
export class SupplierLandingComponent implements OnInit {

  constructor(
    private router: Router,
    private adminService: AdminService) {
  }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.adminService.check_customer.next('partner');
    $(document).ready(function () {

      // Gets the video src from the data-src on each button

      var $videoSrc;
      $('.video-btn').click(function () {
        $videoSrc = $(this).data("src");
      });

      // when the modal is opened autoplay it  
      $('#myModal').on('shown.bs.modal', function (e) {
        // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
        $("#video").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
      })


      // stop playing the youtube video when I close the modal
      $('#myModal').on('hide.bs.modal', function (e) {
        // a poor man's stop video
        $("#video").attr('src', $videoSrc);
      })

      // document ready  
    });
  }

  sheetMetal() {
    this.router.navigate(['/sheet-metal-fabrication']);
  }

  threedPrinting() {
    this.router.navigate(['/3d-printing']);
  }

  vacuumCasting() {
    this.router.navigate(['/vacuum-casting']);
  }

  injectionMolding() {
    this.router.navigate(['/plastic-injection-moulding']);
  }

  metalCasting() {
    this.router.navigate(['/metal-casting']);
  }

  cncmachining() {
    this.router.navigate(['/cnc-machining']);
  }

}
