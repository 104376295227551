<!-- Main -->
<section class="main-session">
    <img src="assets/img/banner-images/CNC Machining@2x.webp" class="img-banner" loading="lazy" alt="Truventor">

    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1> CNC Machining Services </h1>
                    <p class="mt-4">
                        From 3-axis to multi-axis and prototype to batch production, all in one place
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100 mt-5"> Get In Touch </a>
            </div>
        </div>

        <!-- Statistics -->
        <div class="row statistics d-none d-md-flex">
            <!-- CNC Machines -->
            <div class="col">
                <div class="small-info">
                    <h6> 900+ </h6>
                    <hr>
                    <p>CNC Machines</p>
                </div>
            </div>
            <!-- Materials -->
            <div class="col">
                <div class="small-info">
                    <h6> 14 </h6>
                    <hr>
                    <p>Materials</p>
                </div>
            </div>
            <!-- Customers -->
            <div class="col">
                <div class="small-info">
                    <h6> 75+ </h6>
                    <hr>
                    <p>Customers</p>
                </div>
            </div>
            <!-- Tolerance -->
            <div class="col">
                <div class="small-info">
                    <h6> 5 Microns </h6>
                    <hr>
                    <p>Tolerance</p>
                </div>
            </div>

        </div>
        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Our Machining Services -->
<section class="our-cnc-machine-service global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 order-12 order-md-1 col-md-5">
                <h1 class="text-left">
                    Our machining services
                </h1>
                <p>
                    With over 300 manufacturing partners in the CNC machining domain, Truventor currently offers five different CNC machining processes which can be used to manufacture 1 – 1000s of part quantities in as fast as 48 hours.
                    <br><br> Our experienced application engineers work closely with the manufacturing partners to ensure the quality of the part and fast turnaround.
                </p>
            </div>
            <div class="col-12 order-1 order-md-12 col-md-6 d-flex justify-content-center mb-5">
                <img src="assets/img/cnc_machining_service/svg/CNC Machining.svg" class="img-fluid machining-img" alt="CNC Machining" loading="lazy">
            </div>
        </div>
    </div>
</section>

<!-- Capabilities -->
<section class="cnc-capabilities global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <h1>Capabilities</h1>
                    <div class="row row-cols-1 row-cols-md-3">
                        <!-- CNC machining Process -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0" data-aos="fade-up">
                                <img src="./assets/img/cnc_machining_service/CNC Turning.jpg" class="card-img" alt="Laser Cutting" loading="lazy">
                                <div class="card-img-overlay d-flex align-items-end">
                                    <div class="text-holder" style="position: absolute;top: 0;height: 100%; background-color: rgba(16, 24, 32, 1);">
                                        <h5 class="card-title">
                                            CNC machining process
                                        </h5>
                                        <p class="card-text">
                                            With a large number of manufacturing partners from the CNC machining domain located in different geographies and equipped with diverse capabilities, we can produce complex-low-volume parts to large quantities of machined components.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- CNC Milling -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0" data-aos="fade-up">
                                <img src="./assets/img/cnc_machining_service/CNC Milling.jpg" class="card-img" alt="Bending" loading="lazy">
                                <div class="card-img-overlay d-flex align-items-end">
                                    <div class="text-holder hover-holder">
                                        <h5 class="card-title">CNC milling</h5>
                                        <p class="card-text">
                                            In CNC milling, the material remains idle while the cutting tools rotate.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- CNC Turning -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0" data-aos="fade-up">
                                <img src="./assets/img/cnc_machining_service/CNC Turning.jpg" class="card-img" alt="Punching" loading="lazy">
                                <div class="card-img-overlay d-flex align-items-end">
                                    <div class="text-holder hover-holder">
                                        <h5 class="card-title">CNC turning</h5>
                                        <p class="card-text">
                                            In CNC turning, the tool remains in one place while the material is rotated.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Industrial-grade CNC Materials -->
<section class="cnc-industrial-grade main-faq global-space-between" id="cnc-machining-material">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h1 class="py-3"> Industrial-grade CNC materials </h1>
                        </div>
                        <div class="col-12 col-md-6 d-flex align-items-center">
                            In engineering, the right materials matter. We can make sure your final product looks and performs exactly as intended.
                        </div>
                    </div>
                    <!-- Table -->
                    <div class="row my-4">
                        <div class="col">

                            <!-- Collapse One -->
                            <div class="card border-0 mt-2">
                                <div class="card-head">
                                    <h2 class="mb-0 px-0 card-header border-dark bg-transparent" data-toggle="collapse" data-target="#industrial-grade-cnc-materials-collapse-one" aria-expanded="true" aria-controls="faq-supplier-eleven">
                                        Top metals
                                    </h2>
                                </div>

                                <div id="industrial-grade-cnc-materials-collapse-one" class="collapse show" aria-labelledby="industrial-grade-cnc-materials-collapse-one">
                                    <div class="card-body p-3">
                                        <div *ngFor="let metals of cncMaterialsData">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-md-2 p-0">
                                                    <img src="{{metals.imgUrl}}" class="img-fluid list-image" loading="lazy" alt="Truventor">
                                                </div>
                                                <div class="col-12 col-md-10 pl-3 pl-md-0">
                                                    <div class="row align-items-center">
                                                        <div class="col-12 col-md-3"> <b class="d-block mt-2 mt-md-0">
                                                                {{metals?.name}} </b> </div>
                                                        <div class="col-12 col-md-4">
                                                            <p class="m-0"> {{metals?.description}} </p>
                                                        </div>
                                                        <div class="col-12 col-md-5 text-right">
                                                            <a href="{{metals.url}}" class="btn btn-primary py-2 px-4 mt-2 mt-md-0 mr-3">
                                                                Know More
                                                            </a>
                                                            <a [routerLink]="['/contact']"  rel="noopener" class="btn float-left float-md-right py-2 px-4 mt-2 mt-md-0">
                                                                Get In Touch
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col p-0">
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- Collapse Two -->
                            <div class="card border-0 mt-2">
                                <div class="card-head">
                                    <h2 class="mb-0 px-0 card-header border-dark bg-transparent" data-toggle="collapse" data-target="#industrial-grade-cnc-materials-collapse-plastics" aria-expanded="true" aria-controls="faq-supplier-eleven">
                                        Top plastics
                                    </h2>
                                </div>

                                <div id="industrial-grade-cnc-materials-collapse-plastics" class="collapse show" aria-labelledby="industrial-grade-cnc-materials-collapse-plastics">
                                    <div class="card-body p-3">

                                        <div *ngFor="let plastics of cncPlasticsData">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-md-2 p-0">
                                                    <img src="{{plastics.imgUrl}}" class="img-fluid list-image" loading="lazy" alt="Truventor">
                                                </div>
                                                <div class="col-12 col-md-10 pl-3 pl-md-0">
                                                    <div class="row align-items-center">
                                                        <div class="col-12 col-md-3"> <b class="d-block mt-2 mt-md-0">
                                                                {{plastics?.name}} </b> </div>
                                                        <div class="col-12 col-md-4">
                                                            <p class="m-0"> {{plastics?.description}} </p>
                                                        </div>
                                                        <div class="col-12 col-md-5 text-right">
                                                            <a href="{{plastics.url}}" class="btn btn-primary py-2 px-4 mt-2 mt-md-0 mr-3">
                                                                Know More
                                                            </a>
                                                            <a [routerLink]="['/contact']"  rel="noopener" class="btn float-left float-md-right py-2 px-4 mt-2 mt-md-0">
                                                                Get In Touch
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col p-0">
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Tale ending -->
                    <div class="row">
                        <div class="col">
                            Additional alloys and tempers are available upon request. We can source to your project specifications. Talk to our support team at <a href="#"> care@truventor.ai </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Surface Finishing CNC Machinig  -->
<section class="cnc-surface-finishing global-space-between">
    <div class="container">
        <!-- Title -->
        <div class="row">
            <div class="col p-md-0">
                <h1 class="py-4 mb-4">
                    Post processing options
                </h1>
            </div>
        </div>
        <!-- accordion -->
        <div class="row align-items-center">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-6 p-3 p-md-0 d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start">
                <img src="./assets/img/cnc_machining_service/Surface Finishing CNC Machinig@2x.jpg" class="img-fluid w-100" loading="lazy" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5">
                <!-- collapse -->
                <div class="w-100">
                    <!-- Accordion -->
                    <div class="">
                        <div class="col-sm-12 col-md-12 p-0 p-md-auto">
                            <div id="accordion">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h5 class="mb-0 text-uppercase">
                                            <button data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <i class="fa" aria-hidden="true"></i>
                                                Bead blasting
                                            </button>
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                        <div class="card-body">
                                            Bead blasting is a manual process of applying small glass beads under high pressure to the surface of a component.
                                        </div>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h5 class="mb-0 text-uppercase">
                                            <button class="collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <i class="fa" aria-hidden="true"></i>
                                                Polished Surfaces
                                            </button>
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                                        <div class="card-body">
                                            Polishing surfaces can either be done by hand or by utlizing various types of machine polishing equipment.
                                        </div>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h5 class="mb-0 text-uppercase">
                                            <button class="collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <i class="fa" aria-hidden="true"></i>
                                                Powder Coat
                                            </button>
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                                        <div class="card-body">
                                            Powder coating is a process of applying a powder type paint to a component and then baking the paint onto the part.
                                        </div>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h5 class="mb-0 text-uppercase">
                                            <button class="collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <i class="fa" aria-hidden="true"></i>
                                                Anodizing
                                            </button>
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion">
                                        <div class="card-body">
                                            Anodizing is a corrosion and, if specified, wear-resistant coating. It is most commonly associated with aluminum parts and comes in a broad array of colors.
                                        </div>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFive">
                                        <h5 class="mb-0 text-uppercase">
                                            <button class="collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                <i class="fa" aria-hidden="true"></i>
                                                Lapping
                                            </button>
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordion">
                                        <div class="card-body">
                                            Lapping is a precision technique for achieving the highest degree of surface refinement and flatness.
                                        </div>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingSix">
                                        <h5 class="mb-0 text-uppercase">
                                            <button class="collapsed" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                <i class="fa" aria-hidden="true"></i>
                                                Mass Finishing
                                            </button>
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#accordion">
                                        <div class="card-body">
                                            Mass Finishing is a timed batch process that utilizes abrasive media and a rotating or vibrating process vessel to simultaneously deburr and finish multiple machined parts.
                                        </div>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="headingSeven">
                                        <h5 class="mb-0 text-uppercase">
                                            <button class="collapsed" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                <i class="fa" aria-hidden="true"></i>
                                                Roller and Ball Burnishing
                                            </button>
                                        </h5>
                                    </div>
                                    <hr class="m-0 mb-2">

                                    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#accordion">
                                        <div class="card-body">
                                            Burnishing does not remove any material, it is a small-scale forming operation that can improve the finish or hardness of the part’s surface.
                                        </div>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header" id="headingEight">
                                        <h5 class="mb-0 text-uppercase">
                                            <button class="collapsed" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                <i class="fa" aria-hidden="true"></i>
                                                Knurling
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#accordion">
                                        <div class="card-body">
                                            Knurling is a process to add a series of indentations on a machined part so it is easier to handle.
                                        </div>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-quality-assurance></app-quality-assurance>



<!-- Why truventor best -->
<section class="cnc-why-truventor global-space-between bottom-end-padding">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="mb-5 text-center font-weight-bold">
                    Why Truventor?
                </h1>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-2 row-cols-md-5 pt-3 justify-content-center">

            <div class="col my-4">
                <div class="card border-0 px-1 px-md-2" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/Access to global supplier network.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body p-0 mt-3">
                        <p class="card-text text-center">Access to global<br>supplier network</p>
                    </div>
                </div>
            </div>

            <div class="col my-4">
                <div class="card border-0 px-1 px-md-2" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/Deep supplier insights.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body p-0 mt-3">
                        <p class="card-text text-center">Deep supplier<br>insights</p>
                    </div>
                </div>
            </div>

            <div class="col my-4">
                <div class="card border-0 px-1 px-md-2" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/Transparency at each step.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body p-0 mt-3">
                        <p class="card-text text-center">Transparency<br>at each step</p>
                    </div>
                </div>
            </div>

            <div class="col my-4">
                <div class="card border-0 px-1 px-md-2" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/Humanizing communication.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body p-0 mt-3">
                        <p class="card-text text-center">Humanizing<br>communication</p>
                    </div>
                </div>
            </div>

            <div class="col my-4">
                <div class="card border-0 px-1 px-md-2" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/NON- Disclosure Agreement.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body p-0 mt-3">
                        <p class="card-text text-center">
                            Non Disclosure <br> Agreement
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Schedule a meeting -->
<section class="amazing-quote global-space-between">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8">
                <div>
                    <!-- Quote -->
                    <h1 class="text-center text-white">
                        Schedule a meeting or book a demo
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center mt-4">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light px-md-5">
                            Request a call back </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Other Offerings -->
<section class="cnc-other-offering check-out-our-other-offerings global-space-between bottom-end-padding">
    <div class="container">
        <div class="row mb-5">
            <div class="col">
                <h1 class="text-center pb-5"> Check out our other offerings </h1>
            </div>
        </div>
        <div class="row row-cols-2 row-cols-md-5 justify-content-center">

            <!-- 3D Printing -->
            <div class="col p-4 py-md-0 pointer" (click)="threedPrinting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/3DPrinting.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">3D<br>Printing</p>
                    </div>
                </div>
            </div>

            <!-- Vaccum Casting -->
            <div class="col p-4 py-md-0 pointer" (click)="vacuumCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/Vacuum Casting.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Vacuum<br>Casting</p>
                    </div>
                </div>
            </div>

            <!-- Injection Molding -->
            <div class="col p-4 py-md-0 pointer" (click)="injectionMolding()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/Injection Moulding.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Injection<br>Molding</p>
                    </div>
                </div>
            </div>

            <!-- Metal Casting -->
            <div class="col p-4 py-md-0 pointer" (click)="metalCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/Metal Casting.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Metal<br>Casting</p>
                    </div>
                </div>
            </div>

            <!-- Sheet Metal -->
            <div class="col p-4 py-md-0 pointer" (click)="sheetMetal()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="assets/img/cnc_machining_service/svg/Sheet Metal.svg" class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Sheet<br>Metal</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>