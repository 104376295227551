import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-enterprise-page',
  templateUrl: './enterprise-page.component.html',
  styleUrls: ['./enterprise-page.component.scss']
})
export class EnterprisePageComponent implements OnInit {
  mydiv;
  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.toggleDiv(this.mydiv);
  }

  toggleDiv(divid) {

    let varon = divid + 'on';
    let varoff = divid + 'off';

    if (document.getElementById(varon).style.display == 'block') {
      document.getElementById(varon).style.display = 'none';
      document.getElementById(varoff).style.display = 'block';
      document.getElementById('l1').style.color = '#696871';
      document.getElementById('l2').style.color = '#000';

    }

    else {
      document.getElementById(varoff).style.display = 'none';
      document.getElementById(varon).style.display = 'block';
      document.getElementById('l1').style.color = '#000';
      document.getElementById('l2').style.color = '#696871';


    }
  }


  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 40,
    // navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true,
    center: true,
    // loop: true,
    // items: 3,
    // nav: true,
    navText: ["<img src='assets/img/about/icon/arrow-left.png' alt='Truventor.ai'>", "<img src='assets/img/about/icon/arrow-right.png' alt='Truventor.ai'>"],

  }

}
