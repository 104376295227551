<!-- Main -->
<section class="main-session">
    <img src="assets/img/product/Banner Product@2x.png" class="img-banner" loading="lazy" alt="Truventor">

    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>Cloud <span>Manufacturing</span> Platform
                    </h1>
                    <p class="mt-4">
                        From prototype to production, get parts manufactured on-demand irrespective of the quantity, budget or timeline.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row action-button">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/manufacturing-partners']" class="btn btn-primary"> Join Our Partner Network </a>
            </div>
        </div>

        <!-- Statistics -->
        <div class="row statistics d-none d-md-flex">

            <div class="col">
                <div class="small-info">
                    <h6> 22k+ </h6>
                    <hr>
                    <p>CAD Uploads</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info">
                    <h6> 300k+ </h6>
                    <hr>
                    <p>Parts Manufactured</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info">
                    <h6> 6k+ </h6>
                    <hr>
                    <p>Quotations</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info">
                    <h6> 250+ </h6>
                    <hr>
                    <p>Customers Served</p>
                </div>
            </div>

        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>


<!-- Platform for bringing transparency -->

<section class="why-truventor global-space-between">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <h1>
                    Introducing transparency into manufacturing and the supply chain
                </h1>
            </div>
            <div class="col-md-6">
                <div class="row row-cols-2 row-cols-md-3">
                    <div class="col-md-6 p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <h5>
                            Design input
                        </h5>
                        <p>
                            DFM Feedback, Process Optimisation
                        </p>
                    </div>

                    <div class="col-md-6 p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <h5>
                            Manufacturing data
                        </h5>
                        <p>
                            Parts cycle time, material utilisation
                        </p>
                    </div>

                    <div class="col-md-6 p-3 " data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <h5>
                            Inspection data
                        </h5>
                        <p>
                            Live visual inspection of parts
                        </p>
                    </div>

                    <div class="col-md-6 p-3 " data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <h5>
                            Delivery data
                        </h5>
                        <p>
                            Online tracking and notifications
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<!-- Product Features -->
<section class="how-it-works global-space-between bottom-end-padding">
    <div class="container-fluid">
        <!-- how it works desktop -->
        <div class="">
            <div class="row justify-content-end">
                <div class="col-md-5">
                    <div class="wrapper">
                        <h1 class="font-weight-bold">Features</h1>

                        <ul class="nav nav-tabs row" id="myTab" role="tablist">
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link active" data-toggle="tab" href="#home" role="tab" aria-controls="home">
                                    <hr>
                                    <h6 class="font-weight-bold">3D viewer</h6>
                                    <p>This cloud-based viewer with advanced features ensures that you view and inspect your files securely</p>
                                    <img src="assets/img/product/features/3d-viewer.png" loading="lazy" class="img-fluid d-block d-md-none" alt="process selection image">
                                </a>
                            </li>
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#profile" role="tab" aria-controls="profile">
                                    <hr>
                                    <h6 class="font-weight-bold">Part analysis</h6>
                                    <p>Our DFM tools and proprietary algorithm can check each 3D file and automatically repair them.</p>
                                    <img src="assets/img/product/features/part-analysis.png" loading="lazy" class="img-fluid d-block d-md-none" alt="instant quote image">
                                </a>
                            </li>
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#settings" role="tab" aria-controls="settings">
                                    <hr>
                                    <h6 class="font-weight-bold">Instant quote</h6>
                                    <p>Based on your material, process and post-processing selections, get instant quotes at the click of a button.</p>
                                    <img src="assets/img/product/features/Instant-costing.png" loading="lazy" class="img-fluid d-block d-md-none" alt="live tracking image">
                                </a>
                            </li>
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#messages" role="tab" aria-controls="messages">
                                    <hr>
                                    <h6 class="font-weight-bold">Real-time order tracking</h6>
                                    <p> Dashboard to track the status of your project throughout the part manufacturing process
                                    </p>
                                    <img src="assets/img/product/features/order-tracking.png" class="img-fluid d-block d-md-none" loading="lazy" alt="upload image">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="col-md-6 d-none d-md-block pr-0">
                    <div class="tab-content text-right">
                        <div class="tab-pane" id="home" role="tabpanel">
                            <img src="assets/img/product/features/3d-viewer.png" loading="lazy" class="img-fluid" alt="process selection image">
                        </div>
                        <div class="tab-pane" id="profile" role="tabpanel">
                            <img src="assets/img/product/features/part-analysis.png" loading="lazy" class="img-fluid" alt="instant quote image">
                        </div>
                        <div class="tab-pane" id="settings" role="tabpanel">
                            <img src="assets/img/product/features/Instant-costing.png" loading="lazy" class="img-fluid" alt="live tracking image">
                        </div>
                        <div class="tab-pane active" id="messages" role="tabpanel">
                            <img src="assets/img/product/features/order-tracking.png" loading="lazy" class="img-fluid" alt="upload image">
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
</section>






<!--  Accelerate ,Secure And Improve -->
<section class="amazing-quote global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="quote-background-image">
                    <!-- Quote -->
                    <h1 class="text-center text-white">
                        Accelerate, secure and improve the engineering and manufacturing performance at scale.
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center mt-5">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light">Book
                            A Demo </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- How to use our platform -->
<section class="how-to-use side-tab-view global-space-between bottom-end-padding">
    <div class="container">

        <!-- Desktop View -->
        <div class="d-none d-md-block">

            <div class="row align-items-center">
                <div class="col-6 pr-5">
                    <h1 class="text-left mb-3">
                        How to use our platform?
                    </h1>
                    <p>
                        Get started with a simple four-step process
                    </p>
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active" id="how-to-use-upload-tab" data-toggle="pill" href="#how-to-use-upload" role="tab" aria-controls="how-to-use-upload" aria-selected="true">
                            <span class="plus">
                                Upload
                            </span>
                            <p>
                                Start by uploading the 2D or 3D files for the parts you want manufactured. The data uploaded belongs solely to you and has end-to-end protection backed by Truventor's secure file handling system.
                            </p>
                        </a>
                        <a class="nav-link" id="how-to-use-specify-requirement-tab" data-toggle="pill" href="#how-to-use-specify-requirement" role="tab" aria-controls="how-to-use-specify-requirement" aria-selected="false">
                            <span class="plus">
                                Specify requirements
                            </span>
                            <p>
                                Our proprietary algorithm studies the files and makes all the feasible manufacturing technologies and materials available to you. You can specify your detailed requirements by providing all the information required for production.
                            </p>
                        </a>
                        <a class="nav-link" id="how-to-use-place-order-tab" data-toggle="pill" href="#how-to-use-place-order" role="tab" aria-controls="how-to-use-place-order" aria-selected="false">
                            <span class="plus">
                                Place order
                            </span>
                            <p>
                                The best pricing will be made available to you instantly or within a few hours. Placing your order is easy -- all it takes is a few clicks on your dashboard. A Truventor representative will then be assigned to assist you with the next steps.
                            </p>
                        </a>
                        <a class="nav-link" id="how-to-use-track-tab" data-toggle="pill" href="#how-to-use-track" role="tab" aria-controls="how-to-use-track" aria-selected="false">
                            <span class="plus">
                                Track
                            </span>
                            <p>
                                Get ready for a seamless and transparent experience! Access step-by-step updates and reports on the quality, production schedules, dispatch and delivery of your order on our dashboard.
                            </p>
                        </a>

                    </div>

                </div>

                <div class="col-6 pl-0">
                    <div class="tab-content" id="main_id">
                        <div class="tab-pane fade show active" id="how-to-use-upload" role="tabpanel" aria-labelledby="how-to-use-upload-tab">
                            <img src="./assets/img/product/Upload.svg" loading="lazy" class="img-fluid p-5" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="how-to-use-specify-requirement" role="tabpanel" aria-labelledby="how-to-use-specify-requirement-tab">
                            <img src="./assets/img/product/Specify Requirements.svg" loading="lazy" class="img-fluid p-5" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="how-to-use-place-order" role="tabpanel" aria-labelledby="how-to-use-place-order-tab">
                            <img src="./assets/img/product/Place Order.svg" loading="lazy" class="img-fluid p-5" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="how-to-use-track" role="tabpanel" aria-labelledby="how-to-use-track-tab">
                            <img src="./assets/img/product/Track.svg" loading="lazy" class="img-fluid p-5" width="100%" alt="Truventor">
                        </div>
                    </div>
                </div>

            </div>

        </div>

        <!-- Mobile View -->
        <div class="d-block d-md-none mobile-view">
            <div class="row">
                <div class="col">

                    <!-- Title -->
                    <h1 class="text-center text-md-left">
                        How to use Our Platform?
                    </h1>

                    <!-- One -->
                    <div>
                        <div class="d-flex justify-content-center">
                            <img src="./assets/img/product/Upload.svg" loading="lazy" class="img-fluid py-4" alt="Truventor">
                        </div>
                        <h5 class="text-left">
                            Upload
                        </h5>
                        <hr>
                        <p>
                            Start by uploading the 2D or 3D files for the parts you want manufactured. The data uploaded belongs solely to you and has end-to-end protection backed by Truventor's secure file handling system.
                        </p>
                    </div>
                    <!-- Two -->
                    <div>
                        <div class="d-flex justify-content-center">
                            <img src="./assets/img/product/Specify Requirements.svg" loading="lazy" class="img-fluid py-4" alt="Truventor">
                        </div>
                        <h5 class="text-left">
                            Specify Requirements
                        </h5>
                        <hr>
                        <p>
                            Our proprietary algorithm studies the files and makes all the feasible manufacturing technologies and materials available to you. You can specify your detailed requirements by providing all the information required for production.
                        </p>
                    </div>
                    <!-- Three -->
                    <div>
                        <div class="d-flex justify-content-center">
                            <img src="./assets/img/product/Place Order.svg" loading="lazy" class="img-fluid py-4" alt="Truventor">
                        </div>
                        <h5 class="text-left">
                            Place Order
                        </h5>
                        <hr>
                        <p>
                            The best pricing will be made available to you instantly or within a few hours. Placing your order is easy -- all it takes is a few clicks on your dashboard. A Truventor representative will then be assigned to assist you with the next steps.
                        </p>
                    </div>
                    <!-- Four -->
                    <div>
                        <div class="d-flex justify-content-center">
                            <img src="./assets/img/product/Track.svg" loading="lazy" class="img-fluid py-4" width="50%" alt="Truventor">
                        </div>
                        <h5 class="text-left">
                            Track
                        </h5>
                        <hr>
                        <p>
                            Get ready for a seamless and transparent experience! Access step-by-step updates and reports on the quality, production schedules, dispatch and delivery of your order on our dashboard.
                        </p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>

<!-- Security End-to-end file -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-6 d-flex justify-content-center align-items-center">
                <img src="./assets/img/product/File Protection.svg" loading="lazy" class="img-fluid px-3 px-md-5 mr-md-5" width="75%" alt="Truventor">
            </div>
            <!-- Text -->
            <div class="col-sm-12 col-md-6">
                <h1 class="my-4">
                    Your file is safe with us! Avail of our end-to-end file protection security! </h1>
                <div class="col-sm-12 col-md-5 p-0">
                    <a [routerLink]="['/security']" class="btn w-100 px-3 px-sm-0"> Know More </a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Quality Assurance -->
<section class="qa-assurance global-space-between">
    <div class="container">
        <!-- Title -->
        <div class="row">
            <div class="col">
                <h1 class="text-center" data-aos="fade-up">Quality assurance</h1>
                <p class="text-left text-md-center mt-3 col-md-8 mx-auto" data-aos="fade-up">
                    Truventor strives to achieve best-in-class quality through a systematic approach during every stage of part manufacturing.
                </p>
            </div>
        </div>
        <!-- Quality Icons -->
        <div class="row row-cols-2 row-cols-sm-2 row-cols-md-3 mt-3">
            <!-- ISO -->
            <div class="col mt-4">
                <div data-aos="fade-up">
                    <div class="d-flex justify-content-center justify-content-md-start">
                        <img class="img-fluid" loading="lazy" src="./assets/img/product/ISO90012015Certification.svg" alt="quality_assurance_image">
                    </div>
                    <hr class="my-3">
                    <p class="text-center text-md-left">
                        ISO 9001:2015 certification
                    </p>
                </div>

            </div>
            <!-- Automated manufacturing feasibility analysis -->
            <div class="col mt-4">
                <div data-aos="fade-up">
                    <div class="d-flex justify-content-center justify-content-md-start">
                        <img class="img-fluid" loading="lazy" src="./assets/img/product/Automated Manufacturing Feasibility Analysis.svg" alt="quality_assurance_image">
                    </div>
                    <hr class="my-3">
                    <p class="text-center text-md-left">
                        Automated manufacturing feasibility analysis
                    </p>
                </div>
            </div>
            <!-- Truventor Certified Quality & Manufacturing Benchmarks -->
            <div class="col mt-4">
                <div data-aos="fade-up">
                    <div class="d-flex justify-content-center justify-content-md-start">
                        <img class="img-fluid" loading="lazy" src="./assets/img/product/Truventor Certified Quality & Manufacturing Benchmarks.svg" alt="quality_assurance_image">
                    </div>
                    <hr class="my-3">
                    <p class="text-center text-md-left">
                        Truventor-certified quality and manufacturing benchmarks </p>
                </div>
            </div>
            <!-- Quality inspection report (On request) -->
            <div class="col mt-4">
                <div data-aos="fade-up">
                    <div class="d-flex justify-content-center justify-content-md-start">
                        <img class="img-fluid" loading="lazy" src="./assets/img/product/QualityInspectioReport(On Request).svg" alt="quality_assurance_image">
                    </div>
                    <hr class="my-3">
                    <p class="text-center text-md-left">
                        Quality inspection report (On request)
                    </p>
                </div>
            </div>
            <!-- Analysis Of Root-cause In Case Of Non-conformance -->
            <div class="col mt-4">
                <div data-aos="fade-up">
                    <div class="d-flex justify-content-center justify-content-md-start">
                        <img class="img-fluid" loading="lazy" src="./assets/img/product/AnalysisOfRoot-CauseInCaseOfNon-Conformance.svg" alt="quality_assurance_image">
                    </div>
                    <hr class="my-3">
                    <p class="text-center text-md-left">
                        Analysis of root-cause in case of non-conformance
                    </p>
                </div>
            </div>
            <!-- Fesibility Analysis -->
            <div class="col mt-4">
                <div data-aos="fade-up">
                    <div class="d-flex justify-content-center justify-content-md-start">
                        <img class="img-fluid" loading="lazy" src="./assets/img/product/Fesibility Analysis.svg" alt="quality_assurance_image">
                    </div>
                    <hr class="my-3">
                    <p class="text-center text-md-left">
                        Feasibility analysis
                    </p>
                </div>
            </div>
        </div>

    </div>
</section>

<section class="work-smart global-space-between bottom-end-padding">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-sm-12 col-md-4 p-0">
                <img class="img-fluid" src="./assets/img/product/Work smarter, faster, and more in sync with a manufacturing Eco system@2x.jpg" width="100%" loading="lazy" alt="Truventor">
            </div>
            <div class="col-sm-12 col-md-8 d-flex justify-content-start align-items-center">
                <div class="col-sm-12 col-md-12 col-lg-12 p-3 p-md-5">
                    <h1 class="font-weight-bold py-5 py-md-0">
                        Looking to work smarter, faster <br> and more in sync with <br> a manufacturing ecosystem?
                        <!-- Work smarter, <br> faster, and more in <br> sync with a <br> manufacturing Eco <br> system -->
                    </h1>
                    <div class="col-sm-12 col-md-6 col-lg-5 p-0 pt-0 pt-md-5">
                        <a  [routerLink]="['/contact']" rel="noopener" class="btn btn-primary px-5 w-100"> Get Started </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>