import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../shared/admin.service';
import { reloadTime, SHEET_METAL_MATERIAL } from '../../shared/data/index';

declare var $: any;

@Component({
  selector: 'app-sheetmetalfabrication',
  templateUrl: './sheetmetalfabrication.component.html',
  styleUrls: ['./sheetmetalfabrication.component.scss']
})
export class SheetmetalfabricationComponent implements OnInit {
  private fragment: string;
  public sheetMetalMaterial = SHEET_METAL_MATERIAL;
  public reloadTime = reloadTime;

  constructor(
    private adminservice: AdminService,
    private router: Router,
    private route: ActivatedRoute
  ) { }


  
  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      if(this.fragment != 'sheet-metal-material'){
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        setTimeout(() => {
          document.getElementById('sheet-metal-material').scrollIntoView();          
        }, reloadTime);
      }
    });
    


    
   

    this.adminservice.check_customer.next('customer');
    $("#accordion-post-processing").on("hide.bs.collapse show.bs.collapse", e => {
      $(e.target)
        .prev()
        .find("i:last-child")
        .toggleClass("fa-minus fa-plus");
    });


    $("#grow-your-businness-accordion-one").on("hide.bs.collapse show.bs.collapse", e => {
      $(e.target)
        .prev()
        .find("i:last-child")
        .toggleClass("fa-minus fa-plus");
    });

  }

  // ngAfterViewInit(): void {
    
  //   try {
  //     document.querySelector('#' + this.fragment).scrollIntoView(
  //       {
  //         behavior : 'smooth'
  //       }
  //     );
  //   } catch (e) { }
  // }

  threedPrinting() {
    this.router.navigate(['/3d-printing']);
  }

  vacuumCasting() {
    this.router.navigate(['/vacuum-casting']);
  }

  injectionMolding() {
    this.router.navigate(['/plastic-injection-moulding']);
  }

  metalCasting() {
    this.router.navigate(['/metal-casting']);
  }

  cncmachining() {
    this.router.navigate(['/cnc-machining']);
  }

}


export interface SHEET_METAL_MATERIAL{
  id : Number,
  name : String,
  description: String,
}