<section class="contact-cover imprint-main-session">
    <!-- <img src="assets/img/contact/terms.png" class="img-banner" alt="Truventor"> -->

    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="content">
                    <h1>Client Terms and Conditions</h1>
                    <p>All your questions answered at one place
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="privacy">
    <div class="container">
        <div class="d-none d-md-block">
            <div class="row">
                <div class="col-md-4">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#home" role="tab"
                                aria-controls="home">Overview</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#profile" role="tab"
                                aria-controls="profile">Acceptance of terms and conditions</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#messages" role="tab"
                                aria-controls="messages">Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#settings" role="tab"
                                aria-controls="settings">Placing orders</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#automeans" role="tab"
                                aria-controls="automeans">Pricing and payment</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#intellectual" role="tab"
                                aria-controls="intellectual">Manufacturing your order</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#useoftvweb" role="tab"
                                aria-controls="useoftvweb">Shipping</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#otherweb" role="tab"
                                aria-controls="otherweb">Product</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#changeprivacy" role="tab"
                                aria-controls="changeprivacy">Intellectual property in the design and product</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#contactus" role="tab"
                                aria-controls="contactus">Marketplace only
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#eleven" role="tab"
                                aria-controls="eleven">Pricing and payment
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#twelve" role="tab"
                                aria-controls="twelve">Supplier products and services
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#thirteen" role="tab"
                                aria-controls="thirteen">Delivery and performance
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#fourteen" role="tab"
                                aria-controls="fourteen">Refunds and returns
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#fifteen" role="tab"
                                aria-controls="fifteen">Intellectual property in the platform

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#sixteen" role="tab"
                                aria-controls="sixteen">Operation of the platform
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#seventeen" role="tab"
                                aria-controls="seventeen">Modifications to the platform

                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#eighteen" role="tab"
                                aria-controls="eighteen">Advertising
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#nineteen" role="tab"
                                aria-controls="nineteen">Warranties
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#twenty" role="tab"
                                aria-controls="twenty">Limitation of liability
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#twentyone" role="tab"
                                aria-controls="twentyone">Indemnity
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#twentytwo" role="tab"
                                aria-controls="twentytwo">General
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <div class="verticle-line text-center"></div>
                </div>
                <div class="col-md-6">
                    <div class="tab-content">
                        <div class="tab-pane active" id="home" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                1 Overview
                                Truventor Client Terms and Conditions
                                1.1 Truventor AI and Robotics Pty Ltd ACN 622 249 969 (we, us, our, TV) operates an
                                online
                                intelligent cloud manufacturing platform with domain address www.truventor.ai and
                                related
                                applications (Platform) on which clients (Client, you, your) can access a range of
                                manufacturing services from us and our registered suppliers (Supplier). These terms and
                                conditions (Terms and Conditions) constitute an agreement between us and you for the
                                purpose
                                of your use of the Platform and any services offered by the Platform from time to time.
                                These are the Client terms and conditions and apply to Clients only.
                                1.2 If you are a Supplier, please access our Supplier Terms and Conditions at link.
                                1.3 The key features of our Platform include:
                                (a) Truventor Manufacture (TV Manufacture), an online manufacturing service through
                                which
                                you can:
                                (i) Place manufacturing orders;
                                (ii) Upload 3D models and designs relating to parts, assembly requirements, or
                                products (Designs) for your manufacturing projects; and
                                (iii) Have your Designs manufactured.
                                (b) Truventor Manage (TV Manage), an online marketplace and software as a service
                                through
                                which you can access and manage a private network of your own independent Suppliers,
                                which
                                includes access to our network of Suppliers; and
                                (c) Other Truventor services which may be available through the Platform.
                                (Services)
                                1.4 We reserve the right to update, modify, replace, limit, and expand the Platform and
                                the
                                Services.
                                1.5 You should read these Terms and Conditions carefully prior to using the Platform.
                            </p>
                        </div>
                        <div class="tab-pane" id="profile" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>

                            <p>
                                2 Acceptance of Terms and Conditions
                                2.1 These Terms and Conditions record an agreement between you and us for the purpose of
                                you
                                browsing or using the Platform.
                                2.2 By using the Platform you are accepting these Terms and Conditions and agree to be
                                bound by them.
                                2.3 We reserve the right to make changes to these Terms and Conditions from time to time
                                without notice.
                            </p>
                        </div>
                        <div class="tab-pane" id="messages" role="tabpanel">

                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                3 Use
                                3.1 To use the Platform, you must register for an account in accordance with this
                                clause.
                                3.2 To register an account and / or place an order, you may be required to provide
                                (among other things):
                                1
                                (a) Your name;
                                (b) Details of your business, including:
                                (i) Name;
                                (ii) Address; and
                                (iii) Any other required details.
                                (c) Valid contact details including;
                                (i) An email address; and
                                (ii) A telephone number.
                                (d) A name and address for delivery purposes; and
                                (e) Any other information required to complete the registration or ordering process,
                                (Client Data).
                                What constitutes Client Data may vary from time to time, noting that we may require
                                additional
                                information to that set out above.
                                3.3 Failure to supply correct Client Data may result in us being unable to provide the
                                Services, and / or may limit your access to the Platform.
                                3.4 We reserve the right to decline your registration request if you do not comply with
                                our registration requirements as detailed in these Terms and Conditions and on the
                                Platform.
                                3.5 You agree to permit us to use your Client Data for the purpose of providing the
                                functionality of the Platform and any related purpose. You acknowledge that in order to
                                do so, we may share the Client Data internally, and with other parties, including
                                Suppliers, financiers, logistic partners and other stakeholders.
                                3.6 You agree to use the Platform for their intended purpose. You may not use the
                                Platform for any illegal or unauthorised purpose, including the transmission of malware.
                                You must not use the Platform to violate any laws, including but not limited to
                                copyright laws.
                                3.7 You must not use the Platform for any unconscionable behaviour or activity,
                                including but not limited to:
                                (a) Engaging in abusive, derogatory, discriminatory, or vexatious behaviour;
                                (b) Engaging in any activity that disrupts or corrupts the Platform or the networks that
                                host the Platform; or
                                (c) Attempting to hack, reverse-engineer, disable, circumvent, or otherwise gain access
                                to any security, database, or other secure aspect of the Platform.
                                3.8 You are responsible for maintaining the security of your login and account
                                information (including the password). We are not liable for any loss or damage from your
                                failure to comply
                                2

                                with this security obligation.
                                3.9 You are responsible for all activities that occur on your account, and we will not
                                be liable for any loss or damage from activities on your account.
                                3.10 All Client Data will be stored and maintained by or on behalf of us in accordance
                                with applicable laws.
                                3.11 We may from time to time email you regarding our Service or third party products or
                                services that may be of interest to you. The emails may contain code that enables our
                                database to track your usage of the emails, including whether the email was opened and /
                                or what links (if any) were clicked. We may use this information to improve the
                                functionality of the Platform and to improve the Service for you.
                                3.12 Our use of any information provided within the Client Data will be in accordance
                                with our Privacy Policy available at link.
                            </p>
                        </div>
                        <div class="tab-pane" id="settings" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>

                            <p>
                                4 Placing Orders
                                4.1 To place a manufacturing order (Order) on TV Manufacture to have a product (Product)
                                manufactured, you must upload a request for quotation consisting of a Design, raw
                                material specification, and other details as required (RFQ), in a file that is
                                compatible with the Platform, and which meets the file requirements specified on the
                                Platform.
                                4.2 Once a RFQ is uploaded, the Platform will extract the data from the Design to
                                calculate timelines and pricing of parts to complete the Order. You are required to
                                follow the prompts on the Platform to complete the order, which will include but is not
                                limited to selecting the material, quantities, and manufacturing processes. You must
                                complete the Order as prompted by the Platform. The Design and other information
                                contained in the Order will constitute your Order specifications (Specifications).
                                4.3 You may be able to see and review the Order, estimated costs, and estimated
                                timelines prior to finalising the order. No agreement to manufacture any Order exists
                                until we confirm that an Order has been accepted.
                                4.4 In most instances, the estimated costs of your Order will be provided immediately.
                                However, for particularly large Orders or specific manufacturing requirements, we may be
                                required to submit a request for quote (RFQ) to our Suppliers. When this is required, we
                                will make reasonable endeavours to procure that the RFQ is answered within 48 hours,
                                subject to weekends and public holidays, however this timeline may vary depending on the
                                size of the products and the availability of our Suppliers. Once the RFQ is answered,
                                you will receive a notification notifying you of the quoted cost and delivery timelines,
                                after which you may complete your Order.
                                4.5 You may not cancel an Order that has been accepted. We may, at our absolute
                                discretion, permit you to cancel an Order that has been accepted and charge a reasonable
                                cancellation fee.
                                4.6 Upon an Order being accepted by us, we will manufacture or have manufactured the
                                Order in accordance with the Specifications. You are solely responsible for ensuring
                                that the Specifications contained in your Order are accurate, functional, and suitable
                                for your manufacturing requirements. We accept no liability and will not be liable for
                                any malfunctions, disfunctions, or design incompatibilities which are a result of the
                                Specifications.
                                4.7 You may track the status of your Order through the Platform. Any time periods
                                provided in the
                                3
                                Order tracking functionality are estimations only, and do not bind us to complete the
                                order by a certain date.
                                4.8 We may at any time during the quotation, order, and manufacturing process cancel any
                                Order if there are technical or other reasons to do so. Whether you will be entitled to
                                a full, partial, or any discount will depend on the circumstances of the cancellation,
                                and will be determined at our absolute discretion.
                                4.9 Your Orders, including your uploaded Designs and Specifications, can be stored on
                                your account. You should maintain your own backups and storage systems for your Designs.
                                The Platform is not intended to operate as a portfolio or storage system for your
                                Designs.
                                4.10 We do not guarantee that there will be no corruptions to or loss of data with
                                respect to your stored Designs, and you fully release us from and indemnify us against
                                any loss or damages caused by any loss of data relating to your use of the Platform.
                            </p>
                        </div>
                        <div class="tab-pane" id="automeans" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                5 Pricing and Payment
                                5.1 Unless otherwise agreed at our absolute discretion, you must pay the price (Price)
                                at checkout in full to complete your Order. If the Order has been referred to our
                                Suppliers for an RFQ, you must pay the Price once the RFQ is completed in accordance
                                with clause 4.4.
                                5.2 The Price at checkout may include the fee for the service, the price for the
                                materials used, shipping fees, and any other applicable costs. A breakdown of these
                                costs will be displayed at checkout. The Price is payable in full at check out.
                                5.3 In the case of large Orders, we may, at our discretion, allow for the Price to be
                                paid in instalments. When we allow for the Price to be Paid in instalments, the Price
                                will be payable according to the instalment payment instructions provided through the
                                Platform.
                                5.4 We use a third-party service provider for provision of the payment service at
                                check-out and we are not liable for any issues, including charges from your financial
                                institution, which may arise as a result of this service.
                                5.5 You acknowledge that the credit card, debit card or other payment method provided
                                has been verified against a recent bank statement to confirm accuracy of the details
                                provided.
                                5.6 You acknowledge that you have authority to utilise the nominated credit card, debit
                                card or other payment method for the purpose of paying the Price.
                                5.7 You acknowledge that it is your responsibility to ensure that there are sufficient
                                cleared funds or financial capacity in the nominated account to honour the transaction
                                when it falls due.
                                5.8 We will not be held responsible for any fees and charges imposed by your financial
                                institution or any other third party that may be incurred if there are insufficient
                                funds in your account or the payment fails.
                            </p>
                        </div>
                        <div class="tab-pane" id="intellectual" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>6 Manufacturing your Order
                                6.1 We reserve the right to alter, modify, or change the Specifications of any Product
                                in the event we determine that the manufacture of a part in accordance with your
                                Specifications is impossible, impractical, or likely that have an adverse impact on the
                                resulting part or design. Where possible, we will seek your consultation or confirmation
                                before making any such alterations, modifications, or changes.
                                6.2 You acknowledge that we engage third party manufacturers to manufacture your Order,
                                and agree that we may delegate the manufacture of your Order to such third parties. You
                                therefore acknowledge and agree that we will share your Specifications, including your
                                Design, with our
                                4
                                Suppliers for the purposes of quoting and manufacturing your Order. Our Suppliers are
                                subject to their own terms and conditions between us and them which may be accessed here
                                link. By accepting these Terms and Conditions, you agree that your terms on which we
                                engage Suppliers are acceptable to you.
                                6.3 We ensure that our Suppliers and their / our manufacturing process meets relevant
                                standards and regulations. When we process an Order, we ensure that your specification
                                incl. explicitly required industrial norms and standard requirements are met by the
                                Supplier we engage. You, however, are responsible to ensure that your Specifications
                                comply with any local laws, regulations, or requirements that may apply to your final
                                Product. We fully disclaim all liability for, and you fully indemnify against, any
                                claims, fines, charges, loss, damages, breaches of any agreements, laws, regulations,
                                contracts, intellectual property or copyrights, or any other damages incurred by your
                                final product, whether we were involved in the partial or total manufacture of that
                                product.
                                6.4 We reserve the right to refuse to manufacture an Order that is illegal or designed
                                to cause harm or injury to any human, or which is designed with the intention of
                                facilitating any illegal activity such as but not limited to, the production of illicit
                                drugs, the unlawful destruction of property, or any other criminal activity.</p>
                        </div>
                        <div class="tab-pane" id="useoftvweb" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                7 Shipping
                                7.1 You will be provided an estimated shipping date at the time you place your Order,
                                which will be calculated on the basis of your Specifications. The estimated shipping
                                date is an estimate only, and your Product may arrive before or after the shipping date
                                provided. While we will use all reasonable efforts to ensure the shipping date is met,
                                we are in no way liable, and you fully indemnify us from any liability incurred, for any
                                loss or damage caused by a Product being shipped later than the estimated date provided.
                                7.2 Should a delay be caused to your order, we will make reasonable efforts to notify
                                you of the delay and provide a revised estimated shipping date. We will not be liable
                                for any damage or loss caused by a delay, whether the delay is a Supplier’s fault or
                                otherwise.
                                7.3 Your Order will be delivered to your nominated shipping address. You are responsible
                                for ensuring that an authorised party is available to accept delivery of the shipment.
                                We are not responsible for any loss or damage caused, whether due to delay or due to
                                damage to the Product, caused by you failing to accept delivery.
                            </p>
                        </div>
                        <div class="tab-pane" id="otherweb" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                8 Product
                                8.1 Upon delivery of a Product, you should inspect the Product carefully. All Products
                                will be deemed accepted upon delivery to you (subject to 8.2). We will use commercially
                                reasonable efforts to manufacture the Part in accordance with your Specifications,
                                however we accept no responsibility for a manufactured Product that does not suit your
                                needs due to deficient Specifications. Subject to the following clause 8.2, we do not
                                warrant that the Product can or will be manufactured in accordance with Specifications,
                                and we do not warrant that the Product will meet your requirements or will be
                                merchantable or suitable for any particular purpose.
                                8.2 We warrant only that the Product manufactured by us will substantially meet the
                                features of the Specifications within the limitations of the required manufacturing
                                technology. If the Product is faulty on delivery, you may within one business day of
                                accepting delivery of the Product request that the Product is reviewed by us. We will
                                confirm whether you are entitled to a replacement, repair, partial or total refund, or
                                any other recourse we consider appropriate at our absolute discretion. For the avoidance
                                of doubt, we will only be responsible to provide you with any recourse if the product is
                                faulty due to an error on our behalf and if such error is not disclaimed by these Terms
                                and Conditions.
                            </p>
                        </div>
                        <div class="tab-pane" id="changeprivacy" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>9 Intellectual Property in the Design and Product
                                9.1 We do not claim any ownership over the Specifications, Design, or Product, or any
                                other content or data you upload to the Platform (Client IP). By uploading the Client IP
                                to the Platform, you grant us a non-exclusive, non-revokable, worldwide license use the
                                Client IP for the sole purposes of designing, manufacturing and delivering the Product
                                to you. You also agree that we may use the Client IP to improve the functionality of the
                                Platform, or for any other reason in keeping with our Privacy Policy, which can be found
                                here link. All Client IP is maintained and treated with strict confidence, and we ensure
                                that our third party suppliers, contractors, and stakeholders maintain the same. For the
                                avoidance of doubt, we do not purport to hold any proprietary rights in the Client IP
                                except to provide the Services.
                                9.2 By submitting an Order through the Platform, you guarantee and warrant to us that
                                you are the owner and/or you have obtained the rights necessary to grant us use of the
                                Client IP without any violation of the intellectual property rights of any third party.
                                You further represent and warrant that the client IP does not infringe upon,
                                misappropriate or violate any third party’s rights, including intellectual property
                                rights, copyrights, and any other proprietary rights. If we, in our sole discretion,
                                determine that any aspect of the Client IP infringes upon any rights of any third party,
                                we reserve the right to take any action we see fit including:
                                (a) Cancelling any Order;
                                (b) Permanently or temporarily suspending you from accessing the Platform; or
                                (c) Any other action reasonably necessary to protect our liability and remedy and such
                                infringement.</p>
                        </div>
                        <div class="tab-pane" id="contactus" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>

                            <p>
                                10 Marketplace Only
                                10.1 TV Manage provides a marketplace and software as a service through which you can
                                engage with and manage your own network of Suppliers, which includes access to our
                                network of Suppliers, and buy products or services from Suppliers (Transaction). Our
                                involvement in the Transaction is limited to that of a facilitator to the Transaction in
                                consideration of which we charge relevant Suppliers a commission payment. We reserve the
                                right to include a subscription fee for the use of TV Manage. We are not and do not
                                purport to be a Supplier, manufacturer, guarantor, or otherwise a contractual party to
                                the Transaction. We are in no way responsible for completion of the Suppliers
                                obligations and by transacting with the Supplier you assume all legal and transaction
                                risk in dealing with the Supplier.
                                10.2 Our involvement in the Transaction is limited to the following capacity:
                                (a) Connecting you with Suppliers via TV Manage;
                                (b) Facilitating the Transaction by allowing the Supplier and you to engage through TV
                                Manage and facilitate all steps of the Transaction; and
                                (c) Collecting payment from the Buyer and remitting it to the Seller.
                                10.3 When you engage a Supplier through TV Manage, you are entering a legally binding
                                contract with the Supplier. We are not a party to this contract and are not responsible
                                for the performance of your or the Supplier’s obligations under the contract.
                                10.4 Suppliers may have their own terms and conditions, standard form contracts, or
                                other agreements that you may be required to adhere to in engaging them (Supplier
                                Terms). When
                                6
                                this is the case, the Transaction is subject to the Supplier Terms which you should
                                review carefully before entering the Transaction.
                                10.5 Where a Supplier does not have any Supplier Terms, the Transaction will be subject
                                to terms and conditions implied at law, and implied by your conduct and exchanges with
                                the Supplier. Please make yourself aware of such implied conditions before entering the
                                Transaction.
                                10.6 By using TV Manage, you fully release and discharge us from any claims, actions,
                                demands or proceedings which may arise from a Transaction. You agree that we are in no
                                way liable for any obligation arising from a Transaction, including but not limited to:
                                (a) Delivery or performance of a product or service;
                                (b) The quality or standard of a product or service;
                                (c) Reimbursement, compensation, or damages arising from a product or service;
                                (d) Product or service warranties provided by the Supplier; or
                                (e) Product or service warranties and obligations placed on the Supplier of a product or
                                service under a relevant law.
                            </p>
                        </div>

                        <div class="tab-pane" id="eleven" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>

                            <p>

                                11 Pricing and Payment
                                11.1 Weuse a third-party service provider for provision of the payment service at
                                check-out and we are not liable for any issues, including charges from your financial
                                institution, which may arise as a result of this service.
                                11.2 You acknowledge that the credit card, debit card or payment method provided has
                                been verified against a recent bank statement to confirm accuracy of the details
                                provided.
                                11.3 You acknowledge that you have authority to utilise the nominated credit card, debit
                                card or payment method for the purpose of paying for the Transaction.
                                11.4 You acknowledge that it is your responsibility to ensure that there are sufficient
                                cleared funds or financial capacity in the nominated account to honour the transaction
                                when it falls due.
                                11.5 We will not be held responsible for any fees and charges imposed by your financial
                                institution or any other third party that may be incurred if there are insufficient
                                funds in your account or the payment fails.
                                11.6 Charges associated with delivery of orders will be calculated at the time of
                                processing the transaction and will form part of the price of the Transaction.
                                11.7 When you place an order on the Platform, the Order will be submitted to the
                                relevant Supplier for consideration. The Supplier may either accept the Order, or
                                provide you with a quote for your consideration. Once the Supplier accepts the Order, or
                                you accept the Quote, you and the Supplier have entered into a Transaction. No fee will
                                be payable until a Transaction is entered in to. The Supplier will accept or reject the
                                order at its own discretion, and we assume no liability and make no warranty or
                                guarantee that the order will be accepted by the Supplier.
                                11.8 When you enter into a Transaction on the Platform, payment for the Transaction will
                                be accepted and processed by our nominated third-party payment processor. Confirmation
                                will be sent to the email address supplied in your Client Data and payment will be
                                remitted to the Supplier.
                                7
                                11.9 The Transaction will not be processed until payment is received in full by us or
                                our nominated third party (including any applicable delivery fees and other charges).
                                11.10 In the case of large Orders, we may, at our discretion, allow for the payment to
                                be made in instalments. When we allow for the payment to be made in instalments, the
                                Price will be payable according to the instalment payment instructions provided through
                                the Platform.
                                11.11 All orders placed are final and you cannot change or cancel your order, unless:
                                (a) Such change or cancellation is otherwise required by law;
                                (b) Such change or cancellation has been agreed to in writing with the relevant
                                Supplier; or
                                (c) Such change or cancellation is allowed for in these Terms and Conditions.
                                11.12 It is the Supplier’s responsibility to issue with you a GST compliant invoice.
                                11.13 If the Supplier cancels an order that has already been processed, or otherwise
                                fails to deliver your order whether in full or in part, you agree that any complaint,
                                demand for refund or remedy, legal action, or action otherwise is to be taken against
                                the Supplier directly and you fully release and discharge us from any such action. To
                                the maximum extent permitted by law, we will not be liable to you for any loss, whether
                                liquidated or otherwise, associated with you entering into a Transaction with a
                                Supplier.
                            </p>
                        </div>
                        <div class="tab-pane" id="twelve" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                12 Supplier Products and Services
                                12.1 Products and services advertised and quoted by Suppliers on TV Manage are listed by
                                the relevant Supplier. You agree that to the extent permitted by law, we will not be
                                held responsible for inaccuracies or errors pertaining to products or services listed or
                                quoted by a Supplier, including but not limited to:
                                (a) Price;
                                (b) Availability; or
                                (c) Quality or suitability.
                                12.2 You acknowledge and understand that we are not acting as Supplier and accept no
                                responsibilities placed on Suppliers under the law or otherwise.
                                12.3 You agree that you will make your own enquiries to assess the suitability of a
                                product or service listed prior to entering into a Transaction to purchase or pay for
                                the product or service.
                                12.4 You agree and accept that we make no warranty or guarantee as to the quality or
                                suitability of a product or service listed on TV Manage by a Supplier.
                                12.5 You agree that a listing on the Platform for a product or service does not
                                constitute an offer to sell or provide that product or service and is merely information
                                published on the Platform advertising the product or service.
                            </p>
                        </div>
                        <div class="tab-pane" id="thriteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                13 Delivery and Performance
                                13.1 The Supplier is responsible for the delivery of a product or performance of a
                                service the subject of a Transaction. We are not responsible and accept no
                                responsibility for the delivery of a product or performance of a service the subject of
                                a Transaction.
                                13.2 Suppliers are responsible for organising delivery and may charge different rates.
                                You are responsible for confirming the fees associated with delivery prior to processing
                                your order. You are not entitled to a refund after an order has been processed if you
                                are unhappy with the delivery charges.
                            </p>
                        </div>
                        <div class="tab-pane" id="fourteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                14 Refunds and Returns
                                14.1 Notwithstanding any other provision of these Terms and Conditions, we are in no way
                                required
                                to refund any money spent in a Transaction.
                                14.2 Suppliers may have their own policies and procedures in relation to returns. We
                                make no guarantee and do not warrant in any way the policies and procedures of
                                individual Suppliers in relation to returns. Where you wish to return an item, we are
                                under no obligation to help facilitate that return.
                                14.3 All products and services listed by Suppliers may come with guarantees that cannot
                                be excluded under applicable laws. Any claims under applicable consumer laws with
                                respect to a product or service are against the Supplier only and you agree and fully
                                release and discharge us from any liability, claim, or action against the supply or
                                delivery of a service or product under an applicable consumer law.
                                14.4 Products you purchase from Suppliers on TV Manage may come with warranty documents.
                                Any such warranty is provided by the Supplier only and is not given by us.
                            </p>
                        </div>
                        <div class="tab-pane" id="fifteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                15 Intellectual Property in the Platform
                                15.1 Nothing in these Terms and Conditions or otherwise creates an assignment of any
                                rights in the
                                Platform beyond the ability to use it for its intended use.
                                15.2 Nothing in these Terms and Conditions or otherwise creates the right for you to
                                sublicense the Platform.
                                15.3 By accepting these Terms and Conditions you undertake not to reverse engineer,
                                replicate, or otherwise copy the Platform. We reserve all our rights to seek
                                compensation, damages, injunctions, or any other remedy available to us at law if any
                                attempt to do so, whether successful or unsuccessful, is made by you, your business or
                                its members, employees, officers, affiliates, customers, or anyone else.
                                15.4 All intellectual property and ownership rights subsisting in the Platform
                                (including the user interface and the software comprising the Platform), the data
                                derived from use of the Platform, and any other documentation, information or materials
                                that are supplied by the Platform to you, remain our or our third party licensors
                                exclusive property. For the avoidance of doubt, nothing in these Terms and Conditions
                                assigns any ownership rights to you.
                            </p>
                        </div>
                        <div class="tab-pane" id="sixteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                16 Operation of the Platform
                                16.1 As a user of the Platform you warrant that you will comply fully with all of our
                                operating
                                instructions. Any failure to comply fully with operating instructions may result in the
                                sub-par
                                9
                                or faulty performance of the Platform.
                                16.2 We accept no liability, and you release us from any such liability, associated with
                                faults or errors caused by your non-compliance with all operating instructions.
                            </p>
                        </div>
                        <div class="tab-pane" id="seventeen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>

                                17 Modifications to the Platform
                                17.1 We reserve the right to modify or discontinue, temporarily or permanently the
                                Platform and its
                                products and services with or without notice.
                                17.2 We accept no liability if any such modification or discontinuance causes any loss
                                or damage to you or causes any disruptions to your use of the Platform.
                            </p>
                        </div>
                        <div class="tab-pane" id="eighteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                18 Advertising
                                18.1 We may display advertisements by third party sponsors on the Platform (Ads). In
                                doing so, we do not warrant the functionality of the business, product, or service being
                                advertised in the Ad. We do not hold ourselves out as partners, affiliates, guarantors,
                                or has having any other type of legal or professional relationship with the third party
                                sponsors.
                                18.2 Where Ads link to third party websites, we do not warrant the safety,
                                functionality, or suitability of these websites. We are in no way liable for any loss,
                                damage, risk or liability incurred from you visiting any third party websites accessible
                                through Ads.
                                18.3 Advertised third party websites may be subject to their own terms and conditions.
                                We do not warrant the validity or suitability of these third party terms and conditions.
                            </p>

                        </div>
                        <div class="tab-pane" id="nineteen" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                19 Warranties
                                19.1 By using the Platform, you represent and warrant to use that we will not be liable
                                for any direct or indirect expense, damage or injury (including indirect loss of
                                revenue, profits, goodwill or business opportunity) howsoever caused, including arising
                                directly or indirectly from or in connection with your use of the Platform.
                                19.2 Subject to any of the rights you may have under consumer protection laws, we
                                exclude to the fullest extent possible all implied terms and warranties, whether
                                statutory or otherwise, relating to the Platform or any other matter under these Terms
                                and Conditions.
                            </p>

                        </div>
                        <div class="tab-pane" id="tewnty" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                20 Limitation of liability
                                20.1 The Platform is made available to you on an “as is” basis. We make no warranties,
                                representations or conditions of any kind, express, statutory or implied as to:
                                (a) The operation and functionality of the Platform;
                                (b) the accuracy, integrity, completeness, quality, legality, usefulness, safety and IP
                                rights of any of the Platform content; and/or
                                (c) the products and services associated with the Platform or its content. We further
                                disclaim all warranties, express, statutory or implied, including, but not limited to,
                                implied warranties of merchantability, merchantable quality, durability, fitness for a
                                particular purpose and/or non-infringement. No advice or information, whether oral or
                                written, that you obtain from us shall create any warranty, representation or condition
                                not expressly stated herein.
                                20.2 We disclaim all liability for any:
                                (a) indirect, special, incidental, punitive, exemplary, reliance or consequential
                                damages;
                                10
                                (b) loss of profits;
                                (c) business interruption;
                                (d) network interruptions;
                                (e) loss or damage to reputation of us or any third party; or
                                (f) loss of information or data. We further disclaim all liability for any loss or
                                damage arising out of your use of the Platform.
                                20.3 Your use of the Platform is at your own discretion and risk, and you will be solely
                                responsible for any resulting loss or damage, including but not limited to, any loss of
                                data or damage to your computer(s) or networks from viruses that may be downloaded to
                                your computer in the course of using the Platform. Some jurisdictions do not allow the
                                exclusion of certain warranties, the limitation or exclusion of implied warranties, or
                                limitations on how long an implied warranty may last, so the above limitations may not
                                apply to you. If you reside in such a jurisdiction, the above limitations shall apply to
                                you to the fullest extent permitted under applicable law.
                            </p>
                        </div>
                        <div class="tab-pane" id="twentyone" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                21 Indemnity
                                21.1 You agree to defend, indemnify and hold harmless us, our members, managers,
                                subsidiaries, affiliates, any related companies, suppliers, licensors and partners, and
                                the officers, directors, employees, agents and representatives of each of them,
                                including costs, liabilities and legal fees, from any claim or demand made by any third
                                party due to or arising out of:
                                (a) Your access to or use of the Platform;
                                (b) Your violation of these Terms and Conditions;
                                (c) Any infringement by you of any intellectual property or other right of any person or
                                entity. We reserve the right, at your expense, to assume the exclusive defence and
                                control of any matter for which you are required to indemnify us and you agree to
                                cooperate with our defence of these claims. You agree not to settle any matter without
                                our prior written consent. We will use reasonable efforts to notify you of any such
                                claim, action or proceeding upon becoming aware of it.
                            </p>

                        </div>
                        <div class="tab-pane" id="twentytwo" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                22 General
                                22.1 To the extent permitted by law, we reserve the right to create, collect and / or
                                store any metadata resulting from your use of the Platform. To the extent permitted by
                                law, we may use metadata for a variety of functions including (but not limited to)
                                conducting investigations, helping us manage and administer the Platform, for purposes
                                of search engine optimisation and to drive traffic to the Platform. To the extent
                                permitted by law, we reserve the right to share your information (including metadata)
                                with government agencies and authorities to the extent this is required or requested of
                                us.
                                22.2 You understand that we use third party vendors and hosting partners to provide the
                                necessary hardware, software, networking, storage, and related technology required to
                                run the Platform.
                                22.3 You must not modify, adapt or hack the Platform or modify another website or
                                software so as to falsely imply that it is associated with the Platform or us.
                                22.4 You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of
                                the Platform, use of the Platform, or access to the Platform without our prior written
                                express permission. We claim copyright and all other intellectual property rights over
                                the Platform and its other products and services and reserves all of our rights.
                                11
                                22.5 Verbal, physical, written or other abuse (including threats of abuse or
                                retribution) of any of our employees, members, representatives, or officers may result
                                in you being banned from the Platform.
                                22.6 We do not warrant that:
                                (a) The Platform will meet your specific requirements;
                                (b) The Platform will be uninterrupted, timely, secure, or error-free;
                                (c) The content that may be obtained from the use of the Platform will be accurate or
                                reliable;
                                (d) Any errors in the Platform will be corrected.
                                22.7 You expressly understand, agree and warrant that we shall not be liable for any
                                direct, indirect, incidental, special, consequential or exemplary damages, including but
                                not limited to, damages for loss of profits, goodwill, use, data or other intangible
                                losses (even if we have been advised of the possibility of such damages), resulting
                                from:
                                (a) the use or the inability to use the Platform;
                                (b) unauthorised access to or alteration of your transmissions or data;
                                (c) or any other matter relating to the Platform.
                                22.8 Our failure to exercise or enforce any right or provision of these Terms and
                                Conditions shall not constitute a waiver of such right or provision. The Terms and
                                Conditions constitutes the entire agreement between you and us and governs your use of
                                the Platform, superseding any prior agreements between you and us.
                                22.9 Any part of these Terms and Conditions that is invalid, prohibited or not
                                enforceable will not void the balance of the Terms and Conditions. All other parts will
                                be enforced without regard to the invalid provision and continue to be valid in
                                accordance with their terms.
                                22.10 We make no representations or warranties that the content of the Platform complies
                                with the laws (including intellectual property laws) of your jurisdiction.
                                22.11 Any queries about the Terms and Conditions should be send to us by email at
                                [care@truventor.ai].
                                22.12 These Terms and Conditions are governed by the laws of the State of Victoria,
                                Australia and you agree to be bound by the exclusive jurisdiction of the courts of
                                Victoria.
                            </p>

                        </div>

                    </div>


                </div>
            </div>
        </div>

        <div class="d-block d-md-none">
            <div class="row">
                <div class="col">

                    <div class="mb-5">
                        <h4>
                            Information We Collect
                        </h4>
                        <hr>
                        <p>
                            The following are terms and conditions of a legal agreement between you and Truventor AI &
                            Robotics PVT LTD and its affiliates (collectively, “Truventor” or “our service partners”).
                            These terms and conditions (collectively with Truventor’s “Privay Policy” and the “Terms and
                            Conditions”) govern your use of www.truventor.ai (the “Truventor Website” or “Website”), and
                            the services, features, and content we offer (together with the Website and the
                            Applications, “Truventor Services” or “Services”).
                            By using the Services, you acknowledge you have read, understood and agree to be bound by
                            these Terms and Conditions and to comply with all applicable laws and regulations. If you do
                            not agree with these Terms and Conditions, you should not use the Website, Applications, or
                            Services.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Truventor Services
                        </h4>
                        <hr>
                        <p>
                            The Truventor Services are made up of different services provided through the Website. One
                            of our Services enables you to upload a 3D file (a “product”) to our Website and view it in
                            3D.
                            <br>
                            If you want to manufacture your 3D Model, you can place an order with us and we will
                            manufacture it for you at your direction. At a charge of a service price and price for the
                            substance used for the 3D Model, we manufacture the 3D Model for you in available materials
                            and ship it to you.
                            <br>
                            Further, Truventor offers other services and community features through the Website
                            including a forum, a blog, tutorials etc. where you can get in touch with other users or
                            share your experiences
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Account Terms
                        </h4>
                        <hr>
                        <p>
                            You represent and warrant that you are at least 10 years of age to use this Service. If you
                            are under 18 years of age, you may use Truventor’s website only with involvement of a parent
                            or guardian. We may, in our sole discretion, refuse to offer the to any person or entity and
                            change its eligibility criteria at any time. You are solely responsible for ensuring that
                            these Terms and Conditions are in compliance with all laws, rules and regulations applicable
                            to you and the right to access the Services is revoked where these Terms and Conditions or
                            use of the Services is prohibited or to the extent offering, sale or provision of the
                            Services conflicts with any applicable law, rule or regulation. Further, the Services are
                            offered only for your use, and not for the use or benefit of any third party.
                            To use some Services, you must register for an account on the Services (an “Account”). You
                            must provide current, accurate identification, contact, and other information that may be
                            required as part of
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Truventor Services
                        </h4>
                        <hr>
                        <p>
                            The Truventor Services are made up of different services provided through the Website. One
                            of our Services enables you to upload a 3D file (a “product”) to our Website and view it in
                            3D.
                            If you want to manufacture your 3D Model, you can place an order with us and we will
                            manufacture it for you at your direction. At a charge of a service price and price for the
                            substance used for the 3D Model, we manufacture the 3D Model for you in available materials
                            and ship it to you.
                            <br>
                            Further, Truventor offers other services and community features through the Website
                            including a forum, a blog, tutorials etc. where you can get in touch with other users or
                            share your experiences.the Account registration process and/or continued use of the Service,
                            and you must keep your Account information updated. You shall not:
                            <br>

                            (i) select or use as a username a name of another person with the intent to impersonate that
                            person;
                            <br>
                            (ii) use as a username a name subject to any rights of a person other than you without
                            appropriate authorization; or
                            <br>
                            (iii) use, as a username, a name that is otherwise offensive, vulgar or obscene. You are
                            responsible for maintaining the confidentiality of your Service password and Account, and
                            are solely responsible for all activities that occur on your Account.
                            You must notify us immediately of any change in your eligibility to use the Services, breach
                            of security or unauthorized use of your Account. You should never publish, distribute or
                            post login information for your Account. We will be liable for any loss or damage from your
                            failure to maintain the security of your Account and password. We reserve the right to
                            refuse service to or terminate service of anyone at any time without notice for any reason.
                            You shall have the ability to delete your Account, either directly or through a request made
                            to us at care@truventor.ai
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Ordering
                        </h4>
                        <hr>
                        <p>
                            (a) Placing an order. To order a manufacturing of 3D CAD data through our website, we
                            request you to upload the design online. Truventor owns no obligation to check the source of
                            the file uploaded on website and we will not be responsible to further check the
                            authenticity of files. We presume the file uploaded by you is proprietary of you or you own
                            rights to make a copy of it for personal / commercial use. Once the files are uploaded on
                            our secured server, it further hits a third party secured server for repairing, data
                            extraction and 3D printability check. The information helps in calculating instant timelines
                            and costing of parts. Then you can select the material you want the product to be made of.
                            As the next step, we ask you to provide us with information we need for the delivery of the
                            product. Finally you will be prompted for information required to initiate the payment or
                            pay later. Depending on the payment method you select you may be redirected to a third party
                            payment provider website. Please be informed that the language to be used during the whole
                            ordering process is English.
                            <br>
                            <br>
                            (b) Unacceptable orders, cancellation of orders by us. If a product is available through our
                            Website, the default assumption is that it can be produced. There are, however, technical or
                            other reasons why Truventor may not accept or may cancel your order during the entire
                            ordering process, even after our acceptance of your order. Such reasons can be, for example,
                            but not limited to, the unfeasibility of the model, an infringing feature of your product,
                            unavailability of the manufacturing partner or the destination of the delivery you indicated
                            in your order. In case we cancel your partial or entire order, we will contact you via email
                            and, at Truventor’ sole and exclusive discretion, we may issue you store credit or a full
                            refund for the cancelled item(s).
                            <br>
                            <br>
                            (c) Prices. The prices displayed on the Website are only indications and are valid only for
                            the moment that they are displayed. Truventor reserves the right to change its prices on the
                            website at any time without prior notice to you. Such change however will have no effect on
                            orders completed before the posting of a new price on the website. We do our best to prevent
                            errors in the pricing. However, should an error occur, we are not bound by our offer and may
                            cancel the order. In the event of an error, any monies paid related to that order will be
                            refunded to you.
                            <br>
                            <br>
                            (d) Payment and price. The amount indicated on the ordering page includes the fee for the
                            service (such as printing, finishing and packing the model), the price for the materials
                            used to produce the product, and, if applicable, the markup charged by the designer. The
                            price quoted during the payment checkout includes shipping costs, sales tax (as applicable)
                            but excludes any import duty or taxes (if applicable). You can initiate your payment for
                            your order via credit card, debit card, or bank transfer. In case you choose to pay by bank
                            transfer you will receive the payment instructions via email from Truventor after
                            confirmation of your order.
                            <br>
                            <br>
                            For orders paid for via credit card, Truventor will begin production after authorizing and
                            holding payment. For orders paid for via bank transfer, Truventor will begin production
                            after the funds arrive in the Truventor bank account.
                            <br>
                            <br>
                            Truventor WILL NOT BE RESPONSIBLE FOR ANY PAYMENT FAILURE HOSTED BY THIRD PARTY PAYMENT
                            GATEWAYS.
                            <br>
                            <br>
                            (e) Acceptance. After accepting your order we will send you an order confirmation by e-mail
                            address associated with your Truventor Account. The order confirmation will be effective as
                            of the date on which the order confirmation is sent to you. If we cannot accept your order
                            we will contact you by email. You should verify the details in the confirmation email. If
                            there are errors you should contact us as soon as possible by sending an email to customer
                            service.
                            <br>
                            <br>
                            (g) Shipping. Your order will be delivered to your shipping address. We will use our
                            commercially reasonable efforts to ship the product to the shipping address within the
                            delivery time indicated in our confirmation email; nevertheless, depending on your shipping
                            address it may take longer time. Any shipping and delivery dates given by us are only best
                            estimates and we are not liable for any damage or cost caused by delay in shipping or
                            delivery. We take every to ensure your product is well packed before it is dispatched. We
                            take extreme measures to ensure that your order arrives in pristine condition. However due
                            to the delicate nature of these products, in rare circumstances items can arrive damaged. We
                            are unable to take responsibility for any loss or damages of products in transit. But we
                            will make contact with the relevant courier to track your package or initiate a claim for
                            lost products. Title and risk of loss is transferred to you upon transfer of the goods from
                            Truventor to the shipment carrier (FOB Shipping Point, EX Works).
                            <br>
                            <br>
                            (h) Return and Cancellation of order. Due to the personalized feature of our Services (i.e.
                            the product is supplied by us based on your specifications), you cannot cancel your order
                            after it goes into production. Additionally, we are not able to refund purchases of a
                            "discount coupon" or "any other discounted product" at any point.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Warranty & Disclaimer
                        </h4>
                        <hr>
                        <p>
                            Truventor warrants only that the model manufactured by us will substantially meet the
                            features of the indicated products within the limitations of the 3D printing technology.
                            Truventor recommends but does not give any warranty about the products themselves and does
                            not guarantee that the product will be fit for any particular purposes. Please inspect the
                            product upon receipt immediately. If the product is faulty when you received it and you
                            believe you are entitled to a replacement in accordance with the warranty above, please
                            contact us within 1 day after receipt of the product. Such warranty claim can be made only
                            within 1 day after receipt of the model by you. Do not return the product unless we require
                            you to do so. We will confirm whether you are entitled to a replacement or refund.
                            Transportation costs in respect of a faulty product will be borne by us.
                            We have no special relationship with or fiduciary duty to you. You acknowledge that we have
                            no duty to take any action regarding:
                            <br>
                            (a) which users gain access to the Services
                            <br>
                            (b) what content you access via the Services or
                            <br>
                            (c) how you may interpret or use the content.
                            <br>
                            THE SERVICES AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY
                            KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
                            NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES
                            IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
                            DISCLAIMED. WE, AND OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT
                            PROVIDERS DO NOT WARRANT THAT:
                            <br>
                            (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION
                            <br>
                            (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED
                            <br>
                            (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF VIRUSES OR
                            OTHER HARMFUL COMPONENTS OR
                            <br>
                            (IV) THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS. YOUR USE
                            OF THE SERVICES IS SOLELY AT YOUR OWN RISK. SOME STATES DO NOT ALLOW LIMITATIONS ON IMPLIED
                            WARRANTIES, SO THE FOREGOING LIMITATIONS MAY NOT APPLY TO YOU.
                            <br>
                            PLEASE NOTE THAT, UNLESS OTHERWISE STATED IN THE DESCRIPTION FOR A SPECIFIC METERIAL, THE
                            MATERIALS WE USE FOR MANUFACTURING THE PRODUCT MAKE THE PRODUCT SUITABLE ONLY FOR DECORATIVE
                            PURPOSES AND THEY ARE NOT SUITED FOR ANY OTHER PURPOSE. THE PRODUCTS ARE NOT SUITED TO BE
                            USED AS TOYS, OR TO BE GIVEN TO CHILDREN. THE PRODUCTS SHOULD NOT COME IN CONTACT WITH
                            ELECTRICITY OR FOOD OR LIQUIDS AND SHOULD BE KEPT AWAY FROM HEAT UNLESS MENTIONED OTHERWISE.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Use of the Truventor Website
                        </h4>
                        <hr>
                        <p>
                            Copyright and all other proprietary rights in the content of the Website and the Services
                            (including but not limited to software, audio, video, text and photographs and excluding all
                            User Generated Content) rests with Truventor or its licensors. All rights in the content not
                            expressly granted herein are reserved. the content published on this Website are not allowed
                            to be reproduced or distributed in unmodified form for personal, non-commercial use, unless
                            provided that it is attributed to Truventor. Any other use of the content, including without
                            limitation distribution, reproduction, modification, display or transmission without the
                            prior written consent of Truventor is unlicensed by Truventor. You also agree that you will
                            not use any robot, spider, other automated device, or manual process to monitor or copy any
                            content from the Service. Our rights include rights to (i) the Service developed and
                            provided by us; and (ii) all software associated with the Service. All copyright and other
                            proprietary notices shall be retained on all reproductions.
                            <br>
                            <br>
                            This Website may provide links to other websites that are not under the control of
                            Truventor. Truventor shall not be responsible in any way for the content of such other
                            websites. Truventor provides such links only as a convenience to you, and the inclusion of
                            any link to any such websites does not imply endorsement by Truventor of the content of such
                            websites.
                            <br>
                            <br>
                            Since the Truventor Service is a web based service, it might be subject to temporary
                            downtime. From time to time we also update our Website, which will result in the Website not
                            being available for a certain period of time. We will endeavor to ensure that the Website
                            operates reliably and consistently, but we do not warrant that the Website will operate
                            uninterrupted without any faults. We do not guarantee that any content will be made
                            available on the Website or through the Services. We reserve the right to, but do not have
                            any obligation to, (i) remove, edit or modify any content in our sole discretion, at any
                            time, without notice to you and for any reason (including, but not limited to, upon receipt
                            of claims or allegations from third parties or authorities relating to such content or if we
                            are concerned that you may have violated these Terms and Conditions), or for no reason at
                            all and (ii) to remove or block any from the Services.
                            <br>
                            <br>
                            We are not responsible for any losses suffered by you as a result of any interruption of the
                            Website, suspension of your access to the Website.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            User Generated Content
                        </h4>
                        <hr>
                        <p>
                            The term “User Generated Content” when used in this Terms and Conditions shall mean any
                            content uploaded to the Website, the Application or the Services by you, including but not
                            limited to all uploaded 3D Models, pictures of 3D Models and comments on the Website. All
                            User Generated Content should not be or contain abusive, harassing, threatening, defamatory,
                            obscene, fraudulent, deceptive, misleading, offensive, pornographic, illegal or unlawful
                            information or be likely to infringe or infringing on any intellectual property rights.
                            Intellectual property rights means copyright, patent, registered design, design right,
                            trademark, trade secret or any other proprietary or industrial right.
                            <br>
                            Truventor has no obligation to review the User Generated Content and can in no way be held
                            responsible for the content of the User Generated Content. Opinions expressed in the User
                            Generated Content are not necessarily the opinion of Truventor.
                            <br>
                            Except for your 3D Models, which are referenced below, by submitting User Generated Content
                            through the Services or Website, you hereby do and shall grant us a worldwide,
                            non-exclusive, royalty-free, fully paid, sublicensable and transferable license to use,
                            edit, modify, reproduce, distribute, prepare derivative works of, display, perform, and
                            otherwise fully exploit the User Generated Content in connection with the Website, the
                            Applications, the Services and our (and our successors’ and assigns’) businesses, including
                            without limitation for promoting and redistributing part or all of the Website, the
                            Applications or the Services (and derivative works thereof) in any media formats and through
                            any media channels (including, without limitation, third party websites and feeds). You also
                            hereby do and shall grant each user of the Website, the Applications, or the Services a
                            non-exclusive license to access your User Generated Content through the Website, the
                            Applications or the Services, and share your User Generated Content on blogs and social
                            media, provided such sharing prominently links back to the model page on the Truventor site
                            and is not done on site that competes directly or indirectly with Truventor Services. For
                            clarity, the foregoing license grants to us and our users does not affect your other
                            ownership or license rights in your User Generated Content, including the right to grant
                            additional licenses to your User Generated Content, unless otherwise agreed in writing. You
                            represent and warrant that you have all rights to grant such licenses to us without
                            infringement or violation of any third party rights, including without limitation, any
                            privacy rights, publicity rights, copyrights, contract rights, or any other intellectual
                            property or proprietary rights.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Intellectual Property Rights of 3D Designs
                        </h4>
                        <hr>
                        <p>
                            You, as a designer, retain all your intellectual property rights in your 3D Models,
                            including without limitation any and all derivative works like 3D renders. Except for the
                            rights and licenses with respect to your 3D Models specified below, Truventor shall not use,
                            modify or display your 3D Models or derivatives thereof unless exclusively discussed and
                            agreed upon. By uploading your 3D Models to the Services, you represent and warrant that it
                            is your original creation and not copied from any third party and/or entity. You further
                            represent and warrant that you have all rights to grant the below licenses to us without
                            infringement or violation of any third party rights, including without limitation, any
                            privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other
                            intellectual property or proprietary rights.
                            <br>
                            By uploading your 3D Model to the Services, you grant Truventor a non-exclusive, royalty
                            free, worldwide, transferable, and sublicensable right and license (i) to use your 3D Model
                            for the manufacturing of your 3D Model by in house capacity or manufacturing partners in
                            order to fulfill your order; (ii) to generate and display 3D renders of your 3D Model; and
                            (iii) to use the 3D Model as necessary for the operation and maintenance of Truventor
                            Services including without limitation for the internal testing and educational purposes of
                            Truventor and Truventor manufacturing partners.(iv) Use the 3D model for marketing and
                            promotion of Truventor In all ways but not limited to website, flyers, brochures, public
                            events etc.
                            <br>
                            Except for your 3D Models, which are referenced below, by submitting User Generated Content
                            through the Services or Website, you hereby do and shall grant us a worldwide,
                            non-exclusive, royalty-free, fully paid, sublicensable and transferable license to use,
                            edit, modify, reproduce, distribute, prepare derivative works of, display, perform, and
                            otherwise fully exploit the User Generated Content in connection with the Website, the
                            Applications, the Services and our (and our successors’ and assigns’) businesses, including
                            without limitation for promoting and redistributing part or all of the Website, the
                            Applications or the Services (and derivative works thereof) in any media formats and through
                            any media channels (including, without limitation, third party websites and feeds). You also
                            hereby do and shall grant each user of the Website, the Applications, or the Services a
                            non-exclusive license to access your User Generated Content through the Website, the
                            Applications or the Services, and share your User Generated Content on blogs and social
                            media, provided such sharing prominently links back to the model page on the Truventor site
                            and is not done on site that competes directly or indirectly with Truventor Services. For
                            clarity, the foregoing license grants to us and our users does not affect your other
                            ownership or license rights in your User Generated Content, including the right to grant
                            additional licenses to your User Generated Content, unless otherwise agreed in writing. You
                            represent and warrant that you have all rights to grant such licenses to us without
                            infringement or violation of any third party rights, including without limitation, any
                            privacy rights, publicity rights, copyrights, contract rights, or any other intellectual
                            property or proprietary rights.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Indemnification
                        </h4>
                        <hr>
                        <p>
                            You shall defend, indemnify, and hold harmless us, our affiliates and each of our and their
                            respective employees, contractors, directors, suppliers and representatives from all
                            liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or
                            relate to your use or misuse of, or access to, the Services, content, or otherwise from your
                            User Generated Content, products, , violation of these Terms and Conditions, or infringement
                            by you, or any third party using your Account or identity in the Services, of any
                            intellectual property or other right of any person or entity. We reserve the right to assume
                            the exclusive defense and control of any matter otherwise subject to indemnification by you,
                            in which event you will assist and cooperate with us in asserting any available defenses.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Limitation of Liability
                        </h4>
                        <hr>
                        <p>
                            IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, OR SUPPLIERS, BE
                            LIABLE UNDER ANY CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR
                            EQUITABLE THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY LOSS OF INCOME, LOSS OF PROFITS,
                            LOSS OF CONTRACTS, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR FOR
                            ANY INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL LOSS
                            OR DAMAGE OF ANY KIND (HOWSOEVER ARISING) , (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR
                            THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN
                            EXCESS OF (IN THE AGGREGATE) THE GREATER OF (A) THE FEE RECEIVED FROM YOU BY Truventor FOR
                            THE USE OF THE SERVICES DURING THE IMMEDIATELY PRECEDING THREE (3) MONTHS PERIOD OR (B) INR
                            5000.00.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Applicable Law
                        </h4>
                        <hr>
                        <p>
                            These Terms of Service shall be governed by the laws of Mahrashtra, India without regard to
                            the conflicts of law principles thereof that would apply the law of any jurisdiction other
                            than Maharashtra, India. You agree that any and all disputes arising from or relating to the
                            subject matter of these Terms of Service, including disputes relating to the validity
                            thereof, and any disputes related to the use of the Services, shall be governed by the
                            exclusive jurisdiction and venue of the state courts located Pune, Maharashtra, India.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Miscellaneous
                        </h4>
                        <hr>
                        <p>
                            (a) Force Majeure. We shall not be liable for any failure to perform our obligations
                            hereunder where such failure results from any cause beyond our reasonable control,
                            including, without limitation, mechanical, electronic or communications failure or
                            degradation.
                            <br>
                            <br>
                            (b) Assignment. These Terms of Service are personal to you, and are not assignable,
                            transferable or sublicensable by you except with our prior written consent. We may assign,
                            transfer or delegate any of our rights and obligations hereunder without consent.
                            <br>
                            <br>
                            (c) Agency. No agency, partnership, joint venture, or employment relationship is created as
                            a result of these Terms of Service and neither party has any authority of any kind to bind
                            the other in any respect.
                            <br>
                            <br>
                            (d) Notices. Unless otherwise specified in these Term, all notices under these Terms of
                            Service will be in writing and will be deemed to have been duly given when received, if
                            personally delivered or sent by certified or registered mail, return receipt requested; when
                            receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the day after
                            it is sent, if sent for next day delivery by recognized overnight delivery service.
                            Electronic notices should be sent to care@truventor.ai
                            <br>
                            <br>
                            (e) No Waiver. Our failure to enforce any part of these Terms shall not constitute a waiver
                            of our right to later enforce that or any other part of these Terms. Waiver of compliance in
                            any particular instance does not mean that we will waive compliance in the future. In order
                            for any waiver of compliance with these Terms to be binding, we must provide you with
                            written notice of such waiver through one of our authorized representatives.
                            <br>
                            <br>
                            (f) Headings. The section and paragraph headings in these Terms are for convenience only and
                            shall not affect their interpretation.
                            <br>
                            <br>
                            (g) Feedback. Since we always want to further improve our Services, we welcome all comments,
                            suggestions, recommendations and feedback (collectively, the “Feedback”). You hereby grant
                            to us a world-wide, royalty free, irrevocable, perpetual license to use and otherwise
                            incorporate any Feedback in connection with the Services.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Contact Information
                        </h4>
                        <hr>
                        <p>
                            If you have any questions regarding these Terms and Conditions or the Services, you can
                            contact us by sending an email at care@truventor.ai
                            <br>
                            SHOULD YOU EXPERIENCE ANY PROBLEMS, PLEASE CONTACT US AND WE WILL DO OUR BEST TO FIX IT AS
                            SOON AS POSSIBLE. Truventor MAY CONTACT YOU TO ASK YOU ABOUT YOUR EXPERIENCES.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Content Policy
                        </h4>
                        <hr>
                        <p>
                            Our goal is to let the Truventor community be as free as possible in sharing their
                            creativity with others. We want to be an inspiring place, and a free flow of ideas, designs,
                            and conversation is an essential element of this. So is striving to be nice, civil and
                            helpful. In light of that, we have a few common sense rules that we ask you to keep in mind.
                            These especially relate to copyrights and keeping Truventor appropriate for a general
                            audience. Users of the Truventor Service are responsible for making sure their content
                            complies with this Content Policy.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Copyrights
                        </h4>
                        <hr>
                        <p>
                            As specified in our terms & conditions, we expect that the creator of a 3D design hold the
                            copyrights to the content. Hence, please only upload your own original work, work that is
                            freely available through a Creative Commons license, or work that does not infringe on the
                            rights of another copyright holder.
                            Be nice! This sounds simple but lots of mistakes happen when people ignore this simple rule.
                            We would like everyone to make whatever they like, as 3D printing is a technology that
                            enables innovation and the possibility to make completely new things is unlimited. However,
                            we do take copyright seriously and are legally obliged to respond to any copyright
                            infringement notices we receive.
                            <br>
                            <br>
                            Please do not infringe other people's intellectual property rights. (Intellectual property
                            rights means copyright, patent, registered design, design right, trade mark or trade
                            secret.)
                            <br>
                            <br>
                            We live in a culture of re-mix, re-use, and mashup but a good rule of thumb here is that if
                            a person does not clearly give you the right to use their design, invention, logo, text,
                            slogan etc. then you do not have the right to use it. If you do obtain work through a
                            permissive license, please comply with the terms of that license. For example, if you are
                            relying on a Creative Commons attribution license, please always give the originator of the
                            material credit for their work. Please familiarize yourself with the legalities behind it if
                            you are planning to rely on fair use to make of works created by others.
                        </p>
                    </div>

                </div>
            </div>

        </div>
    </div>
</section>