<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_template/MS - IMG_9532@2x.jpg" class="img-banner d-none d-md-block" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Mild Steel (MS)
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Low cost steel with moderate strength, good case hardening properties, formability and weldability.
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/MS - IMG_9532@2x.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Mild Steel.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>
        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Mild Steel is basically iron containing a small percentage of carbon, and is strong and tough but not readily tempered. It also known as plain-carbon steel and low-carbon steel and is now the most common form of steel because its price is relatively low
                    while it provides material properties that are acceptable for many applications. Mild steel contains approximately 0.05–0.25% carbon.
                    <br> It has poor machinability but has moderate strength, good case hardening properties, formability, and weldability.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Mild Steel
                    </strong>
                </h6>
                <img src="./assets/img/material_template/Group 19494@2x.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                Silvery-White
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-)125µm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                370 x 350 x 2000 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships In
                            </th>
                            <td>
                                5-7 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Good hardness and efficient malleability
                        </p>
                        <p class="mb-2">
                            Good formability and weldability
                        </p>
                        <p class="mb-2">
                            Affordable and easily available in different shapes and sizes
                        </p>
                        <p class="mb-2">
                            Good choice for general purpose metal parts
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Not suited for high stress application
                        </p>
                        <p class="mb-2">
                            Relatively heavy parts than Aluminium thus not suited for application in automotive, aerospace etc.
                        </p>
                        <p class="mb-2">
                            Not suitable for food packaging or medicinal usage or any health related application
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Rivets and parts requiring strong welds
                        </p>
                        <p class="mb-2">
                            General purpose fabrication parts
                        </p>
                        <p class="mb-2">
                            Machinery parts
                        </p>
                        <p class="mb-2">
                            Jigs and fixtures
                        </p>
                        <p class="mb-2">
                            Axles, shafts, bolts, and pins
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <div class="row">
                    <div class="col-12 col-md-8">
                        <h1 class="mb-5">
                            Design Guidelines For Mild Steel (MS)
                        </h1>
                    </div>
                </div>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">


                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/mildsteel/General Achievable Tolerance@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            General achievable tolerance
                        </h6>
                        <p class="mt-2">

                            For a given part geometry, the Standard Tolerance : (+/-) <span class="text-primary">125
                                µm</span> & Achievable: (+/-) <span class="text-primary">100 µm</span> , (+/-) <span class="text-primary">50 µm</span>

                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/mildsteel/Wall Thickness@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1mm</span>. For decent strength: <span class="text-primary">2mm</span> & For rigid wall: <span class="text-primary">2.5mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/mildsteel/Pocket Depth@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Pocket Depth
                        </h6>
                        <p class="mt-2">
                            General pocket depth up to 3 times the diameter of the end mill
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/mildsteel/Engraving@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Engraving:
                        </h6>
                        <p class="mt-2">
                            Always prefer engraving over embossing Recommended fonts for a clear, legible text: Arial, Gothic Letter strand width should be at least <span class="text-primary">2 mm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/mildsteel/Internal Radii@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Internal Radii
                        </h6>
                        <p class="mt-2">
                            Minimum Internal radii achieved is <span class="text-primary">1 mm</span> Depends highly on the depth of pocket being milled
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/mildsteel/Holes@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Holes
                        </h6>
                        <p class="mt-2">
                            Use standard drill bit sizes Minimum : <span class="text-primary">1 mm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/mildsteel/Threads@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Threads
                        </h6>
                        <p class="mt-2">
                            Use standard metric inserts Smallest threaded insert available: <span class="text-primary">M2</span>
                        </p>
                    </div>

                </div>


            </div>
        </div>
    </div>
</section>

<app-cost-estimate></app-cost-estimate>