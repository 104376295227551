<section class="main-session">
    <img src="assets/img/customer_landing/Landing_page_customer_Hero_banner_Image_2x.webp" class="img-banner"
        alt="banner">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <div class="heading">
                    <!-- <h1>
                        Manufacturing <br>
                        <span>Ecosystem</span> <br> At Your Fingertips
                    </h1> -->
                    <h1 style="font-size:4.34rem">Convert Your Designs Into Reality</h1>
                    <h1 style="font-size:4.34rem">
                        Build
                        <a href="" class="typewrite" data-period="2000"
                        data-type='[ "Drones", "Robots", "E-Vehicles", "Satellites", "Machines", "Anything","Anywhere", "Anytime" ]'>
                            <span class="wrap"></span>
                        </a>
                    </h1>
                    <h1 style="font-size:4.34rem">
                        On The Cloud
                    </h1>

                    <!-- <p style="font-size: 2.7rem;width:100%;font-weight: 600;">Made Faster, Transparent and Simple!</p> -->


                    <!-- <p class="mt-4" style="font-size: 2rem;font-weight: 500;"> Faster, Effortless and Transparent!</p> -->

                    <p class="mt-4">Manufacturing Made Faster, Transparent and Simple!</p>

                    <!-- <p class="mt-4">For transparent, agile and secure manufacturing<br> of custom products </p> -->
                    <div class="d-flex button-row">
                        <a [routerLink]="['/contact']" rel="noopener"
                            class="btn btn-primary">
                            Get In Touch </a>
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener"
                            class="btn btn-light">
                            Book A Demo
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row statistics landing-statistics d-none d-md-flex">
            <div class="col">
                <div class="small-info">
                    <h6> 250+ </h6>
                    <hr>
                    <p>Customer</p>
                </div>
            </div>
            <div class="col">
                <div class="small-info">
                    <h6> 700+ </h6>
                    <hr>
                    <p>Partners</p>
                </div>
            </div>
            <div class="col">
                <div class="small-info">
                    <h6> 3000+ </h6>
                    <hr>
                    <p>Machines</p>
                </div>
            </div>
            <div class="col">
                <div class="small-info">
                    <h6> 300k+ </h6>
                    <hr>
                    <p>Parts Manufactured</p>
                </div>
            </div>
            <div class="col">
                <div class="small-info">
                    <h6> 50+ </h6>
                    <hr>
                    <p>Materials</p>
                </div>
            </div>
        </div>
    </div>
    <div class="end d-none d-md-block">
        <div class="border-line">
        </div>
    </div>
</section>

<!-- Manufacturing capabilities -->
<section class="capabilities global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="my-5 text-center">Our current manufacturing capabilities</h1>
            </div>
        </div>
        <div class="row row-cols-2 row-cols-md-6">
            <div class="col pointer" (click)="sheetMetal()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Sheet Metal.svg" class="card-img-top" loading="lazy"
                        alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Sheet<br>Metal</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="cncmachining()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/CNC Machining.svg" class="card-img-top" loading="lazy"
                        alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">CNC<br>Machining</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="threedPrinting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/3D Printing.svg" class="card-img-top" loading="lazy"
                        alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">3D<br>Printing</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="vacuumCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Vacuum Casting.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Vacuum<br>Casting</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="injectionMolding()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Injection Molding.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Injection<br>Molding</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="metalCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Metal Casting.svg" class="card-img-top" loading="lazy"
                        alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Metal<br>Casting</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center">
            <div class="d-none d-md-block">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-capa"> Get In Touch </a>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center mb-5 mt-5 mt-md-0">It starts here!</h1>
            </div>
        </div>


        <div class="row know-more-card">
            <div class="col-md-4">
                <div class="card border-0 h-100">
                    <img src="./assets/img/customer_landing/Design.webp" class="img-fluid img-border mx-auto"
                        loading="lazy" alt="Truventor">
                    <div class="card-body pt-2">
                        <h5 class="card-title font-weight-bold">Design</h5>
                        <hr class="primary-bg">
                        <p class="card-text">Design and optimize the parts with our AI-driven design tools.
                        </p>
                    </div>
                    <div class="card-footer">
                        <a [routerLink]="['/design']" class="btn">Know more</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card border-0 h-100">
                    <img src="./assets/img/customer_landing/Manufacture@2x.webp" class="img-fluid img-border w-100"
                        loading="lazy" alt="Truventor">
                    <div class="card-body pt-2">
                        <h5 class="card-title font-weight-bold">Manufacture</h5>
                        <hr class="primary-bg">
                        <p class="card-text">Truventor will take ownership of your production, be it one part or a
                            million parts.</p>
                    </div>
                    <div class="card-footer">
                        <a [routerLink]="['/manufacturing-as-a-service']" class="btn">Know more</a>
                    </div>
                </div>
            </div>


            <div class="col-md-4">
                <div class="card border-0 h-100">
                    <img src="./assets/img/customer_landing/Manage@2x.webp" class="img-fluid img-border w-100"
                        loading="lazy" alt="Truventor">
                    <div class="card-body pt-2">
                        <h5 class="card-title font-weight-bold">Manage</h5>
                        <hr class="primary-bg">
                        <p class="card-text">You manage your supplier network in the most efficient way possible.</p>
                    </div>
                    <div class="card-footer">
                        <a [routerLink]="['/manage']" class="btn">Know more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- How it works -->
<section class="how-it-works global-space-between">
    <div class="container-fluid">
        <div class="">
            <div class="row justify-content-end">
                <div class="col-12 col-md-5">
                    <div class="wrapper">
                        <h1 class="font-weight-bold">How it works?</h1>
                        <p> Truventor's highly collaborative manufacturing process is easier, faster, cheaper and
                            reliable. Here's how it works: </p>
                        <ul class="nav nav-tabs row" id="myTab" role="tablist">
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link active" data-toggle="tab" href="#home" role="tab"
                                    aria-controls="home">
                                    <hr>
                                    <h6 class="font-weight-bold">Upload</h6>
                                    <p>Upload or simply drag and drop your CAD files on the Truventor platform.</p>
                                    <img src="assets/img/customer_landing/hiw/upload.webp"
                                        class="img-fluid d-block d-md-none" alt="upload image">
                                </a>
                            </li>
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#messages" role="tab"
                                    aria-controls="messages">
                                    <hr>
                                    <h6 class="font-weight-bold">Process selection</h6>
                                    <p>Select the manufacturing technology and raw materials.</p>
                                    <img src="assets/img/customer_landing/hiw/Process-Selection.webp"
                                        class="img-fluid d-block d-md-none" alt="process selection image">
                                </a>
                            </li>
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#profile" role="tab"
                                    aria-controls="profile">
                                    <hr>
                                    <h6 class="font-weight-bold">Instant quote</h6>
                                    <p>Compare real-time quotations for various materials.</p>
                                    <img src="assets/img/customer_landing/hiw/instant_costing.webp"
                                        class="img-fluid d-block d-md-none" alt="instant quate image">
                                </a>
                            </li>
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#settings" role="tab"
                                    aria-controls="settings">
                                    <hr>
                                    <h6 class="font-weight-bold">Live tracking</h6>
                                    <p>Live track your order status and receive updates on your timeline.</p>
                                    <img src="assets/img/customer_landing/hiw/live-tracking.webp"
                                        class="img-fluid d-block d-md-none" alt="live tracking image">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 pr-0 d-none d-md-block">
                    <div class="tab-content text-right">
                        <div class="tab-pane active" id="home" role="tabpanel">
                            <img src="assets/img/customer_landing/hiw/upload.webp" loading="lazy" class="img-fluid"
                                alt="upload image">
                        </div>
                        <div class="tab-pane" id="messages" role="tabpanel">
                            <img src="assets/img/customer_landing/hiw/Process-Selection.webp" loading="lazy"
                                class="img-fluid" alt="process selection image">
                        </div>
                        <div class="tab-pane" id="profile" role="tabpanel">
                            <img src="assets/img/customer_landing/hiw/instant_costing.webp" loading="lazy"
                                class="img-fluid" alt="instant quate image">
                        </div>
                        <div class="tab-pane" id="settings" role="tabpanel">
                            <img src="assets/img/customer_landing/hiw/live-tracking.webp" loading="lazy"
                                class="img-fluid" alt="live tracking image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="start-new global-space-between bottom-end-padding">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-6">
                <div class="content">
                    <h1>Start a new project, <br>today! </h1>
                    <p>Tap into the unlimited capacities and <br> capabilities of Truventor manufacturing ecosystem</p>
                    <div class="d-flex align-items-center">
                        <a [routerLink]="['/contact']"  rel="noopener" class="btn px-5">
                            Get In Touch 
                        </a>
                        <p class="m-0 ml-3 text-font">Watch a quick video <i class="fa fa-play-circle"></i>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <button type="button" class="video-wrapper video-btn" data-toggle="modal"
                    data-src="https://www.youtube.com/embed/PI0Ddog3xZI" data-target="#myModal">
                    <img src="./assets/img/customer_landing/Customervideoasset.png" class="img-fluid" loading="lazy"
                        alt="Truventor">
                </button>
            </div>
        </div>
    </div>
</section>

<!-- Amazing Quote! -->
<section class="amazing-quote global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="quote-background-image">
                    <h1 class="text-center">
                        Schedule <br> a meeting <br> or book a demo <br>
                    </h1>
                    <div class="action-button d-flex justify-content-center">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener"
                            class="btn btn-light px-5">
                            Book A Demo </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Why truventor best -->
<section class="why-best global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center font-weight-bold">Why choose Truventor? </h1>
            </div>
        </div>

        <div class="row row-cols-2 row-cols-sm-1 row-cols-md-5 justify-content-center">
            <div class="col">
                <div class="card border-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Access to global supplier network.svg"
                        class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Access to global<br>supplier network</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Deep supplier insights.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Deep supplier<br>insights</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 5 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Transparency at each step.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Transparency<br>at each step</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0  px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Humanizing communication.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Humanizing<br>communication</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Buyer Protection.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Buyer<br>protection</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Testimonials -->
<section class="testimonial global-space-between">
    <div class="container">
        <div class="row pt-5">
            <div class="col-sm-12 col-md-6">
                <h1 class="mb-4 mb-md-0">
                    What our clients <br> say about our <br> company.
                </h1>
            </div>
            <div class="col-sm-12 col-md-6">
                <owl-carousel-o [options]="clientsOptions" class="clients-carouel">
                    <ng-template carouselSlide>
                        <div class="w-100 d-flex justify-content-start justify-content-md-start">
                            <img src="./assets/img/customer_landing/testimonials/Vinayak patole@2x.png" loading="lazy"
                                width="76px" height="76px" alt="client_image">
                        </div>
                        <p class="py-3">
                            “Working with you on 'Univ Aux' was a great pleasure. I truly enjoyed working on the
                            project. We look forward to continuing this momentum in our future projects as well.”
                        </p>
                        <h6>
                            Vinayak T. Patole<br>Lead Engineer-SCM (NPI), Global Supply Chain Center of Excellence,
                            Pune, Eaton Technologies Pvt. Ltd </h6>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="w-100 d-flex justify-content-center justify-content-md-start">
                            <img src="./assets/img/customer_landing/testimonials/Amol Lakal@2x.png" loading="lazy"
                                width="76px" height="76px" alt="client_image">
                        </div>
                        <p class="py-3">
                            “The 3D printing service provided by Truventor was very professional. They delivered exact
                            geometric, surface finish, and material in the quickest possible time. Getting clarity over
                            any ambiguous instruction from a customer before printing parts is an
                            additional effort taken by the Truventor Team. Keep up the good work.”
                        </p>
                        <h6>
                            Amol Lakal<br> Sr. Lead Engineer, Design Studio - Mahindra and Mahindra
                        </h6>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="w-100 d-flex justify-content-center justify-content-md-start">
                            <img src="./assets/img/customer_landing/testimonials/Vivek Nigam@2x.png" loading="lazy"
                                width="76px" height="76px" alt="client_image">
                        </div>
                        <p class="py-3">
                            "We have done a couple of small but critical projects with Truventor. We had a breakdown and
                            were looking for immediate support; team Truventor helped us get those projects to deliver
                            in the expected timeline with the desired accuracy. We were impressed
                            with how the team responded to our requirements and queries. We are now discussing bigger
                            and critical projects with the team, and hope to have a long-term fruitful business
                            relationship.
                        </p>
                        <h6>
                            Vivek Nigam <br> Plant Manager, EMKA India Panel Accessories Pvt.Ltd
                        </h6>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<!-- Companies -->
<section class="clients-list global-space-between">
    <div class="container">
        <app-clients-list></app-clients-list>
    </div>
</section>

<!-- Resource -->
<section class="resouces global-space-between">
    <!-- Title -->
    <div class="container">
        <h1 class="py-3">Resources</h1>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col p-0">
                <owl-carousel-o [options]="customOptions">
                    <ng-template carouselSlide>
                        <a href="https://blogs.truventor.ai/blogs/" target="_blank" rel="noopener"
                            class="res-box card border-0">
                            <img src="./assets/img/home/Knowledge Cockpit @2x.jpg" width="100%" class="card-img"
                                loading="lazy" alt="Truventor">
                            <div class="card-img-overlay d-flex align-items-end p-sm-4 p-md-3">
                                <div class="w-100">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h5>Knowledge Cockpit</h5>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="vertical-bar"></div>
                                            <button>
                                                <img src="./assets/img/arrow-right.svg" loading="lazy" alt="Truventor">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <a [routerLink]="['/case-study']" class="res-box card border-0">
                            <img src="./assets/img/home/Case Studies@2x.jpg" width="100%" class="card-img"
                                loading="lazy" alt="Truventor">
                            <div class="card-img-overlay d-flex align-items-end p-sm-4 p-md-3">
                                <div class="w-100">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h5>Case Studies</h5>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="vertical-bar"></div>
                                            <button>
                                                <img src="./assets/img/arrow-right.svg" loading="lazy" alt="Truventor">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-template>
                    <ng-template carouselSlide>
                        <a [routerLink]="['/articles']" class="res-box card border-0">
                            <img src="./assets/img/home/Articles@2x.jpg" width="100%" class="card-img" loading="lazy"
                                alt="Truventor">
                            <div class="card-img-overlay d-flex align-items-end p-sm-4 p-md-3">
                                <div class="w-100">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h5>Articles</h5>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="vertical-bar"></div>
                                            <button>
                                                <img src="./assets/img/arrow-right.svg" loading="lazy" alt="Truventor">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-template>


                    <ng-template carouselSlide>
                        <a [routerLink]="['/events']" class="res-box card border-0">
                            <img src="./assets/img/home/Events@2x.png" width="100%" class="card-img" loading="lazy"
                                alt="Truventor">
                            <div class="card-img-overlay d-flex align-items-end p-sm-4 p-md-3">
                                <div class="w-100">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <h5>Events</h5>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="vertical-bar"></div>
                                            <button>
                                                <img src="./assets/img/arrow-right.svg" loading="lazy" alt="Truventor">
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<section class="global-space-between bottom-end-padding a-trueventor">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-md-4 p-0 d-none d-md-block">
                <img src="./assets/img/customer_landing/Book a Demo.jpg" width="100%" loading="lazy" alt="Truventor">
            </div>
            <div class="col-sm-12 col-md-8 pl-md-5 d-flex align-items-center">
                <div class="w-100">
                    <h1>
                        Do you want <br> a free quote <br> in 24 hours?
                    </h1>
                    <p class="mt-4 mb-0">
                        Sign up for a free trial and find out how  <br> Truventor makes Manufacturing simple.
                    </p>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <a [routerLink]="['/contact']"  rel="noopener"
                                class="btn btn-primary w-100"> Get In Touch </a>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener"
                                class="btn w-100"> Book A
                                Demo </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<div class="modal" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="" id="video" allowscriptaccess="always"
                        allow="autoplay"></iframe>
                </div>
            </div>
        </div>
    </div>
</div>
