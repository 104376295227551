import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../shared/admin.service';
import { INJECTION_MOULDING_METAL } from '../../shared/data/index';
import { reloadTime } from 'src/app/shared/data/events';

declare var $: any;

@Component({
  selector: 'app-injectionmodule',
  templateUrl: './injectionmodule.component.html',
  styleUrls: ['./injectionmodule.component.scss']
})
export class InjectionmoduleComponent implements OnInit {
  private fragment: string;
  public injectionMouldData = INJECTION_MOULDING_METAL;
  constructor(
    private adminservice: AdminService,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      if (this.fragment != 'injection-mould-material') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        setTimeout(() => {
          document.getElementById('injection-mould-material').scrollIntoView();          
        }, reloadTime);
      }
    });

    this.adminservice.check_customer.next('customer');

    $("#grow-your-businness-accordion-one").on("hide.bs.collapse show.bs.collapse", e => {
      $(e.target)
        .prev()
        .find("i:last-child")
        .toggleClass("fa-minus fa-plus");
    });
  }


  // ngAfterViewInit(): void {
  //   try {
  //     document.querySelector('#' + this.fragment).scrollIntoView(
  //       {
  //         behavior: 'smooth'
  //       }
  //     );
  //   } catch (e) { }
  // }


}
