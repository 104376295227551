import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buyer-protection',
  templateUrl: './buyer-protection.component.html',
  styleUrls: ['./buyer-protection.component.scss']
})
export class BuyerProtectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

}
