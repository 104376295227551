<!-- Main -->
<section class="main-session">
    <img src="assets/img/sheet_metal_capabilities/laser/laser-cover.jpg" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        Laser <br> Processing
                    </h1>
                    <p class="mt-4">
                        Looking for high-quality sheet metal parts? Look no further as we offer all kinds of laser processing services here.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Description -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    Laser Processing
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    In laser processing, a laser beam is projected onto the surface of the material produced by the thermal effect to complete the laser cutting, laser marking, and laser welding, among other processes. Laser processing is also used for drilling, heat treatment,
                    and microprocessing.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div>
                    <div class="row align-items-center my-3">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/Stainless Steel, Carbon Steel.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-8">
                            <h5>
                                Stainless steel, carbon steel
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row align-items-center my-3">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/Aluminum-Copper.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-8">
                            <h5>
                                Aluminum, copper
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Laser power</th>
                            <td>
                                1 -3 KW
                            </td>
                        </tr>
                        <tr>
                            <th>Working area</th>
                            <td>
                                1500×3000mm
                            </td>
                        </tr>
                        <tr>
                            <th>Maximum speed</th>
                            <td>
                                60 m/min
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Most Common Type -->
<section class="side-tab-view-toggle global-space-between">
    <div class="container">

        <div class="row">
            <div class="col">
                <h1 class="text-center mb-5">
                    Most common types of laser processing
                </h1>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-12 col-md-6">
                <div class="tab-content" id="main_id">
                    <div class="tab-pane show active" id="slider_1" role="tabpanel" aria-labelledby="slider_1-tab">
                        <img src="./assets/img/sheet_metal_capabilities/laser/Group 20161@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_2" role="tabpanel" aria-labelledby="slider_2-tab">
                        <img src="./assets/img/sheet_metal_capabilities/laser/Group 20163@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_3" role="tabpanel" aria-labelledby="slider_3-tab">
                        <img src="./assets/img/sheet_metal_capabilities/laser/Group 20162@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="slider_1-tab" data-toggle="pill" href="#slider_1" role="tab" aria-controls="slider_1" aria-selected="true">
                        <span class="toggle">
                            Laser Cutting
                        </span>
                        <hr>
                        <p>
                            Laser cutting is a non-contact type of cutting that uses a laser beam to cut the sheet metal. The use of laser has several benefits including increased accuracy, high quality cuts and also reduced wear and tear as a result of no contact. Types of lasers
                            that can be used include gas (CO2) lasers and fibre lasers.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_2-tab" data-toggle="pill" href="#slider_2" role="tab" aria-controls="slider_2" aria-selected="false">
                        <span class="toggle">
                            Laser Marking
                        </span>
                        <hr>
                        <p>
                            Lasers are increasingly used to imprint unique identification (UID) numbers on parts and products, which allow them to be easily traced in the event of a recall. Laser markings are highly durable and, for medical devices, can withstand many cycles of
                            sterilization.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_3-tab" data-toggle="pill" href="#slider_3" role="tab" aria-controls="slider_3" aria-selected="false">
                        <span class="toggle">
                            Laser Welding
                        </span>
                        <hr>
                        <p>
                            Laser welding is a process used to join together metals or thermoplastics using a laser beam to form a weld. Laser welding is one of the most technically advanced forms of welding. This process is especially effective for products with complex geometries
                            or dissimilar materials that are difficult to join together.
                        </p>
                    </a>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- Application Of Metal Bending -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1>
                        Advantages of laser processing techniques
                    </h1>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row row-cols-2 row-cols-md-2">
                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <p>
                            It allows for very rapid and efficient fabrication, which is particularly important for mass production.
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <p>
                            Miniature parts can be fabricated with good surface quality.
                        </p>
                    </div>

                    <div class="colcol-6 p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <p>
                            Contactless processing avoids contamination with materials from mechanical tools.
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <p>
                            The contactless operation also avoids the wear-off of mechanical tools.
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 5 </div>
                        <hr>
                        <p>
                            Suitable for micromachining and transparent materials.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant laser processing quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <img src="assets/img/sheet_metal_capabilities/laser/Group 13311.jpg" class="fluid-img w-100" alt="video">
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>