import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-terms',
  templateUrl: './client-terms.component.html',
  styleUrls: ['./client-terms.component.scss']
})
export class ClientTermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

}
