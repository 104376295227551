<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_template/Group 19443.jpg" class="img-banner d-none d-md-block" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        PP-Like
                    </h1>
                    <p class="text-primary">
                        3D Printing
                    </p>
                    <p class="mt-4">
                        A photo-cured polymer with dimensional accuracy and smooth surface finish.
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19443.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/PP-Like.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>


        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    PP-Like is a photo-cured resin. It simulates properties in the range of polypropylene to low-end ABS. The combination of mechanical properties and its visual similarity to molded polypropylene has led many to opt for PP-Like as their choice of material.
                    <br> PP-Like is milky white in color. It is suitable for enclosures and snap-fit assemblies. Since parts are fabricated using Stereolithography (SLA) technology, there is support structure generation during fabrication. Despite support
                    structures, PP-Like comes with a smooth surface finish, and little burs, if any, left during the support structure removal can be polished to give the part a superior surface finish.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        PP-Like
                    </strong>
                </h6>
                <img src="./assets/img/material_template/Group 19449.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                Milky-White
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-)150µm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                650 x 600 x 400 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships in
                            </th>
                            <td>
                                3-4 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Aesthetically similar to molded Polypropylene
                        </p>
                        <p class="mb-2">
                            Great feature resolution and dimensional accuracy
                        </p>
                        <p class="mb-2">
                            Smooth surface finish and flexibility
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Support structure generation
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Snap-fit assemblies
                        </p>
                        <p class="mb-2">
                            Enclosures and live hinges
                        </p>
                        <p class="mb-2">
                            Automotive styling parts
                        </p>
                        <p class="mb-2">
                            Master patterns for vacuum casting
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <div class="row">
                    <div class="col-12 col-md-8">
                        <h1 class="mb-5">
                            3D Printing Design Guidelines For PP-Like
                        </h1>
                    </div>
                </div>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">


                    <!--  -->
                    <div class="col p-3 py-3">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pp-like/Wall Thickness@2x.png" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1.2mm</span>. For decent strength: <span class="text-primary">1.5mm</span> & For rigid wall: <span class="text-primary">2.5mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pp-like/Minimum Detail Size@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Minimum detail size
                        </h6>
                        <p class="mt-2">
                            The minimum supported feature thickness should be: At least <span class="text-primary">0.8
                                mm.</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pp-like/Embossing And Engraving@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Embossing and Engraving:
                        </h6>
                        <p class="mt-2">
                            Letter strand width: at least <span class="text-primary">0.5mm</span> Embossing height: at least <span class="text-primary">0.5mm</span> Engraving (cavity) depth: at least <span class="text-primary">0.5mm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pp-like/Overhangs@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Overhangs
                        </h6>
                        <p class="mt-2">
                            An overhanging feature of up to <span class="text-primary">2mm</span> length can be freely supported without any support structures.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3">
                        <!-- Image -->
                        <img src="./assets/img/material_list/pp-like/Support Structures@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Support Structures
                        </h6>
                        <p class="mt-2">
                            Since ABS parts are fabricated in Fused Filament Fabrication (FFF) technology, support structures are generated on part geometries with overhangs and unsupported features.
                        </p>
                    </div>

                </div>


            </div>
        </div>
    </div>
</section>


<!-- post_processing -->
<section class="post_processing global-space-between">
    <div class="container-fluid">
        <div class="row justify-content-end">
            <!-- Heading -->
            <div class="col col-12 col-md-6 pr-md-5">
                <h1>
                    POST- <br>PROCESSING
                </h1>
                <p>
                    ABS Polymers filament comes in white color, and the parts have a rough surface finish. Since parts are fabricated using FFF technology, support structures are generated on the parts.
                </p>
                <hr>
                <h6>
                    Sand Polishing
                </h6>
                <hr>
                <h6>
                    Painting (options)
                    <!-- Color boxes -->
                    <div class="color-options">
                        <div class="option red"></div>
                        <div class="option cyan"></div>
                        <div class="option yellow"></div>
                        <div class="option black"></div>
                    </div>
                </h6>
                <hr>
                <a [routerLink]="['/contact']" rel="noopener" class="btn my-4">
                    Get In Touch
                </a>
            </div>
            <!-- Image -->
            <div class="col col-12 col-md-6">
                <img src="./assets/img/material_template/Group 19464.jpg" class="img-fluid w-100" alt="Truventor">
            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>