<!-- Main -->
<section class="main-session">
    <img src="assets/img/3d_printing_capabilities/micro-sla/Group 20123.jpg" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <!-- Desktop -->
                    <div class="d-none d-md-block">
                        <h1>
                            Desktop Stereolithography (Micro-SLA)
                        </h1>
                    </div>
                    <!-- Mobile -->
                    <div class="d-block d-md-none">
                        <h2>
                            Desktop Stereolithography (Micro-SLA)
                        </h2>
                    </div>

                    <!-- Description -->
                    <p class="mt-4">
                        Ideal for rapid tooling, jigs & fixtures, we provide all types of micro SLA services
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100 mt-5">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!--  -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4 h1">
                    Desktop stereolithography (Micro-SLA)
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    Desktop stereolithography or Micro-SLA is a 3D printing technology, which makes use of photopolymer resins to manufacture proof-of-concept prototypes. It’s a desktop setup as compared to the industrial grade.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div class="mt-5 mt-md-0">
                    <div class="row my-3 align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/3d_printing_capabilities/micro-sla/Group 20129@2x.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                High temperature
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Max part size</th>
                            <td>
                                145 x 145 x 175 mm
                            </td>
                        </tr>
                        <tr>
                            <th>Accuracy </th>
                            <td>
                                (+/-) 150µm
                            </td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>
                                3-4 business days
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant 3D printing quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <div class="video-wrapper">
                            <img src="./assets/img/3d_printing_capabilities/micro-sla/Screen Shot.webp" class="fluid-img w-100" alt="video">
                            <img src="./assets/img/3d_printing_capabilities/svg/PlayButton.svg" class="play-button" alt="Truventor">
                        </div>
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>