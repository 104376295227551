<section class="main-session">
    <img src="assets/img/banner-images/Manufacture Banner Image@2x.webp" class="img-banner" alt="Truventor">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>
                        Truventor <br> Manufacture
                    </h1>
                    <p class="mt-4">
                        Your One-Stop Sourcing Solution for all manufacturing requirements from Prototyping to Batch Production
                    </p>
                </div>
            </div>
        </div>
        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']"  rel="noopener" class="btn btn-primary w-100">  Get In Touch  </a>
            </div>
        </div>
        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>



<!-- Grow your business -->
<section class="grow-your-business global-space-between">
    <div class="container">

        <!-- Desktop Slider -->
        <div class="d-none d-md-block">

            <div class="row align-items-center">
                <div class="col-6">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <img src="./assets/img/manage/manufacture/high-quality-parts.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                            <img src="./assets/img/manage/manufacture/high-quality-parts.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                            <img src="./assets/img/manage/manufacture/high-quality-parts.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <h1 class="text-center text-md-left pl-5">
                        High quality parts – annual cost savings – timely delivery
                    </h1>

                    <div class="content">
                        <div class="nav flex-column nav-pills pl-5" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <span class="plus">
                                    Flexible manufacturing capacity
                                </span>
                                <p>
                                    Huge, technology enabled network of manufacturing partners across India for faster prototyping and batch production orders. 1 part to 10,000 parts, hassle-free.
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                <span class="plus">
                                    Cost saving
                                </span>
                                <p>
                                    Truventor brings high asset utilization to our partners & uses proprietary reverse bidding approach to get cost advantage upto 20%.
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Un-matched delivery timelines
                                </span>
                                <p>
                                    Pre-audited manufacturing partners backed by technology, systems and experienced people ensure guaranteed quality and 99% OTIF with never before transparency.
                                </p>
                            </a>
                        </div>
                    </div>

                    <div class="d-none d-md-block pl-5">
                        <div class="pt-5">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn">
                                Become A Supplier
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Mobile Slider -->
        <div class="row d-block d-md-none">
            <div class="col">

                <!-- Title -->
                <h1 class="text-center text-md-left">
                    Grow your Business in 3 simple steps.
                </h1>

                <!-- One -->
                <div>
                    <img src="./assets/img/supplier/Group 16934-mobile.jpg" loading="lazy" class="img-fluid py-4" width="100%" alt="Truventor">
                    <h5 class="text-left">
                        - Get Started
                    </h5>
                    <hr>
                    <p>
                        Complete your Shop Profile, list your machines, and note your job preferences. Our Partner Team will review this information to determine if your capabilities are a match for our customer network.
                    </p>
                </div>
                <!-- Two -->
                <div>
                    <img src="./assets/img/supplier/Group 16935-mobile.jpg" loading="lazy" class="img-fluid py-4" width="100%" alt="Truventor">
                    <h5 class="text-left">
                        - Quality Vetting & Audit
                    </h5>
                    <hr>
                    <p>
                        All partners are required to complete a paid test part before being granted access to the job board. This is your opportunity to shows us your process and capabilities
                    </p>
                </div>
                <!-- Three -->
                <div>
                    <img src="./assets/img/supplier/Group 16936-mobile.jpg" loading="lazy" class="img-fluid py-4" width="100%" alt="Truventor">
                    <h5 class="text-left">
                        - Get On Work
                    </h5>
                    <hr>
                    <p>
                        Based on rating given by our internal team, you will be approved and granted access to our live job board where you can start taking orders. Our platform will show only orders, based on your capabilities and ratings.
                    </p>
                </div>

            </div>
        </div>

    </div>
</section>



<!-- Manufacturing capabilities -->
<section class="capabilities global-space-between">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="my-5 text-center">Our current manufacturing capabilities</h1>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-2 row-cols-md-6">
            <div class="col pointer" (click)="sheetMetal()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Sheet Metal.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Sheet<br>Metal</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="cncmachining()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/CNC Machining.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">CNC<br>Machining</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="threedPrinting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/3D Printing.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">3D<br>Printing</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="vacuumCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Vacuum Casting.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Vacuum<br>Casting</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="injectionMolding()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Injection Molding.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Injection<br>Molding</p>
                    </div>
                </div>
            </div>

            <div class="col pointer" (click)="metalCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Metal Casting.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Metal<br>Casting</p>
                    </div>
                </div>
            </div>


        </div>

        <!-- Instant Quote Button -->
        <div class="d-flex my-5 justify-content-center">
            <div class="d-none d-md-block">
                <a [routerLink]="['/contact']"  rel="noopener" class="btn">  Get In Touch  </a>
            </div>
        </div>
    </div>
</section>



<!-- Schedule a meeting -->
<section class="amazing-quote global-space-between">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-md-6">
                <div>
                    <!-- Quote -->
                    <h1 class="text-center text-white">
                        Schedule a meeting or book a demo
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center mt-4">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light px-md-5">
                            Request a call back </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Testimonials -->
<section class="testimonial global-space-between">
    <div class="container">
        <div class="row pt-5">
            <!-- Title -->
            <div class="col-sm-12 col-md-6">
                <h1>
                    What our clients <br> say about our <br> company.
                </h1>
            </div>
            <!-- Carousel -->
            <div class="col-sm-12 col-md-6">
                <owl-carousel-o [options]="clientsOptions" class="clients-carouel">
                    <ng-template carouselSlide>
                        <div class="w-100 d-flex justify-content-center justify-content-md-start">
                            <img src="./assets/img/customer_landing/testimonials/Vinayak patole@2x.png" width="76px" height="76px" alt="client_image">
                        </div>
                        <p class="py-3">
                            “Working with you on 'Univ Aux' was a great pleasure. I truly enjoyed working on the project. We look forward to continuing this momentum in our future projects as well.”
                        </p>
                        <h6>
                            Vinayak T. Patole<br>Lead Engineer-SCM (NPI), Global Supply Chain Center of Excellence, Pune, Eaton Technologies Pvt. Ltd </h6>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="w-100 d-flex justify-content-center justify-content-md-start">
                            <img src="./assets/img/customer_landing/testimonials/Amol Lakal@2x.png" width="76px" height="76px" alt="client_image">
                        </div>
                        <p class="py-3">
                            “The 3D printing service provided by Truventor was very professional. They delivered exact geometric, surface finish, and material in the quickest possible time. Getting clarity over any ambiguous instruction from a customer before printing parts is an
                            additional effort taken by the Truventor Team. Keep up the good work.”
                        </p>
                        <h6>
                            Amol Lakal<br> Sr. Lead Engineer, Design Studio - Mahindra and Mahindra
                        </h6>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="w-100 d-flex justify-content-center justify-content-md-start">
                            <img src="./assets/img/customer_landing/testimonials/Vivek Nigam@2x.png" width="76px" height="76px" alt="client_image">
                        </div>
                        <p class="py-3">
                            "We have done a couple of small but critical projects with Truventor. We had a breakdown and were looking for immediate support; team Truventor helped us get those projects to deliver in the expected timeline with the desired accuracy. We were impressed
                            with how the team responded to our requirements and queries. We are now discussing bigger and critical projects with the team, and hope to have a long-term fruitful business relationship.
                        </p>
                        <h6>
                            Vivek Nigam <br> Plant Manager, EMKA India Panel Accessories Pvt.Ltd
                        </h6>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>




<!-- Clients who trust us -->
<section class="clients-list global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center pb-4">
                    Clients who trust us.
                </h1>
            </div>
        </div>
        <app-clients-list></app-clients-list>
    </div>
</section>


<section class="looking-team">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-5 pl-0">
                <img src="assets/img/about/looking-people.webp" class="img-fluid" alt="trueventor">
            </div>
            <div class="col-12 col-md-5">
                <div class="content">
                    <h3>Transforming nanufacturing service</h3>
                    <p class="py-0">We have dedicated team of account managers engaging with customers every day to resolve any business request. We guarantee</p>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"> <i class="fa fa-chevron-right" aria-hidden="true"></i> Regular updates
                        </li>
                        <li class="list-group-item"><i class="fa fa-chevron-right" aria-hidden="true"></i> Proper planning
                        </li>
                        <li class="list-group-item"><i class="fa fa-chevron-right" aria-hidden="true"></i> Peace of mind
                        </li>
                    </ul>
                    <div class="d-flex">
                        <div class="d-none d-md-block">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn">
                                Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</section>


<!-- Advantages -->
<section class="manage-advantages global-space-between">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="my-5 text-center font-weight-bold">
                    Manufacturing industries we cater
                </h1>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-1 row-cols-md-4 justify-content-center pt-4 px-1 px-md-0">

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/manage/manufacture/SPM.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            SPM
                        </p>
                        <hr>
                        <p class="text-center">
                            Spindle shafts, machine guideways, machine covers, machine enclosures, etc.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/manage/manufacture/Robotics.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Robotics
                        </p>
                        <hr>
                        <p class="text-center">
                            Airborne, maritime, mobile, humanoid
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/manage/manufacture/Medical Device.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Medical device
                        </p>
                        <hr>
                        <p class="text-center">
                            Cabinets snd racks, display systems, enclosures and housings, wearables, cartridges, etc.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/manage/manufacture/Aerospace.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Aerospace
                        </p>
                        <hr>
                        <p class="text-center">
                            Seat components, avionics, waste technology systems, galley systems
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- A true inventor and an inventor in true sense!
<section class="true-inventor-and-an-inventor global-space-between bottom-end-padding">
    <div class="container-fluid">
        <div class="row justify-content-start align-items-center">
            <div class="col col-12 col-md-5 pl-0">
                <img src="./assets/img/material_list/Group 19437.jpg" loading="lazy" class="img-fluid w-100" alt="Truventor">
            </div>
            <div class="col col-12 col-md-5">
                <h1>
                    Do you want <br> a free quote <br> in 24 hours?
                </h1>

                <p class="my-5">
                    Sign up for a free trial and find out how  <br> Truventor makes Manufacturing simple.
                </p>

                <div class="row">
                    <div class="col-12 col-md-6">
                        <a [routerLink]="['/contact']"  rel="noopener" class="btn btn-primary w-100">  Get In Touch  </a>
                    </div>
                    <div class="col-12 col-md-6">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn w-100"> Book A
                            Demo </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
 -->
 <section class="global-space-between bottom-end-padding a-trueventor">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12 col-md-4 p-0 d-none d-md-block">
                <img src="./assets/img/customer_landing/Book a Demo.jpg" width="100%" loading="lazy" alt="Truventor">
            </div>
            <div class="col-sm-12 col-md-8 pl-md-5 d-flex align-items-center">
                <div class="w-100">
                    <h1>
                        Do you want <br> a free quote <br> in 24 hours?
                    </h1>
                    <p class="mt-4 mb-0">
                        Sign up for a free trial and find out how  <br> Truventor makes Manufacturing simple.
                    </p>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <a [routerLink]="['/contact']"  rel="noopener"
                                class="btn btn-primary w-100"> Get In Touch </a>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener"
                                class="btn w-100"> Book A
                                Demo </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>