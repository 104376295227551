<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Inconel 625@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Inconel 625
                    </h1>
                    <p class="text-primary">
                        3D Printing
                    </p>
                    <p class="mt-4">
                        Inconel 625 on liner: Inconel 625 is known for its high strength and excellent fabricability.
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Inconel 718.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    The alloy has excellent fatigue strength and stress-corrosion cracking resistance Inconel 625 is a nickel-based superalloy that is highly resistant to corrosion and high temperatures. The strength of Inconel 625 is not compromised by multiple heat cycles,
                    and it can withstand temperatures up to 1000°C. The strength of Inconel 625 lies not only in its nickel-chromium base but also the hardening mechanism of niobium and molybdenum.

                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5">
                    Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Inconel 625
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Inconel 625 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Chemical composition
                            </th>
                            <td>
                                UNS N06625, AMS 5666, AMS 5599, 2.4856, NiCr22Mo9Nb

                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ultimate Tensile Strength
                            </th>
                            <td>
                                920 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Yield Strength
                            </th>
                            <td>
                                670 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Elongation @ Break
                            </th>
                            <td>
                                40 %
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Great strength and toughness
                        </p>
                        <p class="mb-2">
                            High temperature resistance
                        </p>
                        <p class="mb-2">
                            Corrosion resistance
                        </p>
                        <p class="mb-2">
                            High level of fabricability
                        </p>
                        <hr>
                    </div>
                </div>





                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Chemical/Power Processing
                        </p>
                        <p class="mb-2">
                            Aerospace/Automotive
                        </p>
                        <p class="mb-2">
                            Moulding components
                        </p>
                        <p class="mb-2">
                            Heat exchanger
                        </p>
                        <p class="mb-2">
                            Nuclear technology
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>