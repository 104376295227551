<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Tool Steel S7@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Tool <br> Steel S7
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Tool Steel S7 has good resistance to softening at moderately high temperatures.

                    </p>
                </div>
            </div>
        </div>


        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Tool Steel S7.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    S7 tool steel is a versatile formulation suitable for both cold and hot work service. It has a relatively low carbon concentration compared to D2 and A2 steels (0.45 – 0.55%) and significantly less chromium (3.00 – 3.50%). It features excellent impact
                    and shock resistance and decent resistance to softening at high temperatures. This steel can obtain optimum hardness at higher austenitizing temperatures.

                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Tool Steel S7
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Tool Steel S7 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Density
                            </th>
                            <td>
                                0.283 lb/in3 (7833 kg/m3)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Specific Gravity
                            </th>
                            <td>
                                7.83
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Modulus of Elasticity
                            </th>
                            <td>
                                30 x 106 psi (207GPa)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Thermal Conductivity
                            </th>
                            <td>
                                16.5 BTU/hr-ft-°F <br> (28.5 W/m/°K)
                            </td>
                        </tr>


                        <tr>
                            <th>
                                Machinability
                            </th>
                            <td>70-75% of a 1% carbon steel</td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Excellent impact resistance
                        </p>
                        <p class="mb-2">
                            High strength and toughness
                        </p>
                        <p class="mb-2">
                            Suitable for a wide range of die applications
                        </p>

                        <hr>
                    </div>
                </div>


                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Riveting Dies
                        </p>
                        <p class="mb-2">
                            Powder Metal Dies
                        </p>
                        <p class="mb-2">
                            Cold Forming Dies
                        </p>
                        <p class="mb-2">
                            Bending Dies
                        </p>

                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>