<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_template/Group 19444.jpg" class="img-banner d-none d-md-block" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Ultra Detail (Vero)
                    </h1>
                    <p class="text-primary">
                        3D Printing
                    </p>
                    <p class="mt-4">
                        Rigid material with excellent feature retention capability and high-dimensional accuracy
                    </p>
                </div>
            </div>
        </div>


        <!-- Mobile Image -->
        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19444.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Ultra Detail (Vero).pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>
        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Ultra Detail (Vero) is a part of the Vero family which consists of a range of materials viz., Vero WhitePlus, Vero Cyan, Vero Magenta etc. These are rigid, opaque materials which have shore hardness in the range of 83-86D. Due to its excellent feature
                    retention capability, Ultra Detail (Vero) parts are used in applications that require small, detailed features.
                    <br> Ultra Detail (Vero) comes in a pure white color and has a smooth surface finish. The parts can be made matte or glossy during manufacturing itself. This usually eliminates any need for post-processing operations. Since the parts
                    are fabricated in Polyjet technology, they have support structure generation. These are wax-like supports which can be removed manually or with the help of a water-jet. Ultra Detail (Vero) material can be blended with RUBBER-Like (Tango)
                    photopolymer to vary hardness, flexibility, translucency, and heat resistance.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Ultra Detail (Vero)
                    </strong>
                </h6>
                <img src="./assets/img/material_template/Group 19450.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                White
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-)100 - 200 µm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                490 x 390 x 200 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships in
                            </th>
                            <td>
                                6-7 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Rigid material with smooth surface finish
                        </p>
                        <p class="mb-2">
                            Available in variety of colors
                        </p>
                        <p class="mb-2">
                            Excellent feature retention capability and accuracy
                        </p>
                        <p class="mb-2">
                            Can be blended with Flexible materials
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Unsuitable for snap-fits and live hinges
                        </p>
                        <p class="mb-2">
                            Parts may deform/warp when exposed to higher temperatures (greater than 45-50˚C) & loading condition
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Small aesthetic parts and display models with intricate features
                        </p>
                        <p class="mb-2">
                            Parts with multi-material capabilities
                        </p>
                        <p class="mb-2">
                            FMCG and packaging industries
                        </p>
                        <p class="mb-2">
                            Automotive interiors
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <div class="row">
                    <div class="col-12 col-md-8">
                        <h1 class="mb-5">
                            3D Printing Design Guidelines For Ultra Detail (Vero)
                        </h1>
                    </div>
                </div>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">


                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/ultra-vero/Wall Thickness@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1mm</span>. For decent strength: <span class="text-primary">1.5mm</span> & For rigid wall: <span class="text-primary">2mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/ultra-vero/Minimum Detail Size@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Minimum detail size
                        </h6>
                        <p class="mt-2">
                            The minimum supported feature thickness: At least <span class="text-primary">0.5 mm</span> .
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/ultra-vero/Embossing And Engraving@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Embossing and Engraving:
                        </h6>
                        <p class="mt-2">
                            Letter strand width: at least <span class="text-primary">0.4mm</span> Embossing height: at least <span class="text-primary">0.5mm</span> Engraving (cavity) depth: at least <span class="text-primary">0.5mm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/ultra-vero/Support Structures@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Support Structures
                        </h6>
                        <p class="mt-2">
                            Since Ultra Detail (Vero) parts are fabricated in PolyJet technology, support structures are generated enveloping the part geometries.
                        </p>
                    </div>

                </div>


            </div>
        </div>
    </div>
</section>

<!-- post_processing -->
<section class="post_processing global-space-between global-space-between">
    <div class="container">
        <div class="row justify-content-end">
            <!-- Heading -->
            <div class="col col-12 col-md-6 pr-md-5">
                <h1>
                    POST- <br>PROCESSING
                </h1>
                <p>
                    Some of the post-processing options available for Ultra Detail (Vero) parts are as follows:
                </p>
                <hr>
                <h6>
                    Sand Polishing
                </h6>
                <hr>
                <a [routerLink]="['/contact']" rel="noopener" class="btn my-4">
                    Get In Touch
                </a>
            </div>
            <!-- Image -->
            <div class="col col-12 col-md-6">
                <img src="./assets/img/material_template/Group 19464.jpg" class="img-fluid w-100" alt="Truventor">
            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>