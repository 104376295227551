<!-- Main -->
<section class="main-session">
    <div class="container">
        <img src="assets/img/banner-images/Injection Moulding@2x.webp" class="img-banner" alt="Truventor">
        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-8">
                <div class="heading">
                    <h1>Injection Moulding <br> Service</h1>
                    <p class="mt-4">For A Simple, Efficient And Profitable Way To<br>Enhance Business.</p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" class="btn btn-primary w-100"> Contact us</a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<section class="our-vacuum-casting-services global-space-between">
    <div class="container">

        <div class="row justify-content-between align-items-center">
            <div class="col-12 order-12 order-md-1 col-md-5">
                <h1 class="text-left">
                    Our injection moulding services
                </h1>
                <p>
                    At Truventor, we cater to injection moulding (IM) manufacturing orders. We provide end-use plastic and rubber parts for volumes starting at 100 quantities. Our end-to-end projects range from tool design, tool manufacturing and taking moulded parts out
                    of IM. We also handle quality inspection within the committed timeline.
                </p>
            </div>
            <div class="col-12 order-1 order-md-12 col-md-6 text-center">
                <img src="./assets/img/supplier/icons/Injection Molding.svg" loading="lazy" class="img-fluid mx-auto machining-img" alt="vacuum-casting">
            </div>
        </div>
    </div>
</section>

<!-- some cards -->
<section class="some-cards global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1>Capablities</h1>
                <div class="content">
                    <div class="row row-cols-1 row-cols-md-3">
                        <!-- FFF -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0" data-aos="fade-up" data-aos-delay="200">
                                <div class="square">
                                    <img src="/assets/img/injection_moulding/Plastic@2x.jpg" loading="lazy" class="card-img" alt="Laser Cutting">
                                </div>
                                <div class="card-body d-flex align-items-end">
                                    <div class="text-holder hover-holder">
                                        <h5 class="card-title text-uppercase">Plastic</h5>
                                        <p class="card-text">
                                            Plastic injection moulding
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- Micro-SLA -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0" data-aos="fade-up" data-aos-delay="400">
                                <div class="square">
                                    <img src="/assets/img/injection_moulding/Rubber@2x.jpg" loading="lazy" class="card-img" alt="Laser Cutting">
                                </div>
                                <div class="card-body d-flex align-items-end flex-wrap">
                                    <div>
                                        <h5 class="card-title text-uppercase">
                                            Rubber
                                        </h5>
                                        <p class="card-text">
                                            Liquid silicone rubber moulding
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- SLA -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0" data-aos="fade-up" data-aos-delay="600">
                                <div class="square">
                                    <img src="/assets/img/injection_moulding/Over MOuld@2x.jpg" loading="lazy" class="card-img" alt="Laser Cutting">
                                </div>
                                <div class="card-body d-flex align-items-end flex-wrap">
                                    <div>
                                        <h5 class="card-title text-uppercase">
                                            Over MOuld
                                        </h5>
                                        <p class="card-text">
                                            Overmoulding and insert moulding
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section class="global-space-between guide-to-moulding">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="text-center">Guide to diverse moulding processes</h1>
            </div>
            <div class="col-md-4">
                <div class="card mb-3 mb-md-0">
                    <img src="./assets/img/injection_moulding/Silicone Mould (VC)@2x.jpg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <h5 class="card-title">Silicone mould (VC)</h5>
                        <div class="subtiles">
                            <p>Turnaround time</p>
                            <h6>7-15 working days</h6>
                        </div>
                        <div class="subtiles">
                            <p>Ideal for</p>
                            <h6> 1 To 100 units</h6>
                        </div>
                        <div class="subtiles">
                            <p>Materials</p>
                            <h6>PU (Poly-urethane) based
                            </h6>
                        </div>
                        <div class="subtiles">
                            <p>Platen Size (mm)</p>
                            <h6>
                                1205x1205

                            </h6>

                        </div>
                        <div class="subtiles">
                            <p>Surface finish</p>
                            <h6>Light bleed blast</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card mb-3 mb-md-0">
                    <img src="./assets/img/injection_moulding/Soft Tool@2x.jpg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <h5 class="card-title">Soft tool</h5>
                        <div class="subtiles">
                            <p>Turnaround time</p>
                            <h6>3-4 weeks</h6>
                        </div>
                        <div class="subtiles">
                            <p>Ideal for</p>
                            <h6>For 100 – 50K units</h6>
                        </div>
                        <div class="subtiles">
                            <p>Materials</p>
                            <h6>Al- Mild steel- OHNS based</h6>
                        </div>
                        <div class="subtiles">
                            <p>Platen Size (mm)</p>
                            <h6> 1290x1290</h6>
                        </div>
                        <div class="subtiles">
                            <p>Surface finish</p>
                            <h6>Dry blasting,Machining</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card mb-3 mb-md-0">
                    <img src="./assets/img/injection_moulding/Harderend Tool @2x.jpg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <h5 class="card-title">Hardened</h5>
                        <div class="subtiles">
                            <p>Turnaround time</p>
                            <h6>6-8 weeks</h6>
                        </div>
                        <div class="subtiles">
                            <p>Ideal for</p>
                            <h6> For 1 Lacs+ units</h6>
                        </div>
                        <div class="subtiles">
                            <p>Materials</p>
                            <h6>Al- Mild steel- OHNS based</h6>
                        </div>
                        <div class="subtiles">
                            <p>Platen Size (mm)</p>
                            <h6> 1450x1525</h6>
                        </div>
                        <div class="subtiles">
                            <p>Surface finish</p>
                            <h6>Machining,Texturing</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>





<!-- Industrial-grade 3d printing -->
<section class="industrial-grade global-space-between" id="injection-mould-material">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 col-md-7">
                            <h1 class="py-3">
                                Industrial-grade injection moulding materials
                            </h1>
                        </div>
                        <div class="col-12 col-md-5 d-flex align-items-center">
                            <p>
                                In engineering, the right materials matter. We can make sure your final product looks and performs exactly as intended.

                            </p>
                        </div>
                    </div>
                    <!-- Table -->
                    <div class="row my-4 table-product-list">
                        <div class="col">
                            <!-- Collapse One -->


                            <h2 class="mb-0 px-0 card-header border-dark bg-transparent">
                                Top plastics
                            </h2>

                            <div class="card border-0 mt-2">
                                <div class="card-body">
                                    <div *ngFor="let item of injectionMouldData">
                                        <div class="row align-items-center">
                                            <div class="col-12 col-md-12 pl-3 pl-md-0">
                                                <div class="row align-items-center">
                                                    <div class="col-md-2">
                                                        <img src="{{item?.imgUrl}}" class="img-fluid" loading="lazy" alt="Truventor">
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <b> {{item?.name}} </b>
                                                    </div>
                                                    <div class="col-12 col-md-3">
                                                        <p class="mb-0">{{item?.description}}</p>
                                                    </div>
                                                    <div class="col-12 col-md-4 text-md-right">
                                                        <a href="{{item?.url}}" class="btn py-2 px-4 mt-2 mt-md-0 mr-3">
                                                            Know More
                                                        </a>
                                                        <a [routerLink]="['/contact']"  rel="noopener" class="btn py-2 px-4 mt-2 mt-md-0">
                                                            Get In Touch
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col p-0">
                                                <hr class="bg-dark">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- Button -->
                    <div class="row">
                        <div class="col-12 col-md-12">
                            Other materials can be made available based on requirement. Get in touch with our team at
                            <a href="mailto:care@truventor.ai">care@truventor.ai </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>





<section class="our-vacuum-casting-services global-space-between">
    <div class="container">

        <div class="row justify-content-between align-items-center">
            <div class="col-12 order-12 order-md-1 col-md-5">
                <h1 class="text-left">
                    Free injection moulding consultation
                </h1>
                <p>
                    Selecting the apt manufacturing process, materials, and, most importantly, costing and timelines.
                </p>
            </div>
            <div class="col-12 order-1 order-md-12 col-md-6 text-center">
                <img src="assets/img/vacuum_casting/svg/Illustration.svg" loading="lazy" class="img-fluid mx-auto" alt="vacuum-casting" style="height:320px">
            </div>
        </div>
    </div>
</section>




<app-quality-assurance></app-quality-assurance>




<!-- Features -->
<section class="injection-feature side-tab-view global-space-between bottom-end-padding">
    <div class="container">

        <!-- Desktop View -->
        <div class="d-none d-md-block">

            <div class="row align-items-center">
                <div class="col-6 pl-0">
                    <img src="./assets/img/injection_moulding/why-tv.png" loading="lazy" class="img-fluid" width="100%" alt="Truventor">


                </div>
                <div class="col-6 pl-5">
                    <h1 class="text-left mb-3">
                        Why choose Truventor?
                    </h1>
                    <div class="content">
                        <div id="grow-your-businness-accordion-one" class="myaccordion">
                            <div class="card">
                                <div class="card-header" id="growheadingOne">
                                    <h2 class="mb-0">
                                        <button class="d-flex align-items-center justify-content-between w-100" data-toggle="collapse" data-target="#growcollapseOne" aria-expanded="true" aria-controls="growcollapseOne">
                                            One-stop shop
                                            <i class="fa fa-minus" aria-hidden="true"></i>
                                        </button>
                                    </h2>
                                </div>
                                <div id="growcollapseOne" class="collapse show" aria-labelledby="growheadingOne" data-parent="#grow-your-businness-accordion-one">
                                    <div class="card-body">
                                        <p>We deliver quality IM parts at your doorstep. We take your design through DFM, tool design, tool manufacturing, Trials T1 – T2 – T3, and final manufacturing of parts.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="growheadingTwo">
                                    <h2 class="mb-0">
                                        <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseTwo" aria-expanded="false" aria-controls="growcollapseTwo">
                                            Committed timelines
                                            <i class="fa fa-plus" aria-hidden="true"></i>

                                        </button>
                                    </h2>
                                </div>
                                <div id="growcollapseTwo" class="collapse" aria-labelledby="growheadingTwo" data-parent="#grow-your-businness-accordion-one">
                                    <div class="card-body">
                                        <p>Fear you may miss your deadline to deliver parts? Our professional team of engineers and product managers have got you covered. Be assured! The job will be done well in time.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="growheadingThree">
                                    <h2 class="mb-0">
                                        <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseThree" aria-expanded="false" aria-controls="growcollapseThree">
                                            Bridge tooling before production
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                    </h2>
                                </div>
                                <div id="growcollapseThree" class="collapse" aria-labelledby="growheadingThree" data-parent="#grow-your-businness-accordion-one">
                                    <div class="card-body">
                                        <p>With Truventor, you can leverage affordable soft tooling for design and market validation before investing time and money in hard tools.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="growheadingFour">
                                    <h2 class="mb-0">
                                        <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseFour" aria-expanded="false" aria-controls="growcollapseFour">
                                            Your tool, your ownership
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                    </h2>
                                </div>
                                <div id="growcollapseFour" class="collapse" aria-labelledby="growheadingFour" data-parent="#grow-your-businness-accordion-one">
                                    <div class="card-body">
                                        <p>With Truventor, you own the tool/mould. However, we can store the moulds at our shopfloor for a nominal storage fee.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-header" id="growheadingFive">
                                    <h2 class="mb-0">
                                        <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseFive" aria-expanded="false" aria-controls="growcollapseFive">
                                            No MOQ
                                            <i class="fa fa-plus" aria-hidden="true"></i>
                                        </button>
                                    </h2>
                                </div>
                                <div id="growcollapseFive" class="collapse" aria-labelledby="growheadingFive" data-parent="#grow-your-businness-accordion-one">
                                    <div class="card-body">
                                        <p>We are the only injection moulding company that offers you the flexibility to manufacture as you scale. You can keep less inventory and manufacture parts as you scale.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </div>

        <!-- Mobile View -->
        <div class="d-block d-md-none">
            <div class="row">
                <div class="col">

                    <!-- Title -->
                    <h1 class="text-center text-md-left">
                        Features
                    </h1>

                    <!-- One -->
                    <div>
                        <img src="./assets/img/supplier/Group 16937.png" loading="lazy" class="img-fluid py-4" width="100%" alt="Truventor">
                        <h5 class="text-left">
                            Expert Feedback
                        </h5>
                        <hr>
                        <p>
                            Get realtime support from our expert engineering team.
                        </p>
                    </div>
                    <!-- Two -->
                    <div>
                        <img src="./assets/img/supplier/Group 16938.png" loading="lazy" class="img-fluid py-4" width="100%" alt="Truventor">
                        <h5 class="text-left">
                            Quotation Management
                        </h5>
                        <hr>
                        <p>
                            With our platform, you receive only curated orders. Spend less time of on answering RFQs and chasing customers for payments.
                        </p>
                    </div>
                    <!-- Three -->
                    <div>
                        <img src="./assets/img/supplier/Group 16939.png" loading="lazy" class="img-fluid py-4" width="100%" alt="Truventor">
                        <h5 class="text-left">
                            View And Track
                        </h5>
                        <hr>
                        <p>
                            Track all Order updates from Order Timeline feature.Making manufacturing hazzel free
                        </p>
                    </div>


                </div>
            </div>
        </div>

    </div>
</section>


<!-- Schedule a meeting -->
<section class="amazing-quote global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="quote-background-image">
                    <!-- Quote -->
                    <h1 class="text-center">
                        Schedule <br> a meeting <br> or book a demo
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center mt-5">
                        <a href="" class="btn btn-light"> Contact Us </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>




<!-- Seeking For Manufacturing Capabilities -->
<section class="capabilities-inner global-space-between bottom-end-padding">
    <div class="container">
        <!-- Heading -->
        <div class="row mb-5">
            <div class="col">
                <h1 class="mb-5 text-center font-weight-bold">Check out our other offerings</h1>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-2 row-cols-md-6 p-4 p-md-0">

            <div class="col">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Sheet Metal.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Sheet<br>Metal</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/3D Printing.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">3D<br>Printing</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Metal Casting.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Metal<br>Casting</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/CNC Machining.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">CNC<br>Machining</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Injection Molding.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Injection<br>Molding</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Vacuum Casting.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Vacuum<br>Casting</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>