import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { METAL_CASTING, reloadTime } from 'src/app/shared/data';
import { AdminService } from '../shared/admin.service';
declare var $: any;

@Component({
  selector: 'app-metalcasting',
  templateUrl: './metalcasting.component.html',
  styleUrls: ['./metalcasting.component.scss']
})
export class MetalcastingComponent implements OnInit {
  private fragment: string;
  public metalCastingData = METAL_CASTING
  constructor(
    private adminservice: AdminService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      if (this.fragment != 'metal-casting-material') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        setTimeout(() => {
          document.getElementById('metal-casting-material').scrollIntoView();          
        }, reloadTime);
      }
    });
 
    this.adminservice.check_customer.next('customer');

    $("#grow-your-businness-accordion-one").on("hide.bs.collapse show.bs.collapse", e => {
      $(e.target)
        .prev()
        .find("i:last-child")
        .toggleClass("fa-minus fa-plus");
    });
  }

  // ngAfterViewInit(): void {
  //   try {
  //     document.querySelector('#' + this.fragment).scrollIntoView(
  //       {
  //         behavior: 'smooth'
  //       }
  //     );
  //   } catch (e) { }
  // }

  sheetMetal() {
    this.router.navigate(['/sheet-metal-fabrication']);
  }

  threedPrinting() {
    this.router.navigate(['/3d-printing']);
  }

  vacuumCasting() {
    this.router.navigate(['/vacuum-casting']);
  }

  injectionMolding() {
    this.router.navigate(['/plastic-injection-moulding']);
  }

  metalCasting() {
    this.router.navigate(['/metal-casting']);
  }

  cncmachining() {
    this.router.navigate(['/cnc-machining']);
  }

}
