<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Tool Steel A Grade@2x.jpg" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Tool Steel A <br> Grade
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        This versatile material offers high fatigue resistance and good biocompatibility. </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Tool Steel A Grade.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Also known as Air Hardening tool steel, A-grade tool steels have a higher content of chromium which results in a better response to heat treatment. The machinability of A-grade tool steels is quite good. In addition, they have great wear resistance and
                    toughness properties. The increased wear resistance and lower distortion make A2 tool steel particularly useful in longer production runs on either light or heavy gauge stock.

                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Tool Steel A Grade
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Tool Steel A Grade 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Ultimate tensile strength
                            </th>
                            <td>
                                2040 - 2360 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Yield strength
                            </th>
                            <td>
                                1800 - 2140 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Young's modulus
                            </th>
                            <td>
                                209 - 219 GPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Elongation at break
                            </th>
                            <td>
                                3.5 - 6.1 %:
                            </td>
                        </tr>


                        <tr>
                            <th>Hardness</th>
                            <td>57 - 62 HRC</td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Greater size stability and machinability
                        </p>
                        <p class="mb-2">
                            Safer hardening
                        </p>
                        <p class="mb-2">
                            Useful in longer production runs
                        </p>


                        <hr>
                    </div>
                </div>


                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Blanking and forming dies
                        </p>
                        <p class="mb-2">
                            Trimming dies
                        </p>
                        <p class="mb-2">
                            Coining and drawing dies
                        </p>
                        <p class="mb-2">
                            Punches, shear and slitter blades
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>