<!-- Main -->
<section class="main-session">
    <img src="assets/img/banner-images/Metal Casting@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>
                        Metal Casting
                    </h1>
                    <p class="mt-4">
                        With Truventor metal casting services, you can cast various types of geometries in different materials, tolerances, and post-processing.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch </a>
            </div>
        </div>

        <!-- Statistics -->
        <div class="row statistics d-none d-md-flex">
            <div class="col">
                <div class="small-info">
                    <h6> 900+ </h6>
                    <hr>
                    <p>CNCs</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info">
                    <h6> 14 </h6>
                    <hr>
                    <p>No. Of Materials</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info">
                    <h6> 75+ </h6>
                    <hr>
                    <p>Customers</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info">
                    <h6> 5 Microns </h6>
                    <hr>
                    <p>Tolerance</p>
                </div>
            </div>

        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Our Metal Casting Services -->
<section class="our-metal-casting-services global-space-between">
    <div class="container">

        <div class="row align-items-center">
            <div class="col-12 order-12 order-md-1 col-md-5">
                <h1 class="text-left">
                    Our metal casting services
                </h1>
                <p>
                    We offer large range of metal casting solutions serving customers from various industries. We understand the intricacies of different industry requirement and advice our customers on the design and material selection to fulfill their requirement within
                    the stipulated timeline. Our manufacturing partners are equipped with latest softwares, machines and tools to carry out die casting services in efficient manner.
                </p>
            </div>
            <div class="col-12 order-1 order-md-12 col-md-6 d-flex justify-content-center mb-5">
                <img src="assets/img/metal_casting/svg/Metal Casting.svg" class="img-fluid machining-img" alt="injection-moulding">
            </div>
        </div>
    </div>
</section>

<!-- Applications -->
<section class="ideal-application global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <!-- Title -->
                <h1 class="text-center pb-5"> Applications </h1>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3">

            <!-- Automotive -->
            <div class="col mb-4">
                <div class="card border-0 ">
                    <img src="/assets/img/metal_casting/Automotive@2x.png" class="card-img-top" alt="consumer goods">
                    <div class="card-body px-3">
                        <h4 class="card-title py-3">
                            Automotive
                        </h4>
                        <p>
                            Engine parts, brackets, electronic covers, etc.
                        </p>
                    </div>
                </div>
            </div>

            <!--  -->
            <div class="col mb-4">
                <div class="card border-0 ">
                    <img src="/assets/img/metal_casting/Industrial@2x.png" class="card-img-top" alt="consumer goods">
                    <div class="card-body px-3">
                        <h4 class="card-title py-3">
                            Industrial
                        </h4>
                        <p>
                            Motors, generators, pumps and valves, components, etc.
                        </p>
                    </div>
                </div>
            </div>

            <!-- Heavy Equipment -->
            <div class="col mb-4">
                <div class="card border-0 ">
                    <img src="/assets/img/metal_casting/Heavy Equipment@2x.png" class="card-img-top" alt="consumer goods">
                    <div class="card-body px-3">
                        <h4 class="card-title py-3">
                            Heavy equipment
                        </h4>
                        <p>
                            Production of plastic based parts and prototypes
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- Advantages of Metal casting -->
<section class="advantages-of-metal-casting global-space-between">
    <div class="container">
        <div class="row align-items-center">
            <!-- Side Image - Desktop -->
            <div class="col-sm-12 col-md-6 d-none d-md-block">
                <img src="./assets/img/metal_casting/Advantages of Metal casting@2x (1).jpg" class="img-fluid" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5 d-flex align-items-center">
                <div class="col-sm-12 col-md-12 p-2">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 col-md-12">
                            <h1 class="text-left">
                                Advantages of metal casting
                            </h1>
                            <!-- Side Image - Mobile -->
                            <div class="col-sm-12 col-md-6 p-0 py-5 d-block d-md-none">
                                <img src="./assets/img/metal_casting/Advantages of Metal casting@2x (1).jpg" class="img-fluid" width="100%" alt="Truventor">
                            </div>
                            <p>
                                Metal casting is primarily used to make complex and/or large parts, which would have been difficult or expensive to manufacture using other manufacturing processes. Some of the advantages of metal casting includes:
                            </p>
                        </div>
                    </div>
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="w-100">
                            <div class="accordion" id="advantages-of-metal-casting">
                                <!-- Ability to create complex geometries -->
                                <div>
                                    <hr class="m-0">
                                    <div>
                                        <h5 class="text-left">
                                            Ability to create complex geometries
                                        </h5>
                                    </div>
                                    <hr class="m-0">
                                </div>

                                <!-- Fast production cycles -->
                                <div>
                                    <div id="advantages-of-metal-casting-accordion-two">
                                        <h5 class="text-left">
                                            Fast production cycles
                                        </h5>
                                    </div>
                                    <hr class="m-0">
                                </div>

                                <!-- Workability of hard metals -->
                                <div>
                                    <div id="advantages-of-metal-casting-accordion-three">
                                        <h5 class="text-left">
                                            Workability of hard metals
                                        </h5>
                                    </div>
                                    <hr class="m-0">

                                </div>

                                <!-- Eliminate the need to assemble multiple pieces -->
                                <div>
                                    <div id="advantages-of-metal-casting-accordion-four">
                                        <h5 class="text-left">
                                            Eliminate the need to assemble multiple pieces
                                        </h5>
                                    </div>
                                    <hr class="m-0">
                                </div>

                                <!-- Minimal sizing restraints -->
                                <div>
                                    <div id="advantages-of-metal-casting-accordion-five">
                                        <h5 class="text-left">
                                            Minimal sizing restraints
                                        </h5>
                                    </div>
                                    <hr class="m-0">
                                </div>

                                <!-- Versatile surface textures -->
                                <div>
                                    <div id="advantages-of-metal-casting-accordion-six">
                                        <h5 class="text-left">
                                            Versatile surface textures
                                        </h5>
                                    </div>
                                    <hr class="m-0">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Metal casting Materials -->
<section class="metal-casting-materials main-faq global-space-between" id="metal-casting-material">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 col-md-7">
                            <h1 class="py-3">Industrial-grade Metal casting materials </h1>
                        </div>
                        <div class="col-12 col-md-5 d-flex align-items-center">
                            <p>
                                In engineering, the right materials matter. We can make sure your final product looks and performs exactly as intended.
                            </p>
                        </div>
                    </div>
                    <!-- Table -->
                    <div class="row my-4">
                        <div class="col">
                            <!-- Collapse One -->
                            <div class="card border-0 mt-2" id="vacuum-casting-materials">
                                <div class="card-head">
                                    <h2 class="mb-0 px-0 card-header border-dark bg-transparent" data-toggle="collapse" data-target="#vacuum-casting-collapse" aria-expanded="false" aria-controls="vacuum-casting-collapse">
                                        Top metals
                                    </h2>
                                </div>


                                <div id="vacuum-casting-collapse" class="collapse show" aria-labelledby="vacuum-casting-accordion-two" data-parent="#vacuum-casting-materials">
                                    <div class="card-body">

                                        <div *ngFor="let metals of metalCastingData">
                                            <div class="row align-items-center">
                                                <div class="col-12 col-md-2 p-0">
                                                    <img src="{{metals?.imgUrl}}" class="img-fluid list-image" alt="Truventor">
                                                </div>
                                                <div class="col-12 col-md-10">
                                                    <div class="row align-items-center">
                                                        <div class="col-12 col-md-3">
                                                            <b class="d-block mt-2 mt-md-0"> {{metals?.name}} </b>
                                                        </div>
                                                        <div class="col-12 col-md-4">
                                                            <p class="m-0"> {{metals?.description}} </p>
                                                        </div>
                                                        <div class="col-12 col-md-5 text-right">
                                                            <a href="{{metals?.url}}" class="btn btn-primary py-2 px-4 mt-2 mt-md-0 mr-3">
                                                                Know More
                                                            </a>
                                                            <a [routerLink]="['/contact']"  rel="noopener" class="btn float-left float-md-right py-2 px-4 mt-2 mt-md-0">
                                                                Get In Touch
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col p-0">
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Things to Consider When Casting -->
<section class="advantages-of-metal-casting global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1>Things to consider when casting</h1>
                    <p>
                        There are numerous factors that need to be considered to ensure proper size, shape and integrity of the final component. Some of these factors include:
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row row-cols-1 row-cols-md-2">
                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <h5>
                            Type of material
                        </h5>
                        <p>
                            Each metal and casting material retains specific characteristics (hardness, melting point, density, etc.) that will affect the casting process.
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <h5>
                            Cooling rate
                        </h5>
                        <p>
                            This factor depends largely on the type of material from which you craft the mold. Proper cooling is necessary to minimize gas porosity and other negative properties that can result from a fast-cooling rate.
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <h5>Shrinkage </h5>
                        <p>
                            As castings cool, they shrink. To ensure proper component size and integrity, you can utilize risers to feed additional molten metal into the cavity. An oversized mold may also be useful in some applications.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Surface Finishing for Metal Casting -->
<section class="global-space-between exapandable-accordion">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-12 pl-md-0">
                <h1 class="py-4 text-left"> Post processing options </h1>
            </div>
        </div>
        <div class="row align-items-cener">
            <!-- Side Image - Desktop -->
            <div class="col-sm-12 col-md-6 p-0 d-none d-md-block">
                <img src="./assets/img/metal_casting/Surface Finishing for Metal Casting@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5 d-flex align-items-center">
                <div class="col-sm-12 col-md-12 p-2">
                    <!-- Title -->

                    <!-- Side Image - Mobile -->
                    <div class="col-sm-12 col-md-6 p-0 d-block d-md-none">
                        <img src="./assets/img/metal_casting/Surface Finishing for Metal Casting@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="w-100">
                            <div id="grow-your-businness-accordion-one" class="myaccordion">
                                <div class="card">
                                    <div class="card-header" id="growheadingOne">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100" data-toggle="collapse" data-target="#growcollapseOne" aria-expanded="true" aria-controls="growcollapseOne">
                                                FETTLING
                                                <i class="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseOne" class="collapse show" aria-labelledby="growheadingOne" data-parent="#grow-your-businness-accordion-one">
                                        <div class="card-body">
                                            <p>These are the first processes usually performed on raw sand and die-cast components. Fettling removes the risers and excess material.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingTwo">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseTwo" aria-expanded="false" aria-controls="growcollapseTwo">
                                                LINISHING
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseTwo" class="collapse" aria-labelledby="growheadingTwo" data-parent="#grow-your-businness-accordion-one">
                                        <div class="card-body">
                                            <p>Linishing is the process of grinding or sanding the surface to remove surface irregularities.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingThree">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseThree" aria-expanded="false" aria-controls="growcollapseThree">
                                                PEENING
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseThree" class="collapse" aria-labelledby="growheadingThree" data-parent="#grow-your-businness-accordion-one">
                                        <div class="card-body">
                                            <p>Peening is similar to shot blasting but less aggressive process where finer particles are blasted at the surface thus avoiding the risk of surface cracking in some alloys.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingFour">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseFour" aria-expanded="false" aria-controls="growcollapseFour">
                                                HEAT TREATMENT
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseFour" class="collapse" aria-labelledby="growheadingFour" data-parent="#grow-your-businness-accordion-one">
                                        <div class="card-body">
                                            <p>Castings can be subjected to various heat treatment techniques that can be used to harden the material, change the properties of the alloy or remove internal stresses.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-quality-assurance></app-quality-assurance>


<!-- Why Truventor? -->
<section class="why-truventor global-space-between bottom-end-padding">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="mb-5 text-center font-weight-bold">Why Truventor?</h1>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-2 row-cols-md-5 justify-content-center pt-3 px-1 px-md-0">

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/Access to global supplier network.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center">Access to global<br>supplier network</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/Deep supplier insights.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center">Deep supplier<br>insights</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/Transparency at each step.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center">Transparency<br>at each step</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/Humanizing communication.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center">Humanizing<br>communication</p>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/NON- Disclosure Agreement.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center">NON- disclosure <br> Agreement</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Schedule a meeting or book a demo -->
<section class="amazing-quote schedule-a-meeting-injection-moulding global-space-between">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div>
                    <!-- Quote -->
                    <h1 class="text-center text-white">
                        Schedule a <br> meeting or <br> book a demo
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center mt-4">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light px-md-5">
                            Request a call back </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Check out our other offerings -->
<section class="check-out-other-offer check-out-our-other-offerings global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center pb-5"> Check out our other offerings </h1>
            </div>
        </div>
        <div class="row row-cols-2 row-cols-md-5 justify-content-center">

            <!-- Sheet Metal -->
            <div class="col p-4 py-md-0 pointer" (click)="sheetMetal()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/Sheet Metal.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Sheet<br>Metal</p>
                    </div>
                </div>
            </div>

            <!-- CNC Machining -->
            <div class="col p-4 py-md-0 pointer" (click)="cncmachining()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/CNC Machining.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">CNC<br>Machining</p>
                    </div>
                </div>
            </div>

            <!-- 3D Printing -->
            <div class="col p-4 py-md-0 pointer" (click)="threedPrinting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/3DPrinting.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">3D<br>Printing</p>
                    </div>
                </div>
            </div>

            <!-- Vaccum Casting -->
            <div class="col p-4 py-md-0 pointer" (click)="vacuumCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/Vacuum Casting.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Vacuum<br>Casting</p>
                    </div>
                </div>
            </div>

            <!-- Injection Molding -->
            <div class="col p-4 py-md-0 pointer" (click)="injectionMolding()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/metal_casting/svg/Injection Moulding.svg" class="card-img-top" alt="Truventor">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Injection<br>Molding</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>