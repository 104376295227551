<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Copper C-1@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Copper <br> C106
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Copper C106 is resistant to corrosion in most atmospheres including marine and industrial environments.
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Copper C106.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    C106 (DHP; phosphorus deoxidised copper) is a grade of copper from which oxygen is removed by the controlled addition of phosphorus during the melting cycle. It is the material normally used for the manufacture of copper tubes as it can be readily welded
                    or brazed. C106 has deep drawing characteristics superior even to those of tough pitch copper (C101).


                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Copper C106
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Copper C106.png" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Density
                            </th>
                            <td>
                                892 Kg/m3
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Melting Point
                            </th>
                            <td>
                                1083 °C
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Modulus of Elasticity
                            </th>
                            <td>
                                117 GPa
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Electrical Resistivity
                            </th>
                            <td>
                                0.0171 µΩm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Electrical Conductivity
                            </th>
                            <td>
                                100 IACS
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Good thermal & electrical conductivity
                        </p>
                        <p class="mb-2">
                            Non-magnetic & spark resistant
                        </p>
                        <p class="mb-2">
                            Good corrosion resistance
                        </p>
                        <p class="mb-2">
                            Excellent joining properties
                        </p>
                        <p class="mb-2">
                            Ideal for plating and polishing
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Poor machinability
                        </p>

                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Heat exchanger plant
                        </p>
                        <p class="mb-2">
                            Calorifiers
                        </p>
                        <p class="mb-2">
                            Chemical plant
                        </p>
                        <p class="mb-2">
                            Storage tanks
                        </p>
                        <p class="mb-2">
                            Refrigeration plant
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>