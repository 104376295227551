<!--Zoho Campaigns Web-Optin Form Starts Here-->

<div id="customForm">
    <div class="quick_form_9_css" name="SIGNUP_BODY">
        <div>
            <span id="SIGNUP_HEADING"></span>
            <div style="position:relative;">
                <div id="Zc_SignupSuccess" style="display:none;position:absolute;margin-left:4%;width:90%;background-color: white; padding: 3px; border: 3px solid rgb(194, 225, 154);  margin-top: 10px;margin-bottom:10px;word-break:break-all ">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                        <tbody>
                            <tr>
                                <td width="10%">
                                    <img class="successicon" src="https://unfi.maillist-manage.in/images/challangeiconenable.jpg" align="absmiddle">
                                </td>
                                <td>
                                    <span id="signupSuccessMsg" style="color: rgb(73, 140, 132); font-family: sans-serif; font-size: 14px;word-break:break-word">&nbsp;&nbsp;Thank
                                        you for Signing Up</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <form method="POST" id="zcampaignOptinForm" action="https://maillist-manage.in/weboptin.zc"
                target="_zcSignup">
                <div id="errorMsgDiv">Please correct the marked field(s) below.</div>
                <div>
                    <input type="text" placeholder="Email" changeitem="SIGNUP_FORM_FIELD" name="CONTACT_EMAIL"
                        id="EMBED_FORM_EMAIL_LABEL">
                </div>
                <div>
                    <input type="submit" name="SIGNUP_SUBMIT_BUTTON" id="zcWebOptin" value="Subscribe">
                </div>
                <input type="hidden" id="fieldBorder" value="">
                <input type="hidden" id="submitType" name="submitType" value="optinCustomView">
                <input type="hidden" id="emailReportId" name="emailReportId" value="">
                <input type="hidden" id="formType" name="formType" value="QuickForm">
                <input type="hidden" name="zx" id="cmpZuid" value="1df8b9e1b0">
                <input type="hidden" name="zcvers" value="2.0">
                <input type="hidden" name="oldListIds" id="allCheckedListIds" value="">
                <input type="hidden" id="mode" name="mode" value="OptinCreateView">
                <input type="hidden" id="zcld" name="zcld" value="1b9f03965b77f7c">
                <input type="hidden" id="zctd" name="zctd" value="">
                <input type="hidden" id="document_domain" value="">
                <input type="hidden" id="zc_Url" value="unfi.maillist-manage.in">
                <input type="hidden" id="new_optin_response_in" value="0">
                <input type="hidden" id="duplicate_optin_response_in" value="0">
                <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW">
                <input type="hidden" id="zc_formIx" name="zc_formIx"
                    value="3za006630a1be27fae133925247f132ea6bb8404408ae745fa02b9ce8d99fc4796">
                <input type="hidden" id="viewFrom" value="URL_ACTION">
                <input type="hidden" id="scriptless" name="scriptless" value="yes">
            </form> -->
        </div>
    </div>
</div>
<div id="zcOptinOverLay" oncontextmenu="return false" style="display:none;text-align: center; background-color: rgb(0, 0, 0); opacity: 0.5; z-index: 100; position: fixed; width: 100%; top: 0px; left: 0px; height: 988px;">
</div>
<div id="zcOptinSuccessPopup" style="display:none;z-index: 9999;width: 800px; height: 40%;top: 84px;position: fixed; left: 26%;background-color: #FFFFFF;border-color: #E6E6E6; border-style: solid; border-width: 1px;  box-shadow: 0 1px 10px #424242;padding: 35px;">
    <span style="position: absolute;top: -16px;right:-14px;z-index:99999;cursor: pointer;" id="closeSuccess">
        <img src="https://unfi.maillist-manage.in/images/videoclose.png">
    </span>
    <div id="zcOptinSuccessPanel"></div>
</div>

<!--Zoho Campaigns Web-Optin Form Ends Here-->


<footer class="footer">
    <div class="container p-md-0">
        <div class="row row-cols-2 row-cols-md-6">
            <div class="col">
                <!-- <h4><img src="./assets/img/header-img/Logotype-Inverted.svg" alt="Truventor"></h4> -->
                <h5>Company</h5>

                <ul class="list-group list-group-flush">
                    <li class="list-group-item"> <a [routerLink]="['/about']"> About Us</a></li>
                    <!-- <li class="list-group-item"><a [routerLink]="['/contact']">Customer Login </a></li> -->
                    <!-- <li class="list-group-item"><a [routerLink]="['/contact']">Partner Login </a></li> -->
                    <!-- <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/platform']">The Platform
                        </a></li> -->
                    <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/media']">Press
                        </a></li>
                    <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/contact']">Contact Us
                        </a></li>
                </ul>



                <div class="bottom-menu">
                    <h5>Partners </h5>

                    <ul class="list-group list-group-flush">
                        <li class="list-group-item">
                            <a (click)=" scrollToTop()" [routerLink]="['/manufacturing-partners']">
                                Become A Partner</a>
                        </li>
                        <!-- <li class="list-group-item"><a (click)=" scrollToTop()"
                                [routerLink]="['/finance']">Truventor.Finance
                            </a></li> -->
                        <li class="list-group-item">
                            <a (click)=" scrollToTop()" [routerLink]="['/calculate']">Truventor.Calculate
                            </a>
                        </li>
                        <!-- <li class="list-group-item">
                            <a (click)="scrollToTop()" [routerLink]="['/procurement']">Truventor.Materials
                            </a>
                        </li>

                        <li class="list-group-item">
                            <a [routerLink]="['/truventorone']" fragment="truventor-control">Truventor.Control
                            </a>
                        </li>

                        <li class="list-group-item">
                            <a [routerLink]="['/finance']">Truventor.Money
                            </a>
                        </li> -->
                    </ul>
                </div>


            </div>


            <div class="col">
                <div class="bottom-menu-1">
                    <h5> Platform </h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/platform']">Product</a>
                        </li>

                        <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/security']">Security
                            </a>
                        </li>
                        <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/quality-assurance']">
                                Quality Assurance</a></li>
                        <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/truventorone']">
                                Truventor.One</a></li>
                        <!-- <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/buyer-protection']">
                                Buyer Protection</a></li> -->
                        <!-- <li class="list-group-item"><a [routerLink]="['/manufacturing-as-a-service']"> MAAS</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col">
                <h5>Capabilities</h5>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/sheet-metal-fabrication']">Sheet Metal</a></li>
                    <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/cnc-machining']"> CNC
                            Machining </a></li>
                    <li class="list-group-item">
                        <a (click)=" scrollToTop()" [routerLink]="['/3d-printing']">3D Printing
                        </a>
                    </li>
                    <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/vacuum-casting']">Vacuum
                            Casting</a></li>
                    <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/plastic-injection-moulding']">Injection Molding</a></li>
                    <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/metal-casting']">Metal
                            Casting</a></li>
                    <!-- <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/materials']">Materials
                        </a></li> -->
                    <!-- <li class="list-group-item"><a>Post-processing</a></li> -->
                </ul>
            </div>


            <div class="col">
                <div class="bottom-menu-1">
                    <h5>Materials</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><a [routerLink]="['/sheet-metal-fabrication']" fragment="sheet-metal-material">Sheet Metal Materials </a></li>
                        <li class="list-group-item"><a [routerLink]="['/3d-printing']" fragment="3dprinting-material">3D
                                Printing Materials </a></li>
                        <li class="list-group-item"><a [routerLink]="['/cnc-machining']" fragment="cnc-machining-material">CNC Machining Materials </a></li>
                        <li class="list-group-item"><a [routerLink]="['/metal-casting']" fragment="metal-casting-material">Metal Casting Materials </a></li>
                        <li class="list-group-item"><a [routerLink]="['/plastic-injection-moulding']" fragment="injection-mould-material">Injection Moulding Materials </a></li>
                        <li class="list-group-item"><a [routerLink]="['/vacuum-casting']" fragment="vacuumcasting-material">Vacuum Casting Materials </a></li>
                    </ul>
                </div>
            </div>


            <div class="col">


                <div class="bottom-menu-1">
                    <h5>Resources</h5>
                    <ul class="list-group list-group-flush">
                        <li class="list-group-item"><a href="https://blogs.truventor.ai/blogs/">Knowledge
                                Cockpit </a> </li>
                        <li class="list-group-item"><a [routerLink]="['/case-study']">Case Studies </a></li>
                        <li class="list-group-item">
                            <a [routerLink]="['/articles']">Articles
                            </a>
                        </li>
                        <!-- <li class="list-group-item">
                            <a [routerLink]="['/media']" target="_blank" rel="noopener">Media
                            </a>
                        </li> -->
                        <li class="list-group-item">
                            <a [routerLink]="['/events']">Events
                            </a>
                        </li>
                        <li class="list-group-item">
                            <a [routerLink]="['/materials']">Materials Guide
                            </a>
                        </li>
                        <li class="list-group-item"><a (click)=" scrollToTop()" [routerLink]="['/faq']">FAQs</a></li>


                    </ul>
                </div>

            </div>



            <div class="col">
                <h5>Legal</h5>
                <ul class="list-group list-group-flush">
                    <li class="list-group-item"><a [routerLink]="['/terms']">General Terms And
                            Conditions</a></li>
                    <li class="list-group-item">
                        <a [routerLink]="['/client-terms']">Client Terms
                            And Conditions</a>
                    </li>
                    <li class="list-group-item">
                        <a (click)=" scrollToTop()" [routerLink]="['/supplier-terms']">Supplier Terms And Conditions</a>
                    </li>
                    <li class="list-group-item"><a [routerLink]="['/privacy']">Privacy Policy</a></li>
                    <!-- <li class="list-group-item"><a href="#">Customer service
                            agreement</a></li>
                    <li class="list-group-item"><a href="#">Manufacturing partners
                            service agreement</a></li>
                    <li class="list-group-item"><a href="#">End-user licence agreement</a></li> -->
                    <!-- <li class="list-group-item"><a [routerLink]="['/imprint']">Imprint</a></li> -->
                </ul>
            </div>
        </div>

        <div class="row justify-content-end">
            <div class="col-md-5">
                <form method="POST" id="zcampaignOptinForm" action="https://maillist-manage.in/weboptin.zc" target="_zcSignup">

                    <div class="input-group mb-3">
                        <input class="form-control" type="text" placeholder="Email" changeitem="SIGNUP_FORM_FIELD" name="CONTACT_EMAIL" id="EMBED_FORM_EMAIL_LABEL">
                        <div class="input-group-append">
                            <button class="input-group-text" type="submit" name="SIGNUP_SUBMIT_BUTTON" id="zcWebOptin" value="Subscribe">Subscribe</button>
                        </div>
                    </div>
                    <input type="hidden" id="fieldBorder" value="">
                    <input type="hidden" id="submitType" name="submitType" value="optinCustomView">
                    <input type="hidden" id="emailReportId" name="emailReportId" value="">
                    <input type="hidden" id="formType" name="formType" value="QuickForm">
                    <input type="hidden" name="zx" id="cmpZuid" value="1df8b9e1b0">
                    <input type="hidden" name="zcvers" value="2.0">
                    <input type="hidden" name="oldListIds" id="allCheckedListIds" value="">
                    <input type="hidden" id="mode" name="mode" value="OptinCreateView">
                    <input type="hidden" id="zcld" name="zcld" value="1b9f03965b77f7c">
                    <input type="hidden" id="zctd" name="zctd" value="">
                    <input type="hidden" id="document_domain" value="">
                    <input type="hidden" id="zc_Url" value="unfi.maillist-manage.in">
                    <input type="hidden" id="new_optin_response_in" value="0">
                    <input type="hidden" id="duplicate_optin_response_in" value="0">
                    <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW">
                    <input type="hidden" id="zc_formIx" name="zc_formIx" value="3za006630a1be27fae133925247f132ea6bb8404408ae745fa02b9ce8d99fc4796">
                    <input type="hidden" id="viewFrom" value="URL_ACTION">
                    <input type="hidden" id="scriptless" name="scriptless" value="yes">
                </form>

            </div>
        </div>

        <div class="row justify-content-between copyright">
            <div class="col-md-4">
                Copyright © 2022 Truventor AI And Robotics.<br> All rights reserved.
            </div>
            <div class="col-md-4">
                <ul class="list-inline text-right social-links">
                    <li class="list-inline-item">
                        <a href="https://www.facebook.com/Truventor" target="_blank" rel="noopener"><i
                                class="fa fa-facebook"></i></a>
                    </li>
                    <li class="list-inline-item">
                        <a href="https://www.linkedin.com/company/truventor-ai/" target="_blank" rel="noopener"><i
                                class="fa fa-linkedin"></i></a>
                    </li>
                    <li class="list-inline-item">
                        <a href="https://twitter.com/Truventor" target="_blank" rel="noopener"><i
                                class="fa fa-twitter"></i></a>
                    </li>
                    <li class="list-inline-item">
                        <a href="https://www.instagram.com/truventor.ai/" target="_blank" rel="noopener"><i
                                class="fa fa-instagram"></i></a>
                    </li>
                    <li class="list-inline-item">
                        <a href="https://www.youtube.com/channel/UC3ra_vBUtIEYoABoZ-UVbIQ" target="_blank" rel="noopener"><i class="fa fa-youtube-play"></i></a>
                    </li>
                </ul>
            </div>
        </div>


    </div>
</footer>