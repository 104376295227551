import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CNC_MACHINING_METALS, CNC_MACHINING_PLASTICS, reloadTime } from 'src/app/shared/data';
import { AdminService } from '../shared/admin.service';

@Component({
  selector: 'app-cncmachining',
  templateUrl: './cncmachining.component.html',
  styleUrls: ['./cncmachining.component.scss']
})
export class CncmachiningComponent implements OnInit {

  public cncMaterialsData = CNC_MACHINING_METALS;
  public cncPlasticsData = CNC_MACHINING_PLASTICS

  private fragment: string;
  constructor(
    private adminservice: AdminService,
    private router: Router,
    private route: ActivatedRoute

  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
      console.log(this.fragment);
      if (this.fragment != 'cnc-machining-material') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        setTimeout(() => {
          document.getElementById('cnc-machining-material').scrollIntoView();          
        }, reloadTime);
      }
    });
    this.adminservice.check_customer.next('customer')
  }

  // ngAfterViewInit(): void {
  //   try {
  //     document.querySelector('#' + this.fragment).scrollIntoView(
  //       {
  //         behavior: 'smooth'
  //       }
  //     );
  //   } catch (e) { }
  // }

  sheetMetal() {
    this.router.navigate(['/sheet-metal-fabrication']);
  }

  threedPrinting() {
    this.router.navigate(['/3d-printing']);
  }

  vacuumCasting() {
    this.router.navigate(['/vacuum-casting']);
  }

  injectionMolding() {
    this.router.navigate(['/plastic-injection-moulding']);
  }

  metalCasting() {
    this.router.navigate(['/metal-casting']);
  }

}
