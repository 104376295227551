<!-- Main -->
<section class="main-session">
    <img src="assets/img/media/Media Banner Image.png" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>
                        Media
                    </h1>
                    <p class="mt-4">
                        The world took notice! Read on to know what the media has to say about us.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row mt-5">
            <div class="col-sm-12 col-md-4">
                <a href="#" target="_blank" rel="noopener" class="btn btn-primary w-100"> Contact Us </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Media -->
<section class="global-space-between media">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center">
                    Media
                </h1>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 mt-4">

            <div class="col mb-4">
                <a href="https://www.tagmaindia.org/newsletter/april-2021/" target="_blank" rel="noopener" class="card border-0">
                    <img src="/assets/img/media/Tagma Times.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <h4 class="card-title mb-4">
                            Interview of Mr.Soumitra Joshi
                        </h4>
                        <kbd> Manufacturing </kbd>
                        <kbd> Interview </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://economictimes.indiatimes.com/tech/information-tech/truventor-acquires-chizel-io/articleshow/79754493.cms?from=mdr" target="_blank" rel="noopener" class="card border-0 ">
                    <img src="/assets/img/media/Econmic Times.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <h4 class="card-title mb-4">
                            Truventor acquires Chizel.io
                        </h4>
                        <kbd> ChizeL </kbd>
                        <kbd> Acquisition </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.thehindu.com/business/truventor-acquires-chizelio-in-all-stock-deal/article33348239.ece" target="_blank" rel="noopener" class="card border-0 ">
                    <img src="/assets/img/media/The Hindu.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <h4 class="card-title mb-4">
                            Truventor acquires Chizel.io in all stock deal
                        </h4>
                        <kbd> ChizeL </kbd>
                        <kbd> Acquisition </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://yourstory.com/2019/04/make-in-india-chizel-manufacturing-startup/amp" target="_blank" rel="noopener" class="card border-0">
                    <img src="/assets/img/media/Yourstory.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <h4 class="card-title mb-4">
                            Chizel is enabling product companies to build at smaller scale
                        </h4>
                        <kbd> Product </kbd>
                        <kbd> Cloud </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://www.forbes.com/sites/michaelmandel1/2019/09/05/digital-manufacturing-3dprinting-make-in-india-modi-asia-industry/?sh=12af163f1665" target="_blank" rel="noopener" class="card border-0 ">
                    <img src="/assets/img/media/Forbes.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <h4 class="card-title mb-4">
                            Building the New Manufacturing Stack
                        </h4>
                        <kbd> Manufacturing </kbd>
                        <kbd> 5.0 </kbd>
                    </div>
                </a>
            </div>

            <div class="col mb-4">
                <a href="https://inc42.com/buzz/gsf-bullish-on-deeptech-invests-1-mn-in-four-startups/" target="_blank" rel="noopener" class="card border-0 ">
                    <img src="/assets/img/media/Inc42.jpg" class="card-img-top" alt="Truventor">
                    <div class="card-body px-3">
                        <h4 class="card-title mb-4">
                            GSF Bullish On Deeptech, Invests $1 Mn In Four Startups
                        </h4>
                        <kbd> Funding </kbd>
                        <kbd> Startup </kbd>
                    </div>
                </a>
            </div>

        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-12 col-md-4">
                <div class="btn w-100"> Show More </div>
            </div>
        </div>
    </div>
</section>

<!-- Last Section -->
<section class="global-space-between bottom-end-padding a-trueventor">
    <div class="container-fluid">
        <div class="row">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-4 p-0 d-none d-md-block">
                <img src="./assets/img/customer_landing/Book a Demo.jpg" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-8 pl-md-5 d-flex align-items-center">
                <div class="w-100">
                    <!-- Title -->
                    <h1>
                        Do you want <br> a free quote <br> in 24 hours?
                    </h1>
                    <!-- Paragraph -->
                    <p class="mt-4 mb-0">
                        Sign up for a free trial and find out how  <br> Truventor makes Manufacturing simple.
                    </p>
                    <!-- Action Buttons -->
                    <div class="row">

                        <div class="col-sm-12 col-md-4">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                                Get In Touch
                            </a>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn w-100">
                                Book A Demo
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>