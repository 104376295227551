<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Aluminium 5052@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Aluminium <br> 5052
                    </h1>
                    <p class="text-primary">
                        CNC Machining / Sheet Metal fabrication
                    </p>
                    <p class="mt-4">
                        Non-heat treatable alloy with good corrosion resistance
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Aluminum 5052.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Type 5052 aluminum contains 97.25% Al, 2.5%Mg, and 0.25%Cr, and its density is 2.68 g/cm3 (0.0968 lb/in3). Generally, 5052 aluminum alloy is stronger than other popular alloys such as 3003 aluminum and also has improved corrosion resistance due to the
                    absence of copper in its composition.They are sensitive to high temperatures ranging between 200 and 250°C (392 and 482°F), and might lose some of their strength.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5">
                    Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Aluminium 5052
                    </strong>
                </h6>
                <img src="./assets/img/./assets/img/material_list/aluminium-5052/Aluminium5052@2x.png" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Material Color
                            </th>
                            <td>
                                Silver white
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Tensile strength
                            </th>
                            <td>
                                195–290 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Thermal conductivity (k)
                            </th>
                            <td>
                                138 W/m*K
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Specific heat capacity (c)
                            </th>
                            <td>
                                880 J/kg*K
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Melting temperature (Tm)
                            </th>
                            <td>
                                607 °C (1,125 °F)
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Corrosion resistance
                        </p>
                        <p class="mb-2">
                            Light-weight and low maintenance
                        </p>
                        <p class="mb-2">
                            Anti-static
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            It is not easily machined and not heat treatable
                        </p>

                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Pressure vessels
                        </p>
                        <p class="mb-2">
                            Marine equipment
                        </p>
                        <p class="mb-2">
                            Electronic enclosures
                        </p>
                        <p class="mb-2">
                            Milk crates & Appliances
                        </p>
                        <p class="mb-2">
                            Medical equipment
                        </p>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>