import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { LazyimageloadDirective } from '../app/shared/directives/lazyimageload.directive';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MarketingPortalModule } from './marketing-portal/marketing-portal.module';
import { FooterComponent } from './marketing-portal/shared/footer/footer.component';
import { HeaderComponent } from './marketing-portal/shared/header/header.component';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: 'www.truventor.ai'
  },
  palette: {
    popup: {
      background: '#0b1116'
    },
    button: {
      background: '#ff6900'
    }
  },
  position: "bottom-left",
  theme: 'classic',
  type: 'info',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": `
    <div class="cookie-wrapper">
      <img src="assets/img/Cookie.svg" style="height:50px;margin-right:30px">
      <div class="content">{{messageLink}}{{compliance}}</div>
    </div>`
  },
  elements: {
    messageLink: `
      <div id="cookieconsent:desc" class="cc-message">
        {{message}} 
        <a aria-label="learn more about our privacy policy" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a>
      </div>
    `,
  },
  content: {
    message: 'We use cookies to provide our services and for analytics and marketing. To find out more about our use of cookies, please see our privacy policy. By continuing to browse our website, you agree to our use of cookies.',
    privacyPolicyLink: 'Learn More',
    privacyPolicyHref: 'https://www.truventor.ai/privacy',
    dismiss: 'I Accept',
    revokable: 'false'
  }

};



@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    LazyimageloadDirective,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MarketingPortalModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }


