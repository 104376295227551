<!-- Main -->
<section class="main-session">
    <img src="assets/img/manage/manage-cover.png" class="img-banner" alt="Truventor">
    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="heading">
                    <h1>
                        Truventor <br> Manage
                        <!-- <span class="star">*</span> -->

                    </h1>
                    <p class="mt-4">
                        The all-in-one supplier management platform for your manufacturing from start to end.
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-primary w-100"> Book A
                    Demo </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <!-- <p class="ref">*For Enterprise Customers Only</p> -->
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Digitally manage your suppliers -->
<section class="digitally-manage-your-suppliers global-space-between">
    <div class="container">

        <!-- accordion -->
        <div class="row align-items-center">


            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 order-12 order-md-1 pl-0">
                <!-- collapse -->
                <div class="w-100">
                    <!-- Accordion -->
                    <div class="mt-0">
                        <div class="col-sm-12 col-md-10 pl-3 pl-md-0">
                            <h1 class="pb-4">
                                Digitally manage your suppliers
                            </h1>
                            <!-- Accordion -->
                            <div class="accordion" id="digitally-manage-your-suppliers">

                                <!-- Everything you need to know about your supplier real time. -->
                                <div>
                                    <div class="row">
                                        <div class="col">
                                            <hr class="m-0 mb-2">
                                            <h6 class="text-left font-weight-bold">
                                                Everything you need to know about your supplier in real-time.
                                            </h6>
                                            <p>
                                                A unified platform to access manufacturing partners from multiple geographies a single place on the cloud.
                                            </p>
                                            <hr class="m-0 mb-2">
                                        </div>
                                    </div>
                                </div>

                                <!-- + Unlimited Project File Storage -->
                                <div>
                                    <div id="digitally-manage-your-suppliers-accordion-two">
                                        <div class="row">
                                            <div class="col">
                                                <h6 class="text-left">
                                                    <img src="./assets/img/chev-right-black.svg" alt="Truventor" class="mr-2"> Unlimited project file storage
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0">
                                </div>

                                <!-- + NDAs and contracts at single place -->
                                <div>
                                    <div id="digitally-manage-your-suppliers-accordion-three">
                                        <div class="row">
                                            <div class="col">
                                                <h6 class="text-left">
                                                    <img src="./assets/img/chev-right-black.svg" alt="Truventor" class="mr-2"> NDAs and contracts in a single place
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0">
                                </div>

                                <!-- Realtime and updated Supplier info -->
                                <div>
                                    <div id="digitally-manage-your-suppliers-accordion-four">
                                        <div class="row">
                                            <div class="col">
                                                <h6 class="text-left">
                                                    <img src="./assets/img/chev-right-black.svg" alt="Truventor" class="mr-2"> Real-Time supplier info at your fingertips
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0">
                                </div>

                                <!-- Supplier Relationship Score -->
                                <div>
                                    <div id="digitally-manage-your-suppliers-accordion-four">
                                        <div class="row">
                                            <div class="col">

                                                <h6 class="text-left">
                                                    <img src="./assets/img/chev-right-black.svg" alt="Truventor" class="mr-2"> Supplier relationship score
                                                </h6>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <!-- Side Image -->
            <div class="col-sm-12 col-md-6 order-1 order-md-12 p-3 p-md-0 d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start">
                <img src="./assets/img/manage/Manage@2x.png" class="img-fluid w-100" alt="Truventor">
            </div>
        </div>
    </div>
</section>

<!-- Advantages -->
<section class="manage-advantages global-space-between">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="my-5 text-center font-weight-bold">
                    Advantages
                </h1>
                <p class="text-center">
                    <b>
                        The quickest and most accurate way to conduct bids & place orders
                    </b>
                </p>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-1 row-cols-md-4 justify-content-center pt-4 px-1 px-md-0">

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2 ">
                    <img src="./assets/img/manage/svg/Find The Lowest Bidder.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Find the lowest bidder
                        </p>
                        <hr>
                        <p class="text-center">
                            Check the earlier file for corrections in these points
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2 ">
                    <img src="./assets/img/manage/svg/Reduce Risk Upfront.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Reduce risk upfront
                        </p>
                        <hr>
                        <p class="text-center">
                            View Qualified Suppliers, Check Their Rating And Past Performances.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2 ">
                    <img src="./assets/img/manage/svg/Increase Transparency.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Increase transparency
                        </p>
                        <hr>
                        <p class="text-center">
                            Track your order in real-time during each manufacturing phase.
                        </p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 my-4 my-md-0 px-0 px-md-2 ">
                    <img src="./assets/img/manage/svg/Data driven decision making.svg" loading="lazy" class="card-img-top" alt="Truventor">
                    <div class="card-body p-0">
                        <p class="card-text text-center font-weight-bold">
                            Data driven decision making
                        </p>
                        <hr>
                        <p class="text-center">
                            Track, monitor and compile qualitative and quantitative assessments for fact based performance & reviews
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- Works in 3 simple steps -->
<section class="works-in-3-simple-steps global-space-between">
    <div class="container-fluid">
        <!-- 1, 2, 3 -->
        <div class="row">
            <div class="col">
                <!-- Heading -->
                <div class="row pb-5">
                    <div class="col-md-12 text-center pr-5">
                        <h1 class="font-weight-bold">
                            Find new manufacturing partners
                        </h1>
                        <b>
                            Empowering buyers to source faster and smarter
                        </b>
                    </div>
                </div>

                <!-- One -->
                <div class="row py-5 align-items-center justify-content-end">
                    <div class="col-md-5 pr-5">
                        <h1 class="font-weight-bold">
                            Discover your <br> supplier
                        </h1>
                        <hr>
                        <!-- Mobile Image -->
                        <div class="d-block d-md-none">
                            <img src="assets/img/discover/Discover-Your-Supplier.png" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                        <h5 class="mt-3">
                            Search from the list of Truventor-verifed network
                        </h5>
                        <p class="mt-4">
                            Explore through the list of Truventor verified supplier based on cost, rating, geographies and many more parameters. See their detailed profiles before floating enquiries
                        </p>
                    </div>
                    <div class="col-md-6 pr-0 d-none d-md-block">
                        <img src="assets/img/discover/Discover-Your-Supplier.png" loading="lazy" class="img-fluid" alt="Truventor">
                    </div>
                </div>

                <!-- Two -->
                <div class="row align-items-center justify-content-end py-5">
                    <div class="col-md-5 pr-5">
                        <h1 class="font-weight-bold">
                            Conduct bids to get best costing
                        </h1>
                        <hr>
                        <!-- Mobile Image -->
                        <div class="d-block d-md-none">
                            <img src="assets/img/discover/Conduct-Bids.png" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                        <h5 class="mt-3">
                            The quickest and most accurate way to level your bids
                        </h5>
                        <p class="mt-4">
                            - Send RFQs, get and compare quotes from different suppliers. <br> - Network more efficiently and effectively. <br> - Upload your drawings / BOM.<br> - Set your bid conditions. <br> - Get the best cost within a stipulated timeline
                            without the hassle of negotiations.<br> - Award business. <br> - Keep a track of your business allocations.<br>
                        </p>
                    </div>
                    <div class="col-md-6 pr-0 d-none d-md-block">
                        <div class="tab-content">
                            <img src="assets/img/discover/Conduct-Bids.png" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                    </div>
                </div>

                <!-- Three -->
                <div class="row align-items-center justify-content-end py-5">
                    <div class="col-md-5 pr-5">
                        <h1 class="font-weight-bold">
                            Order tracking <br> and delivery
                        </h1>
                        <hr>
                        <!-- Mobile Image -->
                        <div class="d-block d-md-none">
                            <img src="assets/img/discover/Order-Tracking.png" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                        <h5 class="mt-3">
                            Single dasboard for all your orders
                        </h5>
                        <p class="mt-4">
                            - Track in real-time the status and quality updates of the complete part manufacturing lifecycle. <br> - Get complete visibility of the shop floor.
                        </p>
                    </div>
                    <div class="col-md-6 pr-0 d-none d-md-block">
                        <div class="tab-content">
                            <img src="assets/img/discover/Order-Tracking.png" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Button -->
        <div class="row justify-content-center">
            <div class="col-3 mt-5 d-none d-md-block">
                <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-primary w-100"> Book A
                    Demo </a>
            </div>
        </div>
    </div>
</section>

<!-- Advantages for suppliers -->
<section class="advantages-for-suppliers global-space-between bottom-end-padding">
    <div class="container">

        <div class="row align-items-center">
            <div class="col">
                <!-- One -->
                <div class="row justify-content-end align-items-center py-5">
                    <!-- Accordion -->
                    <div class="col-md-6 pr-5">
                        <!-- Title -->
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <h1 class="text-left">
                                    Advantages for suppliers
                                </h1>
                            </div>
                        </div>
                        <!-- Side Image - Mobile -->
                        <div class="col-sm-12 col-md-6 p-0 d-block d-md-none">
                            <img src="assets/img/manage/Advantages.jpg" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Accordion -->
                        <div class="mt-4 mr-0 mr-md-5">
                            <div class="w-100">
                                <div class="accordion" id="advantages-for-suppliers">
                                    <!-- + Mobile app based workflow -->
                                    <div>
                                        <div id="advantages-for-suppliers-accordion-one">
                                            <h6 class="text-left">
                                                Mobile app based workflow
                                            </h6>
                                        </div>
                                        <hr class="m-0">
                                    </div>
                                    <!-- + Enquiry management system for their shop floor -->
                                    <div>
                                        <div id="advantages-for-suppliers-accordion-two">
                                            <h6 class="text-left">
                                                Enquiry management system for their shop floor
                                            </h6>
                                        </div>
                                        <hr class="m-0">
                                    </div>
                                    <!-- + Cash-flow & Payment management -->
                                    <div>
                                        <div id="advantages-for-suppliers-accordion-three">
                                            <h6 class="text-left">
                                                Cash-flow & payment management
                                            </h6>
                                        </div>
                                        <hr class="m-0 mb-2">
                                    </div>
                                    <!-- + Access to early payments -->
                                    <div>
                                        <div id="advantages-for-suppliers-accordion-four">
                                            <h6 class="text-left">
                                                Access to early payments
                                            </h6>
                                        </div>
                                        <hr class="m-0">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Side Image - Desktop -->
                    <div class="col-md-6 pr-0 d-none d-md-block">
                        <div class="tab-content">
                            <img src="assets/img/manage/Advantages.jpg" loading="lazy" class="img-fluid" alt="Truventor">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>