import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alumnium-a5052',
  templateUrl: './alumnium-a5052.component.html',
  styleUrls: ['./alumnium-a5052.component.scss']
})
export class AlumniumA5052Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

}
