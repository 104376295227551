<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Inconel 718@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Inconel 718
                    </h1>
                    <p class="text-primary">
                        3D Printing
                    </p>
                    <p class="mt-4">
                        3D-printed Inconel retains strength over a wide temperature range
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Inconel 718.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    A1008 (AISI 1008) cold rolled (CR) steel sheet exhibits improved formability over hot rolled sheet. ASTM A1008 CR is produced by annealing and temper rolling hot rolled steel sheets. This process provides formability, improved surface texture and flatness.
                    The process of cold rolling the sheet creates a smooth surface which is typically oiled to prevent surface rust from forming. Compared to hot rolled steel, A1008 is known for having excellent weldability and formability.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5">
                    Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Inconel 718
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Inconel 718 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Layer thickness
                            </th>
                            <td>
                                0.03 – 0.1 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Minimum wall thickness
                            </th>
                            <td>
                                1 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Minimum detail
                            </th>
                            <td>
                                0.5 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum part dimensions
                            </th>
                            <td>
                                250 x 250 x 280 mm (offline orders)
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Good mechanical properties
                        </p>
                        <p class="mb-2">
                            High Yield tensile strength
                        </p>
                        <p class="mb-2">
                            High temperature resistant
                        </p>
                        <p class="mb-2">
                            Excellent welding characteristics
                        </p>
                        <hr>
                    </div>
                </div>





                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Chemical processing
                        </p>
                        <p class="mb-2">
                            Aerospace
                        </p>
                        <p class="mb-2">
                            Liquid fuel rocket motor components
                        </p>
                        <p class="mb-2">
                            Cryogenic storage tanks
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>