import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pp-machining',
  templateUrl: './pp-machining.component.html',
  styleUrls: ['./pp-machining.component.scss']
})
export class PpMachiningComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })

  }
  

}
