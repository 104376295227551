<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Copper 260@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Copper 260 <br> (Brass)
                    </h1>
                    <p class="text-primary">
                        Fabrication & machining
                    </p>
                    <p class="mt-4">
                        Copper 260 is an attractive material with a smooth, yellow brass finish
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Copper 260 (Brass).pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    C260 brass is the most ductile of the brasses and is used in greater quantity than any other copper-zinc alloy. Copper alloy 260 has excellent to good corrosion resistance in most environments but is not suitable for use with certain materials, such as
                    acetic acid, moist ammonia or ammonia compounds, hydrochloric acid and nitric acid. Its functional and aesthetic qualities—such as its bending, and tonal quality—make it well-suited for diverse industries.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Copper 260 (Brass)
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Copper 260 Brass 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Material hardness
                            </th>
                            <td>
                                B77 (per Rockwell scale)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ultimate tensile strength
                            </th>
                            <td>
                                62,000 psi
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Yield strength
                            </th>
                            <td>
                                52,000 psi
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Elongation
                            </th>
                            <td>
                                23%
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Conductivity Rating
                            </th>
                            <td>
                                28% per International Annealed Copper Standard (IACS)
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Excellent corrosion resistance
                        </p>
                        <p class="mb-2">
                            Suitable for variety of fabrication processes
                        </p>
                        <p class="mb-2">
                            High ductility
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Not suitable for specific machining operations
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Appliance parts
                        </p>
                        <p class="mb-2">
                            Decorative furniture pieces
                        </p>
                        <p class="mb-2">
                            Electrical and electronic equipment
                        </p>
                        <p class="mb-2">
                            Fasteners and hinges
                        </p>

                        <hr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>