<!-- Main -->
<section class="main-session">
    <img src="assets/img/3d_printing_capabilities/sls/Group20093@2x.jpeg" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        Selective Laser Sintering (SLS)
                    </h1>
                    <p class="mt-4">
                        Create highly durable final parts with our SLS services
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!--  -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    Selective laser sintering (SLS)
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    Selective laser sintering (SLS) is a powder-based 3D printing technology used to manufacture production grade, end-use parts. It finds application in prototyping and low-volume batch production runs.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div class="mt-5 mt-md-0">
                    <div class="row my-3 align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/3d_printing_capabilities/sls/Group 20097@2x.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                Nylon-12 (PA 2200)
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row my-3 align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/3d_printing_capabilities/sls/Group 20096@2x.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                GF Nylon-12 (PA 3200)
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Max part size</th>
                            <td>
                                340 x 340 x 600 mm
                            </td>
                        </tr>
                        <tr>
                            <th>Accuracy </th>
                            <td>
                                (+/-) 200µm
                            </td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>
                                4-5 business days
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1 class="mb-5 mb-md-4">
                        Attributes
                    </h1>
                    <p class="mb-5 mb-md-4">
                        Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                    </p>
                    <!-- Desktop Button -->
                    <div class="d-none d-md-block">
                        <a [routerLink]="['/contact']" class="mt-4 btn px-5"> Get In Touch </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Overview.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Highlights
                        </h3>
                        <p>
                            Fast procedure
                        </p>
                        <p>
                            Does not require a support material, since powder has a supporting effect in the installation space
                        </p>
                        <p>
                            Possibility to use the entire installation space
                        </p>
                        <p>
                            Large selection of materials
                        </p>
                        <p>
                            During the printing process, further objects can be inserted which fit into the remaining installation space
                        </p>
                        <p>
                            Various finishing or post-processing options (see above)
                        </p>
                        <p>
                            Very good mechanical properties
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Limitation.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Limitations
                        </h3>
                        <p>
                            Rough surface
                        </p>
                        <p>
                            Without surface treatment, the objects can turn yellow over time
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Application.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Applications
                        </h3>
                        <p>
                            Medical and healthcare
                        </p>
                        <p>
                            Electronics, packaging and connectors
                        </p>
                        <p>
                            Rapid tooling (concept development and bridge tools)
                        </p>
                        <p>
                            Injection mould inserts
                        </p>
                        <p>
                            Tooling and manufacturing estimating visual aid
                        </p>
                        <p>
                            Jigs and fixtures
                        </p>
                    </div>
                </div>
                <hr>

                <!-- Mobile Button -->
                <div class="px-3 d-block d-md-none">
                    <a [routerLink]="['/contact']" class="mt-4 btn btn-block"> Get In Touch </a>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant 3D printing quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <div class="video-wrapper">
                            <img src="./assets/img/3d_printing_capabilities/sls/Screen Shot.webp" class="fluid-img w-100" alt="video">
                            <img src="./assets/img/3d_printing_capabilities/svg/PlayButton.svg" class="play-button" alt="Truventor">
                        </div>

                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>