import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-toosteel-agrade',
  templateUrl: './material-toosteel-agrade.component.html',
  styleUrls: ['./material-toosteel-agrade.component.scss']
})
export class MaterialToosteelAgradeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

}
