import { Component, OnInit } from '@angular/core';
import { AdminService } from '../shared/admin.service';

@Component({
  selector: 'app-material-nylon12-pa2200',
  templateUrl: './material-nylon12-pa2200.component.html',
  styleUrls: ['./material-nylon12-pa2200.component.scss']
})
export class MaterialNylon12Pa2200Component implements OnInit {

  constructor(
    private adminservice: AdminService
  ) { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })
    this.adminservice.check_customer.next('customer')
  }

}
