<!-- Main -->
<section class="main-session">
    <img src="assets/img/startup/startup-banner.png" class="img-banner" alt="Truventor">
    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>Hardware <span> Startup</span>
                        <br> Program
                    </h1>
                    <p class="mt-4">Program help them with prototyping to production requirements for their hardware product. </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" class="btn btn-primary w-100"> Apply Now </a>
            </div>
        </div>



        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>



<section class="global-space-between">
    <div class="container">
        <div class="row">
            <!-- Image -->
            <div class="col-md-6 p-4 d-flex justify-content-center justify-content-md-left">
                <img src="./assets/img/startup/Overview@2x.png" class="img-fluid" alt="contact image">
            </div>
            <!-- Text -->
            <div class="col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                <div class="col-sm-12 col-md-12 pl-0 pl-md-5">
                    <h1 class="text-center text-md-left talk-to">
                        Overview
                    </h1>
                    <p>
                        You and your team have decided to build an unrivaled Hardware startup. The idea is great, and everyone on your team is passionate about it. However, hardware startups have a unique set of challenges such as designing, prototyping, quality checks, finding
                        the right suppliers among others. Truventor Start-up Program is designed for Early Stage hardware companies to help them with prototyping to production requirements for their hardware product.
                    </p>
                    <div class="action-button mt-4 d-flex justify-content-center justify-content-md-start">
                        <a [routerLink]="['/contact']" class="btn mb-5 mb-md-0"> Contact Us </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>



<section class="how-works global-space-between bottom-end-padding">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-5">
                <div class="content">
                    <h1>How this works? </h1>
                    <p class="mt-3">Truventor is committed to helping the manufacturing industry achieve best-in-class quality through its reliable and transparent cloud manufacturing platform.</p>
                    <a [routerLink]="['/contact']" class="btn how-this-btn">Get In Touch</a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="list-holder">
                    <div class="list">
                        <h5>Step 1</h5>
                        <p>Start-ups from any hardware field including, but not restricted to, Aerospace, Optics, Medical Devices, EVs, IOT, Home Automation, Bio Science, Industrial Engineering etc apply for the program.
                        </p>
                    </div>
                </div>
                <div class="list-holder">
                    <div class="list">
                        <h5>Step 2</h5>
                        <p>Truventor experts select Startups based on pre-defined criteria and sign an MOU.
                        </p>
                    </div>
                </div>
                <div class="list-holder">
                    <div class="list">
                        <h5>Step 3</h5>
                        <p>As a final step, onboarding of the selected start up is done to the Truventor Start-up Program.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- Schedule a meeting -->
<section class="amazing-quote global-space-between">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8">
                <div>
                    <!-- Quote -->
                    <h1 class="text-center text-white">
                        Schedule a meeting or book a demo
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center mt-4">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn btn-light px-md-5">
                            Request a call back </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="grow-your-business global-space-between">
    <div class="container">

        <!-- Desktop Slider -->
        <div class="d-none d-md-block">

            <div class="row align-items-center">
                <div class="col-6 pl-0">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                            <img src="./assets/img/startup/Benefit.svg" class="img-fluid" width="100%" style="height: 320px;" alt="Truventor">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <h1 class="text-center text-md-left pl-3">
                        Benefits and credits
                    </h1>

                    <div class="content">
                        <div class="nav flex-column nav-pills pl-2" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <span class="plus">
                                    Truventor credits
                                </span>
                                <p>
                                    INR 50,000/- in Truventor credits valid for 1 year that can be used for manufacture components from prototyping to production stages using 11 technologies and 28+ materials available with Truventor. (Note: A maximum discount of 15% of order size can be
                                    utilized per order )
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                <span class="plus">
                                    Cloud manufacturing platform
                                </span>
                                <p>
                                    Lifetime access to pro-features of Truventor proprietary manufacturing platform.
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Truventor enterprise support
                                </span>
                                <p>
                                    Truventor enterprise support valid for 1 year. It gives you access to 24x7 access to cloud platform and hardware engineers via email, chat, and phone and trusted advisory checks and guidance from our experienced engineering team.
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-support" role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Content and workshops
                                </span>
                                <p>
                                    Free access to the Truventor technical and business workshops along with quarterly workshops for the incubators and start-ups.
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Mobile Slider -->
        <div class="row d-block d-md-none">
            <div class="col">

                <!-- Title -->
                <h1 class="text-center text-md-left">
                    Benefits and credits
                </h1>

                <!-- One -->
                <div>
                    <img src="./assets/img/startup/Benefit.svg" class="img-fluid py-4 baf-img" width="100%" alt="Truventor">
                    <h5 class="text-left">
                        Truventor credits
                    </h5>
                    <hr>
                    <p>
                        INR 50,000/- in Truventor Credits valid for 1 year that can be used for manufacture components from prototyping to production stages using 11 technologies and 28+ materials available with Truventor. (Note: A maximum discount of 15% of order size can be
                        utilized per order )
                    </p>
                </div>
                <!-- Two -->
                <div>
                    <!-- <img src="./assets/img/supplier/Group 16935-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Cloud manufacturing platform
                    </h5>
                    <hr>
                    <p>
                        Lifetime access to pro-features of Truventor proprietary manufacturing platform.
                    </p>
                </div>
                <!-- Three -->
                <div>
                    <!-- <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Truventor enterprise support
                    </h5>
                    <hr>
                    <p>
                        Truventor enterprise support valid for 1 year. It gives you access to 24x7 access to cloud platform and hardware engineers via email, chat, and phone and trusted advisory checks and guidance from our experienced engineering team.
                    </p>
                </div>

                <div>
                    <!-- <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Content and workshops
                    </h5>
                    <hr>
                    <p>
                        Free access to the Truventor technical and business workshops along with quarterly workshops for the incubators and start-ups. </p>
                </div>

            </div>
        </div>

    </div>
</section>


<section class="grow-your-business global-space-between">
    <div class="container">

        <!-- Desktop Slider -->
        <div class="d-none d-md-block">

            <div class="row align-items-center">

                <div class="col-6">
                    <h1 class="text-center text-md-left pr-5">
                        Why Truventor?
                    </h1>

                    <div class="content pr-5">
                        <div class="nav flex-column nav-pills" id="why-pills-tab" role="tablist" aria-orientation="vertical">
                            <a class="nav-link active" id="why-pills-home-tab" data-toggle="pill" href="#why-pills-home" role="tab" aria-controls="why-pills-home" aria-selected="true">
                                <span class="plus">
                                    Digital manufacturing workflow
                                </span>
                                <p>
                                    We make manufacturing simple and accessible unlike ever before. With our cloud platform, everything is in one place to bring efficiency in your workflow
                                </p>
                            </a>
                            <a class="nav-link" id="why-pills-profile-tab" data-toggle="pill" href="#why-pills-profile" role="tab" aria-controls="why-pills-profile" aria-selected="false">
                                <span class="plus">
                                    Qualified & vetted network of machines
                                </span>
                                <p>
                                    Truventor utilizes un-utilised capacity of best in class suppliers. With our suppliers network, we are the largest on-demand manufacturing company with widest materials offerings. Truventor project engineering team is responsible for making sure that
                                    parts are done correctly and are delivered on time.
                                </p>
                            </a>
                            <a class="nav-link" id="why-pills-messages-tab" data-toggle="pill" href="#why-pills-messages" role="tab" aria-controls="why-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Competitive cost with committed timelines
                                </span>
                                <p>
                                    We bring better service, committed delivery at same price. We have consistently maintained OTIF 95%+.
                                </p>
                            </a>
                            <a class="nav-link" id="why-pills-messages-tab" data-toggle="pill" href="#why-pills-support" role="tab" aria-controls="why-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Extra pair of hands
                                </span>
                                <p>
                                    For companies with existing supplier base, we bring them a whole-suite of additional materials (72 types), processes (14 types) and capacity.
                                </p>
                            </a>
                            <a class="nav-link" id="why-pills-messages-tab" data-toggle="pill" href="#why-pills-support" role="tab" aria-controls="why-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Data security
                                </span>
                                <p>
                                    We understand that very important research work is being done by the incubate companies. The customer data is not shared with anyone, including us, until the customer chooses to place an order. No need to send your files to multiple vendor for cost, timeline
                                    and feasibility.
                                </p>
                            </a>
                            <a class="nav-link" id="why-pills-messages-tab" data-toggle="pill" href="#why-pills-support" role="tab" aria-controls="why-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Knowledge is free
                                </span>
                                <p>
                                    Free access to our exclusive content on different manufacturing processes. We can also take free sessions on accelerating the manufacturing for the companies.
                                </p>
                            </a>
                        </div>
                    </div>


                </div>
                <div class="col-6 pl-0">
                    <div class="tab-content" id="why-pills-tabContent">
                        <div class="tab-pane show active" id="why-pills-home" role="tabpanel" aria-labelledby="why-pills-home-tab">
                            <img src="./assets/img/startup/Why-Truventor@2x.png" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <!-- <div class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                            aria-labelledby="v-pills-profile-tab">
                            <img src="./assets/img/supplier/Group 16935.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="v-pills-messages" role="tabpanel"
                            aria-labelledby="v-pills-messages-tab">
                            <img src="./assets/img/supplier/GetOnWork.png" class="img-fluid" width="100%" alt="Truventor">
                        </div> -->
                    </div>
                </div>

            </div>

        </div>

        <!-- Mobile Slider -->
        <div class="row d-block d-md-none">
            <div class="col">

                <!-- Title -->
                <h1 class="text-center text-md-left">
                    Why Truventor?
                </h1>

                <!-- One -->
                <div>
                    <img src="./assets/img/startup/Why-Truventor@2x.png" class="img-fluid py-4" width="100%" alt="Truventor">
                    <h5 class="text-left">
                        Digital manufacturing workflow
                    </h5>
                    <hr>
                    <p>
                        We make manufacturing simple and accessible unlike ever before. With our cloud platform, everything is in one place to bring efficiency in your workflow
                    </p>
                </div>
                <!-- Two -->
                <div>
                    <!-- <img src="./assets/img/supplier/Group 16935-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Qualified & vetted network of machines
                    </h5>
                    <hr>
                    <p>
                        Truventor utilizes un-utilised capacity of best in class suppliers. With our suppliers network, we are the largest on-demand manufacturing company with widest materials offerings. Truventor Project Engineering team is responsible for making sure that
                        parts are done correctly and are delivered on time.
                    </p>
                </div>
                <!-- Three -->
                <div>
                    <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                    <h5 class="text-left">
                        Competitive cost with committed timelines
                    </h5>
                    <hr>
                    <p>
                        We bring better service, committed delivery at same price. We have consistently maintained OTIF 95%+.
                    </p>
                </div>
                <div>
                    <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                    <h5 class="text-left">
                        Extra pair of hands
                    </h5>
                    <hr>
                    <p>
                        For companies with existing supplier base, we bring them a whole-suite of additional materials (72 types), processes (14 types) and capacity.
                    </p>
                </div>
                <div>
                    <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                    <h5 class="text-left">
                        Data security
                    </h5>
                    <hr>
                    <p>
                        We understand that very important research work is being done by the incubate companies. The customer data is not shared with anyone, including us, until the customer chooses to place an order. No need to send your files to multiple vendor for cost, timeline
                        and feasibility.
                    </p>
                </div>

                <div>
                    <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                    <h5 class="text-left">
                        Knowledge is free
                    </h5>
                    <hr>
                    <p>
                        Free access to our exclusive content on different manufacturing processes. We can also take free sessions on accelerating the manufacturing for the companies.
                    </p>
                </div>
            </div>
        </div>

    </div>
</section>



<section class="global-space-between bottom-end-padding a-trueventor">
    <div class="container-fluid">
        <div class="row">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-4 p-0 d-none d-md-block">
                <img src="./assets/img/startup/CTA@2x.jpg" width="100%" loading="lazy" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-8 pl-md-5 d-flex align-items-center">
                <div class="w-100">
                    <!-- Title -->
                    <h1>
                        Apply to our hardware <br> start-up program!
                    </h1>
                    <!-- Paragraph -->
                    <p class="mt-4 mb-0">
                        Email : care@truventor.ai <br> with questions on Truventor Start-up Program
                        <!-- Convert your bold ideas into reality! <br> Talk to Us, to know more! -->
                    </p>
                    <!-- Action Buttons -->
                    <div class="row">

                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100"> Apply Now </a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>