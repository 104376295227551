<!-- Main -->
<section class="main-session">
    <img src="assets/img/calculate/Baner Image@2x.png" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>
                        Truventor Calculate
                    </h1>
                    <p class="mt-4">
                        A price estimation tool that allows you to process RFQs in minutes instead of days
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener"
                    class="btn btn-primary w-100"> Get In Touch </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Advantages of calculate -->
<section class="side-tab-view global-space-between">
    <div class="container">

        <!-- Desktop View -->
        <div class="d-none d-md-block">

            <div class="row">
                <div class="col-6 pl-0">
                    <div class="tab-content" id="main_id">
                        <div class="tab-pane fade show active" id="how-to-use-specify-requirement" role="tabpanel"
                            aria-labelledby="how-to-use-specify-requirement-tab">
                            <img src="./assets/img/calculate/2.png" class="img-fluid" width="100%" loading="lazy"
                                alt="Truventor">
                        </div>
                        <div class="tab-pane fade " id="how-to-use-upload" role="tabpanel"
                            aria-labelledby="how-to-use-upload-tab">
                            <img src="./assets/img/calculate/1.png" class="img-fluid" width="100%" loading="lazy"
                                alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="how-to-use-place-order-1" role="tabpanel"
                            aria-labelledby="how-to-use-place-order-tab-1">
                            <img src="./assets/img/calculate/4.jpg" class="img-fluid" width="100%" loading="lazy"
                                alt="Truventor">
                        </div>
                        <div class="tab-pane fade" id="how-to-use-place-order" role="tabpanel"
                            aria-labelledby="how-to-use-place-order-tab">
                            <img src="./assets/img/calculate/3.png" class="img-fluid" width="100%" loading="lazy"
                                alt="Truventor">
                        </div>
                    </div>
                </div>

                <div class="col-6 pr-5 advantages-of-calculate">
                    <h1 class="text-left mb-3">
                        Advantages of Truventor.Calculate
                    </h1>
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">

                        <a class="nav-link active" id="how-to-use-specify-requirement-tab" data-toggle="pill"
                            href="#how-to-use-specify-requirement" role="tab"
                            aria-controls="how-to-use-specify-requirement" aria-selected="false">
                            <span class="plus">
                                Sophisticated rendering modes
                            </span>
                            <p>
                                Visualize the 3D model in the four different rendering mode such as such as solid mode,
                                shaded mode, X-ray mode, and wireframe mode to understand its geometry better.
                            </p>
                        </a>
                        <a class="nav-link" id="how-to-use-place-order-tab" data-toggle="pill"
                            href="#how-to-use-place-order" role="tab" aria-controls="how-to-use-place-order"
                            aria-selected="false">
                            <span class="plus">
                                Multi-dimensional section view
                            </span>
                            <p>
                                Section view allows you to slice the 3D model with three directions like X, Y and Z and
                                view its interior geometry. Easy drag slide on slider changes the position of the
                                cutting plan along its axis. One can toggle direction and cutting direction.
                            </p>
                        </a>
                        <a class="nav-link" id="how-to-use-place-order-tab-1" data-toggle="pill"
                            href="#how-to-use-place-order-1" role="tab" aria-controls="how-to-use-place-order-1"
                            aria-selected="false">
                            <span class="plus">
                                Browser-based quote generator
                            </span>
                            <p>
                                Accept request for quotes through our secure supplier portal or even your internet
                                website. Generate quotes in minutes with our online quoting tool.
                            </p>
                        </a>
                        <a class="nav-link" id="how-to-use-upload-tab" data-toggle="pill" href="#how-to-use-upload"
                            role="tab" aria-controls="how-to-use-upload" aria-selected="true">
                            <span class="plus">
                                Effective communication
                            </span>
                            <p>
                                Render the measurements at a single click, take screenshots of dimensions and share it
                                with the team, clients or suppliers for most efficient communication and bringing
                                transparency in the system.
                            </p>
                        </a>
                    </div>

                    <div class="mt-4">
                        <a class="btn" href="https://calendly.com/truventor/15min" target="_blank">Book A Demo</a>

                    </div>

                </div>

            </div>

        </div>

        <!-- Mobile View -->
        <div class="d-block d-md-none mobile-view">
            <div class="row">
                <div class="col">
                    <!-- Title -->
                    <h1 class="text-center text-md-left">
                        Advantages of Truventor.Calculate
                    </h1>

                    <!-- One -->
                    <div>
                        <div class="d-flex justify-content-center">
                            <img src="./assets/img/calculate/2.png" class="img-fluid py-4" loading="lazy"
                                alt="Truventor">
                        </div>
                        <h5 class="text-left">
                            Sophisticated rendering modes
                        </h5>
                        <hr>
                        <p>
                            Visualize the 3D model in the four different rendering mode such as such as solid mode,
                            shaded mode, X-ray mode, and wireframe mode to understand its geometry better.
                        </p>
                    </div>
                    <!-- Two -->
                    <div>
                        <div class="d-flex justify-content-center">
                            <img src="./assets/img/calculate/1.png" class="img-fluid py-4" loading="lazy"
                                alt="Truventor">
                        </div>

                        <h5 class="text-left">
                            Multi-dimensional section view
                        </h5>
                        <hr>
                        <p>
                            Section view allows you to slice the 3D model with three directions like X, Y and Z and
                            view its interior geometry. Easy drag slide on slider changes the position of the
                            cutting plan along its axis. One can toggle direction and cutting direction.
                        </p>
                    </div>
                    <!-- Three -->
                    <div>
                        <div class="d-flex justify-content-center">
                            <img src="./assets/img/calculate/4.jpg" class="img-fluid py-4" loading="lazy"
                                alt="Truventor">
                        </div>
                        <h5 class="text-left">
                            Browser-based quote generator
                        </h5>
                        <hr>
                        <p>
                            Accept request for quotes through our secure supplier portal or even your internet
                            website. Generate quotes in minutes with our online quoting tool.
                        </p>
                    </div>
                    <div>
                        <div class="d-flex justify-content-center">
                            <img src="./assets/img/calculate/3.png" class="img-fluid py-4" loading="lazy"
                                alt="Truventor">
                        </div>
                        <h5 class="text-left">
                            Effective communication
                        </h5>
                        <hr>
                        <p>
                            Render the measurements at a single click, take screenshots of dimensions and share it
                            with the team, clients or suppliers for most efficient communication and bringing
                            transparency in the system.
                        </p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>

<!-- Features -->
<section class="how-it-works global-space-between bottom-end-padding">
    <div class="container-fluid">
        <!-- how it works desktop -->
        <div class="d-none d-sm-none d-md-block">
            <div class="row justify-content-end">
                <div class="col-md-5">
                    <div class="wrapper">
                        <h1 class="font-weight-bold"> Features </h1>
                        <p>
                            3D CAD viewer that simplifies design-to-manufacturing workflow, and provide transparency.
                        </p>
                        <ul class="nav nav-tabs row" id="myTab" role="tablist">
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link active" data-toggle="tab" href="#home" role="tab"
                                    aria-controls="home">
                                    <hr>
                                    <h6 class="font-weight-bold">Multiple view</h6>
                                    <p>Orthographic, perspective, isometric view different standard views</p>
                                </a>
                            </li>
                            <!-- <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#messages" role="tab"
                                    aria-controls="messages">
                                    <hr>
                                    <h6 class="font-weight-bold">2D viewer</h6>
                                    <p>supports the DXF file format mainly used for profile cutting of unfolded sheet
                                        metal components</p>
                                </a>
                            </li> -->
                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#settings" role="tab"
                                    aria-controls="settings">
                                    <hr>
                                    <h6 class="font-weight-bold">CAD meta data</h6>
                                    <p>See volume, surface area and dimension (length, breadth, height) of 3D mode</p>
                                </a>
                            </li>

                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#messages" role="tab"
                                    aria-controls="messages">
                                    <hr>
                                    <h6 class="font-weight-bold">Nesting optimization</h6>
                                    <p>Enhance the cutting optimization through a powerful library for automatic nesting
                                        of complex 2D shapes and 3D blocks.</p>
                                </a>
                            </li>

                            <li class="nav-item col-md-6 p-0">
                                <a class="nav-link" data-toggle="tab" href="#profile" role="tab"
                                    aria-controls="profile">
                                    <hr>
                                    <h6 class="font-weight-bold">Cost calculator </h6>
                                    <p>Calculator provides a powerfull cost estimator for making quick quotes</p>
                                </a>
                            </li>

                        </ul>
                    </div>
                </div>
                <div class="col-md-6 pr-0">
                    <div class="tab-content text-right">
                        <div class="tab-pane active" id="home" role="tabpanel">
                            <img src="assets/img/calculate/View Port.png" class="img-fluid" loading="lazy"
                                alt="Truventor">
                        </div>
                        <!-- <div class="tab-pane" id="messages" role="tabpanel">
                            <img src="assets/img/calculate/DXF.png" class="img-fluid" loading="lazy" alt="Truventor">
                        </div> -->
                        <div class="tab-pane" id="profile" role="tabpanel">
                            <img src="assets/img/calculate/Cost.png" class="img-fluid" loading="lazy" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="messages" role="tabpanel">
                            <img src="assets/img/calculate/nesting.jpg" class="img-fluid" loading="lazy"
                                alt="Truventor">
                        </div>
                        <div class="tab-pane" id="settings" role="tabpanel">
                            <img src="assets/img/calculate/Meta view.png" class="img-fluid" loading="lazy"
                                alt="Truventor">
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- done how it works desktop -->
        <!-- start how it works mobile -->

        <div class="d-block d-sm-block d-md-none">
            <div class="row">

                <!-- Text -->
                <div class="col col-sm-12 p-0 col-md-6 d-flex align-items-center flex-row-reverse">

                    <div class="col-sm-12">
                        <h3 class="font-weight-bold">Features</h3>
                        <p>
                            3D CAD viewer that simplifies design-to-manufacturing workflow, and provide transparency.
                        </p>
                        <!-- 2x2 -->
                        <div class="text-2x2">
                            <div class="row">
                                <div class="col-sm-12 col-md-6 hover-on">
                                    <hr>
                                    <h6 class="font-weight-bold">Multiple View</h6>
                                    <p>
                                        Orthographic, Perspective, Isometric view Different standard views
                                    </p>
                                    <!-- Mobile Only Image -->
                                    <div class="col col-sm-12 col-md-6 d-block d-md-none"> <img
                                            src="./assets/img/calculate/View Port.png" class="img-fluid" width="100%"
                                            loading="lazy" alt="Truventor">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 hover-on">
                                    <hr>
                                    <h6 class="font-weight-bold">CAD meta data</h6>
                                    <p>See volume, surface area and dimension (length, breadth, height) of 3D mode</p>
                                    <!-- Mobile Only Image -->
                                    <div class="col col-sm-12 col-md-6 d-block d-md-none"> <img
                                            src="./assets/img/calculate/Cost.png" class="img-fluid" width="100%"
                                            loading="lazy" alt="Truventor">
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 hover-on">
                                    <hr>
                                    <h6 class="font-weight-bold">Nesting optimization</h6>
                                    <p>
                                        Enhance the cutting optimization through a powerful library for automatic
                                        nesting
                                        of complex 2D shapes and 3D blocks.
                                    </p>
                                    <div class="col col-sm-12 col-md-6 d-block d-md-none">
                                        <img src="./assets/img/calculate/nesting.jpg" class="img-fluid" width="100%"
                                            loading="lazy" alt="Truventor">
                                    </div>
                                </div>

                                <div class="col-sm-12 col-md-6 hover-on">
                                    <hr>
                                    <h6 class="font-weight-bold">Cost calculator </h6>
                                    <p>Calculator provides a powerfull cost estimator for making quick quotes</p>

                                </div>
                                <div class="col col-sm-12 col-md-6 d-block d-md-none">
                                    <img src="./assets/img/calculate/Meta view.png" class="img-fluid" width="100%"
                                        loading="lazy" alt="Truventor">
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- Testimonials -->
<section class="testimonial global-space-between">
    <div class="container">
        <div class="row pt-5">
            <!-- Title -->
            <div class="col-sm-12 col-md-6">
                <h1>
                    What our users say on Play Store.
                </h1>
            </div>
            <!-- Carousel -->
            <div class="col-sm-12 col-md-6">
                <owl-carousel-o [options]="clientsOptions" class="clients-carouel">

                    <ng-template carouselSlide>
                        <div class="w-100 d-flex justify-content-center justify-content-md-start">
                            <img src="./assets/img/customer_landing/testimonials/Calculate Avatar@2x.png" width="76px"
                                height="76px" alt="client_image">
                        </div>
                        <p class="py-3">
                            “It is a great application. It can be used by press brake operators and fabricators to check
                            3D
                            shape of the part which will be much easier than reading CAD blue prints. Costing feature is
                            also
                            great which will help in generating quick quotes.”
                        </p>
                        <h6>
                            Himanshu Saraswat
                        </h6>
                    </ng-template>

                </owl-carousel-o>

            </div>
        </div>
    </div>
</section>

<!-- Control your data -->
<section class="global-space-between">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <div class="d-flex">
                    <img src="assets/img/calculate/Group 20381@2x.png" class="img-fluid" alt="Control data">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <h1>
                    Only you control your data
                </h1>
                <p>
                    We believe that you should be in total control of your data. Thus, to strengthen our commitment, we
                    give you access to DELETE the part file anytime you want. Once the file is deleted by you, it is
                    deleted from our server as well, and so are the links associated with it. This makes it better than
                    any e-mail system out there.
                    However, this does NOT delete the information associated with your CAD design. This information
                    could be part name, dimensions, attributes, recommendations, quotations, manufacturing log etc.
                </p>
            </div>
        </div>
    </div>
</section>

<!-- Video Player -->
<section class="global-space-between bottom-end-padding">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <h1 class="text-center">
                    Modern CAD platform for machine shops
                </h1>
                <img src="assets/img/calculate/Group 20380@2x.png" class="img-fluid" alt="Preview">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-4">
                <a class="btn btn-primary w-100"
                    href="https://play.google.com/store/apps/details?id=io.chizel.chizelviewer" target="__blank">
                    Download Now
                </a>
            </div>
        </div>
    </div>
</section>