<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Aluminum 3003@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Aluminum <br> 3003
                    </h1>
                    <p class="text-primary">
                        Machining / Fabrication
                    </p>
                    <p class="mt-4">
                        Aluminum 3003 has better mechanical properties, especially at elevated temperatures than the 1000 series alloys.
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Aluminum 3003.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    The most commonly used aluminum alloy is 3003. It is mainly used in manufacturing utensils, aluminum storage tanks, and for architectural applications. 3003 is pure aluminum with an addition of manganese that increases the strength up to 20 percent without
                    compromising the flexibility. This is what keeps it highly workable. However, 3003 is not heat treatable.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Aluminum 3003
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Aluminum 3003 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Density
                            </th>
                            <td>
                                2.73 g/cm³
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Melting Point
                            </th>
                            <td>
                                655 °C
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Thermal Expansion
                            </th>
                            <td>
                                23.1 x10-6 /K
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Modulus of Elasticity
                            </th>
                            <td>
                                69.5 GPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Thermal Conductivity
                            </th>
                            <td>
                                190 W/m.K
                            </td>
                        </tr>

                        <tr>
                            <th>
                                Electrical Resistivity
                            </th>
                            <td>
                                0.034 x10-6 Ω .m
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Good weldability/formability
                        </p>
                        <p class="mb-2">
                            Very good corrosion resistance
                        </p>
                        <p class="mb-2">
                            High strength, and heat tolerance
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->


                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Sheet metal stock
                        </p>
                        <p class="mb-2">
                            Builder’s hardware
                        </p>
                        <p class="mb-2">
                            Chemical equipment
                        </p>
                        <p class="mb-2">
                            Trucks and trailers
                        </p>
                        <p class="mb-2">
                            Fuel tanks
                        </p>
                        <p class="mb-2">
                            Cabinets
                        </p>

                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<app-cost-estimate></app-cost-estimate>