import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-galvanized-steel',
  templateUrl: './material-galvanized-steel.component.html',
  styleUrls: ['./material-galvanized-steel.component.scss']
})
export class MaterialGalvanizedSteelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
