export const METAL_LIST = [
    { id: 1, name: 'Aluminium 6082', description: 'Light-weight components / Great machinability', imgUrl: './assets/img/material_list/Group 19466.jpg', tags: ['CNC Machining', 'Sheet Metal Fabrication'], url: '/cnc-machining/materials/aluminium-6082' },
    { id: 2, name: 'Aluminium 6061-T6', description: 'Medium strength alloy used for making lightweight metal components', imgUrl: './assets/img/material_list/Group 19467.jpg', tags: ['CNC Machining', 'Sheet Metal Fabrication'], url: '/cnc-machining/materials/aluminium-6061-T6' },
    { id: 3, name: 'Aluminium 7075', description: ' Non-heat treatable alloy with good corrosion resistance', imgUrl: './assets/img/material_list/ALUMINIUN 7075@2x.png', tags: ['CNC Machining', 'Sheet Metal fabrication'], url: '/cnc-machining/materials/aluminium-7075' },
    { id: 4, name: 'Aluminium 5052', description: 'Non-heat treatable alloy with good corrosion resistance', imgUrl: './assets/img/material_list/Group 19465.jpg', tags: ['CNC Machining', 'Sheet Metal fabrication'], url: '/cnc-machining/materials/aluminium-5052' },
    { id: 5, name: 'Aluminum 3003', description: 'Aluminum 3003 has better mechanical properties, especially at elevated temperatures than the 1000 series alloys.', imgUrl: './assets/img/material_list/Aluminum 3003@2x.jpg', tags: ['CNC Machining', 'Sheet Metal fabrication', 'Metal Casting '], url: '/cnc-machining/materials/aluminium-3003' },
    { id: 6, name: 'Mild Steel', description: 'Low cost steel with moderate strength, good case hardening properties,formability and weldability.', imgUrl: './assets/img/material_list/Mild Steel@2x.png', tags: ['CNC Machining'], url: '/cnc-machining/materials/mild-steel' },
    { id: 7, name: 'Mild Steel 1018', description: '1018 mild steel offers a good balance of toughness, strength and ductility.', imgUrl: './assets/img/material_list/Mild Steel 1018@2x.png', tags: ['CNC Machining'], url: '/cnc-machining/materials/mild-steel-1018' },
    { id: 8, name: 'Stainless Steel (316)', description: 'Stainless Steel 316 gives excellent toughness, even at cryogenic temperatures.', imgUrl: './assets/img/material_list/Stainless Steel 316@2x.jpg', tags: ['CNC Machining', 'Metal Casting', 'Sheet Metal Fabrication'], url: '/cnc-machining/materials/stainless-steel316' },
    { id: 9, name: 'Stainless Steel 304', description: 'Light-weight components / Great machinability', imgUrl: './assets/img/material_list/Stainless Steel 304@2x.jpg', tags: ['CNC Machining', 'Metal Casting', 'Sheet Metal Fabrication'], url: 'cnc-machining/materials/stainless-steel304' },
    { id: 10, name: 'Stainless Steel 301', description: '301 steel is mainly used in the cold working state to withstand higher loads', imgUrl: './assets/img/material_list/Stainless Steel 301@2x.jpg', tags: ['Sheet Metal Fabrication', 'Metal Casting', 'CNC Machining'], url: '/cnc-machining/materials/stainless-steel301' },
    { id: 11, name: 'Galvanized Steel', description: 'Galvanized steel procides extended durability and formability of steel and corrosion protection of zinc.', imgUrl: './assets/img/material_list/Galvanized steel@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/galvanized-steel' },
    { id: 12, name: 'Low Carbon Steel CR 1008', description: 'Low carbon steel 1008 provides excellent surface finish and press formability.', imgUrl: './assets/img/material_list/Low Carbon Steel CR 1008@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/lowcarbon-steel1008' },
    { id: 13, name: 'Titanium Grade 1', description: 'One of the softer and more ductile grades of pure Titanium', imgUrl: './assets/img/material_list/Titanium Grade 1@2x.jpg', tags: ['CNC Machining'], url: '/cnc-machining/materials/titanium-grade1' },
    { id: 14, name: 'Titanium Grade 2', description: 'Require good ductility and corrosion resistance makes it suitable for most industrial applications ', imgUrl: './assets/img/material_list/Titanium Grade 2@2x.jpg', tags: ['CNC Machining'], url: '/cnc-machining/materials/titanium-grade2' },
    { id: 15, name: 'Titanium Grade 3', description: 'Grade 3 has good impact properties at low temperatures', imgUrl: './assets/img/material_list/Titanium Grade 3@2x.jpg', tags: ['CNC Machining'], url: '/cnc-machining/materials/titanium-grade3' },
    { id: 16, name: 'Titanium Grade 4', description: 'Titanium Grade 4 is the highest strength pure unalloyed Titanium', imgUrl: './assets/img/material_list/Titanium Grade 4@2x.jpg', tags: ['CNC Machining'], url: '/cnc-machining/materials/titanium-grade4' },
    { id: 17, name: 'Titanium Grade 5', description: 'This versatile material offers high fatigue resistance and good biocompatibility.', imgUrl: './assets/img/material_list/Titanium Grade 5@2x.jpg', tags: ['CNC Machining'], url: '/cnc-machining/materials/titanium-grade4' },
    { id: 18, name: 'Copper 101', description: 'C101 has good solderability and corrosion resistance and is used for high current applications.', imgUrl: './assets/img/material_list/Copper 101@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/copper-c110' },
    { id: 19, name: 'Copper 106', description: 'Copper C106 is resistant to corrosion in most atmospheres including marine and industrial environments.', imgUrl: './assets/img/material_list/Copper C106@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/copper-106' },
    { id: 20, name: 'Copper 110', description: 'C110 mild steel offers a good balance of toughness, strength and ductility.', imgUrl: './assets/img/material_list/Copper C110@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/copper-c110' },
    { id: 21, name: 'Copper 260', description: 'Copper 260 is an attractive material with a smooth, yellow brass finish', imgUrl: './assets/img/material_list/Copper 260@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/copper-c260' },
    { id: 22, name: 'Brass C360', description: 'Excellent high speed machining operations and superior thread rolling and knurling characteristics.', imgUrl: './assets/img/material_list/Brass C360@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/brass-c360' },
    { id: 23, name: 'Inconel 625', description: 'Inconel 625 on liner: Inconel 625 is known for its high strength and excellent fabricability.', imgUrl: './assets/img/material_list/Inconel 625@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/inconel-625' },
    { id: 24, name: 'Inconel 718', description: '3D-printed Inconel retains strength over a wide temperature range', imgUrl: './assets/img/material_list/Inconel 718@2x.jpg', tags: ['3D Printing'], url: '/cnc-machining/materials/inconel-718' },
    { id: 25, name: 'Tool Steel W Grade', description: 'This grade of tool steel provides maximum toughness and adequate wear-resistance', imgUrl: './assets/img/material_list/Tool Steel W Grade@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/toolsteel-wgrade' },
    { id: 26, name: 'Tool Steel A Grade', description: 'This versatile material offers high fatigue resistance and good biocompatibility. ', imgUrl: './assets/img/material_list/Tool Steel A Grade@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/toolsteel-agrade' },
    { id: 27, name: 'Tool Steel S7', description: 'Tool Steel S7 has good resistance to softening at moderately high temperatures.', imgUrl: './assets/img/material_list/Tool Steel S7@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/inconel-625' },
    { id: 28, name: 'Tool Steel D2', description: 'D2 Tool Steel offers excellent wear resistance', imgUrl: './assets/img/material_list/Tool Steel D2@2x.jpg', tags: ['Sheet Metal Fabrication'], url: '/cnc-machining/materials/inconel-625' },

];




export const PLASTIC_LIST = [
    { id: 1, name: 'ABS (Basic)', description: 'Cost-effective prototypes | Rigid | Non-functional Parts', imgUrl: './assets/img/material_list/Group 19443.jpg', tags: ['FFF'], url: '/3d-printing/materials/abs-basic' },
    { id: 2, name: 'ABS Black (PX-223HT)', description: 'High Temperature material in ABS', imgUrl: './assets/img/material_list/Group 19442.jpg', tags: ['Vacuum Casting', 'CNC Machining'], url: '/cnc-machining/materials/abcblack-px223ht' },
    { id: 3, name: 'ABS Food Grade (9012)', description: 'ABS material, is food grade compliant', imgUrl: './assets/img/material_list/Group 19441.jpg', tags: ['Vacuum Casting'], url: '/3d-printing/materials/abs-9012' },
    { id: 4, name: 'ABS-Like', description: 'Rigid | Smooth Surface | Accurate & High Detail Parts', imgUrl: './assets/img/material_list/Group 19438.jpg', tags: ['SLA'], url: '/3d-printing/materials/abs-like-evolve-128' },
    { id: 5, name: 'Acrylic (PMMA)', description: 'Optically transparent parts | Light-weight components', imgUrl: './assets/img/material_list/Group 19440.jpg', tags: ['CNC Machining', 'Injection Moulding '], url: '/cnc-machining/materials/acrylic' },
    { id: 6, name: 'GF Nylon-12 (PA 3200)', description: 'Glass-Reinforced | High Stiffness | Performance Parts', imgUrl: './assets/img/material_list/Group 19445.jpg', tags: ['SLS'], url: '/3d-printing/materials/gf-nylon-12-pa-3200' },
    { id: 7, name: 'High Temperature (8060)', description: 'High temperature material in PU', imgUrl: './assets/img/material_list/Group 19449.jpg', tags: ['Vacuum Casting'], url: '/3d-printing/materials/high-temperature' },
    { id: 8, name: 'Nylon-6 (Cast)', description: 'Abrasion & wear resistance / Low-coefficient of friction', imgUrl: './assets/img/material_list/Group 19445.jpg', tags: ['Vacuum Casting'], url: '/cnc-machining/materials/cast-nylon-6' },
    { id: 9, name: 'Nylon-12 (PA 2200)', description: 'Strong & Flexible Plastic | Fully Functional Parts', imgUrl: './assets/img/material_list/Group 19447.jpg', tags: ['Vacuum Casting'], url: '/3d-printing/materials/nylon-12-pa-2200' },
    { id: 10, name: 'Nylon (VC 3365)', description: 'Properties of filled nylon', imgUrl: './assets/img/material_list/Group 19450.jpg', tags: ['Vacuum Casting'], url: '/vacuum-casting/materials/nylonvc-3365' },
    { id: 11, name: 'PC-Like (Clear)', description: 'Transparent | Smooth Surface | Aesthetic Parts', imgUrl: './assets/img/material_list/Group 19452.jpg', tags: ['SLA'], url: '/3d-printing/materials/pc-like-flex' },
    { id: 12, name: 'PLA (Basic)', description: 'Bio-degradable | Cost-effective prototypes | Non-functional Parts', imgUrl: './assets/img/material_list/Group 19455.jpg', tags: ['FFF'], url: '/3d-printing/materials/pla-basic' },
    { id: 13, name: 'POM (Delrin)', description: 'Great mechanical properties / High dimensional stability', imgUrl: './assets/img/material_list/Group 19453.jpg', tags: ['CNC Machining'], url: '/cnc-machining/materials/pom' },
    { id: 14, name: 'PP (Machining)', description: 'Strong & Flexible Plastic | Fully Functional Parts', imgUrl: './assets/img/material_list/PPMachining@2x.jpg', tags: ['SLS'], url: '/cnc-machining/materials/pp-machining' },
    { id: 15, name: 'PP-Like', description: 'Flexible | Smooth Surface | High Detail Parts', imgUrl: './assets/img/material_list/Group 19457.jpg', tags: ['SLA'], url: '/3d-printing/materials/pp-like-flex' },
    { id: 16, name: 'Tough Resin', description: 'Tough | Brittle | Smooth Surface | High Detail Parts', imgUrl: './assets/img/material_list/ToughResin@2x.png', tags: ['SLS'], url: '/3d-printing/materials/tough-resin' },
    { id: 17, name: 'Ultra Detail (Vero)', description: 'Excellent feature detail & accuracy | End-use appearance', imgUrl: './assets/img/material_list/Group 19459.jpg', tags: ['Polyjet'], url: '/3d-printing/materials/ultra-detail-vero' },
    { id: 18, name: 'Rubber-Like (Tango)', description: 'Rubber like | Shore Hardness (30A-85A) | Elastic Parts', imgUrl: './assets/img/material_list/Group 19461.jpg', tags: ['Polyjet'], url: '/3d-printing/materials/rubber-like-tango' },
    { id: 19, name: 'ABS', description: 'Cost-effective functional parts / Great machinability', imgUrl: './assets/img/material_list/Group 19464.jpg', tags: ['CNC Machining', 'Injection Molding'], url: '/cnc-machining/materials/abs' },
    { id: 20, name: 'Rubber (VC 3300)', description: 'Strong & Flexible Plastic | Fully Functional Parts', imgUrl: './assets/img/material_list/Group 19463.jpg', tags: ['Vacuum Casting'], url: '/vacuum-casting/materials/rubber-vc3300' },
    { id: 21, name: 'Teflon (PTFE)', description: 'Large operating temperature range \ High chemical resistance', imgUrl: './assets/img/material_list/Group 19462.jpg', tags: ['CNC Machining'], url: '/cnc-machining/materials/teflon' },
    { id: 29, name: 'Polycarbonate (PC)', description: 'PC is known for maintaining coloring and strength over the time.', imgUrl: './assets/img/material_list/Polycarbonate@2x.jpg', tags: ['CNC Machining'], url: '/cnc-machining/materials/polycarbonate' },


];


