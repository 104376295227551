<!-- Main -->
<section class="main-session">
    <img src="assets/img/buyer-protection/bp-cover.jpg" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>
                        Buyer <br>Protection
                    </h1>
                    <p class="mt-4">
                        Our buyer protection program ensures a hassle-free experience in trading
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener"
                    class="btn btn-primary w-100"> Get In Touch </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>



<section class="sheet-metal-fab global-space-between">
    <div class="container">

        <div class="row">
            <div class="col-12 order-12 order-md-1 col-md-5">
                <h1 class="text-left">
                    Overview
                </h1>
                <p class="description">
                    Truventor strives to establish a safe and secure environment to conduct business on a single
                    integrated platform. We assure all our buyers with excellent quality, IP protection and timely
                    delivery thereby minimising the risk in case the terms of the contract are not met. Truventor buyer
                    protection is a free service that is offered to all the buyers to be covered in the event of a
                    delayed shipment, quality, or payment issues.

                </p>
            </div>
            <div class="col-12 order-1 order-md-12 col-md-6 d-flex justify-content-center mb-5">
                <img src="assets/img/buyer-protection/Buyer-Protection-Overview.jpg" loading="lazy" class="img-fluid machining-img"
                    alt="Sheet Metal">
            </div>
        </div>
    </div>
</section>



<section class="side-tab-view-toggle grow-your-business global-space-between">
    <div class="container">

        <!-- Desktop Slider -->
        <div class="d-none d-md-block">

            <div class="row align-items-center">
                <div class="col-6 pl-0">
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane show active" id="v-pills-home" role="tabpanel"
                            aria-labelledby="v-pills-home-tab">
                            <img src="./assets/img/buyer-protection/Group 20425@2x.jpg" class="img-fluid" width="100%"
                                alt="Truventor">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <h1 class="text-center text-md-left pl-3">
                        Foundation of our
                        offerings
                    </h1>

                    <div class="content">
                        <div class="nav flex-column nav-pills pl-2" id="v-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home"
                                role="tab" aria-controls="v-pills-home" aria-selected="true">
                                <span class="plus">
                                    Design and IP protection
                                </span>
                                <p>
                                    At Truventor, we take data security very seriously. We have employed state-of-the
                                    art AWS technology to ensure that our file handling system is robust and globally
                                    accessible. This system provides multiple layers of security and strict access rules
                                    to your highly valuable and confidential CAD files.
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile"
                                role="tab" aria-controls="v-pills-profile" aria-selected="false">
                                <span class="plus">
                                    Payment protection
                                </span>
                                <p>
                                    Truventor assures secure and reliable payment processing, and the supplier will
                                    receive payment only after the buyer is satisfied and has confirmed delivery of the
                                    product as per the order specifications.
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages"
                                role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Quality assurance
                                </span>
                                <p>
                                    We are equipped with ISO quality certifications and practise stringent quality
                                    control. Before dispatching the parts, our quality control department ensures the
                                    part specifications are as per the requirement. We also provide third-party
                                    inspection services.
                                </p>
                            </a>
                            <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-support"
                                role="tab" aria-controls="v-pills-messages" aria-selected="false">
                                <span class="plus">
                                    On-time and reliable delivery
                                </span>
                                <p>
                                    With supply chain strategies and global logistics partnerships in place, we are able
                                    to supply parts safely and on time to any part of the world. We also provide
                                    just-in-time delivery so that you don’t have to worry about the inventory.
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!-- Mobile Slider -->
        <div class="row d-block d-md-none">
            <div class="col">

                <!-- Title -->
                <h1 class="text-center text-md-left">
                    Benefits and credits
                </h1>

                <!-- One -->
                <div>
                    <img src="./assets/img/buyer-protection/Group 20425@2x.jpg" class="img-fluid py-4" width="100%"
                        alt="Truventor">
                    <h5 class="text-left">
                        Design and IP protection
                    </h5>
                    <hr>
                    <p>
                        At Truventor, we take data security very seriously. We have employed state-of-the art AWS
                        technology to ensure that our file handling system is robust and globally accessible. This
                        system provides multiple layers of security and strict access rules to your highly valuable and
                        confidential CAD files.
                    </p>
                </div>
                <!-- Two -->
                <div>
                    <!-- <img src="./assets/img/supplier/Group 16935-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Payment protection
                    </h5>
                    <hr>
                    <p>
                        Truventor assures secure and reliable payment processing, and the supplier will receive payment
                        only after the buyer is satisfied and has confirmed delivery of the product as per the order
                        specifications.
                    </p>
                </div>
                <!-- Three -->
                <div>
                    <!-- <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Quality assurance
                    </h5>
                    <hr>
                    <p>
                        We are equipped with ISO quality certifications and practise stringent quality control. Before
                        dispatching the parts, our quality control department ensures the part specifications are as per
                        the requirement. We also provide third-party inspection services.
                    </p>
                </div>

                <div>
                    <!-- <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        On-time and reliable delivery
                    </h5>
                    <hr>
                    <p>
                        With supply chain strategies and global logistics partnerships in place, we are able
                        to supply parts safely and on time to any part of the world. We also provide
                        just-in-time delivery so that you don’t have to worry about the inventory.</p>
                </div>

            </div>
        </div>

    </div>
</section>


<section class="technical-specifications global-space-between">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-md-5">
                <div class="content">
                    <h1>What is
                        covered?</h1>
                    <p>
                        We’ve got your back!
                        At Truventor, we take customer experience very seriously. Know what’s covered…
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row row-cols-2 row-cols-md-2">
                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <h5>
                            You ordered auto parts and got a medical implant
                        </h5>

                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <h5>
                            You ordered for 3000 parts but only got 2870
                        </h5>

                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <h5>
                            Your parts were damaged in transit
                        </h5>

                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <h5>
                            Major parts was missing that the seller didn't disclose
                        </h5>

                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 5 </div>
                        <hr>
                        <h5>
                            The parts do not match the required specifications
                        </h5>

                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 6 </div>
                        <hr>
                        <h5>
                            Your parts were delayed without any prior intimation.
                        </h5>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>




<section class="side-tab-view-toggle grow-your-business global-space-between">
    <div class="container">

        <!-- Desktop Slider -->
        <div class="d-none d-md-block">

            <div class="row align-items-center">

                <div class="col-6">
                    <h1 class="text-center text-md-left pr-5">
                        Dispute resolution service
                    </h1>

                    <div class="content pr-5">
                        <div class="nav flex-column nav-pills" id="why-pills-tab" role="tablist"
                            aria-orientation="vertical">
                            <a class="nav-link active" id="why-pills-home-tab" data-toggle="pill" href="#why-pills-home"
                                role="tab" aria-controls="why-pills-home" aria-selected="true">
                                <span class="plus">
                                    Delay in delivery
                                </span>
                                <p>
                                    If the parts do not reach a customer on time because of unlikely circumstances (like
                                    a natural calamity or a geopolitical situation), advance communication will be
                                    circulated. However, if the delay is caused by a supplier or logistics partner,
                                    Truventor is liable to pay for the losses incurred.
                                </p>
                            </a>
                            <a class="nav-link" id="why-pills-profile-tab" data-toggle="pill" href="#why-pills-profile"
                                role="tab" aria-controls="why-pills-profile" aria-selected="false">
                                <span class="plus">
                                    Specification mismatch
                                </span>
                                <p>
                                    We have no questions asked refund policy in place if the quality is not as per the
                                    requirement. However, if the customer agrees, we can put the project on a fast track
                                    and manufacture parts again at no extra cost.
                                </p>
                            </a>
                            <a class="nav-link" id="why-pills-messages-tab" data-toggle="pill"
                                href="#why-pills-messages" role="tab" aria-controls="why-pills-messages"
                                aria-selected="false">
                                <span class="plus">
                                    Raise a complaint
                                </span>
                                <p>
                                    In case, the buyer and manufacturing partner do not agree on a concern raised by the
                                    buyer, a complaint can be raised at the dispute resolution center. We will assign a
                                    manager for a timely solution. The buyer can also track the status of the complaint
                                    raised.
                                </p>
                            </a>
                            <a class="nav-link" id="why-pills-messages-tab" data-toggle="pill" href="#why-pills-support"
                                role="tab" aria-controls="why-pills-messages" aria-selected="false">
                                <span class="plus">
                                    Customer service guarantee
                                </span>
                                <p>
                                    We have a dedicated team for customer service and dispute resolution. The team
                                    members are technically qualified to understand different aspects of manufacturing
                                    and will handle the dispute in an unbiased manner.
                                </p>
                            </a>
                        </div>
                    </div>


                </div>
                <div class="col-6 pl-0">
                    <div class="tab-content" id="why-pills-tabContent">
                        <div class="tab-pane show active" id="why-pills-home" role="tabpanel"
                            aria-labelledby="why-pills-home-tab">
                            <img src="./assets/img/buyer-protection/Intersection 10@2x.jpg" class="img-fluid"
                                width="100%" alt="Truventor">
                        </div>

                    </div>
                </div>

            </div>

        </div>

        <!-- Mobile Slider -->
        <div class="row d-block d-md-none">
            <div class="col">

                <!-- Title -->
                <h1 class="text-center text-md-left">
                    Dispute resolution service
                </h1>

                <!-- One -->
                <div>
                    <!-- <img src="./assets/img/startup/Why-Truventor@2x.png" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Delay in delivery
                    </h5>
                    <hr>
                    <p>
                        If the parts do not reach a customer on time because of unlikely circumstances (like a natural
                        calamity or a geopolitical situation), advance communication will be circulated. However, if the
                        delay is caused by a supplier or logistics partner, Truventor is liable to pay for the losses
                        incurred.
                    </p>
                </div>
                <!-- Two -->
                <div>
                    <!-- <img src="./assets/img/supplier/Group 16935-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Specification mismatch
                    </h5>
                    <p>We have no questions asked refund policy in place if the quality is not as per the requirement.
                        However, if the customer agrees, we can put the project on a fast track and manufacture parts
                        again at no extra cost. </p>
                    <hr>

                </div>
                <!-- Three -->
                <div>
                    <!-- <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Raise a complaint
                    </h5>
                    <p>In case, the buyer and manufacturing partner do not agree on a concern raised by the buyer, a
                        complaint can be raised at the dispute resolution center. We will assign a manager for a timely
                        solution. The buyer can also track the status of the complaint raised.</p>
                    <hr>

                </div>
                <div>
                    <!-- <img src="./assets/img/supplier/Group 16936-mobile.jpg" class="img-fluid py-4" width="100%" alt="Truventor"> -->
                    <h5 class="text-left">
                        Customer service guarantee
                    </h5>
                    <p>We have a dedicated team for customer service and dispute resolution. The team members are
                        technically qualified to understand different aspects of manufacturing and will handle the
                        dispute in an unbiased manner.</p>
                    <hr>

                </div>

            </div>
        </div>

    </div>
</section>



<section class="why-best global-space-between bottom-end-padding">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col">
                <h1 class="text-center font-weight-bold">Why choose Truventor? </h1>
            </div>
        </div>

        <!-- Items -->
        <div class="row row-cols-2 row-cols-sm-1 row-cols-md-5 justify-content-center">
            <div class="col">
                <div class="card border-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Access to global supplier network.svg"
                        class="card-img-top" loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Access to global<br>supplier network</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Deep supplier insights.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Deep supplier<br>insights</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 5 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Transparency at each step.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Transparency<br>at each step</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0  px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Humanizing communication.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Humanizing<br>communication</p>
                    </div>
                </div>
            </div>

            <div class="col">
                <div class="card border-0 px-md-2" data-aos="fade-up">
                    <img src="./assets/img/customer_landing/icons/Buyer Protection.svg" class="card-img-top"
                        loading="lazy" alt="Truventor">
                    <div class="card-body">
                        <p class="card-text text-center">Buyer<br>protection</p>
                    </div>
                </div>
            </div>


        </div>
    </div>
</section>




<!-- Last Section -->
<section class="global-space-between bottom-end-padding a-trueventor">
    <div class="container-fluid">
        <div class="row">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-4 p-0 d-none d-md-block">
                <img src="./assets/img/customer_landing/Book a Demo.jpg" width="100%" loading="lazy" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-8 pl-md-5 d-flex align-items-center">
                <div class="w-100">
                    <!-- Title -->
                    <h1>
                        Have more question about Buyer Protection?
                    </h1>
                    <!-- Paragraph -->
                    <p class="mt-4 mb-0">
                        Convert your bold ideas into reality. <br> Get in touch with us, to know how!
                        <!-- Convert your bold ideas into reality! <br> Talk to Us, to know more! -->
                    </p>
                    <!-- Action Buttons -->
                    <div class="row">

                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <a [routerLink]="['/contact']" class="btn btn-primary w-100"> Contact us</a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>