
// Data to Sheet Metal Materials 
export const SHEET_METAL_MATERIAL = [
    { id: 1, name: 'Aluminium 5052', description: 'Medium strength alloy used for making lightweight metal components', imgUrl: './assets/img/material_list/Group 19465.jpg', url: 'cnc-machining/materials/aluminium-5052' },
    { id: 2, name: 'Aluminium 6061-T6', description: 'Medium strength alloy used for making lightweight metal components', imgUrl: './assets/img/material_list/Group 19467.jpg', url: '/cnc-machining/materials/aluminium-6061-T6' },
    { id: 8, name: 'Stainless Steel (316)', description: 'Excellent toughness, even at cryogenic temperatures.', imgUrl: './assets/img/material_list/Stainless Steel 316@2x.jpg', url: '/cnc-machining/materials/stainless-steel316' },
    { id: 9, name: 'Stainless Steel 304', description: 'Light-weight components / Great machinability', imgUrl: './assets/img/material_list/Stainless Steel 304@2x.jpg', url: 'cnc-machining/materials/stainless-steel304' },
    { id: 10, name: 'Stainless Steel 301', description: '301 steel is mainly used in the cold working state to withstand higher loads', imgUrl: './assets/img/material_list/Stainless Steel 301@2x.jpg', url: '/cnc-machining/materials/stainless-steel301' },
    { id: 7, name: 'Mild Steel 1018', description: 'Good balance of toughness, strength and ductility.', imgUrl: './assets/img/material_list/Mild Steel 1018@2x.png', url: '/cnc-machining/materials/mild-steel-1018' },
    { id: 11, name: 'Galvanized Steel', description: 'Galvanized steel procides extended durability and formability of steel and corrosion protection of zinc.', imgUrl: './assets/img/material_list/Galvanized steel@2x.jpg', url: '/cnc-machining/materials/galvanized-steel' },
    { id: 18, name: 'Copper 101', description: 'Good solderability and corrosion resistance and is used for high current applications.', imgUrl: './assets/img/material_list/Copper 101@2x.jpg', url: '/cnc-machining/materials/copper-c110' },
    { id: 21, name: 'Copper 260', description: 'Attractive material with a smooth, yellow brass finish', imgUrl: './assets/img/material_list/Copper 260@2x.jpg', url: '/cnc-machining/materials/copper-c260' },
    { id: 12, name: 'Low Carbon Steel CR 1008', description: 'Low carbon steel 1008 provides excellent surface finish and press formability.', imgUrl: './assets/img/material_list/Low Carbon Steel CR 1008@2x.jpg', url: '/cnc-machining/materials/lowcarbon-steel1008' },
    { id: 20, name: 'Copper 110', description: 'Good balance of toughness, strength and ductility.', imgUrl: './assets/img/material_list/Copper C110@2x.jpg', url: '/cnc-machining/materials/copper-c110' },

]


// Data to 3D Printing Materials 


export const THREED_PRINTING_MATERIAL = [
    { id: 1, name: 'Nylon-12 (PA 2200)', label: 'Selective Laser Sintering (SLS)', description: 'Strong & Flexible Plastic | Fully Functional Parts', imgUrl: './assets/img/online_3d_printing/NYLON-12(PA 2200)@2x.jpg', url: '3d-printing/materials/nylon-12-pa-2200' },
    { id: 2, name: 'GF Nylon-12 (PA 3200)', label: 'Selective Laser Sintering (SLS)', description: 'Glass-reinforced | High Stiffness | Performance Parts', imgUrl: './assets/img/online_3d_printing/GF NYLON-12 (PA 3200)@2x (1).jpg', url: '3d-printing/materials/gf-nylon-12-pa-3200' },
    { id: 3, name: 'PP-LIKE', label: 'Stereolithography (SLA)', description: 'Smooth Surface | Dimensionally Accurate | High Detail Part', imgUrl: './assets/img/online_3d_printing/PP-LIKE@2x.jpg', url: '3d-printing/materials/pp-like-flex' },
    { id: 4, name: 'PC-LIKE (CLEAR)', label: 'Stereolithography (SLA)', description: 'Transparent | Smooth Surface | Aesthetic parts', imgUrl: './assets/img/online_3d_printing/NYLON-12(PA 2200)@2x.jpg', url: '3d-printing/materials/pc-like-flex' },
    { id: 5, name: 'High Tempurature', label: 'Stereolithography (SLA)', description: 'High Heat Deflection Temperature (HDT) of 289°c @0.45 MPa.', imgUrl: './assets/img/online_3d_printing/HIGH TEMPERATURE@2x.jpg', url: '3d-printing/materials/high-temperature' },
    { id: 6, name: 'Ultra Detail (VERO)', label: 'CNC Milling', description: 'Strong & Flexible Plastic | Fully Functional Parts', imgUrl: './assets/img/online_3d_printing/NYLON-12(PA 2200)@2x.jpg', url: '3d-printing/materials/ultra-detail-vero' },
    { id: 7, name: 'Rubber-Like (TANGO)', label: 'Polyjet (PJ)', description: 'High Heat Deflection Temperature (HDT) of 289°c @0.45 MPa.', imgUrl: './assets/img/online_3d_printing/RUBBER-LIKE (TANGO)@2x.jpg', url: '3d-printing/materials/rubber-like-tango' },
    { id: 8, name: 'ABS (BASIC)', label: 'Fused Filament Fabrication (FFF)', description: 'Cost Effective prototypes | Rigid | Non-Functional Parts', imgUrl: './assets/img/online_3d_printing/PP-LIKE@2x.jpg', url: '3d-printing/materials/abs-basic' },
    { id: 9, name: 'Polycarbonate', label: '', description: 'PC is known for maintaining coloring and strength over the time.', imgUrl: './assets/img/material_list/Polycarbonate@2x.jpg', url: 'cnc-machining/materials/polycarbonate' },

]


// CNC MAchingin Data

export const CNC_MACHINING_METALS = [
    { id: 2, name: 'MILD STEEL', description: 'High weldability & malleability | Cost-effective', imgUrl: './assets/img/cnc_machining_service/Mild Steel@2x.jpg', url: 'cnc-machining/materials/mild-steel' },
    { id: 3, name: 'STAINLESS STEEL (SS 316)', description: 'High strength | Corrosion resistant | Medical applications', imgUrl: './assets/img/cnc_machining_service/Stainless Steel (SS 316)@2x.jpg', url: 'cnc-machining/materials/stainless-steel-ss316' },
    { id: 1, name: 'ALUMINIUM 6061 T6', description: 'High strength-to-weight ratio | Aerospace components', imgUrl: './assets/img/cnc_machining_service/Aluminium 6061 T6@2x.jpg', url: 'cnc-machining/materials/aluminium-6061-T6' },
    { id: 1, name: 'Aluminium 6082', description: 'Light-weight components and great machinability', imgUrl: './assets/img/material_list/Group 19466.jpg', url: '/cnc-machining/materials/aluminium-6082' },
    { id: 2, name: 'Aluminium 6061-T6', description: 'Medium strength alloy used for making lightweight metal components', imgUrl: './assets/img/material_list/Group 19467.jpg', url: '/cnc-machining/materials/aluminium-6061-T6' },
    { id: 3, name: 'Aluminium 7075', description: ' Aluminum 7075 alloy is one the strongest aluminum alloys', imgUrl: './assets/img/material_list/ALUMINIUN 7075@2x.png', url: '/cnc-machining/materials/aluminium-7075' },
    { id: 4, name: 'Aluminium 5052', description: 'Non-heat treatable alloy with good corrosion resistance', imgUrl: './assets/img/material_list/Group 19465.jpg', url: '/cnc-machining/materials/aluminium-5052' },
    { id: 5, name: 'Aluminum 3003', description: 'Better mechanical properties, especially at elevated temperatures than the 1000 series alloys.', imgUrl: './assets/img/material_list/Aluminum 3003@2x.jpg', url: '/cnc-machining/materials/aluminium-3003' },
    { id: 6, name: 'Mild Steel', description: 'Low cost steel with moderate strength, good case hardening properties,formability and weldability.', imgUrl: './assets/img/material_list/Mild Steel@2x.png', url: '/cnc-machining/materials/mild-steel' },
    { id: 7, name: 'Mild Steel 1018', description: 'Good balance of toughness, strength and ductility.', imgUrl: './assets/img/material_list/Mild Steel 1018@2x.png', url: '/cnc-machining/materials/mild-steel-1018' },
    { id: 8, name: 'Stainless Steel (316)', description: 'Excellent toughness, even at cryogenic temperatures.', imgUrl: './assets/img/material_list/Stainless Steel 316@2x.jpg', url: '/cnc-machining/materials/stainless-steel316' },
    { id: 9, name: 'Stainless Steel 304', description: 'Light-weight components / Great machinability', imgUrl: './assets/img/material_list/Stainless Steel 304@2x.jpg', url: 'cnc-machining/materials/stainless-steel304' },
    { id: 10, name: 'Stainless Steel 301', description: '301 steel is mainly used in the cold working state to withstand higher loads', imgUrl: './assets/img/material_list/Stainless Steel 301@2x.jpg', url: '/cnc-machining/materials/stainless-steel301' },
    { id: 11, name: 'Galvanized Steel', description: 'Galvanized steel procides extended durability and formability of steel and corrosion protection of zinc.', imgUrl: './assets/img/material_list/Galvanized steel@2x.jpg', url: '/cnc-machining/materials/galvanized-steel' },
    { id: 12, name: 'Low Carbon Steel CR 1008', description: 'Low carbon steel 1008 provides excellent surface finish and press formability.', imgUrl: './assets/img/material_list/Low Carbon Steel CR 1008@2x.jpg', url: '/cnc-machining/materials/lowcarbon-steel1008' },
    { id: 13, name: 'Titanium Grade 1', description: 'One of the softer and more ductile grades of pure Titanium', imgUrl: './assets/img/material_list/Titanium Grade 1@2x.jpg', url: '/cnc-machining/materials/titanium-grade1' },
    { id: 14, name: 'Titanium Grade 2', description: 'Require good ductility and corrosion resistance makes it suitable for most industrial applications', imgUrl: './assets/img/material_list/Titanium Grade 2@2x.jpg', url: '/cnc-machining/materials/titanium-grade2' },
    { id: 15, name: 'Titanium Grade 3', description: 'Grade 3 has good impact properties at low temperatures', imgUrl: './assets/img/material_list/Titanium Grade 3@2x.jpg', url: '/cnc-machining/materials/titanium-grade3' },
    { id: 16, name: 'Titanium Grade 4', description: 'Titanium Grade 4 is the highest strength pure unalloyed Titanium', imgUrl: './assets/img/material_list/Titanium Grade 4@2x.jpg', url: '/cnc-machining/materials/titanium-grade4' },
    { id: 17, name: 'Titanium Grade 5', description: 'This versatile material offers high fatigue resistance and good biocompatibility.', imgUrl: './assets/img/material_list/Titanium Grade 5@2x.jpg', url: '/cnc-machining/materials/titanium-grade4' },
    { id: 18, name: 'Copper 101', description: 'Good solderability and corrosion resistance and is used for high current applications.', imgUrl: './assets/img/material_list/Copper 101@2x.jpg', url: '/cnc-machining/materials/copper-c110' },
    { id: 19, name: 'Copper 106', description: 'Resistant to corrosion in most atmospheres including marine and industrial environments.', imgUrl: './assets/img/material_list/Copper C106@2x.jpg', url: '/cnc-machining/materials/copper-106' },
    { id: 20, name: 'Copper 110', description: 'Good balance of toughness, strength and ductility.', imgUrl: './assets/img/material_list/Copper C110@2x.jpg', url: '/cnc-machining/materials/copper-c110' },
    { id: 21, name: 'Copper 260', description: 'Attractive material with a smooth, yellow brass finish', imgUrl: './assets/img/material_list/Copper 260@2x.jpg', url: '/cnc-machining/materials/copper-c260' },
    { id: 22, name: 'Brass C360', description: 'Excellent high speed machining operations and superior thread rolling and knurling characteristics.', imgUrl: './assets/img/material_list/Brass C360@2x.jpg', url: '/cnc-machining/materials/brass-c360' },
    { id: 23, name: 'Inconel 625', description: 'Inconel 625 on liner: Inconel 625 is known for its high strength and excellent fabricability', imgUrl: './assets/img/material_list/Inconel 625@2x.jpg', url: '/cnc-machining/materials/inconel-625' },
    { id: 24, name: 'Inconel 718', description: '3D-printed Inconel retains strength over a wide temperature range', imgUrl: './assets/img/material_list/Inconel 718@2x.jpg', url: '/cnc-machining/materials/inconel-718' },
    { id: 28, name: 'Tool Steel D2', description: 'D2 Tool Steel offers excellent wear resistance', imgUrl: './assets/img/material_list/Tool Steel D2@2x.jpg', url: '/cnc-machining/materials/toolsteel-d2' },
    { id: 25, name: 'Tool Steel W Grade', description: 'This grade of tool steel provides maximum toughness and adequate wear-resistance', imgUrl: './assets/img/material_list/Tool Steel W Grade@2x.jpg', url: '/cnc-machining/materials/toolsteel-wgrade' },
    { id: 26, name: 'Tool Steel A Grade', description: 'This versatile material offers high fatigue resistance and good biocompatibility.', imgUrl: './assets/img/material_list/Tool Steel A Grade@2x.jpg', url: '/cnc-machining/materials/toolsteel-agrade' },
    { id: 27, name: 'Tool Steel S7', description: 'Tool Steel S7 has good resistance to softening at moderately high temperatures.', imgUrl: './assets/img/material_list/Tool Steel S7@2x.jpg', url: '/cnc-machining/materials/toolsteel-s7' },
]

export const CNC_MACHINING_PLASTICS = [
    { id: 1, name: 'Nylon-6 (CAST)', description: 'Abrasion & wear resistance | Low-coefficient of friction', imgUrl: './assets/img/cnc_machining_service/Nylon-6 (Cast)@2x.jpg', url: 'cnc-machining/materials/cast-nylon-6' },
    { id: 2, name: 'Teflon (PTFE)', description: 'Large operating temperature range | High chemical resistance', imgUrl: './assets/img/cnc_machining_service/Teflon (PTFE)@2x.jpg', url: 'cnc-machining/materials/teflon' },
    { id: 3, name: 'POM (DELRIN)', description: 'Great mechanical properties | High dimensional stability', imgUrl: './assets/img/cnc_machining_service/POM (Delrin)@2x.jpg', url: 'cnc-machining/materials/pom' },
    { id: 4, name: 'Acrylic (PMMA)', description: 'Optically transparent parts | Light-weight components', imgUrl: './assets/img/cnc_machining_service/Acrylic (PMMA)@2x.jpg', url: 'cnc-machining/materials/acrylic' },
    { id: 5, name: 'ABS', description: 'Cost-effective functional parts | Great machinability', imgUrl: './assets/img/cnc_machining_service/ABS@2x.jpg', url: 'cnc-machining/materials/abs' },
]


// Metal Casting Materials

export const METAL_CASTING = [
    { id: 1, name: 'Aluminium', description: 'High strength-to-weight ratio | automotive & aerospace component', imgUrl: './assets/img/metal_casting/aluminium_6061@2x.jpg', url: 'cnc-machining/materials/aluminium-6082' },
    { id: 3, name: 'COPPER', description: 'Superior corrosion resistance, high thermal or electrical conductivity', imgUrl: './assets/img/metal_casting/einzelspulen_12_01@2x.jpg', url: 'cnc-machining/materials/copper-c110' },
    { id: 8, name: 'Stainless Steel (316)', description: 'Excellent toughness, even at cryogenic temperatures.', imgUrl: './assets/img/material_list/Stainless Steel 316@2x.jpg', url: '/cnc-machining/materials/stainless-steel316' },
    { id: 9, name: 'Stainless Steel 304', description: 'Light-weight components / Great machinability', imgUrl: './assets/img/material_list/Stainless Steel 304@2x.jpg', url: 'cnc-machining/materials/stainless-steel304' },
    { id: 10, name: 'Stainless Steel 301', description: '301 steel is mainly used in the cold working state to withstand higher loads', imgUrl: './assets/img/material_list/Stainless Steel 301@2x.jpg', url: '/cnc-machining/materials/stainless-steel301' },
    { id: 11, name: 'Galvanized Steel', description: 'Galvanized steel procides extended durability and formability of steel and corrosion protection of zinc.', imgUrl: './assets/img/material_list/Galvanized steel@2x.jpg', url: '/cnc-machining/materials/galvanized-steel' },
    { id: 12, name: 'Low Carbon Steel CR 1008', description: 'Low carbon steel 1008 provides excellent surface finish and press formability.', imgUrl: './assets/img/material_list/Low Carbon Steel CR 1008@2x.jpg', url: '/cnc-machining/materials/lowcarbon-steel1008' },

]


export const INJECTION_MOULDING_METAL = [
    { id: 1, name: 'Acrylonitrile Butadiene Styrene (ABS)', description: 'Ideal for rapid prototyping', imgUrl: 'assets/img/injection_moulding/Acrylonitrile Butadiene Styrene (ABS)@2x.png', url: '3d-printing/materials/abs-basic' },
    { id: 2, name: 'Polyethylene', description: 'Chemical resistant parts', imgUrl: 'assets/img/injection_moulding/Polyethylene@2x.png', url: 'cnc-machining/materials/teflon' },
    { id: 3, name: 'Polycarbonate (PC)', description: 'Good heat resistance', imgUrl: 'assets/img/injection_moulding/Polycarbonate (PC)@2x.png', url: 'cnc-machining/materials/polycarbonate' },
    { id: 4, name: 'Nylon', description: 'Abrasion & wear resistance', imgUrl: 'assets/img/injection_moulding/Nylon@2x.png', url: 'vacuum-casting/materials/nylonvc-3365' },
    { id: 5, name: 'Glass-filled Nylon', description: 'High stiffness and low', imgUrl: 'assets/img/injection_moulding/Glass-Filled Nylon@2x.png', url: '3d-printing/materials/gf-nylon-12-pa-3200' },
    { id: 7, name: 'Polypropylene (PP)', description: 'Chemical resistant partsHigh Density', imgUrl: 'assets/img/injection_moulding/Polypropylene (PP)@2x.png', url: 'cnc-machining/materials/pp-machining' },
    { id: 9, name: 'Polycarbonate', label: '', description: '', imgUrl: './assets/img/material_list/Polycarbonate@2x.jpg', url: 'cnc-machining/materials/polycarbonate' },

]




export const VACUUM_CASTING_METAL = [
    { id: 1, name: 'ABS Food Grade (9012)', description: 'ABS material, is food grade compliant', imgUrl: './assets/img/vacuum_casting/ABS Food Grade (9012)@2x.jpg', url: '3d-printing/materials/abs-9012' },
    { id: 2, name: 'ABS Black (PX-223/HT)', description: 'High Temperature material in ABS', imgUrl: './assets/img/vacuum_casting/ABS Black (PX-223HT)@2x.jpg', url: 'cnc-machining/materials/abcblack-px223ht' },
    { id: 6, name: 'High temperature (8060)', description: 'High temperature material in PU', imgUrl: './assets/img/vacuum_casting/vc-hightemp@2x.jpg', url: '3d-printing/materials/high-temperature' },
    { id: 7, name: 'Nylon (VC 3365)', description: 'Properties of filled nylon', imgUrl: './assets/img/vacuum_casting/vc-nylon@2x.jpg', url: 'vacuum-casting/materials/nylonvc-3365' },
    { id: 7, name: 'Rubber (VC 3300)', description: 'Flexible material with different shore hardness', imgUrl: './assets/img/vacuum_casting/vc-rubber (1)@2x.png', url: 'vacuum-casting/materials/rubber-vc3300' },

]