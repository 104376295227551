<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Aluminium 7075@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Aluminium <br> 7075
                    </h1>
                    <p class="text-primary">
                        CNC Machining / Sheet Metal fabrication
                    </p>
                    <p class="mt-4">
                        Aluminum 7075 alloy is one the strongest aluminum alloys
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Aluminum 7075 1.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Aluminum 7075 is a heat-treatable aluminum alloy with zinc as the primary alloying element. It has moderated formability when in the fully soft, annealed temper and can be heat-treated to strength levels that are comparable to many steel alloys. 7075
                    aluminum is composed of 90.0% Al, 5.6% Zn, 2.5%Mg, 0.23%Cr, and 1.6% Cu, though these numbers nominally fluctuate depending upon manufacturing factors.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Aluminium 7075
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Aluminium 7075 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Material Color
                            </th>
                            <td>
                                Silver white
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Tensile strength
                            </th>
                            <td>
                                572 MPa (83.0 ksi)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Thermal conductivity (k)
                            </th>
                            <td>
                                130–150 W/m*K
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Specific heat capacity (c)
                            </th>
                            <td>
                                130–150 W/m*K
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Volume resistivity (ρ)
                            </th>
                            <td>
                                51.5 nOhm*m
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            High strength
                        </p>
                        <p class="mb-2">
                            Corrosion resistance
                        </p>
                        <p class="mb-2">
                            Light-weight and low maintenance
                        </p>
                        <p class="mb-2">
                            Anti-static
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            High cost
                        </p>
                        <p class="mb-2">
                            Ductility is lowest when compared to other 7000-series alloys
                        </p>

                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Aircraft component
                        </p>
                        <p class="mb-2">
                            Gears and shaft
                        </p>
                        <p class="mb-2">
                            Pressure vessels
                        </p>
                        <p class="mb-2">
                            Electronic enclosures
                        </p>
                        <p class="mb-2">
                            Hydraulic tubes
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>