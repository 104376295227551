<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Titanium Grade 5@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Titanium <br> Grade 5
                    </h1>
                    <p class="text-primary">
                        DMSL
                    </p>
                    <p class="mt-4">
                        This versatile material offers high fatigue resistance and good biocompatibility.
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Titanium Grade 5.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Commonly referred to as Ti-6AL-4V (or Ti 6-4), this designation refers to its chemical composition of almost 90% titanium, 6% aluminum, 4% vanadium, 0.25% (max) iron and 0.2% (max) oxygen. It has excellent strength, low modulus of elasticity, high corrosion
                    resistance, good weldability and it is heat treatable. The addition of aluminum and vanadium increases the hardness of the material in the alloy matrix, improving its physical and mechanical properties.

                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Titanium Ti-6Al-4V
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Titanium Grade 5  2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Tensile Strength
                            </th>
                            <td>
                                1170 Mpa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Tensile Strength
                            </th>
                            <td>
                                1100 Mpa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Elongation at Break
                            </th>
                            <td>
                                10%
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Modulus of Elasticity
                            </th>
                            <td>
                                114 Gpa
                            </td>
                        </tr>


                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Excellent corrosion resistance
                        </p>
                        <p class="mb-2">
                            Good machinability and weldability
                        </p>
                        <p class="mb-2">
                            Superior strength to weight ratio
                        </p>
                        <p class="mb-2">
                            High strength-to-weight ratio
                        </p>
                        <p class="mb-2">
                            Low modulus of elasticity
                        </p>
                        <hr>
                    </div>
                </div>


                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Aircraft engine and parts
                        </p>
                        <p class="mb-2">
                            Medical and dental implants
                        </p>
                        <p class="mb-2">
                            Fan blades and fan disks
                        </p>
                        <p class="mb-2">
                            Marine applications
                        </p>

                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>