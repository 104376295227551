<!-- Quality -->
<section class="cnc-quality global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">

                    <h1 class="text-center pt-5" data-aos="fade-up"> Quality assurance </h1>

                    <!-- Sentences -->
                    <p class="text-center pt-2" data-aos="fade-up">
                        Truventor strives to achieve best-in-class quality through a <br> systematic approach at every stage of part manufacturing.
                    </p>

                    <!-- Quality Icons -->
                    <div class="row row-cols-1 row-cols-md-3 py-5">
                        <!-- ISO -->
                        <div class="col mt-4" data-aos="fade-up">
                            <div class="d-flex justify-content-center justify-content-md-start">
                                <img class="img-fluid qa-img" src="assets/img/cnc_machining_service/svg/ISO 90012015 certification.svg" alt="quality_assurance_image" loading="lazy">
                            </div>
                            <hr class="my-3">
                            <p class="text-center text-md-left">
                                ISO 9001:2015 certification
                            </p>
                        </div>
                        <!-- Quality inspection report (On request) -->
                        <div class="col mt-4" data-aos="fade-up">
                            <div class="d-flex justify-content-center justify-content-md-start">
                                <img class="img-fluid qa-img" src="assets/img/cnc_machining_service/svg/Quality inspection report (On request).svg" alt="quality_assurance_image" loading="lazy">
                            </div>
                            <hr class="my-3">
                            <p class="text-center text-md-left">
                                Quality inspection report (On request)
                            </p>
                        </div>
                        <!-- Truventor certified quality & manufacturing benchmarks -->
                        <div class="col mt-4" data-aos="fade-up">
                            <div class="d-flex justify-content-center justify-content-md-start">
                                <img class="img-fluid qa-img" src="assets/img/cnc_machining_service/svg/Truventor certified quality & manufacturing benchmarks.svg" alt="quality_assurance_image" loading="lazy">
                            </div>
                            <hr class="my-3">
                            <p class="text-center text-md-left">
                                Truventor certified quality and manufacturing benchmarks
                            </p>
                        </div>

                    </div>

                    <div class="d-flex justify-content-center py-4">
                        <!-- Get an instant quote -->
                        <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary px-5"> Get In Touch </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>