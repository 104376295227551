<!-- Filter and search -->
<section class="search">
    <div class="container mt-5 d-none d-md-block">
        <div class="row align-items-center">
            <div class="col col-12 col-md-5">
                <p class="m-0">
                    one stop shop for global sourcing of high quality zcustom parts.
                </p>
            </div>
            <div class="col col-12 col-md-4">
                <input type="text" class="form-control">
            </div>
            <div class="col col-12 col-md-3">
                <div class="d-flex justify-content-end align-items-center">
                    <b>
                        Filter by
                    </b>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Articles and Features -->
<section class="articles-and-features">
    <div class="container my-0 my-md-5 mt-5 mt-md-0">
        <div class="row">

            <!-- Articles -->
            <div class="col col-12 col-md-9">

                <!-- Image -->
                <div class="card bg-dark text-white">
                    <img class="card-img" src="/assets/img/placeholder/demmy_image.jpg" alt="Card image">
                    <div class="card-img-overlay d-flex align-items-end">
                        <div class="px-0 px-md-5">
                            <p> 9 January 2019 </p>
                            <div class="col-12 col-md-8 p-0">
                                <h2>
                                    Challenges in 3D Printing Industry
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Label -->
                <p class="mt-4">
                    Answer to this question from the majority of us in India would be “NO”. Have you ever thought why 3D printing….
                </p>

                <!-- Author card -->
                <div class="col-12 col-md-10 p-0 mt-4">

                    <!-- Image, Name, Designation -->
                    <div class="row align-items-center">
                        <!-- Image -->
                        <div class="col-3">
                            <img src="/assets/img/placeholder/demmy_9.PNG" class="img-fluid" alt="author_image">
                        </div>
                        <!-- Heading -->
                        <div class="col-9">
                            <h5 class="card-title my-1">
                                ANU DUBEY
                            </h5>
                            <p class="card-text">
                                Technical Content Writer
                            </p>
                        </div>
                    </div>

                    <!-- Command -->
                    <div class="pt-3">
                        <kbd class="mr-2">
                            3D PRINTING
                        </kbd>
                        <kbd class="mr-2">
                            SPOT LIGHT
                        </kbd>
                        <kbd class="mr-2">
                            MANUFACTURING TECHNOLOGY
                        </kbd>
                        <kbd class="mr-2">
                            CHALLENGES
                        </kbd>
                    </div>
                </div>

            </div>

            <!-- Features -->
            <div class="col col-12 col-md-3 d-none d-md-block">
                <h3 class="mx-3 mb-4">
                    Features
                </h3>

                <!-- One -->
                <div class="mt-4">
                    <h6 class="mx-3">
                        <b>
                            Challenges in <br>
                            3D Printing Industry
                        </b>
                    </h6>
                    <hr>
                </div>

                <!-- Two -->
                <div class="mt-4">
                    <h6 class="mx-3">
                        <b>
                            Challenges in <br>
                            3D Printing Industry
                        </b>
                    </h6>
                    <hr>
                </div>

                <!-- Three -->
                <div class="mt-4">
                    <h6 class="mx-3">
                        <b>
                            Challenges in <br>
                            3D Printing Industry
                        </b>
                    </h6>
                    <hr>
                </div>

                <!-- Four -->
                <div class="mt-4">
                    <h6 class="mx-3">
                        <b>
                            Challenges in <br>
                            3D Printing Industry
                        </b>
                    </h6>
                    <hr>
                </div>

                <!-- Five -->
                <div class="mt-4">
                    <h6 class="mx-3">
                        <b>
                            Challenges in <br>
                            3D Printing Industry
                        </b>
                    </h6>
                    <hr>
                </div>

                <!-- Six -->
                <div class="mt-4">
                    <h6 class="mx-3">
                        <b>
                            Challenges in <br>
                            3D Printing Industry
                        </b>
                    </h6>
                    <hr>
                </div>

            </div>

        </div>
    </div>
</section>

<!-- Case Study -->
<section class="case-study">
    <div class="container py-0 py-md-5">
        <div class="row">
            <div class="col">

                <!-- Heading -->
                <h1 class="text-center my-5">
                    Case studies
                </h1>

                <!-- Cards -->
                <div class="row row-cols row-cols-1 row-cols-md-3">

                    <!-- One -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title">
                                    We Have Launched Cpro
                                </h5>
                                <p class="card-text py-2">
                                    A buyer supplier collaboration platform
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Two -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title">
                                    We Have Launched Cpro
                                </h5>
                                <p class="card-text py-2">
                                    A buyer supplier collaboration platform
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Three -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title">
                                    We Have Launched Cpro
                                </h5>
                                <p class="card-text py-2">
                                    A buyer supplier collaboration platform
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Four -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title">
                                    We Have Launched Cpro
                                </h5>
                                <p class="card-text py-2">
                                    A buyer supplier collaboration platform
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Five -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title">
                                    We Have Launched Cpro
                                </h5>
                                <p class="card-text py-2">
                                    A buyer supplier collaboration platform
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Six -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <h5 class="card-title">
                                    We Have Launched Cpro
                                </h5>
                                <p class="card-text py-2">
                                    A buyer supplier collaboration platform
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Action Button -->
                <div class="row justify-content-center my-4">
                    <div class="col-12 col-md-3">
                        <a href="#" class="btn w-100">Show More</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Articles -->
<section class="articles">
    <div class="container py-5">
        <div class="row">
            <div class="col">

                <!-- Heading -->
                <h1 class="text-left my-5">
                    Articles
                </h1>

                <!-- Cards -->
                <div class="row row-cols row-cols-1 row-cols-md-3">

                    <!-- One -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <p class="card-text">
                                    9 January 2019
                                </p>
                                <h5 class="card-title">
                                    Why we have launched CPro – A buyer supplier collaboration platform
                                </h5>
                                <p class="card-text py-2">
                                    Greetings from Team Chizel. If you are reading this article, I am assuming you already know about us, and you are well aware of Chizel’s primary business of Manufacturing-as-a-Service….
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Two -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <p class="card-text">
                                    9 January 2019
                                </p>
                                <h5 class="card-title">
                                    Why we have launched CPro – A buyer supplier collaboration platform
                                </h5>
                                <p class="card-text py-2">
                                    Greetings from Team Chizel. If you are reading this article, I am assuming you already know about us, and you are well aware of Chizel’s primary business of Manufacturing-as-a-Service….
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Three -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <p class="card-text">
                                    9 January 2019
                                </p>
                                <h5 class="card-title">
                                    Why we have launched CPro – A buyer supplier collaboration platform
                                </h5>
                                <p class="card-text py-2">
                                    Greetings from Team Chizel. If you are reading this article, I am assuming you already know about us, and you are well aware of Chizel’s primary business of Manufacturing-as-a-Service….
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Four -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <p class="card-text">
                                    9 January 2019
                                </p>
                                <h5 class="card-title">
                                    Why we have launched CPro – A buyer supplier collaboration platform
                                </h5>
                                <p class="card-text py-2">
                                    Greetings from Team Chizel. If you are reading this article, I am assuming you already know about us, and you are well aware of Chizel’s primary business of Manufacturing-as-a-Service….
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Five -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <p class="card-text">
                                    9 January 2019
                                </p>
                                <h5 class="card-title">
                                    Why we have launched CPro – A buyer supplier collaboration platform
                                </h5>
                                <p class="card-text py-2">
                                    Greetings from Team Chizel. If you are reading this article, I am assuming you already know about us, and you are well aware of Chizel’s primary business of Manufacturing-as-a-Service….
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                    <!-- Six -->
                    <div class="col pb-5">
                        <div class="card border-0">
                            <img class="card-img-top" src="/assets/img/placeholder/demmy_8.PNG" alt="Card image cap">
                            <div class="card-body">
                                <p class="card-text">
                                    9 January 2019
                                </p>
                                <h5 class="card-title">
                                    Why we have launched CPro – A buyer supplier collaboration platform
                                </h5>
                                <p class="card-text py-2">
                                    Greetings from Team Chizel. If you are reading this article, I am assuming you already know about us, and you are well aware of Chizel’s primary business of Manufacturing-as-a-Service….
                                </p>
                                <kbd class="mr-1">3D PRINTING</kbd>
                                <kbd class="mr-1">SPOT LIGHT</kbd>
                            </div>
                        </div>
                    </div>

                </div>

                <!-- Action Button -->
                <div class="row justify-content-center my-4">
                    <div class="col-12 col-md-3">
                        <a href="#" class="btn w-100">Show More</a>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- A true inventor and an inventor in true sense! -->
<section>
    <div class="container-fluid py-5 my-3">
        <div class="row">
            <!-- Side Image -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 p-0 d-none d-md-block">
                <img src="./assets/img/home/Book a Demo@2x.jpg" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 pl-lg-5 d-flex align-items-center">
                <div class="w-100">
                    <!-- Title -->
                    <h1>
                        Do you want <br> a free quote <br> in 24 hours?
                    </h1>
                    <!-- Paragraph -->
                    <p class="mt-4">
                        Sign up for a free trial and find out how  <br> Truventor makes Manufacturing simple.
                    </p>
                    <!-- Action Buttons -->
                    <div class="row pb-3">

                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 pt-4">
                            <a  [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">  Get In Touch  </a>
                        </div>
                        <div class="col-12 col-sm-12 col-md-6 col-lg-4 pt-4">
                            <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn w-100"> Book A
                                Demo </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>