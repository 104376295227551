<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Copper C110@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Copper <br> C110
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        C110 offers the highest electrical and thermal conductivity
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Copper C110.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    C110 is the purest grade of oxygen-free copper. It contains 99.99% copper and has much tighter limits on impurities than other grades. C110 offers the highest electrical and thermal conductivity values available from a commercially available copper alloy
                    and is not susceptible to embrittlement when heated. C110 also has high ductility and is readily cold worked.

                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5">
                    Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Copper C110
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Copper C110 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Melting Point (Liquids)
                            </th>
                            <td>
                                1981°F
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Melting Point (Solidus)
                            </th>
                            <td>
                                1949°F
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Density (C102 Copper Alloy)
                            </th>
                            <td>
                                0.323/cu in
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Density (C110 Copper Alloy)
                            </th>
                            <td>
                                (1) .321-.323 lbs/cu in
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Electrical Resistivity
                            </th>
                            <td>
                                10.3 Ω⋅cmil/ft @ 68°F
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Excellent electrical conductivity
                        </p>
                        <p class="mb-2">
                            Outstanding thermal conductivity
                        </p>
                        <p class="mb-2">
                            Freedom from hydrogen embrittlement
                        </p>

                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Not suitable for welding applications
                        </p>
                        <p class="mb-2">
                            Poor machinability
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Components for vacuum devices
                        </p>
                        <p class="mb-2">
                            Switches
                        </p>
                        <p class="mb-2">
                            Interrupters
                        </p>
                        <p class="mb-2">
                            Hydraulic Shaft
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>