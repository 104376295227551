import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AdminService } from '../shared/admin.service';
declare var $: any;
@Component({
  selector: 'app-customer-landing',
  templateUrl: './customer-landing.component.html',
  styleUrls: ['./customer-landing.component.scss']
})
export class CustomerLandingComponent implements OnInit {

  constructor(
    private router: Router,
    private adminService: AdminService
  ) {
  }

  ngOnInit(): void {
    this.adminService.check_customer.next('customer')

    function leftSlide(tab) {
      $(tab).addClass('animated slideInLeft');
    }

    function rightSlide(tab) {
      $(tab).addClass('animated slideInRight');
    }

    $('li[data-toggle="tab"]').on('shown.bs.tab', function (e) {
      var url = new String(e.target);
      var pieces = url.split('#');
      var seq = $(this).children('a').attr('data-seq');
      var tab = $(this).children('a').attr('href');
      if (pieces[1] == "profile") {
        leftSlide(tab);
      }
    })


    $(document).ready(function () {

      // Gets the video src from the data-src on each button

      var $videoSrc;
      $('.video-btn').click(function () {
        $videoSrc = $(this).data("src");
      });

      // when the modal is opened autoplay it  
      $('#myModal').on('shown.bs.modal', function (e) {
        // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
        $("#video").attr('src', $videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");
      })


      // stop playing the youtube video when I close the modal
      $('#myModal').on('hide.bs.modal', function (e) {
        // a poor man's stop video
        $("#video").attr('src', $videoSrc);
      })

      // document ready  
    });
  }

  clientsOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ["<img src='assets/img/about/icon/arrow-left.png' alt='Truventor.ai'>", "<img src='assets/img/about/icon/arrow-right.png' alt='Truventor.ai'>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    },
    nav: true
  }


  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    margin: 40,
    navText: ['', ''],
    // navText: ["<img src='assets/img/about/icon/arrow-left.png'>", "<img src='assets/img/about/icon/arrow-right.png'>"],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    nav: true
  }

  counter(event) {
    if (!event.namespace) {
      return;
    }
    var slides = event.relatedTarget;
    $('.slider-counter').text(slides.relative(slides.current()) + 1 + '/' + slides.items().length);
  }






  sheetMetal() {
    this.router.navigate(['/sheet-metal-fabrication']);
  }

  threedPrinting() {
    this.router.navigate(['/3d-printing']);
  }

  vacuumCasting() {
    this.router.navigate(['/vacuum-casting']);
  }

  injectionMolding() {
    this.router.navigate(['/plastic-injection-moulding']);
  }

  metalCasting() {
    this.router.navigate(['/metal-casting']);
  }

  cncmachining() {
    this.router.navigate(['/cnc-machining']);
  }

  articles() {
    window.open("https://blogs.truventor.ai/blogs/", '_blank');
  }

}
