<!-- Main -->
<section class="main-session">
    <img src="assets/img/design/Banner image.png" class="img-banner" alt="Truventor">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1>
                        Truventor <br> Design
                    </h1>
                    <p class="mt-4">
                        Design and optimize the parts with our <br> AI-driven design tools
                    </p>
                </div>
            </div>
        </div>
        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" rel="noopener" class="btn btn-primary w-100"> Get In Touch </a>
            </div>
        </div>
        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>


<!-- Control your data -->
<section class="global-space-between">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p>
                    Designing for Manufacturing and Assembly (DFM or DFMA) is one of the most crucial parts of a product development cycle. It involves optimization of the design that suits the production method cutting down on time and wastage without compromising on the
                    quality. With our AI-driven design optimization tools, companies can identify improvements, ensure the best functionality, and maximum efficiency for parts.
                </p>
            </div>
            <div class="col-12 col-md-6">
                <div class="d-flex justify-content-center">
                    <img src="assets/img/design/Group 20362@2x.png" class="img-fluid w-75" alt="Control data">
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Advantages of calculate -->
<section class="side-tab-view global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-6 pl-0 d-none d-md-block">
                <div class="tab-content" id="main_id">
                    <div class="tab-pane fade show active" id="how-to-use-specify-requirement" role="tabpanel" aria-labelledby="how-to-use-specify-requirement-tab">
                        <img src="./assets/img/design/3.png" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                    </div>
                    <div class="tab-pane fade show" id="how-to-use-place-order" role="tabpanel" aria-labelledby="how-to-use-place-order-tab">
                        <img src="./assets/img/design/4.png" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                    </div>
                    <div class="tab-pane fade show" id="how-error" role="tabpanel" aria-labelledby="how-error">
                        <img src="./assets/img/design/1.png" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                    </div>
                    <div class="tab-pane fade show" id="how-to-use-upload" role="tabpanel" aria-labelledby="how-to-use-upload-tab">
                        <img src="./assets/img/design/2.png" class="img-fluid" width="100%" loading="lazy" alt="Truventor">
                    </div>


                </div>
            </div>

            <div class="col-12 col-md-6 pr-auto pr-md-5 advantages-of-calculate">
                <h1 class="text-left mb-3">
                    Benefits of Truventor.Design
                </h1>
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="how-to-use-specify-requirement-tab" data-toggle="pill" href="#how-to-use-specify-requirement" role="tab" aria-controls="how-to-use-specify-requirement" aria-selected="false">
                        <span class="plus mt-3 mt-md-0">
                            Sophisticated rendering modes
                        </span>
                        <p>
                            Visualize the 3D model in the four different rendering mode such as such as solid mode, shaded mode, X-ray mode, and wireframe mode to understand its geometry better.
                        </p>
                        <img src="./assets/img/design/3.png" class="img-fluid d-block d-md-none" width="100%" loading="lazy" alt="Truventor">
                    </a>
                    <a class="nav-link" id="how-to-use-place-order-tab" data-toggle="pill" href="#how-to-use-place-order" role="tab" aria-controls="how-to-use-place-order" aria-selected="false">
                        <span class="plus mt-3 mt-md-0">
                            Multi-dimensional section view
                        </span>
                        <p>
                            Section view allows you to slice the 3D model with three directions like X, Y and Z and view its interior geometry. Easy drag slide on slider changes the position of the cutting plan along its axis. One can toggle direction and cutting direction.
                        </p>
                        <img src="./assets/img/design/4.png" class="img-fluid d-block d-md-none" width="100%" loading="lazy" alt="Truventor">
                    </a>
                    <a class="nav-link" id="how-to-use-upload-tab" data-toggle="pill" href="#how-error" role="tab" aria-controls="how-error" aria-selected="true">
                        <span class="plus mt-3 mt-md-0">
                            DFM and design optimisation
                        </span>
                        <p>
                            Our expertise in manufacturing combined with artificial intelligence unlocks improvement potential that saves time and cost for manufacturers
                        </p>
                        <img src="./assets/img/design/1.png" class="img-fluid d-block d-md-none" width="100%" loading="lazy" alt="Truventor">
                    </a>
                    <a class="nav-link" id="how-to-use-upload-tab" data-toggle="pill" href="#how-to-use-upload" role="tab" aria-controls="how-to-use-upload" aria-selected="true">
                        <span class="plus mt-3 mt-md-0">
                            Effective communication
                        </span>
                        <p>
                            Render the measurements at a single click, take screenshots of dimensions and share it with the team, clients or suppliers for most efficient communication and bringing transparency in the system.
                        </p>
                        <img src="./assets/img/design/1.png" class="img-fluid d-block d-md-none" width="100%" loading="lazy" alt="Truventor">
                    </a>
                </div>

                <div class=" mt-4">
                    <a class="btn" href="https://calendly.com/truventor/15min" target="_blank">Book A Demo</a>
                </div>

            </div>

        </div>
    </div>
</section>

<!-- Features -->
<section class="how-it-works global-space-between bottom-end-padding">
    <div class="container-fluid">
        <div class="row justify-content-end">
            <div class="col-md-5">
                <div class="wrapper">
                    <h1 class="font-weight-bold"> Features </h1>
                    <p>
                        3D CAD viewer that simplifies design-to-manufacturing workflow, and provide transparency.
                    </p>
                    <ul class="nav nav-tabs row" id="myTab" role="tablist">
                        <li class="nav-item col-md-6 p-0">
                            <a class="nav-link" data-toggle="tab" href="#messages" role="tab" aria-controls="messages">
                                <hr>
                                <h6 class="font-weight-bold">Multiple view</h6>
                                <p>It supports the orthographic view, perspective isometric view, 2D view, and different standard views.</p>
                                <img src="assets/img/design/DFM.png" class="img-fluid d-block d-md-none" loading="lazy" alt="Truventor">

                            </a>
                        </li>
                        <li class="nav-item col-md-6 p-0">
                            <a class="nav-link" data-toggle="tab" href="#settings" role="tab" aria-controls="settings">
                                <hr>
                                <h6 class="font-weight-bold">CAD meta data</h6>
                                <p>See volume, surface area and dimension (length, breadth, height) of 3D mode</p>
                                <img src="assets/img/design/View port.png" class="img-fluid d-block d-md-none" loading="lazy" alt="Truventor">


                            </a>
                        </li>
                        <li class="nav-item col-md-6 p-0">
                            <a class="nav-link active" data-toggle="tab" href="#home" role="tab" aria-controls="home">
                                <hr>
                                <h6 class="font-weight-bold">Design optimization</h6>
                                <p>Using advanced tools like ML and AI, the platform rectifies design errors resulting in enhanced efficiency</p>
                                <img src="assets/img/design/Cost Calculatpr.png" class="img-fluid d-block d-md-none" loading="lazy" alt="Truventor">
                            </a>
                        </li>

                        <li class="nav-item col-md-6 p-0">
                            <a class="nav-link" data-toggle="tab" href="#profile" role="tab" aria-controls="profile">
                                <hr>
                                <h6 class="font-weight-bold">Cost calculator </h6>
                                <p>Calculate provide a simple cost estimator for making quick quotes</p>
                                <img src="assets/img/design/Meta Date.png" class="img-fluid d-block d-md-none" loading="lazy" alt="Truventor">

                            </a>
                        </li>

                    </ul>
                </div>
            </div>
            <div class="col-md-6 d-none d-md-block pr-0">
                <div class="tab-content text-right">
                    <div class="tab-pane active" id="home" role="tabpanel">
                        <img src="assets/img/design/DFM.png" class="img-fluid" loading="lazy" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="messages" role="tabpanel">
                        <img src="assets/img/design/View port.png" class="img-fluid" loading="lazy" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="profile" role="tabpanel">
                        <img src="assets/img/design/Cost Calculatpr.png" class="img-fluid" loading="lazy" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="settings" role="tabpanel">
                        <img src="assets/img/design/Meta Date.png" class="img-fluid" loading="lazy" alt="Truventor">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Testimonials -->
<section class="testimonial global-space-between">
    <div class="container">
        <div class="row pt-5">
            <div class="col-sm-12 col-md-6">
                <h1>
                    What our users <br> say about Truventor.Design
                </h1>
            </div>
            <div class="col-sm-12 col-md-6">
                <owl-carousel-o [options]="clientsOptions" class="clients-carouel mt-3 mt-md-0">

                    <ng-template carouselSlide>
                        <div class="w-100 d-flex justify-content-start justify-content-md-start">
                            <img src="./assets/img/customer_landing/testimonials/Calculate Avatar@2x.png" width="76px" height="76px" alt="client_image">
                        </div>
                        <p class="py-3">
                            “It is a great application. It can be used by press brake operators and fabricators to check 3D shape of the part which will be much easier than reading CAD blue prints. Costing feature is also great which will help in generating quick quotes.”
                        </p>
                        <h6>
                            Himanshu Saraswat
                        </h6>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>

<!-- Control your data -->
<section class="global-space-between">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <div class="d-flex">
                    <img src="assets/img/calculate/Group 20381@2x.png" class="img-fluid" alt="Control data">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <h1>
                    Only you control your data
                </h1>
                <p>
                    We believe that you should be in total control of your data. Thus, to strengthen our commitment, we give you access to DELETE the part file anytime you want. Once the file is deleted by you, it is deleted from our server as well, and so are the links
                    associated with it. This makes it better than any e-mail system out there. However, this does NOT delete the information associated with your CAD design. This information could be part name, dimensions, attributes, recommendations,
                    quotations, manufacturing log etc.
                </p>
            </div>
        </div>
    </div>
</section>

<!-- Video Player -->
<section class="global-space-between bottom-end-padding">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col">
                <h1 class="text-center">
                    Modern CAD platform for manufacturers
                </h1>
                <img src="assets/img/calculate/Product preview@2x.jpg" class="img-fluid" alt="Preview">
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-4">
                <a class="btn btn-primary w-100" [routerLink]="['/contact']">
                    Get In Touch
                </a>
            </div>
        </div>
    </div>
</section>