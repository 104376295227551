<!-- Main -->
<section class="main-session">
    <img src="assets/img/sheet_metal_capabilities/punching/Group 20166.jpg" class="img-banner d-none d-md-block" alt="Truventor">
    <img src="assets/img/sheet_metal_capabilities/punching/Group 20168.jpg" class="img-banner d-block d-md-none" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        Punching
                    </h1>
                    <p class="mt-4">
                        Sheet Metal Punching components at Truventor are precisely engineered as per the client's specification
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Description -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    Punching
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    Punching is a forming process that can be used to create a hole in the workpiece. The sheet metal that is to be punched is sandwiched between a punch and a die. Punching falls into one of two subcategories based on the application. In most cases, the
                    intent is to punch holes into a panel of metal for the purpose of fastening latches or other foreign parts.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div>
                    <div class="row align-items-center my-3">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/Stainless Steel, Carbon Steel.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-8">
                            <h5>
                                Steel, stainless steel
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row align-items-center my-3">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/Aluminum-Copper.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-8">
                            <h5>
                                Aluminum, copper
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Maximum stroke rate</th>
                            <td>
                                1350 1/min
                            </td>
                        </tr>
                        <tr>
                            <th>Punching mode X and Y axis</th>
                            <td>
                                3050 mm and 1550 mm
                            </td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>
                                2 - 3 business days
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Most Common Type -->
<section class="side-tab-view-toggle global-space-between">
    <div class="container">

        <div class="row">
            <div class="col">
                <h1 class="text-center mb-5">
                    Types of sheet metal punching operations
                </h1>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-12 col-md-6">
                <div class="tab-content" id="main_id">
                    <div class="tab-pane show active" id="slider_1" role="tabpanel" aria-labelledby="slider_1-tab">
                        <img src="./assets/img/sheet_metal_capabilities/punching/Group 20161@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_2" role="tabpanel" aria-labelledby="slider_2-tab">
                        <img src="./assets/img/sheet_metal_capabilities/punching/Group 20161@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_3" role="tabpanel" aria-labelledby="slider_3-tab">
                        <img src="./assets/img/sheet_metal_capabilities/punching/Group 20161@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_4" role="tabpanel" aria-labelledby="slider_4-tab">
                        <img src="./assets/img/sheet_metal_capabilities/punching/Group 20161@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_5" role="tabpanel" aria-labelledby="slider_5-tab">
                        <img src="./assets/img/sheet_metal_capabilities/punching/Group 20161@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_6" role="tabpanel" aria-labelledby="slider_6-tab">
                        <img src="./assets/img/sheet_metal_capabilities/punching/Group 20161@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="slider_1-tab" data-toggle="pill" href="#slider_1" role="tab" aria-controls="slider_1" aria-selected="true">
                        <span class="toggle">
                            Slotting
                        </span>
                        <hr>
                        <p>
                            Slotting is a punching operation that cuts out an elongated or rectangular hole in sheetmetal work piece.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_2-tab" data-toggle="pill" href="#slider_2" role="tab" aria-controls="slider_2" aria-selected="false">
                        <span class="toggle">
                            Lancing
                        </span>
                        <hr>
                        <p>
                            Lancing is a piercing operation in which the workpiece is sheared and bent with one strike of the die.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_3-tab" data-toggle="pill" href="#slider_3" role="tab" aria-controls="slider_3" aria-selected="false">
                        <span class="toggle">
                            Nibbling
                        </span>
                        <hr>
                        <p>
                            It is a process that removes the material by making holes or by cutting inner or outer profiles by means of multiple punching strokes.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_4-tab" data-toggle="pill" href="#slider_4" role="tab" aria-controls="slider_4" aria-selected="false">
                        <span class="toggle">
                            Perforated
                        </span>
                        <hr>
                        <p>
                            During this process, a sheet with needles is repeatedly pressed onto the passing metal which punches holes into the sheet. The die and punch method is very efficient and can perforate a large surface of sheet very quickly.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_5-tab" data-toggle="pill" href="#slider_5" role="tab" aria-controls="slider_5" aria-selected="false">
                        <span class="toggle">
                            Piercing
                        </span>
                        <hr>
                        <p>
                            It is a shearing process where a punch and die are used to create a hole in sheet metal or a plate.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_6-tab" data-toggle="pill" href="#slider_6" role="tab" aria-controls="slider_6" aria-selected="false">
                        <span class="toggle">
                            Notching
                        </span>
                        <hr>
                        <p>
                            Notching is a metal-cutting process used on sheet-metal or thin bar-stock, sometimes on angle sections or tube.
                        </p>
                    </a>
                </div>
            </div>
        </div>

    </div>
</section>

<!-- Application Of Metal Bending -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1>
                        Application of sheet metal punching process
                    </h1>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row row-cols-2 row-cols-md-2">
                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <p>
                            Metal and construction industries
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <p>
                            Aerospace, electronics industries
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <p>
                            Packaging and white goods
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <p>
                            Food, medical and pharmaceutical industries
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant punching quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <img src="assets/img/sheet_metal_capabilities/punching/Group 13311.jpg" class="fluid-img w-100" alt="video">
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>