<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_template/Group 19487@2x.jpg" class="img-banner d-none d-md-block" alt="Truventor">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Aluminium 6082 (HE 30)
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Light-weight components and great machinability
                    </p>
                </div>
            </div>
        </div>

        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19487@2x.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>
        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Aluminium 6082.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>
        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Aluminium HE 30 alloy a.k.a AL 6082 is a medium strength alloy with excellent corrosion resistance. It has the highest strength of 6000 (6XXX) series alloys. It is also known as a structural alloy. In block, plate, or bar form, AL HE 30 alloy is most
                    commonly used in machining.
                    <br> Although it is a relatively new alloy, due to its higher strength, AL HE 30 has replaced AL 6061 in many applications. The addition of a large amount of manganese controls the grain structure which in turn results in a stronger
                    alloy.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Aluminium 6082 (HE 30)
                    </strong>
                </h6>
                <img src="./assets/img/material_template/Group 19487.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                Yellowish White
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-)125µm (Standard)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                370 x 350 x 200 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships in
                            </th>
                            <td>
                                5-7 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            High strength-to-weight ratio makes it ideal for lightweight structures
                        </p>
                        <p class="mb-2">
                            Most versatile and highest strength alloy in 6000 series Aluminum
                        </p>
                        <p class="mb-2">
                            Good machinability w.r.t metals like SS, MS etc
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Low fatigue strength & elastic strength as compared to steels
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Lightweight and corrosion resistant components
                        </p>
                        <p class="mb-2">
                            Alternative to plastics in high stress applications
                        </p>
                        <p class="mb-2">
                            Automotive components
                        </p>
                        <p class="mb-2">
                            Electronic applications
                        </p>
                        <p class="mb-2">
                            Aerospace components
                        </p>
                        <p class="mb-2">
                            General purpose metal components
                        </p>
                        <p class="mb-2">
                            Trusses, frames and bars
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <div class="row">
                    <div class="col-12 col-md-8">
                        <h1 class="mb-5">
                            Design Guidelines For Aluminium 6082 (HE 30)
                        </h1>
                    </div>
                </div>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">


                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/aluminium-6082/General Achievable Tolerance@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            General achievable tolerance
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the Standard Tolerance : (+/-) <span class="text-primary">125 µm</span> & Achievable: (+/-) <span class="text-primary">100 µm</span> , (+/-) <span class="text-primary">50 µm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/aluminium-6082/Wall Thickness@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1mm</span>. For decent strength: <span class="text-primary">2mm</span> & For rigid wall: <span class="text-primary">2.5mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/aluminium-6082/Pocket Depth@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Pocket Depth
                        </h6>
                        <p class="mt-2">
                            General pocket depth up to 3 times the diameter of the end mill
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/aluminium-6082/Engraving@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Engraving:
                        </h6>
                        <p class="mt-2">
                            Always prefer engraving over embossing Recommended fonts for a clear, legible text: Arial, Gothic Letter strand width should be at least <span class="text-primary">2 mm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/aluminium-6082/Internal Radii@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Internal Radii
                        </h6>
                        <p class="mt-2">
                            Minimum Internal radii achieved is <span class="text-primary">1 mm</span> Depends highly on the depth of pocket being milled
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/aluminium-6082/Holes@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Holes
                        </h6>
                        <p class="mt-2">
                            Use standard drill bit sizes Minimum : <span class="text-primary">1 mm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/aluminium-6082/Threads@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Threads
                        </h6>
                        <p class="mt-2">
                            Use standard metric inserts Smallest threaded insert available: <span class="text-primary">M2</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Looking for a cost estimate? -->
<app-cost-estimate></app-cost-estimate>