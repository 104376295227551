<!-- Main -->
<section class="imprint-main-session">
    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col col-12">
                <h1>
                    Corporate Information
                </h1>
            </div>
        </div>

    </div>
</section>

<!-- Vendor of the website -->
<section class="vendor-of-the-website">
    <div class="container my-md-5 py-5">
        <div class="row">
            <div class="col col-12 col-md-5">
                <h1 class="pt-5 pt-md-0">
                    Vendor Of The Website
                </h1>
            </div>
            <div class="col col-12 col-md-7 my-4 my-md-0">
                <!-- divider here -->
                <span class="divider"></span>
                <!-- Address -->
                <h5 class="ml-3 ml-md-5">
                    TRUVENTOR AI AND Robotics Pty Ltd <br>
                    Address: Level 19 180 Lonsdale street Melbourne VIC 3000 <br>
                    ABN: 93 622 249 969
                </h5>
            </div>
        </div>
    </div>
</section>

<!-- Managing Director -->
<section class="managing-director">
    <div class="container my-md-5 py-5">

        <!-- Title -->
        <div class="row">
            <div class="col-12">
                <h1>
                    Managing Director: Soumitra Joshi
                </h1>
            </div>
        </div>

        <!-- Regulatory Authority -->
        <div class="row mt-5">
            <div class="col-12 col-md-7">
                <h6>
                    Regulatory Authority:
                </h6>
                <hr>
                <div class="col-12 col-md-9 p-0">
                    <p>
                        Australian Securities & Investments Commission
                        Address: Level 7 120 Collins Street Melbourne VIC 3000
                    </p>
                </div>
            </div>
        </div>

        <!-- Regulatory Authority -->
        <div class="row mt-5">
            <div class="col-12 col-md-7">
                <h6>
                    Disclaimer
                </h6>
                <hr>
                <p>
                    The internet offer is compiled and kept up-to-date as conscientiously as possible. Truventor cannot,
                    however, guarantee that the data is free of errors, accurate or complete and, therefore, assumes no
                    liability for loss or damage of any kind incurred directly or indirectly through the use of this
                    website’s online offer. Truventor also declines any liability for website offers of other operators
                    that visitors of this website access through the links embedded here. Liability for such third-party
                    contents rests solely with their owners.
                </p>
            </div>
        </div>

    </div>
</section>