<!-- Main -->
<section class="main-session">
    <img src="assets/img/3d_printing_capabilities/sla/Group 20105.jpg" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <!-- Desktop -->
                    <div class="d-none d-md-block">
                        <h1>
                            Stereolithography (SLA)
                        </h1>
                    </div>
                    <!-- Mobile -->
                    <div class="d-block d-md-none">
                        <h2>
                            Stereolithography (SLA)
                        </h2>
                    </div>
                    <p class="mt-5 mb-5">
                        Build high precision parts and complex geometric shapes by our SLA services
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!--  -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    Stereolithograpy (SLA)
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    Stereolithography (SLA) is a 3D printing technology which makes use of photopolymer resins to manufacture proof of concept prototypes. Stereolithography (SL) is an industrial 3D printing process used to create concept models, cosmetic - rapid prototypes,
                    and complex parts with intricate geometries in as fast as 1 day.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div class="mt-5 mt-md-0">
                    <div class="row my-3 align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/3d_printing_capabilities/sla/Group 20107@2x.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                PC-like (clear)
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row my-3 align-items-center">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/3d_printing_capabilities/sla/Group 20109@2x.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-7">
                            <h5>
                                PP-like
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Max part size</th>
                            <td>
                                350 x 600 x 400 mm
                            </td>
                        </tr>
                        <tr>
                            <th>Accuracy </th>
                            <td>
                                (+/-) 150µm
                            </td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>
                                3-4 business days
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- The SLA 3D Printing Workflow -->
<section class="side-tab-view-toggle global-space-between">
    <div class="container">

        <!-- Desktop View -->
        <div class="d-none d-md-block">

            <div class="row">
                <div class="col">
                    <h1 class="text-center mb-5">
                        The SLA 3D printing workflow
                    </h1>
                </div>
            </div>

            <div class="row mt-5 align-items-center">
                <div class="col-6">
                    <div class="tab-content" id="main_id">
                        <div class="tab-pane show active" id="slider_1" role="tabpanel" aria-labelledby="slider_1-tab">
                            <img src="./assets/img/3d_printing_capabilities/sla/Group 20114@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_2" role="tabpanel" aria-labelledby="slider_2-tab">
                            <img src="./assets/img/3d_printing_capabilities/sla/Group 20116@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                        <div class="tab-pane" id="slider_3" role="tabpanel" aria-labelledby="slider_3-tab">
                            <img src="./assets/img/3d_printing_capabilities/sla/Group 20115@2x.jpg" class="img-fluid" width="100%" alt="Truventor">
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link active" id="slider_1-tab" data-toggle="pill" href="#slider_1" role="tab" aria-controls="slider_1" aria-selected="true">
                            <span class="toggle">
                                Design
                            </span>
                            <hr>
                            <p>
                                Use any CAD software or 3D scan data to design your model, and export it in a 3D printable file format (STL or OBJ)
                            </p>
                        </a>
                        <a class="nav-link" id="slider_2-tab" data-toggle="pill" href="#slider_2" role="tab" aria-controls="slider_2" aria-selected="false">
                            <span class="toggle">
                                Print
                            </span>
                            <hr>
                            <p>
                                After a quick confirmation of the correct setup, the printing process begins and the machine can run unattended until the print is complete.
                            </p>
                        </a>
                        <a class="nav-link" id="slider_3-tab" data-toggle="pill" href="#slider_3" role="tab" aria-controls="slider_3" aria-selected="false">
                            <span class="toggle">
                                Post-Process
                            </span>
                            <hr>
                            <p>
                                Once the printing is completed, parts has to go through certain finishing processes such as machining, priming, painting, and assembly for specific applications or finishes.
                            </p>
                        </a>
                    </div>
                </div>
            </div>

        </div>

        <!-- Mobile View -->
        <div class="d-block d-md-none">
            <div class="row">
                <div class="col">

                    <!-- Title -->
                    <h2 class="text-left text-md-left">
                        The SLA 3D printing workflow
                    </h2>

                    <!-- One -->
                    <div>
                        <img src="./assets/img/3d_printing_capabilities/sla/Group 20114@2x.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                        <h5 class="text-left">
                            Design
                        </h5>
                        <hr>
                        <p>
                            Use any CAD software or 3D scan data to design your model, and export it in a 3D printable file format (STL or OBJ)
                        </p>
                    </div>
                    <!-- Two -->
                    <div>
                        <img src="./assets/img/3d_printing_capabilities/sla/Group 20116@2x.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                        <h5 class="text-left">
                            Print
                        </h5>
                        <hr>
                        <p>
                            After a quick confirmation of the correct setup, the printing process begins and the machine can run unattended until the print is complete.
                        </p>
                    </div>
                    <!-- Three -->
                    <div>
                        <img src="./assets/img/3d_printing_capabilities/sla/Group 20115@2x.jpg" class="img-fluid py-4" width="100%" alt="Truventor">
                        <h5 class="text-left">
                            Post-Process
                        </h5>
                        <hr>
                        <p>
                            Once the printing is completed, parts has to go through certain finishing processes such as machining, priming, painting, and assembly for specific applications or finishes.
                        </p>
                    </div>

                </div>
            </div>
        </div>

    </div>
</section>

<!-- Attributes -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1 class="mb-5 mb-md-4">
                        Attributes
                    </h1>
                    <p class="mb-5 mb-md-4">
                        Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                    </p>
                    <!-- Desktop Button -->
                    <div class="d-none d-md-block">
                        <a [routerLink]="['/contact']" class="mt-4 btn px-5"> Get In Touch </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Overview.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Highlights
                        </h3>
                        <p>
                            Ideal for high precision parts and complex geometric shapes
                        </p>
                        <p>
                            Provide good functional surface quality
                        </p>
                        <p>
                            Smooth finish
                        </p>
                        <p>
                            Economical process and provides speed
                        </p>
                        <p>
                            It’s a multi material process
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Limitation.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Limitations
                        </h3>
                        <p>
                            Depending on the material, components may be brittle
                        </p>
                        <p>
                            Support structures can limit design freedom
                        </p>
                        <p>
                            Components are only UV-resistant to a limited extent
                        </p>
                    </div>
                </div>
                <hr>

                <div class="row">
                    <div class="col-1 pr-0 pr-md-3">
                        <img src="assets/img/3d_printing_capabilities/svg/Application.svg" class="img-fluid w-100 mt-2" alt="icon">
                    </div>
                    <div class="col-11">
                        <h3>
                            Applications
                        </h3>
                        <p>
                            Investment casting patterns
                        </p>
                        <p>
                            Rapid tooling, jigs & fixtures
                        </p>
                        <p>
                            Implants and bio-medical engineering
                        </p>
                    </div>
                </div>
                <hr>

                <!-- Mobile Button -->
                <div class="px-3 d-block d-md-none">
                    <a [routerLink]="['/contact']" class="mt-4 btn btn-block"> Get In Touch </a>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant 3D printing quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <div class="video-wrapper">
                            <img src="./assets/img/3d_printing_capabilities/sla/Screen Shot.webp" class="fluid-img w-100" alt="video">
                            <img src="./assets/img/3d_printing_capabilities/svg/PlayButton.svg" class="play-button" alt="Truventor">
                        </div>
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>