<!-- Main -->
<section class="main-session">
    <img src="assets/img/sheet_metal_capabilities/bending/Mask Group 234.jpg" class="img-banner" alt="Truventor">
    <div class="container">
        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        Bending
                    </h1>
                    <p class="mt-4">
                        Simple or challenging bending job projects? We can help you with all!
                    </p>
                    <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary">
                        Get In Touch
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="end d-none d-md-block">
        <div class="border-line"></div>
    </div>
</section>

<!-- Description -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    Bending
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    Bending is a manufacturing process that alters the shape of sheet metal by the application of force. The sheet metal can be bent at the desired angle; a sequence of several bending operations can result in a desired complex shape or part. Typically, bending
                    involves deformation about a single axis.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div>
                    <div class="row align-items-center my-3">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/Stainless Steel, Carbon Steel.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-8">
                            <h5>
                                Stainless steel, carbon steel
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row align-items-center my-3">
                        <!-- Image -->
                        <div class="col-6 col-md-4">
                            <img src="assets/img/Aluminum-Copper.jpg" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-6 col-md-8">
                            <h5>
                                Aluminium , copper
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content mt-5 mt-md-0">
                    <table class="table">
                        <tr>
                            <th>Max diameter</th>
                            <td>
                                up to ∅ 80 mm
                            </td>
                        </tr>
                        <tr>
                            <th>X Y Z accuracy</th>
                            <td>
                                ± 0.05 mm, ± 0.05 mm and ± 0.05° respectively
                            </td>
                        </tr>
                        <tr>
                            <th>Lead time</th>
                            <td>
                                2 - 3 business days
                            </td>
                        </tr>
                        <tr>
                            <td colspan="2"></td>
                        </tr>
                    </table>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Most Common Type -->
<section class="side-tab-view-toggle global-space-between">
    <div class="container">

        <div class="row">
            <div class="col">
                <h1 class="text-center mb-5">
                    Most common types of metal bending
                </h1>
            </div>
        </div>

        <div class="row align-items-center mt-5">
            <div class="col-12 col-md-6">
                <div class="tab-content" id="main_id">
                    <div class="tab-pane show active" id="slider_1" role="tabpanel" aria-labelledby="slider_1-tab">
                        <img src="./assets/img/sheet_metal_capabilities/bending/Group 20162.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_2" role="tabpanel" aria-labelledby="slider_2-tab">
                        <img src="./assets/img/sheet_metal_capabilities/bending/Group 20162.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_3" role="tabpanel" aria-labelledby="slider_3-tab">
                        <img src="./assets/img/sheet_metal_capabilities/bending/Group 20162.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                    <div class="tab-pane" id="slider_4" role="tabpanel" aria-labelledby="slider_4-tab">
                        <img src="./assets/img/sheet_metal_capabilities/bending/Group 20162.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                    <a class="nav-link active" id="slider_1-tab" data-toggle="pill" href="#slider_1" role="tab" aria-controls="slider_1" aria-selected="true">
                        <span class="toggle">
                            Air Bending
                        </span>
                        <hr>
                        <p>
                            Air bending is a simple method of creating a bend without the need for lower die geometry. During this process, the workpiece comes into contact with the outside edges of the die, as well as the punch tip.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_2-tab" data-toggle="pill" href="#slider_2" role="tab" aria-controls="slider_2" aria-selected="false">
                        <span class="toggle">
                            Bottom Bending
                        </span>
                        <hr>
                        <p>
                            Bottom bending also uses a punch and bottom v-shaped die but bends the metal by bringing the die and punch together. The difference between air bending and bottom bending is that there is a difference in radius between the two.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_3-tab" data-toggle="pill" href="#slider_3" role="tab" aria-controls="slider_3" aria-selected="false">
                        <span class="toggle">
                            Coining
                        </span>
                        <hr>
                        <p>
                            Coining is a bending process that’s characterized by greater force. Like air bending, it compresses sheet metal between a top die and a bottom die. There is more tonnage required on this type of bending than in air bending and bottom bending.
                        </p>
                    </a>
                    <a class="nav-link" id="slider_4-tab" data-toggle="pill" href="#slider_4" role="tab" aria-controls="slider_4" aria-selected="false">
                        <span class="toggle">
                            Roll Bending
                        </span>
                        <hr>
                        <p>
                            The arrangement of the rolls determines the exact bend of the work. Different curves are obtained by controlling the distance and angle between the rolls. A moveable roll provides the ability to control the curve.
                        </p>
                    </a>
                </div>
            </div>
        </div>

    </div>
</section>


<!-- Application Of Metal Bending -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1>
                        Application of metal bending
                    </h1>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row row-cols-2 row-cols-md-2">
                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <p>
                            Automobile parts
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <p>
                            Aircraft panels
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <p>
                            Housings and cabinets
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <p>
                            White goods parts
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant bending quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12">
                        <img src="assets/img/sheet_metal_capabilities/bending/Group 13311.jpg" class="fluid-img w-100" alt="video">
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>