<!-- Main -->
<section class="main-session">
    <img src="assets/img/sheet_metal_fabrication/Sheet Metal banner Image.jpg" class="img-banner" alt="Truventor">
    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-7">
                <div class="heading">
                    <h1> Sheet Metal Fabrication </h1>
                    <p class="mt-4">Save time and get precision sheet metal parts with our fabrication services.</p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch </a>
            </div>
        </div>

        <!-- Statistics -->
        <div class="row statistics d-none d-md-flex">
            <div class="col">
                <div class="small-info">
                    <h6> 25+ </h6>
                    <hr>
                    <p>Materials</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info">
                    <h6> 70,000+ </h6>
                    <hr>
                    <p>Parts Manufactured</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info">
                    <h6> 250+ </h6>
                    <hr>
                    <p>Mfg Partners</p>
                </div>
            </div>

            <div class="col">
                <div class="small-info">
                    <h6> 45+ </h6>
                    <hr>
                    <p>Customers</p>
                </div>
            </div>

        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Our Sheet Metal Fabrication Services -->
<section class="sheet-metal-fab global-space-between">
    <div class="container">

        <div class="row">
            <div class="col-12 order-12 order-md-1 col-md-5">
                <h1 class="text-left">
                    Our sheet metal fabrication services
                </h1>
                <p class="description">
                    With our highly experienced team in sheet metal we have served many global and domestic companies. We manufacture parts like enclosures, chassis, brackets, clamps, etc for application across medical, automotive, aerospace, power equipment, special purpose
                    machine manufacturers, and many more.
                </p>
            </div>
            <div class="col-12 order-1 order-md-12 col-md-6 d-flex justify-content-center mb-5">
                <img src="assets/img/sheet_metal_fabrication/svg/Sheet Metal.svg" class="img-fluid machining-img" alt="Sheet Metal" loading="lazy">
            </div>
        </div>
    </div>
</section>

<!-- Sheet fab capabilities -->
<section class="sheet-fab-capabilities global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <h1>Capabilities</h1>
                    <div class="row row-cols-1 row-cols-md-2">

                        <!-- Laser Cutting -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0">
                                <img src="/assets/img/sheet_metal_fabrication/Laser Cuttting.jpg" class="card-img" alt="Laser Cutting" loading="lazy">
                                <div class="card-img-overlay d-flex align-items-end">
                                    <div class="text-holder hover-holder">
                                        <h5 class="card-title">Laser cutting</h5>
                                        <p class="card-text">
                                            Laser cutting is a thermal process in which a focused laser beam is used to cut the material.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Bending -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0">
                                <img src="/assets/img/sheet_metal_fabrication/Bending.jpg" class="card-img" alt="Bending" loading="lazy">
                                <div class="card-img-overlay d-flex align-items-end">
                                    <div class="text-holder hover-holder">
                                        <h5 class="card-title">Bending</h5>
                                        <p class="card-text">
                                            Bending is a manufacturing process that alters the shape of sheet metal by the application of a force.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Punching -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0">
                                <img src="/assets/img/sheet_metal_fabrication/Punching.jpg" class="card-img" alt="Punching" loading="lazy">
                                <div class="card-img-overlay d-flex align-items-end">
                                    <div class="text-holder hover-holder">
                                        <h5 class="card-title">Punching</h5>
                                        <p class="card-text">
                                            Punching is a forming process that can be used to create a hole in the workpiece.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Welding -->
                        <div class="col p-3">
                            <div class="card bg-dark text-white border-0">
                                <img src="/assets/img/sheet_metal_fabrication/Gas welding.jpg" class="card-img" alt="Welding" loading="lazy">
                                <div class="card-img-overlay d-flex align-items-end">
                                    <div class="text-holder hover-holder">
                                        <h5 class="card-title">Welding</h5>
                                        <p class="card-text">
                                            Welding is a fabrication process by which two pieces of metal can be joined together.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Top Materials -->
<section class="top-materials global-space-between" id="sheet-metal-material">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="content">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h1 class="py-3">
                                Industrial-grade sheet metal materials
                            </h1>
                        </div>
                        <div class="col-12 col-md-6 d-flex align-items-center">
                            <p>
                                In engineering, the right materials matter. We can make sure your final product looks and performs exactly as intended.

                            </p>
                        </div>
                    </div>

                    <!-- Table -->
                    <div class="row">
                        <div class="col">

                            <!-- Collapse One -->
                            <div class="card border-0 mt-2" id="industrial-grade-cnc-materials-top-metals">


                                <h2 class="mb-0 px-0 card-header bg-transparent" data-toggle="collapse" data-target="#industrial-grade-cnc-materials-collapse-one" aria-expanded="true" aria-controls="industrial-grade-cnc-materials-collapse-one">
                                    Top metals
                                </h2>


                                <div id="industrial-grade-cnc-materials-collapse-one" class="collapse show" aria-labelledby="industrial-grade-cnc-materials-accordion-one" data-parent="#industrial-grade-cnc-materials-top-metals">
                                    <div class="card-body px-3">
                                        <!-- One -->


                                        <div *ngFor="let metal of sheetMetalMaterial">
                                            <div class="row align-items-center">
                                                <div class="col p-0">
                                                    <div class="row align-items-center">
                                                        <div class="col-12 col-md-9 col-lg-8">
                                                            <div class="row my-2 align-items-center">
                                                                <div class="col-12 col-md-3">
                                                                    <img src="{{metal?.imgUrl}}" class="img-fluid list-image" alt="Truvento">
                                                                </div>
                                                                <div class="col-12 col-md-4"> <b class="d-block mt-3 mt-md-0"> {{metal.name}} </b>
                                                                </div>
                                                                <div class="col-12 col-md-5">
                                                                    <p class="m-0">
                                                                        {{metal.description}}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-12 col-md-3 col-lg-4 text-md-right text-left">
                                                            <a href="{{metal?.url}}" class="btn btn-primary py-2 px-4 mt-2 mt-md-0 mr-3">
                                                                Know More
                                                            </a>
                                                            <a [routerLink]="['/contact']" rel="noopener" class="btn py-2 px-4 mt-2 mt-md-0"> Get
                                                                A Quote
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col p-0">
                                                    <hr>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Tale ending -->
                    <div class="row mt-1 mt-md-0">
                        <div class="col">
                            <p class="mt-2">
                                Additional alloys and tempers are available upon request. We can source to your project specifications. Talk to our support team at <a href="#"> care@truventor.ai </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Post processing options -->
<section class="post-processing-options exapandable-accordion global-space-between">
    <div class="container">
        <!-- Title -->
        <div class="row">
            <div class="col p-md-0">
                <h1 class="py-4"> Post processing options </h1>
            </div>
        </div>
        <!-- accordion -->
        <div class="row align-items-center">
            <!-- Side Image -->
            <div class="col-sm-12 col-md-6 p-0 d-flex justify-content-center justify-content-md-start align-items-center">
                <img src="./assets/img/sheet_metal_fabrication/POst Procrssing.jpg" class="img-fluid w-100 p-3 p-md-0" alt="Truventor" loading="lazy">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5">
                <!-- collapse -->
                <div class="w-100">
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="col-sm-12 col-md-12 pl-0">
                            <div id="accordion-post-processing" class="myaccordion">
                                <div class="card">
                                    <div class="card-header" id="headingOne">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                BEAD BLASTING
                                                <i class="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion-post-processing">
                                        <div class="card-body">
                                            <p>Bead blasting is a finishing process that consists of shooting thousands of tiny abrasive particles (such as glass or sand) at the surface of a metal object using compressed air.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingTwo">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                ANODIZING
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion-post-processing">
                                        <div class="card-body">
                                            <p>Anodizing is a technique that uses an electrochemical process to create a corrosion-resistant layer on the surface of the sheet metal part.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingThree">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                POWDER COATING
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordion-post-processing">
                                        <div class="card-body">
                                            <p>Powder coating is an aesthetic finishing process that consists of spraying powdered paint onto the surface of a sheet metal component.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="headingFour">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                ELECTROPLATING
                                                <i class="fa fa-plus" aria-hidden="true"></i>

                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordion-post-processing">
                                        <div class="card-body">
                                            <p>Electroplating is a popular finishing process used for sheet metal parts in which fine layers of another type of metal are bonded to the part’s surface.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-quality-assurance></app-quality-assurance>

<!-- Automated Nesting Platform -->
<section class="automated-nesting-platform global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <h1> Automated <br> nesting platform </h1>
            </div>
            <div class="col-12 col-md-6">
                <p>
                    Truventor offers high-quality nesting layout calculation services, supporting various sheet metal technologies and material management.
                </p>
            </div>
        </div>
        <!-- Image -->
        <div class="row">
            <div class="col py-3">
                <img src="/assets/img/sheet_metal_fabrication/Automated Nesting.jpg" class="img-fluid w-100" loading="lazy" alt="Truventor">
            </div>
        </div>
    </div>
</section>

<!-- Applications -->
<section class="sheet-fab-applications global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <!-- Title -->
                <h1 class="text-center pb-5"> Applications </h1>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3">

            <!-- Consumer Goods -->
            <div class="col mb-4">
                <div class="card border-0">
                    <figure>
                        <img src="/assets/img/sheet_metal_fabrication/Consumer Goods.jpg" class="card-img-top" alt="consumer goods" loading="lazy">
                    </figure>

                    <div class="card-body">
                        <h4 class="card-title py-1 font-weight-bold"> Consumer Goods </h4>
                        <p class="card-text py-2">Packaging items, Furniture, etc </p>
                        <div class="py-1 d-flex justify-content-start align-items-cener flex-wrap">
                            <kbd>Sheet Metal</kbd>
                            <kbd>Welding</kbd>
                        </div>
                    </div>
                </div>
            </div>

            <!-- White Goods -->
            <div class="col mb-4">
                <div class="card border-0">
                    <figure>
                        <img src="/assets/img/sheet_metal_fabrication/White Goods.jpg" class="card-img-top" alt="consumer goods" loading="lazy">
                    </figure>
                    <div class="card-body">
                        <h4 class="card-title py-1 font-weight-bold"> White Goods </h4>
                        <p class="card-text py-2">Components for TV, Refrigerator, etc </p>
                        <div class="py-1 d-flex justify-content-start align-items-cener flex-wrap">
                            <kbd>Sheet Metal</kbd>
                            <kbd>Bending</kbd>
                        </div>
                    </div>
                </div>
            </div>

            <!-- General Engineering -->
            <div class="col mb-4">
                <div class="card border-0">
                    <figure>
                        <img src="/assets/img/sheet_metal_fabrication/Genera Engg.jpg" class="card-img-top" alt="consumer goods" loading="lazy">
                    </figure>
                    <div class="card-body">
                        <h4 class="card-title py-1 font-weight-bold"> General Engineering </h4>
                        <p class="card-text py-2">Racks & Panels, Enclosures, Clamps, etc </p>
                        <div class="py-1 d-flex justify-content-start align-items-cener flex-wrap">
                            <kbd>Sheet Metal</kbd>
                            <kbd>Laser Cutting</kbd>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Why Choose Us? -->
<section class="grow-your-business global-space-between">
    <div class="container">
        <div class="row align-items-center">

            <!-- Title -->
            <h1 class="text-left d-block d-md-none pl-4"> Why Choose <br> Us? </h1>
            <!-- Image -->
            <div class="col-sm-12 col-md-6 p-4 p-md-0">
                <img src="./assets/img/sheet_metal_fabrication/Why choose us.jpg" class="img-fluid" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5 d-flex align-items-center">
                <div class="col-sm-12 col-md-12 p-2">
                    <!-- Title -->
                    <h1 class="text-left d-none d-md-block">
                        Why choose us?
                    </h1>
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="w-100">
                            <div id="grow-your-businness-accordion-one" class="myaccordion">
                                <div class="card">
                                    <div class="card-header" id="growheadingOne">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100" data-toggle="collapse" data-target="#growcollapseOne" aria-expanded="true" aria-controls="growcollapseOne">
                                                Unlimited capacities and capabilities
                                                <i class="fa fa-minus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseOne" class="collapse show" aria-labelledby="growheadingOne" data-parent="#grow-your-businness-accordion-one">
                                        <div class="card-body">
                                            <p>Sheet metal fabrication involves multiple processes, custom tools, geometries, materials, tolerences and post processing. We undertake everything under one roof ensuring quality.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingTwo">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseTwo" aria-expanded="false" aria-controls="growcollapseTwo">
                                                Committed timelines
                                                <i class="fa fa-plus" aria-hidden="true"></i>

                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseTwo" class="collapse" aria-labelledby="growheadingTwo" data-parent="#grow-your-businness-accordion-one">
                                        <div class="card-body">
                                            <p>We understand the impact of over-commitment and not delivering parts on time. We are a professional team of engineers and product managers and our only job is to ensure quality and timelines. We do it best.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingThree">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseThree" aria-expanded="false" aria-controls="growcollapseThree">
                                                Full transparency

                                                <i class="fa fa-plus" aria-hidden="true"></i>


                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseThree" class="collapse" aria-labelledby="growheadingThree" data-parent="#grow-your-businness-accordion-one">
                                        <div class="card-body">
                                            <p>We bring transparency into manufacturing and the supply chain by providing design optimisation, manufacturing, inspection and delivery data.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header" id="growheadingFour">
                                        <h2 class="mb-0">
                                            <button class="d-flex align-items-center justify-content-between w-100 collapsed" data-toggle="collapse" data-target="#growcollapseFour" aria-expanded="false" aria-controls="growcollapseFour">
                                                No Minimum order quantity
                                                <i class="fa fa-plus" aria-hidden="true"></i>
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="growcollapseFour" class="collapse" aria-labelledby="growheadingFour" data-parent="#grow-your-businness-accordion-one">
                                        <div class="card-body">
                                            <p>Truventor provides fabrication services for quantities as less as one part. Our flexibility allows you to scale production depending on forecast, so you can stock up less inventory.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- Instant Quote -->
            <div class="col">
                <div class="d-flex justify-content-center mt-5 pt-5 pb-5">
                    <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary px-5 py-3">Get In touch</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sheet-fab-other-offering check-out-our-other-offerings global-space-between bottom-end-padding">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center pb-5"> Check out our other offerings </h1>
            </div>
        </div>
        <div class="row row-cols-2 row-cols-md-5 justify-content-center">
            <!-- CNC Machining -->
            <div class="col p-4 py-md-0 pointer" (click)="cncmachining()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/CNC Machining.svg" class="card-img-top" alt="Truventor" loading="lazy">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">CNC<br>Machining</p>
                    </div>
                </div>
            </div>

            <!-- 3D Printing -->
            <div class="col p-4 py-md-0 pointer" (click)="threedPrinting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/3D Printing.svg" class="card-img-top" alt="Truventor" loading="lazy">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">3D<br>Printing</p>
                    </div>
                </div>
            </div>

            <!-- Vaccum Casting -->
            <div class="col p-4 py-md-0 pointer" (click)="vacuumCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Vacuum Casting.svg" class="card-img-top" alt="Truventor" loading="lazy">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Vacuum<br>Casting</p>
                    </div>
                </div>
            </div>

            <!-- Injection Molding -->
            <div class="col p-4 py-md-0 pointer" (click)="injectionMolding()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Injection Molding.svg" class="card-img-top" alt="Truventor" loading="lazy">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Injection<br>Molding</p>
                    </div>
                </div>
            </div>

            <!-- Metal Casting -->
            <div class="col p-4 py-md-0 pointer" (click)="metalCasting()">
                <div class="card border-0" data-aos="fade-up">
                    <img src="./assets/img/supplier/icons/Metal Casting.svg" class="card-img-top" alt="Truventor" loading="lazy">
                    <div class="card-body pt-3">
                        <p class="card-text text-center">Metal<br>Casting</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>