import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreloadingstrategyService } from '../shared/services/preloadingstrategy.service';
import { AbcblackPx223htComponent } from './abcblack-px223ht/abcblack-px223ht.component';
import { Abs9012Component } from './abs9012/abs9012.component';
import { AlumniumA5052Component } from './alumnium-a5052/alumnium-a5052.component';
import { AlumniumA7075Component } from './alumnium-a7075/alumnium-a7075.component';
import { BlogComponent } from './blog/blog.component';
import { CalculateComponent } from './calculate/calculate.component';
import { ClientTermsComponent } from './client-terms/client-terms.component';
import { CncMachiningMillingComponent } from './cnc-machining-milling/cnc-machining-milling.component';
import { CncMachiningTurningComponent } from './cnc-machining-turning/cnc-machining-turning.component';
import { CncmachineSubcapabilitiesComponent } from './cncmachine-subcapabilities/cncmachine-subcapabilities.component';
import { CustomerLandingComponent } from './customer-landing/customer-landing.component';
import { EnterprisePageComponent } from './enterprise-page/enterprise-page.component';
import { EventsComponent } from './events/events.component';
import { FaqComponent } from './faq/faq.component';
import { FinanceComponent } from './finance/finance.component';
import { ImprintComponent } from './imprint/imprint.component';
import { ManageComponent } from './manage/manage.component';
import { ManufacturingComponent } from './manufacturing/manufacturing.component';
import { MaterialAbsBasicComponent } from './material-abs-basic/material-abs-basic.component';
import { MaterialAbsLikeComponent } from './material-abs-like/material-abs-like.component';
import { MaterialAbsComponent } from './material-abs/material-abs.component';
import { MaterialAcrylicPmmaComponent } from './material-acrylic-pmma/material-acrylic-pmma.component';
import { MaterialAluminium3003Component } from './material-aluminium3003/material-aluminium3003.component';
import { MaterialAluminium6061T6Component } from './material-aluminium6061-t6/material-aluminium6061-t6.component';
import { MaterialAluminium6082He30Component } from './material-aluminium6082-he30/material-aluminium6082-he30.component';
import { MaterialBrassc360Component } from './material-brassc360/material-brassc360.component';
import { MaterialCopperc101Component } from './material-copperc101/material-copperc101.component';
import { MaterialCopperc106Component } from './material-copperc106/material-copperc106.component';
import { MaterialCopperc110Component } from './material-copperc110/material-copperc110.component';
import { MaterialCopperc260Component } from './material-copperc260/material-copperc260.component';
import { MaterialGalvanizedSteelComponent } from './material-galvanized-steel/material-galvanized-steel.component';
import { MaterialGfNylon12Component } from './material-gf-nylon12/material-gf-nylon12.component';
import { MaterialHighTemperatureComponent } from './material-high-temperature/material-high-temperature.component';
import { MaterialInconel625Component } from './material-inconel625/material-inconel625.component';
import { MaterialInconel718Component } from './material-inconel718/material-inconel718.component';
import { MaterialLowcarbonSteel1008Component } from './material-lowcarbon-steel1008/material-lowcarbon-steel1008.component';
import { MaterialMildsteelMsComponent } from './material-mildsteel-ms/material-mildsteel-ms.component';
import { MaterialNylon12Pa2200Component } from './material-nylon12-pa2200/material-nylon12-pa2200.component';
import { MaterialNylon6CastComponent } from './material-nylon6-cast/material-nylon6-cast.component';
import { MaterialPcLikeClearComponent } from './material-pc-like-clear/material-pc-like-clear.component';
import { MaterialPlaBasicComponent } from './material-pla-basic/material-pla-basic.component';
import { MaterialPolycarbonattePcComponent } from './material-polycarbonatte-pc/material-polycarbonatte-pc.component';
import { MaterialPomDelrinComponent } from './material-pom-delrin/material-pom-delrin.component';
import { MaterialPpLikeComponent } from './material-pp-like/material-pp-like.component';
import { MaterialRubberLikeTangoComponent } from './material-rubber-like-tango/material-rubber-like-tango.component';
import { MaterialStainlessSteelSs316Component } from './material-stainless-steel-ss316/material-stainless-steel-ss316.component';
import { MaterialStainlessSteel301Component } from './material-stainless-steel301/material-stainless-steel301.component';
import { MaterialStainlessSteel304Component } from './material-stainless-steel304/material-stainless-steel304.component';
import { MaterialStainlessSteel316Component } from './material-stainless-steel316/material-stainless-steel316.component';
import { MaterialTeflonPtfeComponent } from './material-teflon-ptfe/material-teflon-ptfe.component';
import { MaterialTitaniumG1Component } from './material-titanium-g1/material-titanium-g1.component';
import { MaterialTitaniumG2Component } from './material-titanium-g2/material-titanium-g2.component';
import { MaterialTitaniumG3Component } from './material-titanium-g3/material-titanium-g3.component';
import { MaterialTitaniumG4Component } from './material-titanium-g4/material-titanium-g4.component';
import { MaterialTitaniumG5Component } from './material-titanium-g5/material-titanium-g5.component';
import { MaterialToolsteeld2Component } from './material-toolsteeld2/material-toolsteeld2.component';
import { MaterialToolsteels7Component } from './material-toolsteels7/material-toolsteels7.component';
import { MaterialToolsteelwgradeComponent } from './material-toolsteelwgrade/material-toolsteelwgrade.component';
import { MaterialToosteelAgradeComponent } from './material-toosteel-agrade/material-toosteel-agrade.component';
import { MaterialToughResinComponent } from './material-tough-resin/material-tough-resin.component';
import { MaterialUltraDetailVeroComponent } from './material-ultra-detail-vero/material-ultra-detail-vero.component';
import { MediaComponent } from './media/media.component';
import { MetalcastingComponent } from './metalcasting/metalcasting.component';
import { MildSteel1018Component } from './mild-steel1018/mild-steel1018.component';
import { NylonVc3365Component } from './nylon-vc3365/nylon-vc3365.component';
import { Online3dprintingComponent } from './online3dprinting/online3dprinting.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PpMachiningComponent } from './pp-machining/pp-machining.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ProcurementComponent } from './procurement/procurement.component';
import { ProductComponent } from './product/product.component';
import { ProductsecurityComponent } from './productsecurity/productsecurity.component';
import { QualityassuranceComponent } from './qualityassurance/qualityassurance.component';
import { RubberVc3300Component } from './rubber-vc3300/rubber-vc3300.component';
import { SheetmetalBendingComponent } from './sheetmetal-bending/sheetmetal-bending.component';
import { SheetmetalLaserComponent } from './sheetmetal-laser/sheetmetal-laser.component';
import { SheetmetalPunchingComponent } from './sheetmetal-punching/sheetmetal-punching.component';
import { SheetmetalWeldingComponent } from './sheetmetal-welding/sheetmetal-welding.component';
import { SheetmetalfabricationComponent } from './sheetmetalfabrication/sheetmetalfabrication.component';
import { StarupprogramComponent } from './starupprogram/starupprogram.component';
import { SupplierLandingComponent } from './supplier-landing/supplier-landing.component';
import { SupplierPageNotFoundComponent } from './supplier-page-not-found/supplier-page-not-found.component';
import { SupplierTermsComponent } from './supplier-terms/supplier-terms.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ThreeDPrintingFffComponent } from './three-d-printing-fff/three-d-printing-fff.component';
import { ThreeDPrintingMicroSlaComponent } from './three-d-printing-micro-sla/three-d-printing-micro-sla.component';
import { ThreeDPrintingPolyjetComponent } from './three-d-printing-polyjet/three-d-printing-polyjet.component';
import { ThreeDPrintingSlaComponent } from './three-d-printing-sla/three-d-printing-sla.component';
import { ThreeDPrintingSlsComponent } from './three-d-printing-sls/three-d-printing-sls.component';
import { TruventorDesignComponent } from './truventor-design/truventor-design.component';
import { VaccumCastingComponent } from './vaccum-casting/vaccum-casting.component';
// import { AdowrdThreeDPrintingServiceComponent } from './adowrd-three-d-printing-service/adowrd-three-d-printing-service.component';
// import { AdwordFdm3dprinitingBangloreComponent } from './adword-fdm3dpriniting-banglore/adword-fdm3dpriniting-banglore.component';
// import { AdwordFdm3dprintingDelhiComponent } from './adword-fdm3dprinting-delhi/adword-fdm3dprinting-delhi.component';
// import { AdwordFdm3dprintingHyderabadComponent } from './adword-fdm3dprinting-hyderabad/adword-fdm3dprinting-hyderabad.component';
// import { AdwordFdm3dprintingIndiaComponent } from './adword-fdm3dprinting-india/adword-fdm3dprinting-india.component';
// import { AdwordFdm3dprintingMumbaiComponent } from './adword-fdm3dprinting-mumbai/adword-fdm3dprinting-mumbai.component';
// import { AdwordFdm3dprintingComponent } from './adword-fdm3dprinting/adword-fdm3dprinting.component';
// import { AdwordOnline3dprintingPuneComponent } from './adword-online3dprinting-pune/adword-online3dprinting-pune.component';
// import { AdwordThreeDPrintingCompanyInIndiaComponent } from './adword-three-d-printing-company-in-india/adword-three-d-printing-company-in-india.component';




const routes: Routes = [
  { path: '', component: CustomerLandingComponent },
  { path: 'about', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  { path: 'contact', loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule) },
  { path: 'articles', loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule) },
  { path: 'buyer-protection', loadChildren: () => import('./buyer-protection/buyer-protection.module').then(m => m.BuyerProtectionModule) },
  { path: 'case-study', loadChildren: () => import('./case-studies/case-studies.module').then(m => m.CaseStudiesModule) },
  { path: 'plastic-injection-moulding', loadChildren: () => import('./injectionmodule/injectionmodule.module').then(m => m.InjectionmoduleModule) },
  { path: 'materials', loadChildren: () => import('./material-list/material-list.module').then(m => m.MaterialListModule) },
  { path: 'cnc-machining', loadChildren: () => import('./cncmachining/cncmachining.module').then(m => m.CncmachiningModule) },
  { path: 'discover', loadChildren: () => import('./discover/discover.module').then(m => m.DiscoverModule) },
  { path: 'events', loadChildren: () => import('./events/events.module').then(m => m.EventsModule) },
  { path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
  { path: 'finance', loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule) },
  { path: 'manage', loadChildren: () => import('./manage/manage.module').then(m => m.ManageModule) },
  { path: 'cnc-machining/materials/abs', loadChildren: () => import('./material-abs/material-abs.module').then(m => m.MaterialAbsModule) },
  { path: '3d-printing/materials/abs-basic', loadChildren: () => import('./material-abs-basic/material-abs-basic.module').then(m => m.MaterialAbsBasicModule) },


  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: 'platform', component: ProductComponent },
  { path: 'manufacturing-partners', component: SupplierLandingComponent },
  { path: '3d-printing', component: Online3dprintingComponent },
  { path: 'sheet-metal-fabrication', component: SheetmetalfabricationComponent },
  { path: 'supplier-page-not-found', component: SupplierPageNotFoundComponent },
  { path: 'terms', component: TermsConditionsComponent },
  { path: 'client-terms', component: ClientTermsComponent },
  { path: 'supplier-terms', component: SupplierTermsComponent },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'vacuum-casting', component: VaccumCastingComponent },
  { path: 'metal-casting', component: MetalcastingComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'manufacturing-as-a-service', component: ManufacturingComponent },
  { path: 'procurement', component: ProcurementComponent },
  { path: '3d-printing/materials/gf-nylon-12-pa-3200', component: MaterialGfNylon12Component },
  { path: '3d-printing/materials/high-temperature', component: MaterialHighTemperatureComponent },
  { path: '3d-printing/materials/pc-like-flex', component: MaterialPcLikeClearComponent },
  { path: '3d-printing/materials/ultra-detail-vero', component: MaterialUltraDetailVeroComponent },
  { path: '3d-printing/materials/rubber-like-tango', component: MaterialRubberLikeTangoComponent },
  { path: '3d-printing/materials/pla-basic', component: MaterialPlaBasicComponent },
  { path: '3d-printing/materials/pp-like-flex', component: MaterialPpLikeComponent },
  { path: '3d-printing/materials/tough-resin', component: MaterialToughResinComponent },
  { path: '3d-printing/materials/nylon-12-pa-2200', component: MaterialNylon12Pa2200Component },
  { path: '3d-printing/materials/abs-like-evolve-128', component: MaterialAbsLikeComponent },
  { path: 'cnc-machining/materials/aluminium-6082', component: MaterialAluminium6082He30Component },
  { path: 'cnc-machining/materials/aluminium-6061-T6', component: MaterialAluminium6061T6Component },
  { path: 'cnc-machining/materials/mild-steel', component: MaterialMildsteelMsComponent },
  { path: 'cnc-machining/materials/stainless-steel-ss316', component: MaterialStainlessSteelSs316Component },
  { path: 'cnc-machining/materials/acrylic', component: MaterialAcrylicPmmaComponent },
  { path: 'cnc-machining/materials/cast-nylon-6', component: MaterialNylon6CastComponent },
  { path: 'cnc-machining/materials/pom', component: MaterialPomDelrinComponent },
  { path: 'cnc-machining/materials/teflon', component: MaterialTeflonPtfeComponent },
  { path: 'security', component: ProductsecurityComponent },
  { path: 'quality-assurance', component: QualityassuranceComponent },
  { path: 'sheet-metal/bending', component: SheetmetalBendingComponent },
  { path: 'sheet-metal/welding', component: SheetmetalWeldingComponent },
  { path: 'sheet-metal/punching', component: SheetmetalPunchingComponent },
  { path: 'sheet-metal/laser-cutting', component: SheetmetalLaserComponent },
  { path: 'cnc-machining/milling', component: CncMachiningMillingComponent },
  { path: 'cnc-machining/turning', component: CncMachiningTurningComponent },
  { path: 'cnc-machining/drilling', component: CncmachineSubcapabilitiesComponent },
  { path: '3d-printing/processes/fff', component: ThreeDPrintingFffComponent },
  { path: '3d-printing/processes/sls', component: ThreeDPrintingSlsComponent },
  { path: '3d-printing/processes/sla', component: ThreeDPrintingSlaComponent },
  { path: '3d-printing/processes/micro-sla', component: ThreeDPrintingMicroSlaComponent },
  { path: '3d-printing/processes/polyjet', component: ThreeDPrintingPolyjetComponent },
  { path: 'media', component: MediaComponent },
  { path: 'calculate', component: CalculateComponent },
  { path: 'startup-program', component: StarupprogramComponent },
  { path: 'truventorone', component: EnterprisePageComponent },
  { path: 'cnc-machining/materials/aluminium-5052', component: AlumniumA5052Component },
  { path: 'cnc-machining/materials/mild-steel-1018', component: MildSteel1018Component },
  { path: 'vacuum-casting/materials/nylonvc-3365', component: NylonVc3365Component },
  { path: 'vacuum-casting/materials/rubber-vc3300', component: RubberVc3300Component },
  { path: 'cnc-machining/materials/aluminium-7075', component: AlumniumA7075Component },
  { path: 'cnc-machining/materials/aluminium-3003', component: MaterialAluminium3003Component },
  { path: 'cnc-machining/materials/pp-machining', component: PpMachiningComponent },
  { path: '3d-printing/materials/abs-9012', component: Abs9012Component },
  { path: 'cnc-machining/materials/abcblack-px223ht', component: AbcblackPx223htComponent },
  { path: 'cnc-machining/materials/stainless-steel301', component: MaterialStainlessSteel301Component },
  { path: 'cnc-machining/materials/stainless-steel316', component: MaterialStainlessSteel316Component },
  { path: 'cnc-machining/materials/stainless-steel304', component: MaterialStainlessSteel304Component },
  { path: 'cnc-machining/materials/copper-c110', component: MaterialCopperc110Component },
  { path: 'cnc-machining/materials/copper-c106', component: MaterialCopperc106Component },
  { path: 'cnc-machining/materials/copper-c101', component: MaterialCopperc101Component },
  { path: 'cnc-machining/materials/copper-c260', component: MaterialCopperc260Component },
  { path: 'cnc-machining/materials/galvanized-steel', component: MaterialGalvanizedSteelComponent },
  { path: 'cnc-machining/materials/brass-c360', component: MaterialBrassc360Component },
  { path: 'cnc-machining/materials/titanium-grade1', component: MaterialTitaniumG1Component },
  { path: 'cnc-machining/materials/titanium-grade2', component: MaterialTitaniumG2Component },
  { path: 'cnc-machining/materials/titanium-grade3', component: MaterialTitaniumG3Component },
  { path: 'cnc-machining/materials/titanium-grade4', component: MaterialTitaniumG4Component },
  { path: 'cnc-machining/materials/titanium-grade5', component: MaterialTitaniumG5Component },
  { path: 'cnc-machining/materials/lowcarbon-steel1008', component: MaterialLowcarbonSteel1008Component },
  { path: 'cnc-machining/materials/toolsteel-d2', component: MaterialToolsteeld2Component },
  { path: 'cnc-machining/materials/toolsteel-s7', component: MaterialToolsteels7Component },
  { path: 'cnc-machining/materials/toolsteel-wgrade', component: MaterialToolsteelwgradeComponent },
  { path: 'cnc-machining/materials/toolsteel-agrade', component: MaterialToosteelAgradeComponent },
  { path: 'cnc-machining/materials/inconel-625', component: MaterialInconel625Component },
  { path: 'cnc-machining/materials/inconel-718', component: MaterialInconel718Component },
  { path: 'cnc-machining/materials/polycarbonate', component: MaterialPolycarbonattePcComponent },
  { path: 'design', component: TruventorDesignComponent },
  // { path: '3d-printing/materials/abs-basic', component: MaterialTemplateComponent },
  // { path: 'adword/online-3d-printing-pune', component: AdwordOnline3dprintingPuneComponent },
  // { path: 'adword/fdm-3d-printing', component: AdwordFdm3dprintingComponent },
  // { path: 'adword/fdm-3d-printing-india', component: AdwordFdm3dprintingIndiaComponent },
  // { path: 'adword/fdm-3d-printing-banglore', component: AdwordFdm3dprinitingBangloreComponent },
  // { path: 'adword/fdm-3d-printing-mumbai', component: AdwordFdm3dprintingMumbaiComponent },
  // { path: 'adword/fdm-3d-printing-delhi', component: AdwordFdm3dprintingDelhiComponent },
  // { path: 'adword/fdm-3d-printing-hyderabad', component: AdwordFdm3dprintingHyderabadComponent },
  // { path: 'adword/3d-printing-company-in-india', component: AdwordThreeDPrintingCompanyInIndiaComponent },
  // { path: 'adword/3d-printing-service', component: AdowrdThreeDPrintingServiceComponent },



];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadingstrategyService,
    // initialNavigation: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class MarketingPortalRoutingModule { }
