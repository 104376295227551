<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_template/Mask Group 196@2x.jpg" class="img-banner d-none d-md-block" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        ABS-Like
                    </h1>
                    <p class="text-primary">
                        3D Printing | SLS
                    </p>
                    <p class="mt-4">
                        ABS-like properties with dimensionally accurate parts and smooth surface finish
                    </p>
                </div>
            </div>
        </div>

        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Mask Group 196@2x.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/ABS-Like(SomosEvoLVe128).pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    ABS-Like is a photo-cured resin that simulates properties close to ABS material. The combination of mechanical properties and its visual similarity to an injection molded component make it perfect for building parts and prototypes for functional testing
                    applications.
                    <br><br> ABS-Like material is white in color. Since parts are fabricated using Stereolithography (SLA) technology, there is support structure generation during fabrication. Despite support structures, ABS-Like material comes with a
                    smooth surface finish, and little burs, if any, left during the support structure removal can be polished to give the part a superior surface finish.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        ABS-Like
                    </strong>
                </h6>
                <img src="./assets/img/material_template/Group 19492@2x.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                White
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-) 150µm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                600 x 600 x 450 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships In
                            </th>
                            <td>
                                1-2 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            High strength & durability
                        </p>
                        <p class="mb-2">
                            Great feature resolution and dimensional accuracy
                        </p>
                        <p class="mb-2">
                            Smooth surface finish
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Support structure generation
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Tough functional prototypes
                        </p>
                        <p class="mb-2">
                            Proof of concept prototypes
                        </p>
                        <p class="mb-2">
                            Master patterns for Vacuum casting
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <div class="row">
                    <div class="col-12 col-md-8">
                        <h1 class="mb-5">
                            3D Printing Design Guidelines For PLA (Basic)
                        </h1>
                    </div>
                </div>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_template/demmy_image.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1mm</span>. For decent strength: <span class="text-primary">1.5mm</span> & For rigid wall: <span class="text-primary">2.5mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_template/demmy_image.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Minimum detail size
                        </h6>
                        <p class="mt-2">
                            The minimum supported feature thickness: At least <span class="text-primary">0.8mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_template/demmy_image.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Embossing and Engraving:
                        </h6>
                        <p class="mt-2">
                            Letter strand width: at least <span class="text-primary">0.8mm</span> <br> Embossing height: at least <span class="text-primary">0.8mm</span> <br> Engraving (cavity) depth: at least
                            <span class="text-primary">0.8mm</span> <br>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_template/demmy_image.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Overhangs
                        </h6>
                        <p class="mt-2">
                            An overhanging feature of up to <span class="text-primary">2mm</span> length can be freely supported without any support structures.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_template/demmy_image.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Support Structures
                        </h6>
                        <p class="mt-2">
                            Since ABS-Like parts are fabricated in Stereolithography (SLA) technology, support structures are generated on part geometries wherever there are overhanging features or undercuts.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<app-cost-estimate></app-cost-estimate>