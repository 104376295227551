<!-- Looking for a cost estimate? -->
<section class="find-the-cost global-space-between bottom-end-padding">
    <div class="container-fluid">
        <div class="row justify-content-start align-items-center">
            <!-- Image -->
            <div class="col col-12 col-md-4 px-0 ">
                <img src="./assets/img/material_template/Group 19465.jpg" class="img-fluid w-100" alt="Truventor">
            </div>
            <!-- Heading -->
            <div class="col col-12 col-md-4 ml-md-3">
                <h1 class="mt-5">
                    Want to find the cost of your parts?
                </h1>

                <p class="my-5">
                    Highly flexible, on-demand manufacturing for the future.
                </p>

                <div class="row">
                    <div class="col-12 col-md-6 mt-3">
                        <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100"> Upload Design </a>
                    </div>
                    <div class="col-12 col-md-6 mt-3">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener" class="btn w-100"> Book A
                            Demo </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>