// Data to Sheet Metal Materials 
export const EVENTS_DATA = [
    { id: 7, name: 'INFRACONEX 2022', date: 'Feb 10-12', location: 'India', imgUrl: './assets/img/events/infraconex.png', url: 'https://www.infraconex.com/', tags: '' },
    { id: 4, name: 'TIMTOS x TMTS', date: 'February 21-26', location: 'Taiwan', imgUrl: './assets/img/events/timtos.png', url: 'https://www.timtos.com.tw/en/index.html', tags: '' },
    { id: 6, name: 'EIMA Agrimach India', date: 'March', location: 'India', imgUrl: './assets/img/events/EIMA-AgriMach-India.png', url: 'http://www.eimaagrimach.in/', tags: '' },
    { id: 5, name: 'MACH 2022', date: 'April 4-8', location: 'UK', imgUrl: './assets/img/events/MACH_2022.png', url: 'https://www.machexhibition.com/', tags: '' },
    { id: 3, name: 'IMTERMOLD', date: 'April 20-23', location: 'Japan', imgUrl: './assets/img/events/intermold.png', url: 'http://www.intermold.jp/english/', tags: '' },
    { id: 2, name: 'Hannover Messe', date: 'April 25-29', location: 'Germany', imgUrl: './assets/img/events/Hannover_Messe.png', url: 'https://www.hannovermesse.de/en/', tags: '' },
    { id: 1, name: 'Die Mould India', date: 'April 27-30', location: 'India', imgUrl: './assets/img/events/die_mould_india_logo_11065.jpg', url: 'https://www.diemouldindia.org/', tags: '' },
];

export const reloadTime = 500;


