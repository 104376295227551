import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-material-aluminium3003',
  templateUrl: './material-aluminium3003.component.html',
  styleUrls: ['./material-aluminium3003.component.scss']
})
export class MaterialAluminium3003Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
