<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Titanium Grade 4@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Titanium <br> Grade 4
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Titanium Grade 4 is the highest strength pure unalloyed Titanium

                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Titanium Grade 4.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Commercially pure Grade 4 is unalloyed titanium providing reasonably high mechanical strength (typical yield strength 559 MPa) combined with good weldability. Compared with CP Grade 2 and CP Grade 3, Grade 4 possesses lower toughness despite its higher
                    strength. Grade 4 titanium has a density of 4.54 g/cc - less than 60% that of steel. The material is used for a diverse range of industrial applications.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Titanium Grade 4
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Titanium Grade 4 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Hardness, Vickers
                            </th>
                            <td>
                                280
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Tensile Strength, Ultimate
                            </th>
                            <td>
                                550 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Tensile Strength, Yield
                            </th>
                            <td>
                                480 - 552 MPa
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Elongation at Break
                            </th>
                            <td>
                                15 %
                            </td>
                        </tr>


                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            High strength pure unalloyed Titanium
                        </p>
                        <p class="mb-2">
                            Excellent strength-to-density-ratio
                        </p>
                        <p class="mb-2">
                            Good Corrosion resistance
                        </p>
                        <p class="mb-2">
                            High oxygen and extra high strength
                        </p>
                        <hr>
                    </div>
                </div>


                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Aircraft structural honeycomb
                        </p>
                        <p class="mb-2">
                            Gaskets
                        </p>
                        <p class="mb-2">
                            Medical implants
                        </p>
                        <p class="mb-2">
                            Heat exchanger parts
                        </p>

                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>