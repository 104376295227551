<section class="main-session">
    <img src="assets/img/tv-one/Banner Image Tru One@2x.png" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="heading">
                    <h1>
                        Truventor.<span>One</span>
                    </h1>
                    <p class="mt-4">
                        A comprehensive software suite for <br> Buyer-Seller integration
                    </p>
                </div>
            </div> 
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>




<section class="global-space-">
    <div class="container">
        <div class="row">
            <div class="col-md-6 d-flex justify-content-center justify-content-md-start align-items-center">
                <div class="col-sm-12 col-md-12 p-0">
                    <h1 class="text-center text-md-left talk-to mt-5 mt-md-0">
                        Overview
                    </h1>
                    <p>
                        Welcome to the world of benefits & get access to exclusive privileges from the coolest
                        manufacturing platform. Now streamline your business's internal operations with Truventor.One, a
                        software suite that will help integrate the manufacturing and purchase
                        of an enterprise. It will further strengthen your relationship with the supply chain partner
                        leading to greater efficiency and savings. It’s time to give the enterprise way of life a try.
                    </p>
                    <div class="action-button mt-4 d-flex justify-content-center justify-content-md-start">
                        <a [routerLink]="['/contact']" class="btn mb-5 mb-md-0"> Contact Us </a>
                    </div>
                </div>
            </div>
            <!-- Image -->
            <div class="col-md-6 p-4 d-flex justify-content-center justify-content-md-left">
                <img src="./assets/img/tv-one/Group 26238@2x.png" loading="lazy" class="img-fluid" alt="contact image">
            </div>
            <!-- Text -->

        </div>
    </div>

</section>





<section class="tv-one">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <h1>Truventor.<span style="color: #ff6900;">One</span></h1>
                <p class="mb-4">Accelerate towards a digital future with Truventor.One</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <h4>For Buyer</h4>
                <div class="item" id="truventor-control">
                    <h5>Truventor.Design </h5>
                    <p>With AI-driven design optimization tools, companies can identify improvements, ensure the best
                        functionality, and maximum efficiency </p>
                    <a [routerLink]="['/design']">Know More</a>
                </div>
                <div class="item">
                    <h5>Truventor.Manufacture</h5>
                    <p>From prototype to production, get parts manufactured on-demand irrespective of the quantity,
                        budget, timeline and location. </p>
                    <a [routerLink]="['/manufacturing-as-a-service']" style="margin-bottom: 56px;display: block;">Know
                        More</a>
                </div>
                <div class="item">
                    <h5>Truventor.Manage</h5>
                    <p>Conduct bids, meet new suppliers and manage suppliers all under Truventor Manage, the all-in-one
                        supplier management platform. </p>
                    <a [routerLink]="['/manage']">Know More</a>
                </div>
            </div>
            <div class="col-md-4">
                <img src="assets/img/tv-one/Apps@2x.png" loading="lazy" class="img-fluid img-center" alt="Truventor">
            </div>
            <div class="col-md-4">
                <h4>For Supplier</h4>
                <div class="item">
                    <h5>Truventor.Calculate</h5>
                    <p>Truventor.Calculate is a simple cost estimator for making instant quotes with advance CAD
                        features </p>
                    <a [routerLink]="['/calculate']" style="margin-bottom: 56px;display: block;">Know More</a>
                </div>
                <div class="item">
                    <h5>Truventor.Procure </h5>
                    <p>With our network of 100+ raw material traders and efficient logistics management, you can save up
                        to 7% on raw material procurement. </p>
                    <a [routerLink]="['/procurement']">Know More</a>
                </div>
                <div class="item">
                    <h5>Truventor.Control </h5>
                    <p>Truventor.Control lets you have full control on asset utilisation, energy saving, machine and
                        manpower utilisation and other shop floor activities </p>
                    <a href="#">Coming Soon</a>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-12 text-center mt-4 h4-class">
                <h1>Apps contextually integrated for teams to work as one</h1>
            </div>
            <div class="col-md-12">
                <div class="img-holder">
                    <img src="assets/img/tv-one/Group 26243@2x.png" loading="lazy" class="img-fluid" alt="Truventor">
                </div>
            </div>
        </div>
    </div>
</section>


<!-- Schedule a meeting -->
<section class="amazing-quote global-space-between">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-12 col-md-8">
                <div>
                    <!-- Quote -->
                    <h1 class="text-center text-white">
                        Schedule a meeting or book a demo
                    </h1>
                    <!-- Action Button -->
                    <div class="action-button d-flex justify-content-center mt-4">
                        <a href="https://calendly.com/truventor/15min" target="_blank" rel="noopener"
                            class="btn btn-light px-md-5">
                            Request a call back
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="foundation global-space-between">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-9">
                <h1 class="text-center mb-5">
                    Key features of Truventor.<span style="color: #ff6900;">One</span>
                </h1>
            </div>
        </div>


        <div class="row">
            <div class="col-12 col-md-6 order-last order-md-first">
                <img src="assets/img/enterprise/Ultimately Transparency.svg" loading="lazy" alt="Truventor"
                    class="img-fluid">
            </div>
            <div class="col-12 col-md-6">
                <div class="col p-3" data-aos="fade-up">
                    <div class="circle"> 1 </div>
                    <hr>
                    <h5>
                        360° dashboard providing ultimate transparency
                    </h5>
                    <p>
                        This is a digital tool to provide 100% transparency at each stage of your product manufacturing
                        cycle time. From a dedicated dashboard you can track and control your production such as
                        material utilisation, part cycle time, part throughput rate, energy
                        consumption rate, visual part inspection and shipment, among others.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col p-3" data-aos="fade-up">
                    <div class="circle"> 2 </div>
                    <hr>
                    <h5>
                        Integrated applications
                    </h5>
                    <p>
                        Buyers and suppliers get access to all the applications under one integrated app and store
                        unlimited data. Truventor.One makes information flow easy, streamlines processes, reduces IT
                        complexity and enables effective collaboration between individuals
                        and departments.
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <img src="assets/img/tv-one/Group 26253@2x.png" loading="lazy" alt="Truventor" class="img-fluid">
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 order-last order-md-first">
                <img src="assets/img/tv-one/Group 26255@2x.png" loading="lazy" alt="Truventor" class="img-fluid">
            </div>
            <div class="col-12 col-md-6">
                <div class="col p-3" data-aos="fade-up">
                    <div class="circle"> 3 </div>
                    <hr>
                    <h5>
                        Super easy administration
                    </h5>
                    <p>
                        You don't have to generate separate invoices or create several accounts, with Truventor.One you
                        get access to all the features and apps under one integrated platform. It ensures seamless
                        functionality of administrative work making your tasks easier.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="col p-3" data-aos="fade-up">
                    <div class="circle"> 4 </div>
                    <hr>
                    <h5>
                        Buyer protection
                    </h5>
                    <p>
                        With our highly secure platform, we assure our enterprise customers of excellent quality, IP
                        protection, data security, and timely delivery, thereby minimising the risk and maximising the
                        outcome. Buyer protection provides enterprise grade security and
                        protection against delayed shipments, quality, or payment issues.
                    </p>
                    <a [routerLink]="['/buyer-protection']" class="btn">Know More</a>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <img src="assets/img/enterprise/Buyer Protection.svg" loading="lazy" alt="Truventor" class="img-fluid">
            </div>
        </div>
        <div class="row car-box">
            <div class="col-12 col-md-6 order-last order-md-first">
                <div class="slider-box text-center">

                    <owl-carousel-o [options]="customOptions">
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="card-box">
                                    <img src="assets/img/enterprise/Saurabh Deolwadkar@2x.jpg" loading="lazy"
                                        alt="Truventor" class="img-fluid">
                                    <h4 class="mt-3">Saurabh Deolwadkar</h4>
                                    <p>Supply Chain Manager</p>
                                </div>

                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="card-box">
                                    <img src="assets/img/enterprise/Kulwinder Sidhu@2x.jpg" loading="lazy"
                                        alt="Truventor" class="img-fluid">
                                    <h4 class="mt-3">Kulwinder Sidhu</h4>
                                    <p>Technical Program Manager</p>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="item">
                                <div class="card-box">
                                    <img src="assets/img/enterprise/Vedant Kulshrestha@2x.jpg" loading="lazy"
                                        alt="Truventor" class="img-fluid">
                                    <h4 class="mt-3">Vedant Kulshrestha</h4>
                                    <p>Customer Success Manager</p>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="col p-3" data-aos="fade-up">
                    <div class="circle"> 5 </div>
                    <hr>
                    <h5>
                        Dedicated support team
                    </h5>
                    <p>
                        Truventor.One customer get round-the-clock technical support from highly qualified engineers and
                        subject matter experts. Additionally, a dedicated Project Manager is assigned to our
                        Truventor.One customer. The Project Manager not only acts as the point
                        of contact but also ensures the high-quality result is delivered within the stipulated time
                    </p>
                </div>
            </div>

        </div>
    </div>
</section>

<!-- 
<section class="pricing compare-plans">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="content">
                    <h1>Pricing & Plans</h1>
                    <p>Different plans for different needs</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center">
                <span class="labels" id="l1">Buyer</span>
                <label class="switch text-center">
                    <input type="checkbox" (change)="toggleDiv('mydiv');">
                    <span class="slider round"></span>
                </label>
                <span class="labels" id="l2">Supplier</span>

            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-md-10">
                <div id="mydivon" style="display:block">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-6">
                            <div class="box mb-3 mb-lg-0">
                                <div class="titles">
                                    <h5 class="text-center">Truventor.Design, Truventor.Manufacture, Truventor.Manage
                                    </h5>
                                    <h2 class="text-center">
                                        Standard
                                    </h2>
                                    <p class="text-center">₹25k/mo for first 10 users</p>
                                </div>

                                <ul class="list-unstyled">
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> Per
                                        Organization / Year For 10 Users
                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> Available
                                        In Monthly / Annual Payment Options
                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> 6 months
                                        premium support
                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Red.svg" alt="Truventor" class="img-fluid"> Lifetime
                                        updates
                                    </li>
                                </ul>
                                <div class="text-center">
                                    <a [routerLink]="['/contact']" class="btn btn-primary w-50">
                                        Get In Touch >
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="box">
                                <div class="titles">
                                    <h5>Truventor.Design, Truventor.Manufacture, Truventor.Manage</h5>
                                    <h2 class="text-center">
                                        Custom
                                    </h2>
                                    <p>Per user</p>
                                </div>

                                <ul class="list-unstyled">
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> Best suited
                                        for Enterprise customers

                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid">Available in
                                        bi-annual/ annual payment options


                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid">Unlimited
                                        monthly technical support

                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> Enterprise
                                        grade security and governance

                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> API
                                        integration available
                                    </li>
                                </ul>
                                <div class="text-center">
                                    <a [routerLink]="['/contact']" class="btn btn-primary w-50">Get In Touch ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="mydivoff" style="display:none">
                    <div class="row justify-content-center">
                        <div class="col-12 col-md-6">
                            <div class="box mb-3 mb-lg-0">
                                <div class="titles">
                                    <h5 class="text-center">Truventor.Calculate, Truventor.Procure, Truventor.Control
                                    </h5>
                                    <h2 class="text-center">
                                        Standard
                                    </h2>
                                    <p class="text-center">₹25k/mo for first 10 users</p>
                                </div>

                                <ul class="list-unstyled">
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> Per
                                        Organization / Year For 10 Users
                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> Available
                                        In Monthly / Annual Payment Options
                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> 6 months
                                        premium support
                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Red.svg" alt="Truventor" class="img-fluid"> Lifetime
                                        updates
                                    </li>
                                </ul>
                                <div class="text-center">
                                    <a [routerLink]="['/contact']" class="btn btn-primary w-50">
                                        Get In Touch >
                                    </a>

                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="box">
                                <div class="titles">
                                    <h5>Truventor.Calculate, Truventor.Procure, Truventor.Control</h5>
                                    <h2 class="text-center">
                                        Custom
                                    </h2>
                                    <p>Per user</p>
                                </div>

                                <ul class="list-unstyled">
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> Best suited
                                        for Enterprise customers

                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid">Available in
                                        bi-annual/ annual payment options


                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid">Unlimited
                                        monthly technical support

                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> Enterprise
                                        grade security and governance

                                    </li>
                                    <li class="list-unstyled-item">
                                        <img src="assets/img/enterprise/Green.svg" alt="Truventor" class="img-fluid"> API
                                        integration available
                                    </li>
                                </ul>
                                <div class="text-center">
                                    <a [routerLink]="['/contact']" class="btn btn-primary w-50">Get In Touch ></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-6">
                        <div class="bottom-faq">
                            <div class="media">
                                <img src="assets/img/tv-one/Arrow.svg" class="mr-3" alt="Truventor">
                                <div class="media-body">
                                    <h5 class="mt-0">How Truventor.One support works?</h5>
                                    <p>The Truventor.One support is valid for a year. During this, you will have access
                                        to our
                                        cloud platform & hardware engineers. You can reach us from anywhere and anytime
                                        via
                                        email, chat, and call.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="bottom-faq">
                            <div class="media">
                                <img src="assets/img/tv-one/Arrow.svg" class="mr-3" alt="Truventor">
                                <div class="media-body">
                                    <h5 class="mt-0">How do we ensure good quality?</h5>
                                    <p>We are equipped with ISO quality certifications and practice stringent quality
                                        control.
                                        Before dispatching the parts, our quality control department ensures the part
                                        specifications are as per the requirement.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="bottom-faq">
                            <div class="media">
                                <img src="assets/img/tv-one/Arrow.svg" class="mr-3" alt="Truventor">
                                <div class="media-body">
                                    <h5 class="mt-0">How do you ensure data security?</h5>
                                    <p>At Truventor, we take data security very seriously. Our end-to-end file
                                        protection and
                                        stringent rules to access data will ensure your CAD files stay confidential. For
                                        more
                                        details, you can visit our ‘Security’ page.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="bottom-faq">
                            <div class="media">
                                <img src="assets/img/tv-one/Arrow.svg" class="mr-3" alt="Truventor">
                                <div class="media-body">
                                    <h5 class="mt-0">How do we resolve any disagreement?</h5>
                                    <p>If a buyer and a manufacturing partner disagree on a concern raised by the buyer,
                                        either
                                        of them can raise a complaint at our dispute resolution center. A manager will
                                        be
                                        assigned for a timely solution.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->


<section class="getting-started">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 text-center">
                <div class="content">
                    <h1>Getting started is easy</h1>
                    <!-- <p>Create custom landing pages with Omega that converts more visitors than any website.</p> -->
                    <a [routerLink]="['/contact']" rel="noopener"
                        class="btn btn-primary">
                        Know More
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>