<!-- Main -->
<section class="main-session">
    <img src="assets/img/sheet_metal_capabilities/bending/Mask Group 234.jpg" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-9">
                <div class="heading">
                    <h1>
                        Bending
                    </h1>
                    <p class="mt-4">
                        We undertake simple to challenging projects for bending job work
                    </p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Description -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="mb-4">
                    Bending
                </h1>
                <p class="mr-0 mr-md-5 mb-5">
                    Bending is a manufacturing process that alters the shape of sheet metal by the application of a force. The sheet metal can be bent at the desired angle and a sequence of several bending operations can result in a desired complex shape or part. Typically,
                    bending involves deformation about a single axis.
                </p>
            </div>
            <div class="col col-12 col-md-6">
                <h5 class="mb-4 font-weight-bold">
                    Compatible materials
                </h5>

                <!-- Materials -->
                <div>
                    <div class="row">
                        <!-- Image -->
                        <div class="col-5">
                            <img src="assets/img/placeholder/demmy_2.PNG" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-7">
                            <h5>
                                ABS
                            </h5>
                        </div>
                    </div>
                    <!--  -->
                    <div class="row">
                        <!-- Image -->
                        <div class="col-5">
                            <img src="assets/img/placeholder/demmy_2.PNG" class="img-fluid" alt="Truventor">
                        </div>
                        <!-- Text -->
                        <div class="col-7">
                            <h5>
                                PLA (Basic)
                            </h5>
                        </div>
                    </div>
                </div>

                <!-- Details -->
                <div class="content">
                    <div class="row py-2">
                        <div class="col"> <b>Max part size</b> </div>
                        <div class="col">
                            <p>550 x 550 x 550 mm</p>
                        </div>
                    </div>
                    <hr class="p-0 m-0">

                    <div class="row py-2">
                        <div class="col"> <b>Accuracy</b> </div>
                        <div class="col">
                            <p>(+/-) 500µm</p>
                        </div>
                    </div>
                    <hr class="p-0 m-0">

                    <div class="row py-2">
                        <div class="col"> <b>Max. Part Size</b> </div>
                        <div class="col">
                            <p>550 x 550 x 550 mm</p>
                        </div>
                    </div>
                    <hr class="p-0 m-0">
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Most Common Type -->
<section class="most-common-types-metal-bending global-space-between">
    <div class="container">
        <!-- Title -->
        <div class="row">
            <div class="col col-12 p-md-0">
                <h1 class="py-4 text-center">
                    Most common types of metal bending
                </h1>
            </div>
        </div>
        <!-- accordion -->
        <div class="row">

            <!-- Side Image -->
            <div class="col-sm-12 col-md-5 p-3 d-flex justify-content-center justify-content-md-start align-items-center align-items-md-start">
                <img src="./assets/img/placeholder/demmy_15.PNG" class="img-fluid w-100" alt="Truventor">
            </div>

            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-7">
                <!-- collapse -->
                <div class="w-100">
                    <!-- Accordion -->
                    <div class="mt-4">
                        <div class="col-sm-12 col-md-10 pl-3 pl-md-0">
                            <!-- Accordion -->
                            <div class="accordion" id="most-common-types-metal-bending">

                                <!-- Preparation of master pattern -->
                                <div>
                                    <div id="most-common-types-metal-bending-accordion-one">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#most-common-types-metal-bending-collapse-one" aria-expanded="true" aria-controls="most-common-types-metal-bending-collapse-one">
                                                    <h6 class=" text-left">
                                                        Air Bending
                                                    </h6>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="most-common-types-metal-bending-collapse-one" class="collapse show" aria-labelledby="most-common-types-metal-bending-accordion-one" data-parent="#most-common-types-metal-bending">
                                        <p>
                                            Air bending is a simple method of creating a bend without the need for lower die geometry. During this process, the work piece comes into contact with the outside edges of the die, as well as the punch tip.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <!-- Making of Mould -->
                                <div>
                                    <div id="most-common-types-metal-bending-accordion-two">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#most-common-types-metal-bending-collapse-two" aria-expanded="true" aria-controls="most-common-types-metal-bending-collapse-two">
                                                    <h6 class="text-left">
                                                        Bottom Bending
                                                    </h6>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="most-common-types-metal-bending-collapse-two" class="collapse" aria-labelledby="most-common-types-metal-bending-accordion-two" data-parent="#most-common-types-metal-bending">
                                        <p>
                                            Bottom bending also uses a punch and bottom v-shaped die but bends the metal by bringing the die and punch together. The difference between air bending and bottom bending is that there is a difference in radius between the two.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <!-- Pouring -->
                                <div>
                                    <div id="most-common-types-metal-bending-accordion-three">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#most-common-types-metal-bending-collapse-three" aria-expanded="true" aria-controls="most-common-types-metal-bending-collapse-three">
                                                    <h6 class="text-left">
                                                        Coining
                                                    </h6>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="most-common-types-metal-bending-collapse-three" class="collapse" aria-labelledby="most-common-types-metal-bending-accordion-three" data-parent="#most-common-types-metal-bending">
                                        <p>
                                            Coining is a bending process that’s characterized by greater force. Like air bending, it compresses sheet metal between a top die and a bottom die. There is more tonnage required on this type of bending than in air bending and bottom bending.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                                <!-- Extracting & Finishing -->
                                <div>
                                    <div id="most-common-types-metal-bending-accordion-four">
                                        <div class="row">
                                            <div class="col">
                                                <button class="w-100 p-0" type="button" data-toggle="collapse" data-target="#most-common-types-metal-bending-collapse-four" aria-expanded="true" aria-controls="most-common-types-metal-bending-collapse-four">
                                                    <h6 class="text-left">
                                                        Roll Bending
                                                    </h6>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="m-0 mb-2">
                                    <div id="most-common-types-metal-bending-collapse-four" class="collapse" aria-labelledby="most-common-types-metal-bending-accordion-four" data-parent="#most-common-types-metal-bending">
                                        <p>
                                            The arrangement of the rolls determines the exact bend of the work. Different curves are obtained by controlling the distance and angle between the rolls. A moveable roll provides the ability to control the curve.
                                        </p>
                                        <hr class="m-0 mb-2">
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Application Of Metal Bending -->
<section class="application-of-metal-bending global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1>
                        Application of metal bending
                    </h1>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="row row-cols-2 row-cols-md-2">
                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <p>
                            Automobile parts
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <p>
                            Aircraft panels
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <p>
                            Housings and cabinets
                        </p>
                    </div>

                    <div class="col col-6 p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <p>
                            White goods parts
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<!-- Upload a CAD file -->
<section class="adword-how-it-works global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <div class="row justify-content-center">
                    <div class="col-12 col-md-5">
                        <h3 class="text-center font-weight-bold">
                            Upload a CAD file for an instant 3D printing quote.
                        </h3>
                    </div>
                </div>

                <div class="d-none d-md-block">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-12 my-3">
                        <img src="assets/img/sheet_metal_capabilities/bending/Group 13311.jpg" class="fluid-img w-100" alt="video">
                    </div>
                </div>

                <div class="d-block d-md-none">
                    <div class="row justify-content-center mt-3">
                        <div class="col-10 col-md-3">
                            <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary btn-block"> Get In Touch </a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>