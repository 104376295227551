<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_template/Group 19441.jpg" class="img-banner d-none d-md-block" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        High Temp Resin
                    </h1>
                    <p class="text-primary">
                        3D Printing | Micro-SLA
                    </p>
                    <p class="mt-4">
                        Suitable for High temperature applications with minimal loading.
                    </p>
                </div>
            </div>
        </div>


        <!-- Mobile Image -->
        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19441.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/HighTemperature.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    High Temperature material, manufactured by Formlabs Ltd., is a resin based material compatible with Desktop Stereolithography (Micro-SLA) technology. One of the highlights of this material is its high Heat Deflection Temperature (HDT) of 238˚C @ 0.45
                    MPa, which is incomparable with any other 3D Printing material currently available in the market.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        (ABS)Acrylonitrile-Butadlene-Styrene
                    </strong>
                </h6>
                <img src="./assets/img/material_template/Group 19226.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                Light Yellow
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-)500µm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                145 x 145 x 175 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships in
                            </th>
                            <td>
                                7-8 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            High Heat deflection temperature
                        </p>
                        <p class="mb-2">
                            Good mechanical properties
                        </p>
                        <p class="mb-2">
                            Smooth surface finish
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Unsuitable for Snap-fits and live-hinges
                        </p>
                        <p class="mb-2">
                            Support structure generation
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            High temperature applications
                        </p>
                        <p class="mb-2">
                            Electronic enclosures
                        </p>
                        <p class="mb-2">
                            3D Printed Molds with desktop injection molding
                        </p>
                        <p class="mb-2">
                            Automotive, packaging industries
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <h1 class="mb-5">
                    3D Printing Design Guidelines For ABS
                </h1>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">


                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/high-temp/Wall Thickness@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1mm</span>. For decent strength: 1.5mm & For rigid wall: <span class="text-primary">2mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/high-temp/Minimum Detail Size@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Minimum detail size
                        </h6>
                        <p class="mt-2">
                            The minimum supported feature thickness: At least <span class="text-primary">0.8 mm</span> .
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/high-temp/Embossing And Engraving@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Embossing and Engraving:
                        </h6>
                        <p class="mt-2">
                            Letter strand width: at least <span class="text-primary">0.6mm</span> Embossing height: at least <span class="text-primary">0.6mm</span> Engraving (cavity) depth: at least <span class="text-primary">0.6mm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/high-temp/Overhangs@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Overhangs
                        </h6>
                        <p class="mt-2">
                            An overhanging feature of up to <span class="text-primary">2mm</span> length can be freely supported without any support structures.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/high-temp/Support Structures@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Support Structures
                        </h6>
                        <p class="mt-2">
                            Since High Temperature parts are fabricated in Desktop Stereolithography (Micro-SLA) technology, support structures are generated on part geometries wherever there are overhanging features or undercuts.
                        </p>
                    </div>

                </div>


            </div>
        </div>
    </div>
</section>

<!-- post_processing -->
<section class="post_processing global-space-between">
    <div class="container-fluid">
        <div class="row justify-content-end">
            <!-- Heading -->
            <div class="col col-12 col-md-5 pr-md-5">
                <h1>
                    POST- <br> PROCESSING
                </h1>
                <p>
                    Some of the post-processing options available for High Temperature parts are as follows:
                </p>
                <hr>
                <h6>
                    Sand Polishing
                </h6>
                <hr>
                <h6>
                    Painting (options)
                    <!-- Color boxes -->
                    <div class="color-options">
                        <div class="option red"></div>
                        <div class="option cyan"></div>
                        <div class="option yellow"></div>
                        <div class="option black"></div>
                    </div>
                </h6>
                <hr>
                <a [routerLink]="['/contact']" rel="noopener" class="btn my-4">
                    Get In Touch
                </a>
            </div>
            <!-- Image -->
            <div class="col col-12 col-md-6 px-0">
                <img src="./assets/img/material_template/Group 19464.jpg" class="img-fluid w-100" alt="Truventor">
            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>