<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/Stainless Steel -2@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Stainless <br> Steel 304
                    </h1>
                    <p class="text-primary">
                        Vacuum Casting
                    </p>
                    <p class="mt-4">
                        1018 mild steel offers a good balance of toughness, strength and ductility.
                    </p>
                </div>
            </div>
        </div>



        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Stainless Steel 304.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Type 304 stainless steel has good forming and welding properties as well as strong corrosion resistance. Type 304 is the most versatile and widely used stainless steel. Type 304 stainless steel is an austenitic grade that can be severely deep drawn. This
                    property has resulted in 304 being the dominant grade used in applications like sinks and saucepans. It is also known as "18/8" stainless steel because of its composition, which includes 18% chromium and 8% nickel.

                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Stainless Steel 304
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/Stainless Steel 304.png" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Density
                            </th>
                            <td>
                                8.03g/cm3
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Electrical resistivit
                            </th>
                            <td>
                                72 microhm-cm (20C)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Specific heat
                            </th>
                            <td>
                                500 J/kg °K (0-100°C)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Thermal conductivity
                            </th>
                            <td>
                                16.3 W/m-k (100°C)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Modulus of Elasticity
                            </th>
                            <td>
                                193 x 103 in tension
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Excellent corrosion and oxidisation resistance
                        </p>
                        <p class="mb-2">
                            Good machinability & drawability
                        </p>
                        <p class="mb-2">
                            Excellent forming and welding properties
                        </p>

                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Poor Corrosion Resistance
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Brewery, food, dairy and pharmaceutical production equipment
                        </p>
                        <p class="mb-2">
                            Springs, screws, nuts & bolts
                        </p>
                        <p class="mb-2">
                            Architectural panelling
                        </p>
                        <p class="mb-2">
                            Sanitary ware and troughs
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>