<div class="not-found-supplier">
    <img src="assets/img/404/Page Not Found Banner Image.jpg" class="img-banner d-none d-md-block" alt="Truventor">
    <img src="assets/img/404/Page Not Found Mobile Image.jpg" class="img-banner d-block d-md-none" alt="Truventor">
    <div class="container">
        <div class="row">
            <div class="col-sm-12 col-md-12">
                <div class="content">
                    <h1>Page not found</h1>
                    <p>
                        The Link You Clicked May Be Broken Or The Page May Have Been Removed.
                    </p>
                    <div class="col-sm-12 col-md-3 p-0">
                        <a href="#" class="btn btn-light w-100"> Go Home </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>