<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_list/aluminium-5052/PP(Machining)@2x.webp" class="img-banner" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        PP- <br> Machining
                    </h1>
                    <p class="text-primary">
                        CNC Machining
                    </p>
                    <p class="mt-4">
                        Polypropylene is a low cost, chemical resistant plastic with excellent aesthetic qualities.
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <!-- <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19442.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div> -->
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/PP Machining.pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>

    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Polypropylene (PP) is an economical material that offers a combination of outstanding physical, chemical, mechanical, thermal, and electrical properties not found in any other thermoplastic. It is sometimes referred to as the ‘steel of the plastic industry’
                    due to its adaptability.Polypropylene is available in both homopolymer and copolymer grades.


                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        PP-Machining
                    </strong>
                </h6>
                <img src="./assets/img/material_list/aluminium-5052/PP 2@2x.webp" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Agency/Method
                            </th>
                            <td>
                                ERM®
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Density
                            </th>
                            <td>
                                0.9 g/mL at 25 °C (lit.)
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Storage Temp
                            </th>
                            <td>
                                −20°C
                            </td>
                        </tr>
                        <tr>
                            <th>
                                SMILES string
                            </th>
                            <td>
                                CC=C
                            </td>
                        </tr>

                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Good to excellent chemical resistance
                        </p>
                        <p class="mb-2">
                            Semi rigid
                        </p>
                        <p class="mb-2">
                            Good fatigue resistance
                        </p>
                        <p class="mb-2">
                            High flammability
                        </p>
                        <p class="mb-2">
                            Excellent electrical resistance
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Poor Corrosion Resistance
                        </p>

                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Valves
                        </p>
                        <p class="mb-2">
                            Filter components
                        </p>
                        <p class="mb-2">
                            Bearings
                        </p>
                        <p class="mb-2">
                            Packaging
                        </p>
                        <p class="mb-2">
                            Chemical processing & storage applications
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>


<app-cost-estimate></app-cost-estimate>