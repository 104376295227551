<section class="contact-cover imprint-main-session">
    <!-- <img src="assets/img/contact/terms.png" class="img-banner" alt="Truventor"> -->

    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="content">
                    <h1>General Terms and Conditions</h1>
                    <p>All your questions answered at one place
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>



<section class="privacy">
    <div class="container">
        <div class="d-none d-md-block">
            <div class="row">
                <div class="col-md-4">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#home" role="tab"
                                aria-controls="home">Overview </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#profile" role="tab" aria-controls="profile">Acceptance of terms and conditions</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#messages" role="tab"
                                aria-controls="messages">Use</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#settings" role="tab"
                                aria-controls="settings">Intellectual property</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#automeans" role="tab"
                                aria-controls="automeans">Operation of the website</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#intellectual" role="tab"
                                aria-controls="intellectual">Modifications to the website</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#useoftvweb" role="tab"
                                aria-controls="useoftvweb">Advertising and linking to third party content</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#otherweb" role="tab"
                                aria-controls="otherweb">Warranties</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#changeprivacy" role="tab"
                                aria-controls="changeprivacy">Limitation of liability </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#contactus" role="tab"
                                aria-controls="contactus">Indemnity
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#lastone" role="tab"
                                aria-controls="lastone">General
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-md-2">
                    <div class="verticle-line text-center"></div>
                </div>
                <div class="col-md-6">
                    <div class="tab-content">
                        <div class="tab-pane active" id="home" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                1 Overview
                                1.1 Truventor AI and Robotics Pty Ltd ACN 622 249 969 (we, us, our, TV) operates an
                                online
                                intelligent cloud manufacturing platform with domain address www.truventor.ai (Website)
                                on
                                which clients (User, you, your) can access a range of manufacturing services from us and
                                our
                                registered suppliers (Supplier). These terms and conditions (Terms and Conditions)
                                constitute an agreement between us and you for the purpose of your use of the Website
                                and any
                                services offered by the Website from time to time.
                                1.2 If you are a Supplier, please access our Supplier Terms and Conditions at link.
                                1.3 The key features accessed through the Website include:
                                (a) Truventor Manufacture (TV Manufacture), an online manufacturing service through
                                which you can:
                                (i) Place manufacturing orders;
                                (ii) Upload 2D and 3D models and designs (Designs) for your manufacturing
                                projects; and
                                (iii) Have your Designs manufactured and delivered.
                                (b) Truventor Manage (TV Manage), an online marketplace through which you can access
                                and manage a private network of your own independent Suppliers, including access to
                                our network of Suppliers.
                                (c) Other Truventor services which may be available through the website
                                (Services)
                                1.4 These are the User terms and conditions and apply to access and use of the Website
                                only, and
                                do not apply to use of TV Manage or TV Manufacture. If you would like to access the
                                terms
                                and conditions that apply to your use of TV Manage and TV manufacture, you can view our
                                terms and conditions of use for TV Manage and TV Manufacture here: link.
                                1.5 We reserve the right to update, modify, replace, limit, and expand the Website and
                                the Services.
                                1.6 You should read these Terms and Conditions carefully prior to using the Website.
                            </p>
                        </div>
                        <div class="tab-pane" id="profile" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p>
                            <p>
                                2 Acceptance of Terms and Conditions
                                2.1 These Terms and Conditions record an agreement between you and us for the purpose of
                                you
                                browsing or using the Website.
                                2.2 By using the Website, you are accepting these Terms and Conditions and agree to be
                                bound by
                                them.
                                2.3 These Terms and Conditions apply continuously and anew each time you use the
                                Website. We
                                reserve the right to make changes to these Terms and Conditions from time to time
                                without
                                notice, and you should check the Terms and Conditions upon each use of the Website.
                            </p>
                        </div>
                        <div class="tab-pane" id="messages" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p> <br>
                            <p>
                                3 Use
                                3.1 Use of the Website does not require an account or registration, and any person or
                                entity that can
                                access that Website may use it.
                                3.2 Use of any of the Services may require registration in accordance with the relevant
                                terms and
                                conditions for those Services.
                                3.3 You agree to use the Website for its intended purpose. You may not use the Website
                                for any
                                illegal or unauthorised purpose, including the transmission of malware. You must not use
                                the
                                Website to violate any laws in your jurisdiction, including but not limited to copyright
                                laws.
                                3.4 You must not use the Website for any unconscionable behaviour or activity, including
                                but not
                                limited to:
                                (a) Engaging in abusive, derogatory, discriminatory, or vexatious behaviour;
                                (b) Engaging in any activity that disrupts or corrupts the Website or the networks that
                                host
                                the Website; or
                                (c) Attempting to hack, reverse-engineer, disable, circumvent, or otherwise gain access
                                to
                                any security, database, or other secure aspect of the Website.
                                3.5 You may link to the Website only:
                                (a) With our prior express permission;
                                (b) In a way that is fair, unbiased, legal and does not damage or exploit our
                                reputation; or
                                (c) In a way that does not suggest either express or implied any association or
                                endorsement
                                by us.
                                3.6 You must remove or procure the removal of any link to the Website immediately upon
                                receiving
                                notice from us to do so. We may give such notice at our absolute discretion without
                                justification.
                            </p>

                        </div>
                        <div class="tab-pane" id="settings" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p> <br>

                            <p>
                                4 Intellectual Property
                                4.1 Nothing in these Terms and Conditions or otherwise creates an assignment of any
                                rights in the
                                Website beyond the ability to use it for its intended use.
                                4.2 Nothing in these Terms and Conditions or otherwise creates the right for you to
                                sublicense the
                                Website.
                                4.3 By accepting these Terms and Conditions you undertake not to reverse engineer,
                                replicate, or
                                otherwise copy the Website or parts thereof. We reserve all our rights to seek
                                compensation,
                                damages, injunctions, or any other remedy available to us at law if any attempt to do
                                so, whether
                                successful or unsuccessful, is made by you, your business or its members, employees,
                                officers,
                                affiliates, customers, or anyone else.
                                4.4 All intellectual property and ownership rights subsisting in the Website (including
                                the user
                                interface and the software comprising the Website), the data derived from your use of
                                the
                                Website, all content including but not limited to trademarks, names, layout, colour
                                schemes,
                                URL’s, photos, drawings, images, catalogues, audio, video, and any other documentation,
                                information or materials that are supplied by the Website to you (Website Content),
                                remain
                                3
                                our or our third party licensors exclusive property. For the avoidance of doubt, nothing
                                in these
                                Terms and Conditions assigns any ownership rights to you. Nothing in these Terms and
                                Conditions gives you the right to use any of the Website Content for any reason
                                including
                                without limitation utilizing, copying, distributing, re-publishing or copying the
                                Website
                                Content.
                            </p>
                        </div>
                        <div class="tab-pane" id="automeans" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p> <br>

                            <p>
                                5 Operation of the Website
                                5.1 As a user of the Website you warrant that, if applicable, you will comply fully with
                                all of our
                                operating instructions. Any failure to comply fully with operating instructions may
                                result in the
                                sub-par or faulty performance of the Website.
                                5.2 We accept no liability, and you release us from any such liability, ass
                            </p>
                        </div>
                        <div class="tab-pane" id="intellectual" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p> <br>
                            <p>
                                6 Modifications to the Website
                                6.1 We reserve the right to modify or discontinue, temporarily or permanently the
                                Website and its
                                products and services with or without notice.
                                6.2 We accept no liability if any such modification or discontinuance causes any loss or
                                damage to
                                you or causes any disruptions to your use of the Website
                            </p>
                        </div>
                        <div class="tab-pane" id="useoftvweb" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p> <br>
                            <p>
                                7 Advertising and Linking to Third Party Content
                                7.1 We may display advertisements by third party sponsors on the Website (Ads). In doing
                                so, we
                                do not warrant the functionality of the business, product, or service being advertised
                                in the Ad.
                                We do not hold ourselves out as partners, affiliates, guarantors, or has having any
                                other type of
                                legal or professional relationship with the third party sponsors.
                                7.2 Where Ads link to third party websites, we do not warrant the safety, functionality,
                                or suitability
                                of these websites. We are in no way liable for any loss, damage, risk or liability
                                incurred from
                                you visiting any third party websites accessible through Ads.
                                7.3 Advertised third party websites may be subject to their own terms and conditions. We
                                do not
                                warrant the validity or suitability of these third party terms and conditions.
                                7.4 The Website may contain links to third-party websites not under our control or
                                operation. We
                                may provide any such links only as a convenience. We do not endorse and we are not
                                responsible for nor have any control over the contents of any linked site or any link
                                contained
                                in a linked site. Linked sites may be subject to their own terms and conditions. We do
                                not verify
                                or warrant the legality or suitability of those terms and conditions, however encourage
                                you to
                                read them before accessing or using the linked site. We accept no liability for any loss
                                or
                                damage suffered as a result of you accessing a linked site.
                            </p>
                        </div>
                        <div class="tab-pane" id="otherweb" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p> <br>

                            <p>
                                8 Warranties
                                8.1 By using the Website, you represent and warrant to use that we will not be liable
                                for any direct
                                or indirect expense, damage or injury (including indirect loss of revenue, profits,
                                goodwill or
                                business opportunity) howsoever caused, including arising directly or indirectly from or
                                in
                                connection with your use of the Website.
                                8.2 Subject to any of the rights you may have under consumer protection laws, we exclude
                                to the
                                fullest extent possible all implied terms and warranties, whether statutory
                            </p>
                        </div>
                        <div class="tab-pane" id="changeprivacy" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p> <br>
                            <p>
                                9 Limitation of liability
                                4
                                9.1 The Website is made available to you on an “as is” basis. We make no warranties,
                                representations or conditions of any kind, express, statutory or implied as to:
                                (a) The operation and functionality of the Website;
                                (b) the accuracy, integrity, completeness, quality, legality, usefulness, safety and IP
                                rights
                                of any of the Website content; and/or
                                (c) the products and services associated with the Website or its content. We further
                                disclaim all warranties, express, statutory or implied, including, but not limited to,
                                implied warranties of merchantability, merchantable quality, durability, fitness for a
                                particular purpose and/or non-infringement. No advice or information, whether oral or
                                written, that you obtain from us shall create any warranty, representation or condition
                                not expressly stated herein.
                                9.2 We disclaim all liability for any:
                                (a) indirect, special, incidental, punitive, exemplary, reliance or consequential
                                damages;
                                (b) loss of profits;
                                (c) business interruption;
                                (d) network interruptions;
                                (e) loss or damage to reputation of us or any third party; or
                                (f) loss of information or data. We further disclaim all liability for any loss or
                                damage
                                arising out of your use of the Website.
                                9.3 Your use of the Website is at your own discretion and risk, and you will be solely
                                responsible
                                for any resulting loss or damage, including but not limited to, any loss of data or
                                damage to
                                your computer(s) or networks from viruses that may be downloaded to your computer or
                                mobile
                                devicesin the course of using the Website. Some jurisdictions do not allow the exclusion
                                of
                                certain warranties, the limitation or exclusion of implied warranties, or limitations on
                                how long
                                an implied warranty may last, so the above limitations may not apply to you. If you
                                reside in
                                such a jurisdiction, the above limitations shall apply to you to the fullest extent
                                permitted under
                                applicable law.
                            </p>
                        </div>
                        <div class="tab-pane" id="contactus" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p> <br>
                            <p>
                                10 Indemnity
                                10.1 You agree to defend, indemnify and hold harmless us, our members, managers,
                                subsidiaries,
                                affiliates, any related companies, suppliers, licensors and partners, and the officers,
                                directors,
                                employees, agents and representatives of each of them, including costs, liabilities and
                                legal fees,
                                from any claim or demand made by any third party due to or arising out of:
                                (a) Your access to or use of the Website;
                                (b) Your violation of these Terms and Conditions;
                                (c) Any infringement by you of any intellectual property or other right of any person or
                                entity. We reserve the right, at your expense, to assume the exclusive defence and
                                control of any matter for which you are required to indemnify us and you agree to
                                cooperate with our defence of these claims. You agree not to settle any matter without
                                our prior written consent. We will use reasonable efforts to notify you of any such
                                claim, action or proceeding upon becoming aware of it.
                            </p>
                        </div>
                        <div class="tab-pane" id="lastone" role="tabpanel">
                            <p>Last modified on : 16-07-2021</p> <br>
                            <p>11 General
                                5
                                11.1 To the extent permitted by law, we reserve the right to create, collect and / or
                                store any metadata
                                resulting from your use of the Website. To the extent permitted by law, we may use
                                metadata
                                for a variety of functions including (but not limited to) conducting investigations,
                                helping us
                                manage and administer the Website, for purposes of search engine optimisation and to
                                drive
                                traffic to the Website. To the extent permitted by law, we reserve the right to share
                                your
                                information (including metadata) with government agencies and authorities to the extent
                                this is
                                required or requested of us.
                                11.2 You understand that we may use third party vendors and hosting partners to provide
                                the
                                necessary hardware, software, networking, storage, and related technology required to
                                run the
                                Website.
                                11.3 You must not modify, adapt or hack the Website or modify another website or
                                software so as
                                to falsely imply that it is associated with the Website or us.
                                11.4 You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of
                                the Website,
                                use of the Website, or access to the Website without our prior written express
                                permission. We
                                claim copyright and all other intellectual property rights over the Website and its
                                other products
                                and services and reserves all of our rights.
                                11.5 Verbal, physical, written or other abuse (including threats of abuse or
                                retribution) of any of our
                                employees, members, representatives, or officers may result in you being banned from the
                                Website.
                                11.6 We do not warrant that:
                                (a) The Website will meet your specific requirements;
                                (b) The Website will be uninterrupted, timely, secure, or error-free;
                                (c) The content that may be obtained from the use of the Website will be accurate or
                                reliable;
                                (d) Any errors in the Website will be corrected.
                                11.7 You expressly understand, agree and warrant that we shall not be liable for any
                                direct, indirect,
                                incidental, special, consequential or exemplary damages, including but not limited to,
                                damages
                                for loss of profits, goodwill, use, data or other intangible losses (even if we have
                                been advised
                                of the possibility of such damages), resulting from:
                                (a) the use or the inability to use the Website;
                                (b) unauthorised access to or alteration of your transmissions or data;
                                (c) or any other matter relating to the Website.
                                11.8 Our failure to exercise or enforce any right or provision of these Terms and
                                Conditions shall
                                not constitute a waiver of such right or provision. The Terms and Conditions constitutes
                                the
                                entire agreement between you and us and governs your use of the Website, superseding any
                                prior agreements between you and us.
                                11.9 Any part of these Terms and Conditions that is invalid, prohibited or not
                                enforceable will not
                                void the balance of the Terms and Conditions. All other parts will be enforced without
                                regard
                                to the invalid provision and continue to be valid in accordance with their terms.
                                11.10 We make no representations or warranties that the content of the Website complies
                                with the
                                laws (including intellectual property laws) of your jurisdiction.
                                11.11 Any queries about the Terms and Conditions should be send to us by email at
                                6
                                [care@truventor.ai].
                                11.12 These Terms and Conditions are governed by the laws of the State of Victoria,
                                Australia, and
                                you agree to be bound by the exclusive jurisdiction of the courts of Victoria. </p>
                        </div>
                    </div>


                </div>
            </div>
        </div>

        <div class="d-block d-md-none">
            <div class="row">
                <div class="col">

                    <div class="mb-5">
                        <h4>
                            Information We Collect
                        </h4>
                        <hr>
                        <p>
                            The following are terms and conditions of a legal agreement between you and Truventor AI &
                            Robotics PVT LTD and its affiliates (collectively, “Truventor” or “our service partners”).
                            These terms and conditions (collectively with Truventor’s “Privay Policy” and the “Terms and
                            Conditions”) govern your use of www.truventor.ai (the “Truventor Website” or “Website”), and
                            the services, features, and content we offer (together with the Website and the
                            Applications, “Truventor Services” or “Services”).
                            By using the Services, you acknowledge you have read, understood and agree to be bound by
                            these Terms and Conditions and to comply with all applicable laws and regulations. If you do
                            not agree with these Terms and Conditions, you should not use the Website, Applications, or
                            Services.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Truventor Services
                        </h4>
                        <hr>
                        <p>
                            The Truventor Services are made up of different services provided through the Website. One
                            of our Services enables you to upload a 3D file (a “product”) to our Website and view it in
                            3D.
                            <br>
                            If you want to manufacture your 3D Model, you can place an order with us and we will
                            manufacture it for you at your direction. At a charge of a service price and price for the
                            substance used for the 3D Model, we manufacture the 3D Model for you in available materials
                            and ship it to you.
                            <br>
                            Further, Truventor offers other services and community features through the Website
                            including a forum, a blog, tutorials etc. where you can get in touch with other users or
                            share your experiences
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Account Terms
                        </h4>
                        <hr>
                        <p>
                            You represent and warrant that you are at least 10 years of age to use this Service. If you
                            are under 18 years of age, you may use Truventor’s website only with involvement of a parent
                            or guardian. We may, in our sole discretion, refuse to offer the to any person or entity and
                            change its eligibility criteria at any time. You are solely responsible for ensuring that
                            these Terms and Conditions are in compliance with all laws, rules and regulations applicable
                            to you and the right to access the Services is revoked where these Terms and Conditions or
                            use of the Services is prohibited or to the extent offering, sale or provision of the
                            Services conflicts with any applicable law, rule or regulation. Further, the Services are
                            offered only for your use, and not for the use or benefit of any third party.
                            To use some Services, you must register for an account on the Services (an “Account”). You
                            must provide current, accurate identification, contact, and other information that may be
                            required as part of
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Truventor Services
                        </h4>
                        <hr>
                        <p>
                            The Truventor Services are made up of different services provided through the Website. One
                            of our Services enables you to upload a 3D file (a “product”) to our Website and view it in
                            3D.
                            If you want to manufacture your 3D Model, you can place an order with us and we will
                            manufacture it for you at your direction. At a charge of a service price and price for the
                            substance used for the 3D Model, we manufacture the 3D Model for you in available materials
                            and ship it to you.
                            <br>
                            Further, Truventor offers other services and community features through the Website
                            including a forum, a blog, tutorials etc. where you can get in touch with other users or
                            share your experiences.the Account registration process and/or continued use of the Service,
                            and you must keep your Account information updated. You shall not:
                            <br>

                            (i) select or use as a username a name of another person with the intent to impersonate that
                            person;
                            <br>
                            (ii) use as a username a name subject to any rights of a person other than you without
                            appropriate authorization; or
                            <br>
                            (iii) use, as a username, a name that is otherwise offensive, vulgar or obscene. You are
                            responsible for maintaining the confidentiality of your Service password and Account, and
                            are solely responsible for all activities that occur on your Account.
                            You must notify us immediately of any change in your eligibility to use the Services, breach
                            of security or unauthorized use of your Account. You should never publish, distribute or
                            post login information for your Account. We will be liable for any loss or damage from your
                            failure to maintain the security of your Account and password. We reserve the right to
                            refuse service to or terminate service of anyone at any time without notice for any reason.
                            You shall have the ability to delete your Account, either directly or through a request made
                            to us at care@truventor.ai
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Ordering
                        </h4>
                        <hr>
                        <p>
                            (a) Placing an order. To order a manufacturing of 3D CAD data through our website, we
                            request you to upload the design online. Truventor owns no obligation to check the source of
                            the file uploaded on website and we will not be responsible to further check the
                            authenticity of files. We presume the file uploaded by you is proprietary of you or you own
                            rights to make a copy of it for personal / commercial use. Once the files are uploaded on
                            our secured server, it further hits a third party secured server for repairing, data
                            extraction and 3D printability check. The information helps in calculating instant timelines
                            and costing of parts. Then you can select the material you want the product to be made of.
                            As the next step, we ask you to provide us with information we need for the delivery of the
                            product. Finally you will be prompted for information required to initiate the payment or
                            pay later. Depending on the payment method you select you may be redirected to a third party
                            payment provider website. Please be informed that the language to be used during the whole
                            ordering process is English.
                            <br>
                            <br>
                            (b) Unacceptable orders, cancellation of orders by us. If a product is available through our
                            Website, the default assumption is that it can be produced. There are, however, technical or
                            other reasons why Truventor may not accept or may cancel your order during the entire
                            ordering process, even after our acceptance of your order. Such reasons can be, for example,
                            but not limited to, the unfeasibility of the model, an infringing feature of your product,
                            unavailability of the manufacturing partner or the destination of the delivery you indicated
                            in your order. In case we cancel your partial or entire order, we will contact you via email
                            and, at Truventor’ sole and exclusive discretion, we may issue you store credit or a full
                            refund for the cancelled item(s).
                            <br>
                            <br>
                            (c) Prices. The prices displayed on the Website are only indications and are valid only for
                            the moment that they are displayed. Truventor reserves the right to change its prices on the
                            website at any time without prior notice to you. Such change however will have no effect on
                            orders completed before the posting of a new price on the website. We do our best to prevent
                            errors in the pricing. However, should an error occur, we are not bound by our offer and may
                            cancel the order. In the event of an error, any monies paid related to that order will be
                            refunded to you.
                            <br>
                            <br>
                            (d) Payment and price. The amount indicated on the ordering page includes the fee for the
                            service (such as printing, finishing and packing the model), the price for the materials
                            used to produce the product, and, if applicable, the markup charged by the designer. The
                            price quoted during the payment checkout includes shipping costs, sales tax (as applicable)
                            but excludes any import duty or taxes (if applicable). You can initiate your payment for
                            your order via credit card, debit card, or bank transfer. In case you choose to pay by bank
                            transfer you will receive the payment instructions via email from Truventor after
                            confirmation of your order.
                            <br>
                            <br>
                            For orders paid for via credit card, Truventor will begin production after authorizing and
                            holding payment. For orders paid for via bank transfer, Truventor will begin production
                            after the funds arrive in the Truventor bank account.
                            <br>
                            <br>
                            Truventor WILL NOT BE RESPONSIBLE FOR ANY PAYMENT FAILURE HOSTED BY THIRD PARTY PAYMENT
                            GATEWAYS.
                            <br>
                            <br>
                            (e) Acceptance. After accepting your order we will send you an order confirmation by e-mail
                            address associated with your Truventor Account. The order confirmation will be effective as
                            of the date on which the order confirmation is sent to you. If we cannot accept your order
                            we will contact you by email. You should verify the details in the confirmation email. If
                            there are errors you should contact us as soon as possible by sending an email to customer
                            service.
                            <br>
                            <br>
                            (g) Shipping. Your order will be delivered to your shipping address. We will use our
                            commercially reasonable efforts to ship the product to the shipping address within the
                            delivery time indicated in our confirmation email; nevertheless, depending on your shipping
                            address it may take longer time. Any shipping and delivery dates given by us are only best
                            estimates and we are not liable for any damage or cost caused by delay in shipping or
                            delivery. We take every to ensure your product is well packed before it is dispatched. We
                            take extreme measures to ensure that your order arrives in pristine condition. However due
                            to the delicate nature of these products, in rare circumstances items can arrive damaged. We
                            are unable to take responsibility for any loss or damages of products in transit. But we
                            will make contact with the relevant courier to track your package or initiate a claim for
                            lost products. Title and risk of loss is transferred to you upon transfer of the goods from
                            Truventor to the shipment carrier (FOB Shipping Point, EX Works).
                            <br>
                            <br>
                            (h) Return and Cancellation of order. Due to the personalized feature of our Services (i.e.
                            the product is supplied by us based on your specifications), you cannot cancel your order
                            after it goes into production. Additionally, we are not able to refund purchases of a
                            "discount coupon" or "any other discounted product" at any point.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Warranty & Disclaimer
                        </h4>
                        <hr>
                        <p>
                            Truventor warrants only that the model manufactured by us will substantially meet the
                            features of the indicated products within the limitations of the 3D printing technology.
                            Truventor recommends but does not give any warranty about the products themselves and does
                            not guarantee that the product will be fit for any particular purposes. Please inspect the
                            product upon receipt immediately. If the product is faulty when you received it and you
                            believe you are entitled to a replacement in accordance with the warranty above, please
                            contact us within 1 day after receipt of the product. Such warranty claim can be made only
                            within 1 day after receipt of the model by you. Do not return the product unless we require
                            you to do so. We will confirm whether you are entitled to a replacement or refund.
                            Transportation costs in respect of a faulty product will be borne by us.
                            We have no special relationship with or fiduciary duty to you. You acknowledge that we have
                            no duty to take any action regarding:
                            <br>
                            (a) which users gain access to the Services
                            <br>
                            (b) what content you access via the Services or
                            <br>
                            (c) how you may interpret or use the content.
                            <br>
                            THE SERVICES AND CONTENT ARE PROVIDED “AS IS”, “AS AVAILABLE” AND WITHOUT WARRANTY OF ANY
                            KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
                            NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES
                            IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY
                            DISCLAIMED. WE, AND OUR DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS, PARTNERS AND CONTENT
                            PROVIDERS DO NOT WARRANT THAT:
                            <br>
                            (I) THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION
                            <br>
                            (II) ANY DEFECTS OR ERRORS WILL BE CORRECTED
                            <br>
                            (III) ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICES IS FREE OF VIRUSES OR
                            OTHER HARMFUL COMPONENTS OR
                            <br>
                            (IV) THE RESULTS OF USING THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS. YOUR USE
                            OF THE SERVICES IS SOLELY AT YOUR OWN RISK. SOME STATES DO NOT ALLOW LIMITATIONS ON IMPLIED
                            WARRANTIES, SO THE FOREGOING LIMITATIONS MAY NOT APPLY TO YOU.
                            <br>
                            PLEASE NOTE THAT, UNLESS OTHERWISE STATED IN THE DESCRIPTION FOR A SPECIFIC METERIAL, THE
                            MATERIALS WE USE FOR MANUFACTURING THE PRODUCT MAKE THE PRODUCT SUITABLE ONLY FOR DECORATIVE
                            PURPOSES AND THEY ARE NOT SUITED FOR ANY OTHER PURPOSE. THE PRODUCTS ARE NOT SUITED TO BE
                            USED AS TOYS, OR TO BE GIVEN TO CHILDREN. THE PRODUCTS SHOULD NOT COME IN CONTACT WITH
                            ELECTRICITY OR FOOD OR LIQUIDS AND SHOULD BE KEPT AWAY FROM HEAT UNLESS MENTIONED OTHERWISE.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Use of the Truventor Website
                        </h4>
                        <hr>
                        <p>
                            Copyright and all other proprietary rights in the content of the Website and the Services
                            (including but not limited to software, audio, video, text and photographs and excluding all
                            User Generated Content) rests with Truventor or its licensors. All rights in the content not
                            expressly granted herein are reserved. the content published on this Website are not allowed
                            to be reproduced or distributed in unmodified form for personal, non-commercial use, unless
                            provided that it is attributed to Truventor. Any other use of the content, including without
                            limitation distribution, reproduction, modification, display or transmission without the
                            prior written consent of Truventor is unlicensed by Truventor. You also agree that you will
                            not use any robot, spider, other automated device, or manual process to monitor or copy any
                            content from the Service. Our rights include rights to (i) the Service developed and
                            provided by us; and (ii) all software associated with the Service. All copyright and other
                            proprietary notices shall be retained on all reproductions.
                            <br>
                            <br>
                            This Website may provide links to other websites that are not under the control of
                            Truventor. Truventor shall not be responsible in any way for the content of such other
                            websites. Truventor provides such links only as a convenience to you, and the inclusion of
                            any link to any such websites does not imply endorsement by Truventor of the content of such
                            websites.
                            <br>
                            <br>
                            Since the Truventor Service is a web based service, it might be subject to temporary
                            downtime. From time to time we also update our Website, which will result in the Website not
                            being available for a certain period of time. We will endeavor to ensure that the Website
                            operates reliably and consistently, but we do not warrant that the Website will operate
                            uninterrupted without any faults. We do not guarantee that any content will be made
                            available on the Website or through the Services. We reserve the right to, but do not have
                            any obligation to, (i) remove, edit or modify any content in our sole discretion, at any
                            time, without notice to you and for any reason (including, but not limited to, upon receipt
                            of claims or allegations from third parties or authorities relating to such content or if we
                            are concerned that you may have violated these Terms and Conditions), or for no reason at
                            all and (ii) to remove or block any from the Services.
                            <br>
                            <br>
                            We are not responsible for any losses suffered by you as a result of any interruption of the
                            Website, suspension of your access to the Website.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            User Generated Content
                        </h4>
                        <hr>
                        <p>
                            The term “User Generated Content” when used in this Terms and Conditions shall mean any
                            content uploaded to the Website, the Application or the Services by you, including but not
                            limited to all uploaded 3D Models, pictures of 3D Models and comments on the Website. All
                            User Generated Content should not be or contain abusive, harassing, threatening, defamatory,
                            obscene, fraudulent, deceptive, misleading, offensive, pornographic, illegal or unlawful
                            information or be likely to infringe or infringing on any intellectual property rights.
                            Intellectual property rights means copyright, patent, registered design, design right,
                            trademark, trade secret or any other proprietary or industrial right.
                            <br>
                            Truventor has no obligation to review the User Generated Content and can in no way be held
                            responsible for the content of the User Generated Content. Opinions expressed in the User
                            Generated Content are not necessarily the opinion of Truventor.
                            <br>
                            Except for your 3D Models, which are referenced below, by submitting User Generated Content
                            through the Services or Website, you hereby do and shall grant us a worldwide,
                            non-exclusive, royalty-free, fully paid, sublicensable and transferable license to use,
                            edit, modify, reproduce, distribute, prepare derivative works of, display, perform, and
                            otherwise fully exploit the User Generated Content in connection with the Website, the
                            Applications, the Services and our (and our successors’ and assigns’) businesses, including
                            without limitation for promoting and redistributing part or all of the Website, the
                            Applications or the Services (and derivative works thereof) in any media formats and through
                            any media channels (including, without limitation, third party websites and feeds). You also
                            hereby do and shall grant each user of the Website, the Applications, or the Services a
                            non-exclusive license to access your User Generated Content through the Website, the
                            Applications or the Services, and share your User Generated Content on blogs and social
                            media, provided such sharing prominently links back to the model page on the Truventor site
                            and is not done on site that competes directly or indirectly with Truventor Services. For
                            clarity, the foregoing license grants to us and our users does not affect your other
                            ownership or license rights in your User Generated Content, including the right to grant
                            additional licenses to your User Generated Content, unless otherwise agreed in writing. You
                            represent and warrant that you have all rights to grant such licenses to us without
                            infringement or violation of any third party rights, including without limitation, any
                            privacy rights, publicity rights, copyrights, contract rights, or any other intellectual
                            property or proprietary rights.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Intellectual Property Rights of 3D Designs
                        </h4>
                        <hr>
                        <p>
                            You, as a designer, retain all your intellectual property rights in your 3D Models,
                            including without limitation any and all derivative works like 3D renders. Except for the
                            rights and licenses with respect to your 3D Models specified below, Truventor shall not use,
                            modify or display your 3D Models or derivatives thereof unless exclusively discussed and
                            agreed upon. By uploading your 3D Models to the Services, you represent and warrant that it
                            is your original creation and not copied from any third party and/or entity. You further
                            represent and warrant that you have all rights to grant the below licenses to us without
                            infringement or violation of any third party rights, including without limitation, any
                            privacy rights, publicity rights, copyrights, trademarks, contract rights, or any other
                            intellectual property or proprietary rights.
                            <br>
                            By uploading your 3D Model to the Services, you grant Truventor a non-exclusive, royalty
                            free, worldwide, transferable, and sublicensable right and license (i) to use your 3D Model
                            for the manufacturing of your 3D Model by in house capacity or manufacturing partners in
                            order to fulfill your order; (ii) to generate and display 3D renders of your 3D Model; and
                            (iii) to use the 3D Model as necessary for the operation and maintenance of Truventor
                            Services including without limitation for the internal testing and educational purposes of
                            Truventor and Truventor manufacturing partners.(iv) Use the 3D model for marketing and
                            promotion of Truventor In all ways but not limited to website, flyers, brochures, public
                            events etc.
                            <br>
                            Except for your 3D Models, which are referenced below, by submitting User Generated Content
                            through the Services or Website, you hereby do and shall grant us a worldwide,
                            non-exclusive, royalty-free, fully paid, sublicensable and transferable license to use,
                            edit, modify, reproduce, distribute, prepare derivative works of, display, perform, and
                            otherwise fully exploit the User Generated Content in connection with the Website, the
                            Applications, the Services and our (and our successors’ and assigns’) businesses, including
                            without limitation for promoting and redistributing part or all of the Website, the
                            Applications or the Services (and derivative works thereof) in any media formats and through
                            any media channels (including, without limitation, third party websites and feeds). You also
                            hereby do and shall grant each user of the Website, the Applications, or the Services a
                            non-exclusive license to access your User Generated Content through the Website, the
                            Applications or the Services, and share your User Generated Content on blogs and social
                            media, provided such sharing prominently links back to the model page on the Truventor site
                            and is not done on site that competes directly or indirectly with Truventor Services. For
                            clarity, the foregoing license grants to us and our users does not affect your other
                            ownership or license rights in your User Generated Content, including the right to grant
                            additional licenses to your User Generated Content, unless otherwise agreed in writing. You
                            represent and warrant that you have all rights to grant such licenses to us without
                            infringement or violation of any third party rights, including without limitation, any
                            privacy rights, publicity rights, copyrights, contract rights, or any other intellectual
                            property or proprietary rights.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Indemnification
                        </h4>
                        <hr>
                        <p>
                            You shall defend, indemnify, and hold harmless us, our affiliates and each of our and their
                            respective employees, contractors, directors, suppliers and representatives from all
                            liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or
                            relate to your use or misuse of, or access to, the Services, content, or otherwise from your
                            User Generated Content, products, , violation of these Terms and Conditions, or infringement
                            by you, or any third party using your Account or identity in the Services, of any
                            intellectual property or other right of any person or entity. We reserve the right to assume
                            the exclusive defense and control of any matter otherwise subject to indemnification by you,
                            in which event you will assist and cooperate with us in asserting any available defenses.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Limitation of Liability
                        </h4>
                        <hr>
                        <p>
                            IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, OR SUPPLIERS, BE
                            LIABLE UNDER ANY CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR
                            EQUITABLE THEORY WITH RESPECT TO THE SERVICES (I) FOR ANY LOSS OF INCOME, LOSS OF PROFITS,
                            LOSS OF CONTRACTS, LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR FOR
                            ANY INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, PUNITIVE, COMPENSATORY OR CONSEQUENTIAL LOSS
                            OR DAMAGE OF ANY KIND (HOWSOEVER ARISING) , (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR
                            THE LIKE (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN
                            EXCESS OF (IN THE AGGREGATE) THE GREATER OF (A) THE FEE RECEIVED FROM YOU BY Truventor FOR
                            THE USE OF THE SERVICES DURING THE IMMEDIATELY PRECEDING THREE (3) MONTHS PERIOD OR (B) INR
                            5000.00.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Applicable Law
                        </h4>
                        <hr>
                        <p>
                            These Terms of Service shall be governed by the laws of Mahrashtra, India without regard to
                            the conflicts of law principles thereof that would apply the law of any jurisdiction other
                            than Maharashtra, India. You agree that any and all disputes arising from or relating to the
                            subject matter of these Terms of Service, including disputes relating to the validity
                            thereof, and any disputes related to the use of the Services, shall be governed by the
                            exclusive jurisdiction and venue of the state courts located Pune, Maharashtra, India.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Miscellaneous
                        </h4>
                        <hr>
                        <p>
                            (a) Force Majeure. We shall not be liable for any failure to perform our obligations
                            hereunder where such failure results from any cause beyond our reasonable control,
                            including, without limitation, mechanical, electronic or communications failure or
                            degradation.
                            <br>
                            <br>
                            (b) Assignment. These Terms of Service are personal to you, and are not assignable,
                            transferable or sublicensable by you except with our prior written consent. We may assign,
                            transfer or delegate any of our rights and obligations hereunder without consent.
                            <br>
                            <br>
                            (c) Agency. No agency, partnership, joint venture, or employment relationship is created as
                            a result of these Terms of Service and neither party has any authority of any kind to bind
                            the other in any respect.
                            <br>
                            <br>
                            (d) Notices. Unless otherwise specified in these Term, all notices under these Terms of
                            Service will be in writing and will be deemed to have been duly given when received, if
                            personally delivered or sent by certified or registered mail, return receipt requested; when
                            receipt is electronically confirmed, if transmitted by facsimile or e-mail; or the day after
                            it is sent, if sent for next day delivery by recognized overnight delivery service.
                            Electronic notices should be sent to care@truventor.ai
                            <br>
                            <br>
                            (e) No Waiver. Our failure to enforce any part of these Terms shall not constitute a waiver
                            of our right to later enforce that or any other part of these Terms. Waiver of compliance in
                            any particular instance does not mean that we will waive compliance in the future. In order
                            for any waiver of compliance with these Terms to be binding, we must provide you with
                            written notice of such waiver through one of our authorized representatives.
                            <br>
                            <br>
                            (f) Headings. The section and paragraph headings in these Terms are for convenience only and
                            shall not affect their interpretation.
                            <br>
                            <br>
                            (g) Feedback. Since we always want to further improve our Services, we welcome all comments,
                            suggestions, recommendations and feedback (collectively, the “Feedback”). You hereby grant
                            to us a world-wide, royalty free, irrevocable, perpetual license to use and otherwise
                            incorporate any Feedback in connection with the Services.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Contact Information
                        </h4>
                        <hr>
                        <p>
                            If you have any questions regarding these Terms and Conditions or the Services, you can
                            contact us by sending an email at care@truventor.ai
                            <br>
                            SHOULD YOU EXPERIENCE ANY PROBLEMS, PLEASE CONTACT US AND WE WILL DO OUR BEST TO FIX IT AS
                            SOON AS POSSIBLE. Truventor MAY CONTACT YOU TO ASK YOU ABOUT YOUR EXPERIENCES.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Content Policy
                        </h4>
                        <hr>
                        <p>
                            Our goal is to let the Truventor community be as free as possible in sharing their
                            creativity with others. We want to be an inspiring place, and a free flow of ideas, designs,
                            and conversation is an essential element of this. So is striving to be nice, civil and
                            helpful. In light of that, we have a few common sense rules that we ask you to keep in mind.
                            These especially relate to copyrights and keeping Truventor appropriate for a general
                            audience. Users of the Truventor Service are responsible for making sure their content
                            complies with this Content Policy.
                        </p>
                    </div>

                    <div class="mb-5">
                        <h4>
                            Copyrights
                        </h4>
                        <hr>
                        <p>
                            As specified in our terms & conditions, we expect that the creator of a 3D design hold the
                            copyrights to the content. Hence, please only upload your own original work, work that is
                            freely available through a Creative Commons license, or work that does not infringe on the
                            rights of another copyright holder.
                            Be nice! This sounds simple but lots of mistakes happen when people ignore this simple rule.
                            We would like everyone to make whatever they like, as 3D printing is a technology that
                            enables innovation and the possibility to make completely new things is unlimited. However,
                            we do take copyright seriously and are legally obliged to respond to any copyright
                            infringement notices we receive.
                            <br>
                            <br>
                            Please do not infringe other people's intellectual property rights. (Intellectual property
                            rights means copyright, patent, registered design, design right, trade mark or trade
                            secret.)
                            <br>
                            <br>
                            We live in a culture of re-mix, re-use, and mashup but a good rule of thumb here is that if
                            a person does not clearly give you the right to use their design, invention, logo, text,
                            slogan etc. then you do not have the right to use it. If you do obtain work through a
                            permissive license, please comply with the terms of that license. For example, if you are
                            relying on a Creative Commons attribution license, please always give the originator of the
                            material credit for their work. Please familiarize yourself with the legalities behind it if
                            you are planning to rely on fair use to make of works created by others.
                        </p>
                    </div>

                </div>
            </div>

        </div>
    </div>
</section>