<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_template/Group 19439.jpg" class="img-banner d-none d-md-block" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Nylon-12 (PA 2200)
                    </h1>
                    <p class="text-primary">
                        3D Printing | SLS
                    </p>
                    <p class="mt-4">
                        Strong & flexible plastic with excellent mechanical properties for fully functional parts
                    </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19439.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Nylon-12 (PA 2200).pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>
        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Nylon or PA2200 is a polyamide 12 compound. Nylon is white in color and comes with an inherent grainy surface finish. Since Nylon parts are fabricated using Selective Laser Sintering technology, there is no support structure generation, making it suitable
                    for parts which are complex and which have intricate geometries and moving part assemblies.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Nylon-12 (PA 2200)
                    </strong>
                </h6>
                <img src="./assets/img/material_template/Group 19206.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>
                                Natural Color
                            </th>
                            <td>
                                White
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-) 200µm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                340 x 340 x 600 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships In
                            </th>
                            <td>
                                1-2 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Strong and flexible
                        </p>
                        <p class="mb-2">
                            No support structures
                        </p>
                        <p class="mb-2">
                            FDA approved
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Inherent grainy surface finish
                        </p>
                        <p class="mb-2">
                            Natural white color may dull after repeated use
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Fully functional parts
                        </p>
                        <p class="mb-2">
                            Best suited for snap-fit assemblies and moving parts
                        </p>
                        <p class="mb-2">
                            Low volume production runs
                        </p>
                        <p class="mb-2">
                            Complex and intricate geometries
                        </p>
                        <p class="mb-2">
                            Automotive, medical, packaging industries
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <div class="row">
                    <div class="col-12 col-md-8">
                        <h1 class="mb-5">
                            3D Printing Design Guidelines For NYLON-12 (PA 2200)
                        </h1>
                    </div>
                </div>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">
                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/nylon-12/Wall Thickness@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">1mm</span>. For decent strength: <span class="text-primary">1.5mm</span> & For rigid wall: <span class="text-primary">2.5mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/nylon-12/Minimum Detail Size@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Minimum detail size
                        </h6>
                        <p class="mt-2">
                            The minimum supported feature thickness: At least <span class="text-primary">0.8 mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/nylon-12/Embossing And Engraving@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Embossing and Engraving:
                        </h6>
                        <p class="mt-2">
                            Letter strand width: at least <span class="text-primary">0.6mm</span> <br> Embossing height: at least <span class="text-primary">0.6mm</span> <br> Engraving (cavity) depth: at least
                            <span class="text-primary">0.6mm</span> <br>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/nylon-12/Moving & Interlocking Geometries@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Moving & Interlocking geometries
                        </h6>
                        <p class="mt-2">
                            We recommend a clearance of around <span class="text-primary">0.5mm</span> on the diameter for moving members fabricated in one single go
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3" data-aos="fade-up">
                        <!-- Image -->
                        <img src="./assets/img/material_list/nylon-12/Escape Holes@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Escape Holes
                        </h6>
                        <p class="mt-2">
                            To remove the unsintered powdering a hollow part, a small aperture needs to be provided. Such an aperture in 3D Printing is called as an escape hole. The diameter of an escape hole should be at least <span class="text-primary">5 mm</span>.
                            <br> If the model is complex, multiple escape holes may be needed to remove the unsintered powder successfully.
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<!-- post_processing -->
<section class="post_processing global-space-between">
    <div class="container">
        <div class="row justify-content-end">
            <!-- Heading -->
            <div class="col col-12 col-md-6 pr-md-5">
                <h1>
                    POST- <br>PROCESSING
                </h1>
                <p>
                    Some of the post-processing options available for Nylon-12 (PA 2200) parts are as follows:
                </p>
                <hr>
                <h6>
                    Vibratory Polishing
                </h6>
                <hr>
                <h6>
                    Painting (options)
                    <!-- Color boxes -->
                    <div class="color-options">
                        <div class="option red"></div>
                        <div class="option cyan"></div>
                        <div class="option yellow"></div>
                        <div class="option black"></div>
                    </div>
                </h6>
                <hr>
                <h6>
                    Dyeing
                    <!-- Color boxes -->
                    <div class="color-options">
                        <div class="option red"></div>
                        <div class="option cyan"></div>
                        <div class="option yellow"></div>
                        <div class="option black"></div>
                    </div>
                </h6>
                <hr>
                <a [routerLink]="['/contact']" rel="noopener" class="btn my-4">
                    Get In Touch
                </a>
            </div>
            <!-- Image -->
            <div class="col col-12 col-md-6">
                <img src="./assets/img/material_template/Group 19464.jpg" class="img-fluid w-100" alt="Truventor">
            </div>
        </div>
    </div>
</section>

<app-cost-estimate></app-cost-estimate>