<!-- Main -->
<section class="main-session material-template-main">
    <img src="./assets/img/material_template/Group 19445.jpg" class="img-banner d-none d-md-block" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-6">
                <div class="heading">
                    <h1>
                        Rubber-Like (Tango)
                    </h1>
                    <p class="text-primary">
                        3D Printing
                    </p>
                    <p class="mt-4">
                        This rubber-like material is perfect for part applications requiring varying shore hardness between 30A to 95A. </p>
                </div>
            </div>
        </div>

        <!-- Mobile Image -->
        <div class="row d-block d-md-none">
            <div class="col-12">
                <img src="./assets/img/material_template/Group 19445.jpg" class="img-fluid" alt="Truventor">
            </div>
        </div>
        <!-- Mobile Image -->

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a href="/assets/pdf/datasheets/Rubber-Like (Tango).pdf" target="_blank" class="btn btn-primary w-100">
                    Download Material Datasheet
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Overview -->
<section class="overview global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1>
                    Overview
                </h1>
                <p class="my-5">
                    Rubber-Like (Tango) is part of the Tango family which consists of a range of materials viz. Tango Plus, Tango BlackPlus etc. These are flexible materials which have shore hardness as low as 27-30A. Rubber-Like (Tango) material comes in either Yellowish-clear
                    or Black color, but it can be blended with Ultra Detail (Vero) photopolymer to attain a range of colors along with varying shore hardness (30A – 85A) and translucency.
                    <br> Rubber-Like (Tango) parts can be made matte or glossy during manufacturing itself. This usually eliminates any need for post-processing operations. Since Rubber-Like (Tango) parts are fabricated in Polyjet technology, they have
                    support structure generation. The supports are wax-like supports which can be removed manually or with the help of a water-jet.
                </p>
                <a [routerLink]="['/contact']" rel="noopener" class="btn btn-primary mb-5"> Get In Touch </a>
            </div>
            <!-- Right Size -->
            <div class="col col-12 col-md-6">
                <h6 class="pb-2">
                    <strong>
                        Rubber-Like (Tango)
                    </strong>
                </h6>
                <img src="./assets/img/material_template/Group 19453.jpg" class="img-fluid w-100" alt="material-image">
                <div>
                    <table class="table mt-4">
                        <tr>
                            <th>Natural Color</th>
                            <td>Blue / Black</td>
                        </tr>
                        <tr>

                            <th>
                                Accuracy
                            </th>
                            <td>
                                (+/-)100 -200µm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Maximum Bounding Box
                            </th>
                            <td>
                                490 x 390 x 200 mm
                            </td>
                        </tr>
                        <tr>
                            <th>
                                Ships in
                            </th>
                            <td>
                                6-7 business days
                            </td>
                        </tr>
                        <tr>
                            <th></th>
                            <td></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Attributes -->
<section class="attributes global-space-between">
    <div class="container">
        <div class="row">
            <!-- Left Side -->
            <div class="col col-12 col-md-6">
                <h1 class="mt-md-4">
                    Attributes
                </h1>
                <p class="my-5 my-md-4">
                    Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                </p>
                <a [routerLink]="['/contact']" class="btn mb-5"> Get In Touch </a>
            </div>
            <!-- Right Side -->
            <div class="col col-12 col-md-6 mt-4">

                <!-- One -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Highlights.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Highlights
                        </h5>
                        <p class="mb-2">
                            Highly flexible and elastic parts
                        </p>
                        <p class="mb-2">
                            Excellent feature retention capability & accuracy
                        </p>
                        <p class="mb-2">
                            Available in a variety of colours
                        </p>
                        <p class="mb-2">
                            Can be blended with rigid materials
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Two -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Limitations.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Limitations
                        </h5>
                        <p class="mb-2">
                            Unsuitable for mechanical applications
                        </p>
                        <p class="mb-2">
                            Parts may deform/warp when exposed to higher temperatures (greater than 45-50˚C) & loading conditions
                        </p>
                        <hr>
                    </div>
                </div>

                <!-- Three -->
                <div class="row">
                    <div class="col-2 col-md-1">
                        <img src="./assets/img/material_template/svg/Applications.svg" class="img-fluid" alt="Truventor">
                    </div>
                    <div class="col-10 col-md-10">
                        <h5 class="mb-2">
                            Applications
                        </h5>
                        <p class="mb-2">
                            Small aesthetic parts and display models with intricate features
                        </p>
                        <p class="mb-2">
                            Parts with multi-material capabilities
                        </p>
                        <p class="mb-2">
                            FMCG and packaging industries
                        </p>
                        <hr>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

<!-- design_guide_with_image -->
<section class="design_guide_with_image global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">

                <!-- Title -->
                <div class="row">
                    <div class="col-12 col-md-8">
                        <h1 class="mb-5">
                            3D Printing Design Guidelines For Rubber-Like (Tango)
                        </h1>
                    </div>
                </div>

                <!-- Image Cards -->
                <div class="row row-cols-1 row-cols-md-2 mt-5">


                    <!--  -->
                    <div class="col p-3 py-3">
                        <!-- Image -->
                        <img src="./assets/img/material_list/rubber-tango/Wall Thickness@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Wall thickness
                        </h6>
                        <p class="mt-2">
                            For a given part geometry, the minimum wall thickness should be at least <span class="text-primary">2mm</span>. For decent strength: <span class="text-primary">3mm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3">
                        <!-- Image -->
                        <img src="./assets/img/material_list/rubber-tango/Minimum Detail size@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Minimum detail size
                        </h6>
                        <p class="mt-2">
                            The minimum supported feature thickness should be: At least <span class="text-primary">1mm</span>.
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3">
                        <!-- Image -->
                        <img src="./assets/img/material_list/rubber-tango/Embossing And Engraving@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Embossing and Engraving:
                        </h6>
                        <p class="mt-2">
                            Letter strand width: at least <span class="text-primary">0.7mm</span> Embossing height: at least <span class="text-primary">0.7mm</span> Engraving (cavity) depth: at least <span class="text-primary">0.7mm</span>
                        </p>
                    </div>

                    <!--  -->
                    <div class="col p-3 py-3">
                        <!-- Image -->
                        <img src="./assets/img/material_list/rubber-tango/Support Structures@2x.jpg" class="img-fluid" alt="Truventor">
                        <!-- Title -->
                        <h6 class="mt-4">
                            Support Structures
                        </h6>
                        <p class="mt-2">
                            Since Rubber-Like (Tango) parts are fabricated in Polyjet technology, support structures are generated enveloping the part geometries.
                        </p>
                    </div>

                </div>


            </div>
        </div>
    </div>
</section>


<!-- Looking for a cost estimate? -->
<app-cost-estimate></app-cost-estimate>