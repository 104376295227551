import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alumnium-a7075',
  templateUrl: './alumnium-a7075.component.html',
  styleUrls: ['./alumnium-a7075.component.scss']
})
export class AlumniumA7075Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' })

  }

}
