<!-- Main -->
<section class="main-session">
    <img src="assets/img/quality-assurance/Group 19421.jpg" class="img-banner d-none d-md-block" alt="Truventor">
    <img src="assets/img/quality-assurance/Mask Group 162.jpg" class="img-banner d-block d-md-none" alt="Truventor">

    <div class="container">

        <!-- Heading -->
        <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-7">
                <div class="heading">
                    <h1>
                        Quality assurance
                    </h1>
                    <p>Truventor quality assurance ensures that the final product meet customer expectations.</p>
                </div>
            </div>
        </div>

        <!-- Action Buttons -->
        <div class="row">
            <div class="col-sm-12 col-md-4">
                <a [routerLink]="['/contact']"  rel="noopener" class="btn btn-primary w-100">
                    Get In Touch
                </a>
            </div>
        </div>

        <!-- End -->
        <div class="row end d-none d-md-block">
            <div class="border-line">
            </div>
        </div>
    </div>
</section>

<!-- Digitally manage -->
<section class="global-space-between">
    <div class="container">
        <div class="row">
            <div class="col col-12 col-md-6">
                <h1 class="d-block d-md-none">
                    Digitally manage your suppliers
                </h1>
                <div class="d-flex justify-content-center align-items-center h-100 pb-5 pb-md-0">
                    <img src="./assets/img/quality-assurance/svg/Increase Transparency.svg" class="img-fluid w-75" alt="how it works">
                </div>
            </div>
            <div class="col col-12 col-md-6">
                <h1 class="d-none d-md-block">
                    Digitally manage your suppliers
                </h1>

                <p class="mt-5">
                    We deliver quality parts that meet your requirements. In an unlikely event of nonconformance, we will happily repair or remake your parts.
                </p>

                <div class="row mt-5">
                    <div class="col">
                        <h5 class="font-weight-bold"> FREE </h5>
                        <p>
                            Shipping In India
                        </p>
                    </div>
                    <div class="col">
                        <h5 class="font-weight-bold"> No </h5>
                        <p>
                            Minimum Order Quantity
                        </p>
                    </div>
                    <div class="col">
                        <h5 class="font-weight-bold"> 100% </h5>
                        <p>
                            Quality Guaranteed
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Trusted Customers -->
<section class="clients-list global-space-between">
    <div class="container">
        <div class="row">
            <div class="col">
                <h1 class="text-center py-4">
                    Trusted customers
                </h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-10">
                <app-clients-list></app-clients-list>
            </div>
        </div>

    </div>
</section>

<!-- Foundation of quality -->
<section class="foundation-of-quality global-space-between">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="content">
                    <h1>Foundation <br> of quality <br> assurance</h1>
                    <p>
                        Truventor strives to achieve best-in-class quality though a systematic approach at every stage of part manufacturing.
                    </p>
                </div>
            </div>
            <div class="col-12 col-md-6">

                <div class="row row-cols-2 row-cols-md-2">

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 1 </div>
                        <hr>
                        <h5>
                            ISO 9001:2015 certification
                        </h5>
                        <p>
                            Quality certificate to serve the global needs.
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 2 </div>
                        <hr>
                        <h5>
                            Inspection report
                        </h5>
                        <p>
                            Quality inspection report (On request)
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 3 </div>
                        <hr>
                        <h5>
                            Non-disclosure agreements
                        </h5>
                        <p>
                            Legally binding non-disclosure agreements (NDAs)
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 4 </div>
                        <hr>
                        <h5>
                            DFM
                        </h5>
                        <p>
                            Automated manufacturing feasibility analysis
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 5 </div>
                        <hr>
                        <h5>
                            Certified benchmarks
                        </h5>
                        <p>
                            Truventor-certified quality and manufacturing benchmarks
                        </p>
                    </div>

                    <div class="col p-3" data-aos="fade-up">
                        <div class="circle"> 6 </div>
                        <hr>
                        <h5>
                            Analysis
                        </h5>
                        <p>
                            Analysis of root cause in case of nonconformance
                        </p>
                    </div>

                </div>

            </div>
        </div>
    </div>
</section>

<!-- Talk to Experts -->
<section class="talk-to-expert-quality-assurance global-space-between bottom-end-padding">
    <div class="container-fluid">
        <div class="row">
            <!-- Desktop Image -->
            <div class="col-sm-12 col-md-6 p-0 d-none d-md-block">
                <img src="./assets/img/quality-assurance/Group 19423.jpg" class="img-fluid" width="100%" alt="Truventor">
            </div>
            <!-- Text & Action Button -->
            <div class="col-sm-12 col-md-6 pl-md-5 p-0 d-flex align-items-center">
                <div class="col-sm-12 col-md-8">
                    <!-- Title -->
                    <div>
                        <h1 class="text-center text-md-left">
                            Talk to experts
                        </h1>
                        <p>
                            For any quality-related queries, get in touch with our experts.
                        </p>
                    </div>

                    <!-- Mobile Image -->
                    <div class="col-sm-12 col-md-6 p-0 d-block d-md-none">
                        <img src="./assets/img/quality-assurance/Group 19423.jpg" class="img-fluid" width="100%" alt="Truventor">
                    </div>

                    <div class="form-group">
                        <label for="name" class="mt-3"> Name </label>
                        <input type="text" name="name" id="name" class="form-control" placeholder="John">
                        <label for="email" class="mt-3"> Business E-mail </label>
                        <input type="text" name="email" id="email" class="form-control" placeholder="email@company.com">
                        <label for="number" class="mt-3"> Phone Number </label>
                        <input type="text" name="number" id="number" class="form-control" placeholder="eg. AAFCC8360F">
                        <button class="btn btn-primary btn-block mt-5"> Submit </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>