import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../shared/admin.service';
import { reloadTime, THREED_PRINTING_MATERIAL } from '../../shared/data/index';
declare var $: any;

@Component({
  selector: 'app-online3dprinting',
  templateUrl: './online3dprinting.component.html',
  styleUrls: ['./online3dprinting.component.scss']
})
export class Online3dprintingComponent implements OnInit {
  private fragment: string;
  public threedPrintingMaterial = THREED_PRINTING_MATERIAL;

  constructor(
    private adminservice: AdminService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.fragment.subscribe(fragment => {
      this.fragment = fragment; 
      console.log(this.fragment)
      if (this.fragment != '3dprinting-material') {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      } else {
        setTimeout(() => {
          document.getElementById('3dprinting-material').scrollIntoView();          
        }, reloadTime);
      }
    });

    this.adminservice.check_customer.next('customer');

    $("#surface-finishing-cnc-machine").on("hide.bs.collapse show.bs.collapse", e => {
      $(e.target)
        .prev()
        .find("i:last-child")
        .toggleClass("fa-minus fa-plus");
    });

  }


  // ngAfterViewInit(): void {
  //   try {
  //     document.querySelector('#' + this.fragment).scrollIntoView(
  //       {
  //         behavior: 'smooth'
  //       }
  //     );
  //   } catch (e) { }
  // }


  vacuumCasting() {
    this.router.navigate(['/vacuum-casting']);
  }

  injectionMolding() {
    this.router.navigate(['/plastic-injection-moulding']);
  }

  metalCasting() {
    this.router.navigate(['/metal-casting']);
  }

  cncmachining() {
    this.router.navigate(['/cnc-machining']);
  }

  sheetMetal() {
    this.router.navigate(['/sheet-metal-fabrication']);
  }


}



export interface THREED_PRINTING_MATERIAL {
  id: Number,
  name: String,
  label: String,
  description: String,
  imgUrl: String
}